/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Group } from "~/generated/graphql";

export function extractTagsExpression(group?: Partial<Group>) {
  return (group?.resourceSelectors ?? []).find(
    (selector) => selector?.tagsExpression
  )?.tagsExpression;
}
