/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, useMemo, useState } from "react";
import { isNotEmpty, sortItem } from "~/tools";
import { Customer, MappedCustomer } from "~/types";

type Props = {
  onSubmit: (id: string, name: string, orgName: string) => void;
  customers: Customer[];
};

export function useCustomerSelectorForm({ onSubmit, customers }: Props) {
  const [search, setSearch] = useState("");

  const onCustomerSelected = (id: string, name: string, orgName: string) => {
    onSubmit(id, name, orgName);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const resetSearch = () => setSearch("");

  const sortedCustomers = useMemo(() => {
    const aggregatedCustomers = Object.values(
      customers.reduce<{
        [key: string]: MappedCustomer;
      }>((store, customer) => {
        return {
          ...store,
          [customer.organizationName]: store[customer.organizationName]
            ? {
                ...store[customer.organizationName],
                tenants: [
                  ...store[customer.organizationName].tenants,
                  customer,
                ],
              }
            : {
                organizationName: customer.organizationName,
                tenants: [customer],
              },
        };
      }, {})
    );

    const sortedTenants = aggregatedCustomers.map((customer) => {
      return {
        ...customer,
        tenants: sortItem(customer.tenants, ({ name: a }, { name: b }) =>
          a.localeCompare(b)
        ),
      };
    });

    return sortItem(
      sortedTenants,
      ({ organizationName: a }, { organizationName: b }) => {
        if (b.length === 0) {
          return -1;
        }
        if (a.length === 0) {
          return 1;
        }
        return a.localeCompare(b);
      }
    );
  }, [customers]);

  const filteredCustomers = useMemo(() => {
    const isTenantNameIncludesSearch = (tenant: Customer) =>
      tenant.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());

    return sortedCustomers
      .filter(
        (customer) =>
          customer.organizationName
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          isNotEmpty(
            (customer.tenants ?? []).filter(isTenantNameIncludesSearch)
          )
      )
      .map((customer) => ({
        ...customer,
        tenants: customer.organizationName
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
          ? customer.tenants
          : customer.tenants.filter(isTenantNameIncludesSearch),
      }));
  }, [search, sortedCustomers]);

  return {
    onCustomerSelected,
    onSearchChange,
    resetSearch,
    filteredCustomers,
  };
}
