/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { createCtx } from "~/tools";
import { CustomActionData } from "./constants";

type Props = {
  customActionData?: CustomActionData;
  setCustomActionData: Dispatch<SetStateAction<CustomActionData>>;
};

export const [useCustomActionWizard, CustomActionWizardCtxProvider] =
  createCtx<Props>();
