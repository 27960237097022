/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { useHistory } from "react-router-dom";
import {
  Heading,
  Message,
  Spacer,
  SVGIcon,
  Box,
  Text,
  FlexContainer,
  Button,
} from "@nordcloud/gnui";
import { InfoCard } from "~/components";
import { ROUTES } from "~/routing/routes";

type Props = {
  accountId: string;
  onAddNewAccount: () => void;
  onEditCredentials: () => void;
  step: number;
  accountCredentials: CredentialProps[];
};

export function ConfirmAndFinalize({
  accountId,
  onAddNewAccount,
  onEditCredentials,
  step,
  accountCredentials,
}: Props) {
  const history = useHistory();

  return (
    <>
      <When condition={step === 0}>
        <Row justify="center">
          <Col sm={6} md={6} lg={8}>
            <Heading marginBottom="spacing04" level={4}>
              Account Credentials
            </Heading>
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer alignItems="start" justifyContent="space-between">
                <div>
                  {accountCredentials.map((element) => (
                    <CredentialDetails key={element.label} {...element} />
                  ))}
                </div>
                <div>
                  <Button
                    aria-label="edit button"
                    icon="edit"
                    size="md"
                    severity="low"
                    onClick={onEditCredentials}
                  />
                </div>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </When>
      <When condition={step === 1}>
        <Row justify="center">
          <Col sm={6} md={6} lg={8}>
            <Spacer height="1rem" />
            <When condition={() => true}>
              <Row>
                <Col>
                  <Message status="success" image="success">
                    Account has been added
                  </Message>
                </Col>
              </Row>
              <Spacer height="2rem" />
            </When>
            <Row>
              <Col lg={4}>
                <InfoCard
                  align="center"
                  onClick={() => {
                    history.push(ROUTES.accounts.generateDetails(accountId));
                  }}
                  data-testid="Details"
                >
                  <SVGIcon name="detailsView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    Account Details
                  </Heading>
                </InfoCard>
              </Col>
              <Col lg={4}>
                <InfoCard
                  align="center"
                  onClick={() => history.push(ROUTES.accounts.index)}
                  data-testid="List"
                >
                  <SVGIcon name="listView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    List of Accounts
                  </Heading>
                </InfoCard>
              </Col>
              <Col lg={4}>
                <InfoCard
                  align="center"
                  onClick={onAddNewAccount}
                  data-testid="Add another"
                >
                  <SVGIcon name="wizardView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    Add Another Account
                  </Heading>
                </InfoCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </When>
    </>
  );
}

type CredentialProps = {
  label: string;
  value: string;
};

function CredentialDetails({ label, value }: CredentialProps) {
  return (
    <>
      <Text size="sm" mb={0}>
        {label}
      </Text>
      <Text size="md">{value}</Text>
    </>
  );
}
