/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { AccountCreateWizard } from "./components";

export function AccountCreate() {
  return (
    <>
      <BreadcrumbsBox
        title="Add Account"
        customBreadcrumbs={[
          { label: "Dashboard", uri: "/" },
          { label: "Cloud Accounts", uri: ROUTES.accounts.index },
          { label: "Add Account", uri: ROUTES.accounts.create },
        ]}
      />
      <AccountCreateWizard />
    </>
  );
}
