/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useHistory, useParams } from "react-router-dom";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { DeleteCloudAccount } from "../../../components";
import { AccountRouteParams } from "./types";

export function Action() {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useDisclosure(false);

  const { accountId } = useParams<AccountRouteParams>();

  const history = useHistory();

  const onSuccess = () => history.push(ROUTES.accounts.index);
  return (
    <>
      <PopoverControls
        size="large"
        disabled={false}
        items={[
          {
            label: "Delete Account",
            onClick: openDeleteModal,
          },
        ]}
      />
      <DeleteCloudAccount
        {...{
          accountsIds: [accountId],
          isDeleteModalOpen,
          onSuccess,
        }}
        close={closeDeleteModal}
      />
    </>
  );
}
