/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, useState } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";
import { FlexContainer, Input, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import {
  defaultWindowDuration,
  PlanErrors,
  PlanField,
} from "../../../constants";
import { usePlanWizard } from "../../../PlanProvider";
import { convertToMinutes } from "../../../PlanScheduleForms/utils";
import { FormField, TimeUnits } from "../../../types";
import { DropDownUnit } from "./DropDownUnit";

type Props = {
  control: Control;
  handleChange: (
    name: FormField,
    onChange: (value: string | boolean) => void,
    value: string | boolean
  ) => void;
};

export function WindowDuration({ control, handleChange }: Props) {
  const { setError } = useFormContext();
  const { setPlanData } = usePlanWizard();
  const [durationUnit, setDurationUnit] = useState(TimeUnits.minutes);

  const handleValidationOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const windowDuration = Number(event.target.value);
    if (windowDuration <= 0) {
      setError(FormField.WINDOW_DURATION, {
        type: "min",
        message: MIN_DURATION_MESSAGE,
      });
      setPlanData((prevPlanData) => ({
        ...prevPlanData,
        [PlanField.ERRORS]: [
          ...new Set([
            ...(prevPlanData[PlanField.ERRORS] ?? []),
            PlanErrors.PLAN_ACTION_SETTINGS,
          ]),
        ],
      }));
    } else {
      setPlanData((prevPlanData) => ({
        ...prevPlanData,
        [PlanField.ERRORS]: prevPlanData[PlanField.ERRORS]?.filter(
          (errors) => errors !== PlanErrors.PLAN_ACTION_SETTINGS
        ),
      }));
    }
  };

  return (
    <FlexContainer mb={theme.spacing.spacing04} gap={theme.spacing.spacing03}>
      <div css={{ width: "10rem" }}>
        <Controller
          control={control}
          name={FormField.WINDOW_DURATION}
          render={({ field, formState }) => {
            const error = formState.errors[FormField.WINDOW_DURATION];

            return (
              <FormGroup error={error} customCss={{ position: "relative" }}>
                <Input
                  {...field}
                  value={field.value ?? defaultWindowDuration}
                  name={FormField.WINDOW_DURATION}
                  type="number"
                  id="activeWindowDuration"
                  onChange={(event) => {
                    const windowDuration = Number(event.target.value);

                    const windowDurationInMinutes = convertToMinutes(
                      windowDuration,
                      durationUnit
                    );

                    handleChange(
                      FormField.WINDOW_DURATION,
                      () => field.onChange(windowDurationInMinutes),
                      event.target.value
                    );
                  }}
                  onBlur={handleValidationOnBlur}
                />
              </FormGroup>
            );
          }}
        />
      </div>
      <div css={{ width: "10rem" }}>
        <FormGroup>
          <DropDownUnit {...{ handleChange, setDurationUnit }} />
        </FormGroup>
      </div>
    </FlexContainer>
  );
}

const MIN_DURATION_MESSAGE =
  "Window duration must be a whole number greater than 0";
