/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import {
  Box,
  ExtendedTooltip,
  FlexContainer,
  Message,
  Spacer,
  Tab,
  Tabs as TabContainer,
  theme,
  Text,
  Heading,
} from "@nordcloud/gnui";
import { StyledTab } from "~/components/Styled/Tabs";
import { TooltipText } from "~/components/Styled/TooltipText";
import { useResourceGroup } from "../ResourceGroupProvider";
import { MetadataTab } from "./MetadataTab/MetadataTab";
import { ResourcesTab } from "./ResourcesTab/ResourcesTab";

export function Tabs() {
  const [currentStep, setCurrentStep] = useState(0);

  const { expression } = useResourceGroup();

  return (
    <Box spacing="spacing02" css={{ padding: 0 }}>
      <TabContainer step={currentStep} handleTab={setCurrentStep}>
        <StyledTab heading="Resources">
          <Heading level={4}>List of Group Resources</Heading>
          <Text size="sm" color={theme.color.text.text02}>
            These are resources in the resource group. To edit the resource
            group, click{" "}
            <Text size="sm" as="span" weight="medium">
              Edit Group
            </Text>
          </Text>
          <When condition={expression}>
            <Message status="notification" image="info">
              <FlexContainer direction="column" alignItems="start">
                <Text
                  color={theme.color.text.info}
                  mb={theme.spacing.spacing01}
                >
                  Expression Provided
                </Text>
                <ExtendedTooltip caption={expression} position="center">
                  <TooltipText
                    size="md"
                    weight="regular"
                    color={theme.color.text.text01}
                  >
                    Show Expression
                  </TooltipText>
                </ExtendedTooltip>
              </FlexContainer>
            </Message>
            <Spacer height={theme.spacing.spacing03} />
          </When>
          <ResourcesTab />
        </StyledTab>
        <Tab heading="Metadata">
          <MetadataTab />
        </Tab>
      </TabContainer>
    </Box>
  );
}
