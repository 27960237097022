/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, Modal } from "@nordcloud/gnui";
import { LoaderWrap } from "~/components";
import { useDisclosure } from "~/hooks";
import { useUpdateNotificationGroup } from "~/views/notificationGroups/hooks/useUpdateNotificationGroup/useUpdateNotificationGroup";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";

type Props = {
  channelId: string;
  email: string;
};

export function DeleteEmail({ email, channelId }: Props) {
  const { data } = useNotificationGroup();
  const { isOpen, open, close } = useDisclosure();

  const [updateNotificationGroup, loading] = useUpdateNotificationGroup({
    onSuccess: () => {
      close();
    },
  });

  const onDelete = () => {
    updateNotificationGroup({
      id: data?.id,
      channelsToDelete: [channelId],
    });
  };

  return (
    <>
      <Button icon="trash" severity="low" onClick={open} size="md" />
      <Modal
        onClose={close}
        isOpen={isOpen}
        actionLabel="Delete"
        contentLabel="Delete email"
        actions={[
          {
            order: 0,
            onAction: close,
            label: "Close",
            severity: "low",
          },
          {
            order: 1,
            onAction: onDelete,
            label: "Delete",
            disabled: loading,
            severity: "high",
          },
        ]}
      >
        <LoaderWrap inContent loading={loading}>
          Are you sure you want to delete {email}?
        </LoaderWrap>
      </Modal>
    </>
  );
}
