/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { When } from "react-if";
import { Link } from "react-router-dom";
import { FlexContainer, Spacer, Tab, Tabs, Text, theme } from "@nordcloud/gnui";
import { DataDisplayWrapper } from "~/components";
import { ROUTES } from "~/routing/routes";
import { isNotNil } from "~/tools";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayBooleanProperties } from "~/views/events/components/DisplayResourceOperation";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { PlanEntityIncomingEventDetails } from "~/views/events/types";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";

type Props = {
  planEntity?: PlanEntityIncomingEventDetails;
  planId?: string;
};

export function IncomingEventActionSettingsSection({
  planEntity,
  planId,
}: Props) {
  const [tab, setTab] = useState(0);
  return (
    <>
      <Tabs step={tab} handleTab={setTab}>
        <Tab
          label={
            <span>
              <TabIcon caption="General Action Settings" icon="settings" />
            </span>
          }
          width="auto"
        >
          <Text weight="medium">General Action Settings</Text>
          <Text size="sm" tag="div">
            Action Name
          </Text>
          <FlexContainer direction="row" justifyContent="space-between">
            <DataDisplayWrapper hasData={planEntity?.action?.name != null}>
              <Text>{planEntity?.action?.name}</Text>
            </DataDisplayWrapper>
          </FlexContainer>
          <Spacer height={theme.spacing.spacing04} />
          <DisplayExecutionPolicy policy={planEntity?.executionPolicy} />
          <When condition={isNotNil(planEntity?.windowDuration)}>
            <DisplayWindowDuration
              windowDuration={planEntity?.windowDuration}
            />
          </When>
          <DisplayBooleanProperties
            title="Resource Operation"
            condition={planEntity?.runInSequence ?? false}
            options={["Sequenced", "Simultaneous"]}
          />
          <When condition={planEntity?.skipWindow}>
            <Text size="sm" tag="div">
              Ignore Window
            </Text>
            <Text mb={theme.spacing.spacing01}>
              {convertSkipWindow(planEntity?.skipWindow)}
            </Text>
            <Spacer height={theme.spacing.spacing04} />
          </When>

          <Link to={`${ROUTES.plans.index}/${planId}`}>
            Go to plan to edit settings
          </Link>
        </Tab>
      </Tabs>
    </>
  );
}
