/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import { Link } from "react-router-dom";
import { Spacer, Text, theme } from "@nordcloud/gnui";
import {
  EventAction,
  EventActionBatch,
  ExecutionPolicy,
  ExecutionStatus,
} from "~/generated/graphql";
import { ROUTES } from "~/routing/routes";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { ApproveEvent } from "~/views/events/components/ApproveEvent/ApproveEvent";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayBooleanProperties } from "~/views/events/components/DisplayResourceOperation";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { RefetchOptions, convertMinutesToTime } from "~/views/events/helpers";
import { EventEntity } from "~/views/events/types";
import { SystemActionNames } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";

export function GeneralActionSettings({
  eventEntity,
  planId,
  isBatchEntity,
}: {
  eventEntity?: EventEntity;
  planId?: string;
  isBatchEntity: boolean;
}) {
  const { event } = useEvent();

  const actions = event?.actions?.filter(
    (action: EventAction) =>
      action.globalState?.status === ExecutionStatus.ApprovalRequired
  );

  const batches = event?.actionBatches?.filter(
    (batch: EventActionBatch) =>
      batch.status === ExecutionStatus.ApprovalRequired
  );

  const isApproveButtonVisible = isBatchEntity
    ? event?.actionBatches
        ?.filter((batch: EventActionBatch) => batch.id === eventEntity?.id)
        ?.some(
          (batch: EventActionBatch) =>
            batch.status === ExecutionStatus.ApprovalRequired
        )
    : event?.actions
        ?.filter((eventA: EventAction) => eventA.id === eventEntity?.id)
        ?.some(
          (action: EventAction) =>
            action.globalState?.status === ExecutionStatus.ApprovalRequired
        );

  const isDelay = eventEntity?.action?.name === SystemActionNames.DELAY;

  return (
    <>
      <Text weight="medium">
        General {isBatchEntity ? "Batch" : "Action"} Settings
      </Text>
      <DisplayExecutionPolicy
        policy={eventEntity?.executionPolicy || ExecutionPolicy.Anyway}
      />
      <If condition={isDelay && eventEntity?.windowDuration}>
        <Then>
          <Text size="sm" tag="div">
            Action Delay
          </Text>
          <Text mb={theme.spacing.spacing01}>
            {convertMinutesToTime(Number(eventEntity?.windowDuration))}
          </Text>
          <Spacer height={theme.spacing.spacing03} />
        </Then>
        <Else>
          <When condition={eventEntity?.windowDuration}>
            <DisplayWindowDuration
              windowDuration={eventEntity?.windowDuration}
            />
          </When>
        </Else>
      </If>

      <When condition={eventEntity?.runInSequence}>
        <DisplayBooleanProperties
          title="Resource Operation"
          condition={eventEntity?.runInSequence}
          options={["Sequenced", "Simultaneous"]}
        />
      </When>
      {eventEntity && "skipWindow" in eventEntity && (
        <>
          <Text size="sm" tag="div">
            Ignore Window
          </Text>
          <Text mb={theme.spacing.spacing01}>
            {convertSkipWindow(eventEntity?.skipWindow)}
          </Text>
          <Spacer height={theme.spacing.spacing04} />
        </>
      )}
      <When condition={isApproveButtonVisible}>
        <ApproveEvent
          actions={isBatchEntity ? [] : actions?.slice(0, 1)}
          batches={isBatchEntity ? batches?.slice(0, 1) : []}
          eventId={event?.id}
          refetchQueries={RefetchOptions.EVENT}
        />
      </When>
      <Spacer height={theme.spacing.spacing02} />
      <Link to={`${ROUTES.plans.index}/${planId}`}>
        Go to plan to edit settings
      </Link>
    </>
  );
}
