/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Button, ExtendedTooltip } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { ActionLogModal } from "../Tabs/Details/components/ActionLogModal";

type Props = {
  outputUri?: string | null;
  actionLogLabel?: string;
  asIcon?: boolean;
};

export function LogsOutputButton({ outputUri, actionLogLabel, asIcon }: Props) {
  const { isOpen, open, close } = useDisclosure();
  const label = "Logs Output";

  const button = asIcon ? (
    <Button severity="low" icon="eventLog" onClick={open} />
  ) : (
    <Button severity="low" onClick={open}>
      {label}
    </Button>
  );
  return (
    <>
      <ExtendedTooltip caption={label} position="start">
        {button}
      </ExtendedTooltip>
      <When condition={isOpen}>
        <ActionLogModal
          close={close}
          isOpen={isOpen}
          actionOutputUrl={outputUri}
          actionLogLabel={actionLogLabel}
        />
      </When>
    </>
  );
}
