/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useState } from "react";
import { Else, If, Then, When } from "react-if";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  ActionBatchesFragment,
  EventActionFragment,
} from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { inflect, capitalize, isEmpty, sortItem } from "~/tools";
import { useApproveEvent } from "~/views/events/hooks";
import { RefetchOptions } from "../../helpers";

type Props = {
  eventId?: string;
  actions?: EventActionFragment[];
  batches?: ActionBatchesFragment["actionBatches"];
  hasIcon?: boolean;
  refetchQueries: RefetchOptions;
};

export function ApproveEvent({
  eventId,
  hasIcon,
  actions,
  batches,
  refetchQueries,
}: Props) {
  const { isOpen, open, close } = useDisclosure();
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const [selectedBatches, setSelectedBatches] = useState<string[]>([]);

  const sortedEntities = useMemo(() => {
    const batchItems = batches?.map((batch) => ({
      ...batch,
      actions: batch.actions.map((action) => ({
        ...action,
      })),
    }));

    const actionItems = actions?.map((action) => ({
      ...action,
    }));

    return sortItem(
      [...(batchItems ?? []), ...(actionItems ?? [])],
      (a, b) => a.order - b.order
    );
  }, [batches, actions]);

  const actionLength = sortedEntities?.length ?? 1;
  const actionLabel = inflect("action")(
    Math.max(actionLength, batches?.length ? 2 : 0)
  );

  const handleChange = (item: typeof sortedEntities[number]) => {
    const isEventActionBatch = item.__typename === "EventActionBatch";
    const selectedState = isEventActionBatch
      ? selectedBatches
      : selectedActions;
    const setSelectedState = isEventActionBatch
      ? setSelectedBatches
      : setSelectedActions;

    setSelectedState((prevState) =>
      selectedState.includes(item.id)
        ? prevState.filter((id) => id !== item.id)
        : [...prevState, item.id]
    );
  };

  const clean = () => {
    close();
    setSelectedActions([]);
    setSelectedBatches([]);
  };

  const {
    approveEventAction,
    approveEventActionBatch,
    actionLoading,
    batchLoading,
  } = useApproveEvent({
    onSuccess: clean,
    refetchQueries,
  });

  const handleApprove = () => {
    selectedActions.forEach((id) => {
      approveEventAction(eventId ?? "", id ?? "");
    });
    selectedBatches.forEach((id) => {
      approveEventActionBatch(eventId ?? "", id ?? "");
    });
  };

  useEffect(() => {
    if (actions?.length === 1 && !batches?.length) {
      setSelectedActions([actions[0].id]);
    } else if (batches?.length === 1 && !actions?.length) {
      setSelectedBatches([batches[0].id]);
    }
  }, [isOpen, actions, batches]);

  const loading = actionLoading || batchLoading;

  return (
    <>
      <When condition={!loading}>
        <Button
          icon={hasIcon ? "checkmark" : undefined}
          mr={theme.spacing.spacing02}
          status="accent"
          onClick={open}
          disabled={loading}
        >
          Approve {capitalize(actionLabel)}
        </Button>
        <Modal
          onClose={close}
          isOpen={isOpen}
          contentLabel={`Approve ${capitalize(actionLabel)}`}
          actions={[
            {
              order: 0,
              onAction: close,
              label: "Cancel",
              severity: "low",
            },
            {
              order: 1,
              onAction: handleApprove,
              label: "Approve",
              severity: "high",
              disabled: isEmpty(sortedEntities),
            },
          ]}
        >
          <If condition={actionLength > 1}>
            <Then>
              <Text>{`Please select ${actionLabel} to approve:`}</Text>
              {sortedEntities?.map((item) => (
                <div key={item.id} css={{ marginTop: theme.spacing.spacing04 }}>
                  <Checkbox
                    aria-labelledby={item?.name}
                    labelText={item.name}
                    id={item.id}
                    onChange={() => handleChange(item)}
                    checked={
                      item.__typename === "EventActionBatch"
                        ? selectedBatches?.includes(item.id)
                        : selectedActions?.includes(item.id)
                    }
                  />
                </div>
              ))}
            </Then>
            <Else>
              <Text tag="div">{`Are you sure you want to approve ${
                actions?.[0]?.name || batches?.[0]?.name
              }?`}</Text>
            </Else>
          </If>
          <Spacer height={theme.spacing.spacing06} />
          <Box boxStyle="lightGrey">
            <Text tag="div" size="sm" color={theme.color.text.text02}>
              The status of the corresponding event will update within
              approximately 1 minute.
            </Text>
          </Box>
        </Modal>
      </When>
    </>
  );
}
