/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Switch, Case, Default } from "react-if";
import { Button, Sidebar, theme } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { useCloudAccount } from "../../../../CloudAccountProvider";
import { AwsForm } from "./AwsForm";
import { AzureForm } from "./AzureForm";

export function CredentialEditSidebar() {
  const { isOpen, open, close } = useDisclosure();
  const { cloudAccount } = useCloudAccount();

  return (
    <>
      <Button
        mr={theme.spacing.spacing04}
        severity="low"
        icon="edit"
        onClick={open}
      >
        Edit Credentials
      </Button>
      <Sidebar title="Edit Credentials" isOpen={isOpen} onClick={close}>
        <Switch>
          <Case condition={cloudAccount?.provider === "AWS"}>
            <AwsForm />
          </Case>
          <Case condition={cloudAccount?.provider === "AZURE"}>
            <AzureForm />
          </Case>
          <Default>Feature not available on this account type</Default>
        </Switch>
      </Sidebar>
    </>
  );
}
