/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { RoleBindingByUser } from "~/generated/graphql";
import { LoaderWrap } from "~/components/Loader";
import { Pagination } from "~/components/Pagination";
import { TableWrapper } from "~/components/Tables";
import { usersColumns } from "./UsersTableColumns";

type Props = {
  loading: boolean;
  data: RoleBindingByUser[];
  count: number;
};
export function UsersTable({ loading, data, count }: Props) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  return (
    <>
      <LoaderWrap loading={loading} inContent>
        <TableWrapper<Partial<RoleBindingByUser & { id: string }>>
          loading={loading}
          data={data}
          columns={usersColumns()}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          stripped
        />
        <Pagination count={count} />
      </LoaderWrap>
    </>
  );
}
