/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Channel } from "~/generated/graphql";
import { NoData } from "~/components/NoData";
import { TableWrapper } from "~/components/Tables";
import { isEmpty, isNotNil } from "~/tools";
import {
  Channels,
  ChannelsTypes,
  filterWebhookChannelByType,
} from "~/views/notificationGroups/components/helpers";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";
import { columns } from "./SlackTableColumns";

export function SlackTable() {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data } = useNotificationGroup();
  if (data === undefined) {
    return <NoData message="no data" />;
  }

  const { webhookChannel } = filterWebhookChannelByType(
    data?.channels ?? [],
    Channels.SLACK
  );

  const slackData = webhookChannel.map((channel) => ({
    webhookUrl:
      channel.typeProperties.__typename === ChannelsTypes.WEBHOOK
        ? channel.typeProperties.url
        : "",
    id: channel.id,
  }));

  return (
    <If condition={isEmpty(webhookChannel)}>
      <Then>
        <NoData hasIcon message={"There are no Slack Channels."} />
      </Then>
      <Else>
        <TableWrapper<Partial<Channel>>
          loading={false}
          data={slackData.filter(isNotNil)}
          columns={columns()}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          stripped
        />
      </Else>
    </If>
  );
}
