/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { When } from "react-if";
import { z } from "zod";
import { Button, Input, Label, Sidebar, Spacer, theme } from "@nordcloud/gnui";
import { RbacRoleBindingType } from "~/generated/graphql";
import { FormGroup, stringRequired } from "~/components/Forms";
import { useDisclosure } from "~/hooks";
import { showError, showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotEmpty, isNotNil } from "~/tools";
import { useCreateRoleBinding } from "~/views/permissions/hooks/useCreateRoleBinding/useCreateRoleBinding";
import { SelectRolesSidebar } from "../../components/SelectRolesSidebar";
import { useDeleteRoleBinding } from "../../hooks/useDeleteRoleBinding/useDeleteRoleBinding";
import { FormData } from "../formConfig";

function refineRoles(el: string[]): boolean {
  return el.every((i) => i.length > 1);
}

export const schema = z.object({
  subject: stringRequired("Email"),
  roleIds: z
    .string()
    .array()
    .refine((val) => refineRoles(val), { message: "Role is required" }),
});

type Props = {
  email: string;
  roleIds: string[];
};

export function UserEdit({ email, roleIds }: Props) {
  const { isOpen, open, close } = useDisclosure();

  const {
    handleSubmit,
    register,
    formState,
    clearErrors,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      subject: email,
      roleIds: roleIds,
    },
  });

  const watcher = watch();

  const compareRole = roleIds.filter((role) => !watcher.roleIds.includes(role));
  const bindingsToDelete = compareRole.map((role) => `user__${email}__${role}`);

  const showUserRolesUpdateSuccess = () => {
    showSuccess(generateActionSuccessText("User roles")()("updated")());
  };

  const [deleteRoleBinding] = useDeleteRoleBinding({
    onSuccess: showUserRolesUpdateSuccess,
  });

  const clean = () => {
    clearErrors();
    reset({
      subject: "",
      roleIds: [""],
    });
    close();
    showUserRolesUpdateSuccess();
  };

  const [createRoleBinding, { loading }] = useCreateRoleBinding({
    onSuccess: clean,
  });

  const onSubmit = (formData: FormData) => {
    if (isNotNil(formData.roleIds)) {
      Promise.all(bindingsToDelete.map((id) => deleteRoleBinding(id)))
        .then(() =>
          Promise.all(
            formData.roleIds
              .filter((id) => !roleIds.includes(id))
              .map((id) =>
                createRoleBinding({
                  roleId: id,
                  subject: formData.subject ?? "",
                  type: RbacRoleBindingType.User,
                })
              )
          )
        )
        .catch(() => showError());
    }
  };

  const closeSidebar = () => {
    reset({
      subject: email,
      roleIds: roleIds,
    });
    close();
  };

  return (
    <>
      <Button
        aria-label="edit button"
        icon="edit"
        size="md"
        severity="low"
        onClick={open}
      />
      <When condition={isOpen}>
        <Sidebar title="Edit User" isOpen={isOpen} onClick={closeSidebar}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup error={formState.errors["subject"]}>
              <Label name="Email" htmlFor="subject" required />
              <Input disabled id="subject" {...register("subject")} />
            </FormGroup>
            <FormGroup error={formState.errors["roleIds"]}>
              <Label name="User Role" htmlFor="role" required />
              <SelectRolesSidebar
                ids={watcher.roleIds.filter(isNotEmpty)}
                onSelect={(selectedRoleIds: string[]) =>
                  setValue("roleIds", selectedRoleIds)
                }
                isLoading={loading}
              />
            </FormGroup>
            <Spacer height={theme.spacing.spacing02} />
            <Button
              disabled={loading}
              initialState={loading ? "loading" : "success"}
            >
              Apply
            </Button>
          </form>
        </Sidebar>
      </When>
    </>
  );
}
