/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  OnChangeFn,
  RowSelectionState,
  ExpandedState,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Table } from "@nordcloud/gnui";
import { LoaderWrap } from "~/components/Loader";
import { StyledRow, StyledTd, StyledTh } from "~/components/Tables/styles";

type Props<T> = {
  loading: boolean;
  data: T[];
  columns: ColumnDef<T>[];
  stripped?: boolean;
  selectedRowsHandler?: (ids: string[]) => void;
  rowSelection: RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;
};

export function TableWrapper<
  T extends { id?: string; name?: string; subRows?: T[] }
>({
  loading,
  data,
  columns,
  stripped = false,
  selectedRowsHandler,
  rowSelection,
  setRowSelection,
}: Props<T>) {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
  });

  useEffect(() => {
    const selected = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original.id ?? "");

    selectedRowsHandler?.(selected);
  }, [rowSelection, selectedRowsHandler, table]);

  return (
    <LoaderWrap loading={loading} inContent>
      <Table stripped={stripped}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <StyledRow key={headerGroup.id} data-testid="table-row">
              {headerGroup.headers.map((header) => (
                <StyledTh
                  key={header.id}
                  style={header.column.columnDef.meta?.css}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </StyledTh>
              ))}
            </StyledRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <StyledRow key={row.id} data-testid={row.original.name}>
              {row.getVisibleCells().map((cell) => (
                <StyledTd key={cell.id} style={cell.column.columnDef.meta?.css}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </StyledTd>
              ))}
            </StyledRow>
          ))}
        </tbody>
      </Table>
    </LoaderWrap>
  );
}
