/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, FormProvider } from "react-hook-form";
import { Else, If, Then, Unless } from "react-if";
import {
  ExtendedTooltip,
  Label,
  MultipleSelect,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { ExecutionPolicy } from "~/generated/graphql";
import { getFirstItem } from "~/tools";
import {
  PlanField,
  SystemActionNames,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { useFormDefaultValues } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/components/ActionSettingsForm/defaultFormValues";
import { SelectButtonStyled } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/components/ActionSettingsForm/SelectButtonStyled";
import { useStepState } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/StepProvider";
import { FormField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { updateEntities } from "../utils";
import { executionPolicy, resourceOperation, skipWindow } from "./options";
import { WindowDuration } from "./WindowDuration";

type Props = {
  nextStep?: () => void;
  setTab: (key: number) => void;
};

export function ActionSettingsForm({ nextStep, setTab }: Props) {
  const { stepState, updateStepState } = useStepState();
  const { setPlanData } = usePlanWizard();

  const executionItem = getFirstItem(stepState.entities ?? []);
  const listId = executionItem.listId;
  const isFirstEntity = listId === stepState.selectedEntity?.listId;
  const isBatchAction = !!stepState.selectedEntity?.batchId;

  const formMethods = useFormDefaultValues(stepState);

  const { control, handleSubmit } = formMethods;

  const handleChange = (
    name: FormField,
    onChange: (value: string | boolean) => void,
    value: string | boolean
  ) => {
    const updatedSetting = { [name]: value };
    const updatedEntities = updateEntities(stepState, updatedSetting);

    updateStepState({
      entities: updatedEntities,
      selectedEntity: {
        ...stepState.selectedEntity,
        ...updatedSetting,
      },
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: updatedEntities,
      },
    }));
    onChange(value);
  };

  const submit = () => {
    if (stepState.selectedEntity?.notificationGroups) {
      setTab(2);
    } else {
      nextStep?.();
    }
  };

  const finalExecutionPolicy = executionPolicy.map((policy) => ({
    ...policy,
    disabled:
      (isBatchAction && policy.value !== ExecutionPolicy.Anyway) ||
      (!isBatchAction &&
        isFirstEntity &&
        policy.value === ExecutionPolicy.SuccessOrApproval),
  }));

  const modifiedResourceOperation = resourceOperation.map((operation) => ({
    ...operation,
    disabled: isBatchAction && operation.value,
  }));

  return (
    <FormProvider {...formMethods}>
      <form
        id="planSettingsForm"
        onSubmit={handleSubmit(submit)}
        css={{ minHeight: "13rem" }}
      >
        <If
          condition={
            stepState.selectedEntity?.actionName === SystemActionNames.DELAY
          }
        >
          <Then>
            <Label css={{ marginBottom: "0" }} htmlFor="delay" name="Delay" />
            <Text mb={theme.spacing.spacing02} size="sm">
              Next action will start after this time has passed.
            </Text>
            <WindowDuration control={control} handleChange={handleChange} />
          </Then>
          <Else>
            <Label name="Execution Policy" css={{ marginBottom: 0 }} />
            <Text mb={theme.spacing.spacing02} size="sm" tag="div">
              Specify the behavior of this action based on the status of the
              previous action.
            </Text>
            <Controller
              control={control}
              name={FormField.EXECUTION_POLICY}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultipleSelect size="small">
                    {finalExecutionPolicy.map((policy) => (
                      <ExtendedTooltip
                        caption={policy.description}
                        key={policy.value}
                        position="start"
                      >
                        <SelectButtonStyled
                          key={policy.value}
                          name={policy.value}
                          value={policy.value}
                          labelText={policy.label}
                          isActive={value === policy.value}
                          disabled={policy.disabled}
                          onClick={() =>
                            !policy.disabled &&
                            handleChange(
                              FormField.EXECUTION_POLICY,
                              onChange,
                              policy.value
                            )
                          }
                        />
                      </ExtendedTooltip>
                    ))}
                  </MultipleSelect>
                );
              }}
            />
            <Spacer height={theme.spacing.spacing04} />
            <Label name="Resource Operation" css={{ marginBottom: 0 }} />
            <Text mb={theme.spacing.spacing02} size="sm">
              Select the resource process below.
            </Text>
            <Controller
              control={control}
              name={FormField.RUN_IN_SEQUENCE}
              render={({ field: { onChange, value } }) => {
                return (
                  <MultipleSelect size="small">
                    {modifiedResourceOperation.map((operation) => (
                      <ExtendedTooltip
                        caption={operation.description}
                        position="start"
                        key={operation.label}
                      >
                        <SelectButtonStyled
                          key={operation.label}
                          name={operation.label}
                          value={operation.label}
                          labelText={operation.label}
                          isActive={value === operation.value}
                          disabled={operation.disabled}
                          onClick={() =>
                            !operation.disabled &&
                            handleChange(
                              FormField.RUN_IN_SEQUENCE,
                              onChange,
                              operation.value
                            )
                          }
                        />
                      </ExtendedTooltip>
                    ))}
                  </MultipleSelect>
                );
              }}
            />
            <Unless condition={isBatchAction}>
              <Spacer height={theme.spacing.spacing04} />
              <Label
                css={{ marginBottom: "0" }}
                htmlFor="activeWindowDuration"
                name="Action Window Duration"
              />
              <Text mb={theme.spacing.spacing02} size="sm" tag="div">
                Enter the amount of time for each action. Any resources that are
                not up to date during this time period will not be executed.
              </Text>
              <WindowDuration control={control} handleChange={handleChange} />
              <Label name="Skip window" />
              <Controller
                control={control}
                name={FormField.SKIP_WINDOW}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MultipleSelect size="small">
                      {skipWindow.map((skipWindowOption) => (
                        <ExtendedTooltip
                          caption={skipWindowOption.description}
                          position="start"
                          key={skipWindowOption.value}
                        >
                          <SelectButtonStyled
                            key={skipWindowOption.value}
                            name={skipWindowOption.value}
                            value={skipWindowOption.value}
                            labelText={skipWindowOption.label}
                            isActive={value === skipWindowOption.value}
                            onClick={() =>
                              handleChange(
                                FormField.SKIP_WINDOW,
                                onChange,
                                skipWindowOption.value
                              )
                            }
                          />
                        </ExtendedTooltip>
                      ))}
                    </MultipleSelect>
                  );
                }}
              />
            </Unless>
          </Else>
        </If>
      </form>
    </FormProvider>
  );
}
