/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function lowercase<T extends string>(input: T): Lowercase<T> {
  return input.toLowerCase() as Lowercase<T>;
}
export function capitalize<T extends string>(word: T): Capitalize<T> {
  const lowercasedWord = word.toLowerCase();
  return (lowercasedWord.charAt(0).toUpperCase() +
    lowercasedWord.slice(1)) as Capitalize<T>;
}

export function trimText(text: string, length: number) {
  return text.length > length ? `${text.substring(0, length)}...` : text;
}

export function inflect(singular: string, plural = `${singular}s`) {
  return (quantity: number) => (Math.abs(quantity) <= 1 ? singular : plural);
}

export function getTitleText(str: string) {
  return str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

export function getTextFromStringify(str: string) {
  return str.replace(/["'|[\]]/g, "").replace(/,/g, ", ");
}

export function verbalize(count: number) {
  return `${count > 1 ? "have" : "has"}`;
}
