/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { Dropdown, Label, Text, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { timezones } from "~/constants";
import { FormField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";

export function ScheduleTimezoneLabel() {
  return (
    <>
      <Label name="Plan Timezone" required />
      <Text
        mb={theme.spacing.spacing02}
        size="sm"
        color={theme.color.text.text02}
      >
        By default, the local timezone will be used. You can change it to:
      </Text>
    </>
  );
}

type Props = {
  generateDates?: (timezone: string) => void;
};

export function ScheduleTimezone({ generateDates }: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormGroup error={errors[FormField.SCHEDULE_TIMEZONE]}>
      <ScheduleTimezoneLabel />
      <Controller
        name={FormField.SCHEDULE_TIMEZONE}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Dropdown
            name="Select timezone"
            data-testid="dropdown-select-timezone"
            options={timezones}
            onChange={(timezone) => {
              onChange(timezone);
              generateDates?.(timezone);
            }}
            value={value}
            minNumOfOptionsToShowSearchBox={10}
          />
        )}
      />
    </FormGroup>
  );
}
