/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import { Box, FlexContainer, Spacer, theme } from "@nordcloud/gnui";
import { EventsSortFields, SortOrder } from "~/generated/graphql";
import { NoData } from "~/components";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { TableSearchInput } from "~/components/SearchInput";
import { minSearchLength } from "~/components/SearchInput/const";
import { SortSelector } from "~/components/SortSelector";
import { dateFormat } from "~/constants";
import { useQueryState } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import {
  QueryGetEvents,
  SortFields,
  sortOptions,
  weekBegin,
  weekEnd,
} from "./components/constants";
import { EventsFilters } from "./components/EventFilters";
import { EventFiltersSidebar } from "./components/EventFiltersSidebar";
import { EventsTable } from "./components/EventsTable";
import { IncomingEventsTable } from "./components/IncomingEventsTable";
import { EventType, Period } from "./types";

const breadcrumbsLabels = [
  { label: "Dashboard", uri: "/" },
  { label: "Events", uri: ROUTES.events.index },
];

export function EventsPage() {
  const { queryState, updateQueryState } = useQueryState<QueryGetEvents>();

  const handleSortDirection = useCallback(
    (order: SortOrder) => {
      updateQueryState({
        ...queryState,
        order,
      });
    },
    [queryState, updateQueryState]
  );

  const handleSortField = useCallback(
    (field: SortFields) => {
      updateQueryState({
        ...queryState,
        sortField: field,
      });
    },
    [queryState, updateQueryState]
  );

  const handlePhraseField = useCallback(
    (field: string | undefined) => {
      updateQueryState({
        ...queryState,
        searchPhrase: field,
      });
    },
    [queryState, updateQueryState]
  );

  const handleDateChange = useCallback(
    (startDate: string, endDate: string, viewPeriod: Period) => {
      const startDateUTC = dayjs(startDate)
        .tz("UTC")
        .format(dateFormat.fullDate);

      const endDateUTC = dayjs(endDate).tz("UTC").format(dateFormat.fullDate);

      updateQueryState({
        ...queryState,
        page: "1",
        startDateUTC,
        endDateUTC,
        viewPeriod: viewPeriod,
      });
    },
    [queryState, updateQueryState]
  );

  const handleEventTypeChange = useCallback(
    (type: EventType) => {
      // Clear Event Status filters and search phrase when changing event type
      updateQueryState({
        ...queryState,
        eventsType: type,
        eventStatuses: [],
        excludeEventStatuses: false,
        searchPhrase: undefined,
      });
    },
    [queryState, updateQueryState]
  );

  const isEmptyDateRange = !queryState.startDateUTC && !queryState.endDateUTC;

  useEffect(() => {
    if (isEmptyDateRange) {
      handleDateChange(weekBegin, weekEnd, Period.week);
    }
    if (!queryState.eventsType) {
      handleEventTypeChange(EventType.pastCurrent);
    }
    if (!queryState.order) {
      handleSortDirection(SortOrder.Asc);
    }
    if (!queryState.sortField) {
      handleSortField(EventsSortFields.StartTime);
    }
  }, [
    handleDateChange,
    handleEventTypeChange,
    handleSortDirection,
    handleSortField,
    isEmptyDateRange,
    queryState.eventsType,
    queryState.order,
    queryState.sortField,
  ]);

  const showCurrentEvents =
    !queryState.eventsType || queryState.eventsType === EventType.pastCurrent;

  const isSearchAllowed =
    !queryState.searchPhrase ||
    queryState.searchPhrase.length >= minSearchLength;

  return (
    <>
      <Row>
        <Col>
          <BreadcrumbsBox
            title="Events"
            customBreadcrumbs={breadcrumbsLabels}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <EventsFilters
              handleDateChange={handleDateChange}
              handleEventTypeChange={handleEventTypeChange}
            />
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer justifyContent="space-between">
                <SortSelector<SortFields>
                  options={sortOptions}
                  value={queryState.sortField ?? EventsSortFields.StartTime}
                  direction={queryState.order ?? SortOrder.Desc}
                  onSelect={handleSortField}
                  onSortChange={handleSortDirection}
                />
                <Spacer width={theme.spacing.spacing04} />
                <TableSearchInput<QueryGetEvents>
                  onSubmit={handlePhraseField}
                  placeholder={`Type a Keyword (e.g. Plan Name, Plan ID, etc.)`}
                />
                <Spacer width={theme.spacing.spacing04} />
                <EventFiltersSidebar />
              </FlexContainer>
            </Box>
            <When condition={!isEmptyDateRange}>
              <If condition={!isSearchAllowed}>
                <Then>
                  <NoData
                    hasIcon
                    message={`No results found for your search. Please enter at least ${minSearchLength} characters to start the search`}
                  />
                </Then>
                <Else>
                  <If condition={showCurrentEvents}>
                    <Then>
                      <EventsTable />
                    </Then>
                    <Else>
                      <IncomingEventsTable />
                    </Else>
                  </If>
                </Else>
              </If>
            </When>
          </Box>
        </Col>
      </Row>
    </>
  );
}
