/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CloudAccountStatus } from "~/generated/graphql";

export function getAccountStatusIconName(status: string) {
  switch (status) {
    case CloudAccountStatus.AccessOk:
      return "cloud";
    case CloudAccountStatus.InProgress:
      return "cloud";
    case CloudAccountStatus.AccessMissing:
      return "cloudOffline";
    case CloudAccountStatus.AccessError:
      return "cloudOffline";
    default:
      return "cloudOffline";
  }
}

export function getAccountStatusMessage(status: string) {
  switch (status) {
    case CloudAccountStatus.AccessOk:
      return "Connected";
    case CloudAccountStatus.AccessMissing:
      return "Access Missing";
    case CloudAccountStatus.AccessError:
      return "Access Error";
    case CloudAccountStatus.InProgress:
      return "In progress";
    default:
      return "Disconnected";
  }
}

export function getAccountColorContext(status: string) {
  switch (status) {
    case CloudAccountStatus.AccessOk:
      return "success";
    case CloudAccountStatus.AccessMissing:
      return "danger";
    case CloudAccountStatus.AccessError:
      return "danger";
    case CloudAccountStatus.InProgress:
      return "notification";
    default:
      return "danger";
  }
}
