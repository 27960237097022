/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { When } from "react-if";
import { v4 } from "uuid";
import { Button, Dropdown, FlexContainer, Label, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { dateFormat, timezones } from "~/constants";
import { isNotEmpty } from "~/tools";
import { ScheduleTimezoneLabel } from "~/views/plans/components";
import { PlanExtended } from "~/views/plans/PlanDetails/PlanProvider";
import { ScheduleState } from "~/views/plans/PlanDetails/types";
import { convertDateItemToDateString } from "~/views/plans/utils";
import { PlanData, PlanField } from "../../constants";
import { FormField, DateItem } from "../../types";
import { ExecutionDateItem } from ".";

type Props = {
  planData?: Partial<PlanExtended>;
  planDataForm?: PlanData;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
  updateState?: Dispatch<Partial<ScheduleState>>;
};

export function SpecifiedDatesSchedule({ planDataForm, setPlanData }: Props) {
  const {
    setValue,
    formState: { errors },
    control,
    clearErrors,
    reset,
    watch,
  } = useFormContext();

  const initialDates = useMemo(
    () => planDataForm?.[PlanField.SCHEDULE_PLAN]?.scheduleExecutionDates ?? [],
    [planDataForm]
  );

  const handleTimezoneChange = (tz: string) => {
    setValue(FormField.SCHEDULE_TIMEZONE, tz);
    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_TIMEZONE]: tz,
      },
    }));
    clearErrors(FormField.SCHEDULE_TIMEZONE);
  };

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: FormField.SCHEDULE_EXECUTION_DATES,
  });

  const initialFields = useMemo(() => {
    return (
      initialDates?.map((date: DateItem) => {
        return {
          [FormField.EXECUTION_DATE]: convertDateItemToDateString(
            date,
            dateFormat.shortDate
          ),
          [FormField.EXECUTION_TIME]: convertDateItemToDateString(
            date,
            dateFormat.hourMinute
          ),
        };
      }) ?? []
    );
  }, [initialDates]);

  const timezone = watch(FormField.SCHEDULE_TIMEZONE);
  const onAddNewExecutionDate = () => {
    const lastSchedule = watch(FormField.SCHEDULE_EXECUTION_DATES)[
      fields.length - 1
    ];
    const isPastDate = dayjs(
      `${lastSchedule?.executionDate} ${lastSchedule?.executionTime}`
    ).isBefore(dayjs().tz(timezone).format(dateFormat.fullDate));

    const executionDate = isPastDate
      ? dayjs().tz(timezone).format(dateFormat.shortDate)
      : lastSchedule?.executionDate;
    const executionTime = isPastDate
      ? dayjs().tz(timezone).format(dateFormat.hourMinute)
      : lastSchedule?.executionTime;
    const newExecutionDate = lastSchedule
      ? dayjs(executionDate).format(dateFormat.shortDate)
      : dayjs().tz(timezone).format(dateFormat.shortDate);
    const newExecutionTime = lastSchedule
      ? dayjs(`${executionDate} ${executionTime}`)
          .add(3, "hour")
          .format(dateFormat.hourMinute)
      : dayjs().tz(timezone).format(dateFormat.hourMinute);

    append({
      [FormField.EXECUTION_DATE]: newExecutionDate,
      [FormField.EXECUTION_TIME]: newExecutionTime,
    });

    setPlanData?.((prevPlanData) => {
      const newDates = [
        ...(prevPlanData[PlanField.SCHEDULE_PLAN]?.scheduleExecutionDates ??
          []),
        {
          id: v4(),
          executionDate: newExecutionDate,
          executionTime: newExecutionTime,
        },
      ];
      reset({
        [FormField.SCHEDULE_EXECUTION_DATES]: newDates,
      });
      return {
        ...prevPlanData,
        [PlanField.SCHEDULE_PLAN]: {
          ...prevPlanData[PlanField.SCHEDULE_PLAN],
          [FormField.SCHEDULE_EXECUTION_DATES]: newDates,
        },
      };
    });
  };

  useEffect(() => {
    setValue(
      FormField.SCHEDULE_TIMEZONE,
      planDataForm?.[PlanField.SCHEDULE_PLAN]?.scheduleTimezone ??
        dayjs.tz.guess()
    );
    replace(initialFields);
  }, [initialFields, planDataForm, replace, setValue]);

  return (
    <>
      <FormGroup error={errors[FormField.SCHEDULE_TIMEZONE]}>
        <ScheduleTimezoneLabel />
        <Controller
          name={FormField.SCHEDULE_TIMEZONE}
          control={control}
          render={() => (
            <Dropdown
              name="Select timezone"
              data-testid="dropdown-select-timezone"
              options={timezones}
              onChange={handleTimezoneChange}
              value={
                planDataForm?.[PlanField.SCHEDULE_PLAN]?.scheduleTimezone ??
                dayjs.tz.guess()
              }
              minNumOfOptionsToShowSearchBox={10}
            />
          )}
        />
      </FormGroup>
      <When condition={isNotEmpty(fields)}>
        <FlexContainer
          alignItems="flex-start"
          css={{ width: "20rem" }}
          justifyContent="space-between"
        >
          <Label name="Select Date" required />
          <Label name="Select Time" required />
        </FlexContainer>
      </When>
      <FormGroup error={errors[FormField.SCHEDULE_EXECUTION_DATES]}>
        {fields.map((field, index) => (
          <ExecutionDateItem key={field.id} remove={remove} index={index} />
        ))}
      </FormGroup>
      <Button
        severity="low"
        icon="plus"
        type="button"
        css={{ marginTop: theme.spacing.spacing08 }}
        onClick={onAddNewExecutionDate}
      >
        Add Date
      </Button>
    </>
  );
}
