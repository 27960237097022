/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { FlexContainer, Tag } from "@nordcloud/gnui";
import { RoleBindingByUser } from "~/generated/graphql";
import {
  ActionsColumn,
  FlexColumnLarge,
  FlexColumn,
  MediumColumn,
} from "~/components/Tables/styles";
import { dateFormat } from "~/constants";
import { isRoleEditable } from "~/views/permissions/utils";
import { Actions } from "./Actions";

const columnHelper = createColumnHelper<RoleBindingByUser>();

export function usersColumns() {
  return [
    columnHelper.accessor((row) => row?.subject, {
      header: "User Name",
      cell: (cell) => (
        <FlexContainer justifyContent="flex-start" alignItems="center">
          {cell.getValue()}
        </FlexContainer>
      ),
      meta: FlexColumn,
    }),
    columnHelper.accessor((row) => row?.roles, {
      header: "Roles",
      cell: ({ row }) => (
        <div>
          {row.original.roles?.map((role) => (
            <div key={role?.id}>
              <Tag text={role?.name} />
            </div>
          ))}
        </div>
      ),
      meta: FlexColumnLarge,
    }),
    columnHelper.accessor((row) => row?.roles, {
      header: "Added",
      cell: ({
        row: {
          original: {
            changeDetails: { createdAt },
          },
        },
      }) => <div>{dayjs(createdAt).format(dateFormat.dayMonthYear)}</div>,
      meta: MediumColumn,
    }),
    columnHelper.accessor((row) => row?.roles, {
      header: "Updated",
      cell: ({
        row: {
          original: {
            changeDetails: { updatedAt },
          },
        },
      }) => <div>{dayjs(updatedAt).format(dateFormat.dayMonthYear)}</div>,
      meta: MediumColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const roleIds = row.original.roles.map((r) => r.id);
        const systemRoleNames = row.original.roles
          .filter((role) => !isRoleEditable(role))
          .map((role) => role.name);

        return (
          <FlexContainer justifyContent="space-around">
            <Actions
              roleIds={roleIds}
              email={row.original.subject}
              systemRoleNames={systemRoleNames}
            />
          </FlexContainer>
        );
      },
      meta: ActionsColumn,
    }),
  ] as never;
}
