/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ActionSortFields,
  PlanActionType,
  SystemActionsSortFields,
} from "~/generated/graphql";
import { sortItem } from "~/tools";
import { useGetCustomActions } from "~/views/customActions/hooks/useGetCustomActions/useGetCustomActions";
import { useGetSystemActions } from "~/views/plans/hooks";

const ACTIONS_LIMIT = "100";

export function useGetAllActions() {
  const { data: customActions, loading: loadingCustomActions } =
    useGetCustomActions({
      page: "1",
      limit: ACTIONS_LIMIT,
      sort: {
        field: ActionSortFields.Name,
      },
    });

  const customActionsWithType = customActions?.map((action) => ({
    ...action,
    actionType: PlanActionType.Custom,
  }));

  const { data: systemActions, loading: loadingSystemActions } =
    useGetSystemActions({
      page: "1",
      limit: ACTIONS_LIMIT,
      sort: {
        field: SystemActionsSortFields.Name,
      },
    });

  const systemActionsWithType = systemActions?.map((action) => ({
    ...action,
    actionType: PlanActionType.System,
  }));
  const sortActionBasedOnVersion = sortItem(
    systemActionsWithType,
    (a, b) => parseFloat(a.version) - parseFloat(b.version)
  );
  const removeDuplicatedActionWithVersions = [
    ...sortActionBasedOnVersion
      .reduce((a, c) => a.set(c.name, c), new Map())
      .values(),
  ];

  const loading = loadingCustomActions || loadingSystemActions;

  return {
    loading,
    customActions: customActionsWithType,
    systemActions: removeDuplicatedActionWithVersions,
  };
}
