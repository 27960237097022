/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Box, Tabs as TabContainer } from "@nordcloud/gnui";
import { StyledTab } from "~/components/Styled/Tabs";
import { DetailsTab } from "./DetailsTab/DetailsTab";
import { GeneralNotificationsTab } from "./GeneralNotificationsTab/GeneralNotificationsTab";
import { MetadataTab } from "./MetadataTab/MetadataTab";

export function Tabs() {
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <Box spacing="spacing02" css={{ padding: 0 }}>
      <TabContainer step={currentStep} handleTab={setCurrentStep}>
        <StyledTab heading="Details">
          <DetailsTab />
        </StyledTab>
        <StyledTab heading="Metadata">
          <MetadataTab />
        </StyledTab>
        <StyledTab heading="General Notifications">
          <GeneralNotificationsTab />
        </StyledTab>
      </TabContainer>
    </Box>
  );
}
