/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SetStateAction, useEffect, useState } from "react";
import { When } from "react-if";
import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  theme,
} from "@nordcloud/gnui";
import { SearchInput } from "~/components";
import { maxSearchLength, searchDelay } from "~/components/SearchInput/const";
import { useSleep } from "~/hooks";
import { noop } from "~/tools";
import { Mode } from "./types";

type Props = {
  allowModeChange?: boolean;
  defaultMode?: Mode;
  onModeChange?: (mode: Mode) => void;
  showSearchBar?: boolean;
  onSetSearch?: (value: SetStateAction<string>) => void;
};

const minSearchLength = 1;

export function FilterHeader({
  allowModeChange = false,
  defaultMode = Mode.INCLUDES,
  onModeChange = noop,
  showSearchBar = false,
  onSetSearch = noop,
}: Props) {
  const [mode, setMode] = useState(defaultMode);
  const [input, setInput] = useState("");
  const { sleep, cancelSleep } = useSleep();

  useEffect(() => {
    onSetSearch(input);
  }, [onSetSearch, input]);

  const handleModeChange = (selectedMode: Mode) => {
    setMode(selectedMode);
    onModeChange(selectedMode);
  };

  const onChange = async (query: string) => {
    cancelSleep();
    if (query.length !== 0) {
      await sleep(searchDelay);
    }
    setInput(query);
  };

  return (
    <>
      <FlexContainer
        p={theme.spacing.spacing02}
        justifyContent="space-between"
        css={{
          borderBottom: `1px solid ${theme.color.border.border01}`,
        }}
      >
        <When condition={showSearchBar}>
          <SearchInput
            placeholder="Search"
            onSubmit={(query) => {
              cancelSleep();
              setInput(query);
            }}
            onChange={onChange}
            minLength={minSearchLength}
            maxLength={maxSearchLength}
          />
        </When>
        <When condition={allowModeChange}>
          <MultipleSelect
            size="small"
            css={{
              alignContent: "right",
              marginLeft: theme.spacing.spacing03,
            }}
          >
            <SelectButton
              isActive={mode === Mode.INCLUDES}
              name="includes"
              value="1"
              labelText="Includes"
              style={{ width: "5rem" }}
              onClick={() => handleModeChange(Mode.INCLUDES)}
            />
            <SelectButton
              name="excludes"
              value="0"
              labelText="Excludes"
              isActive={mode === Mode.EXCLUDES}
              style={{ width: "5rem" }}
              onClick={() => handleModeChange(Mode.EXCLUDES)}
            />
          </MultipleSelect>
        </When>
      </FlexContainer>
    </>
  );
}
