/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useReducer, useState } from "react";
import { When } from "react-if";
import {
  Button,
  FlexContainer,
  SVGIcon,
  Tab,
  Tabs,
  theme,
} from "@nordcloud/gnui";
import { WizardButton, WrapWizardButton } from "~/components";
import { useCreateNotificationGroup } from "~/views/notificationGroups/hooks/useCreateNotificationGroup/useCreateNotificationGroup";
import { Confirm } from "./Confirm";
import {
  defaultValues,
  NotificationGroupField,
  NotificationGroupData,
} from "./constants";
import { NotificationGroupsDetails } from "./NotificationGroupsDetails";
import { NotificationGroupsWizardCtxProvider } from "./NotificationGroupsProvider";
import { NotificationGroupsSelectFrom } from "./NotificationGroupsSelectFrom/NotificationGroupsSelectFrom";
import { Summary } from "./Summary";

type State = {
  currentStep: number;
  subStep: number;
  notificationGroupId: string;
};

const defaultState = { currentStep: 0, subStep: 0, notificationGroupId: "" };

export function NotificationGroupsCreateWizard() {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    defaultState
  );

  const [notificationGroupData, setNotificationGroupData] =
    useState<NotificationGroupData>(defaultValues);

  const nextStep = () => updateState({ currentStep: state.currentStep + 1 });

  const previousStep = () =>
    updateState({ currentStep: state.currentStep - 1 });

  const handleSteps = (key: number) => updateState({ currentStep: key });

  const handleSuccess = (id: string) =>
    updateState({ notificationGroupId: id, subStep: 1 });

  const { createNotificationGroup, loading } = useCreateNotificationGroup({
    onSuccess: handleSuccess,
  });

  const handleCreateGroup = () =>
    createNotificationGroup({ ...notificationGroupData });

  const handleResetState = () => {
    updateState(defaultState);
  };

  const buttonsStep2 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton form="notificationChanelForm" type="submit">
        <FlexContainer gap={theme.spacing.spacing02}>
          Next Step
          <SVGIcon name="arrowRight" size="sm" />
        </FlexContainer>
      </WizardButton>
    </WrapWizardButton>
  );
  const buttonsStep3 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton
        initialState={loading ? "loading" : ""}
        onClick={handleCreateGroup}
        type="submit"
      >
        Create Notification Groups
      </WizardButton>
    </WrapWizardButton>
  );

  return (
    <NotificationGroupsWizardCtxProvider
      value={{ notificationGroupData, setNotificationGroupData }}
    >
      <Tabs step={state.currentStep} wizard handleTab={handleSteps}>
        <Tab
          disabled
          heading="Notification Group Details"
          buttons={
            <WrapWizardButton>
              <WizardButton type="submit" form="wizard_step_1">
                <FlexContainer gap={theme.spacing.spacing02}>
                  Next Step
                  <SVGIcon name="arrowRight" size="sm" />
                </FlexContainer>
              </WizardButton>
            </WrapWizardButton>
          }
        >
          <NotificationGroupsDetails
            initialValues={notificationGroupData}
            onSubmit={(formData) => {
              setNotificationGroupData((prevResourceData) => ({
                ...prevResourceData,
                [NotificationGroupField.DETAILS]: formData,
              }));
              nextStep();
            }}
          />
        </Tab>
        <Tab disabled heading="Add Notification Group" buttons={buttonsStep2}>
          <NotificationGroupsSelectFrom
            initialValues={notificationGroupData}
            nextStep={nextStep}
          />
        </Tab>
        <Tab
          disabled
          heading="Confirm and Finalize"
          buttons={state.subStep === 0 && buttonsStep3}
        >
          <When condition={state.subStep === 0}>
            <Confirm
              notificationGroup={notificationGroupData}
              onEditDetails={() => updateState({ currentStep: 0 })}
              onEditNotificationChannels={() => updateState({ currentStep: 1 })}
            />
          </When>
          <When condition={state.subStep === 1}>
            <Summary
              notificationGroupId={state.notificationGroupId}
              onAddNewNotificationGroup={handleResetState}
            />
          </When>
        </Tab>
      </Tabs>
    </NotificationGroupsWizardCtxProvider>
  );
}
