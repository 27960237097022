/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ResourcesSortInput,
  ResourceFilterInput,
  useGetResourcesQuery,
} from "~/generated/graphql";
import { isEmpty } from "~/tools";

type Props = {
  page: string;
  limit: string;
  sort?: ResourcesSortInput;
  filter?: ResourceFilterInput;
};

export function useGetResources({ page, limit, sort, filter }: Props) {
  const {
    data: resourcesData,
    loading,
    error,
  } = useGetResourcesQuery({
    variables: { page: Number(page), limit: Number(limit), sort, filter },
  });

  const data = resourcesData?.resources?.result ?? [];
  const count = resourcesData?.resources?.pageInfo?.count ?? 0;
  const noData = isEmpty(data);

  return { data, loading, error, count, noData } as const;
}
