/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { Table } from "@nordcloud/gnui";
import { Resource } from "~/generated/graphql";
import { StyledTd } from "~/components/Tables/styles";
import { ROUTES } from "~/routing/routes";
import { NameCell } from "~/views/resources/components/NameCell";
import { OSCell } from "~/views/resources/components/OSCell";

type Props = {
  resource: Partial<Resource>;
};

export function ResourceRow({ resource }: Props) {
  const os = {
    subType: resource?.os?.subType ?? "",
    type: resource?.os?.type ?? "",
  };

  return (
    <Table.tr>
      <StyledTd css={{ width: "2%" }}>
        <OSCell os={os} />
      </StyledTd>
      <Table.td>
        <NameCell
          to={ROUTES.resources.generateDetails(resource?.id ?? "")}
          provider={resource?.provider}
        >
          {resource?.name}
        </NameCell>
      </Table.td>
    </Table.tr>
  );
}
