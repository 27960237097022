/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Box, Text, theme } from "@nordcloud/gnui";
import { formatText } from "~/tools";
import {
  convertMinutesToReadableTime,
  isAdvanceTrigger,
} from "~/views/plans/utils";
import {
  PlanNotificationItemType,
  PlanActionNotificationItemType,
} from "../types";

type Props = {
  item: PlanNotificationItemType | PlanActionNotificationItemType;
  index: number;
};

export function NotificationItem({ item, index }: Props) {
  const notificationCount = index + 1;

  return (
    <Box
      aria-label={`notification-box`}
      boxStyle="lightGrey"
      mb={theme.spacing.spacing03}
    >
      <Text tag="div" align="left" mb={theme.spacing.spacing04}>
        <Text size="sm" tag="div" weight="medium" mb={theme.spacing.spacing04}>
          Notification #{notificationCount}
        </Text>
        <>
          <Text size="sm" tag="div" color={theme.color.text.text02}>
            Subject:{" "}
          </Text>
          <Text data-testid="notification-subject" tag="div">
            {formatText(item.scope)}
          </Text>
          <Text
            size="sm"
            tag="div"
            color={theme.color.text.text02}
            mt={theme.spacing.spacing04}
          >
            Condition:{" "}
          </Text>
          <If condition={isAdvanceTrigger(item.triggerEvent)}>
            <Then>
              <Text data-testid="notification-condition" tag="span">
                {formatText(item.triggerEvent)} in{" "}
                {convertMinutesToReadableTime(Number(item?.inAdvance))}
              </Text>
            </Then>
            <Else>
              <Text data-testid="notification-condition" tag="span">
                {formatText(item.triggerEvent)}
              </Text>
            </Else>
          </If>
        </>
      </Text>
      <Text
        size="sm"
        tag="div"
        color={theme.color.text.text02}
        data-testid="notification-text"
      >
        Notification Recipients:{" "}
        <Text
          tag="div"
          data-testid="notification-recipients"
          color={theme.color.text.text01}
        >
          {item.notificationGroups.map((group) => group.name).join(", ")}
        </Text>
      </Text>
    </Box>
  );
}
