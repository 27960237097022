/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";

type Props = {
  title: string;
};

export function ResourceDetailsBreadcrumbs({ title }: Props) {
  return (
    <BreadcrumbsBox
      title={title}
      customBreadcrumbs={[
        { label: "Home", uri: "/" },
        { label: "Resources", uri: ROUTES.resources.index },
        { label: title, uri: "" },
      ]}
    />
  );
}
