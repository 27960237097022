/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Box, Tab, Tabs as TabContainer } from "@nordcloud/gnui";
import { ActionPlansTab } from "./ActionPlansTab/ActionPlansTab";
import { ActionSettingsTab } from "./ActionSettingsTab/ActionSettingsTab";

export function Tabs() {
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <Box spacing="spacing02" css={{ padding: 0 }}>
      <TabContainer step={currentStep} handleTab={setCurrentStep}>
        <Tab heading="Action Settings">
          <ActionSettingsTab />
        </Tab>
        <Tab heading="Action Plans">
          <ActionPlansTab />
        </Tab>
      </TabContainer>
    </Box>
  );
}
