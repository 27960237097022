/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useReducer, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Case, Switch } from "react-if";
import { Box } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, isEmpty, isNotEmpty } from "~/tools";
import { areObjectsEqual } from "~/utils/json";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";
import {
  PlanData,
  PlanField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import { PlanGeneralNotificationForm } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanGeneralNotificationForms";
import { PlanWizardCtxProvider } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { TimeUnits } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";
import { NotificationItem } from "~/views/plans/PlanDetails/Tabs/NotificationItem";
import { ScheduleType } from "~/views/plans/types";
import { GeneralNotificationTabBar } from "./GeneralNotificationTabBar";
import {
  getFormDataIds,
  getDeleteNotificationGroupsFromList,
  getDeleteNotificationGroupsFromNestedGroup,
  mapNotificationGroupsToUpdate,
  mapNotifications,
  mapNotificationGroups,
  mergePlanNotifications,
} from "./helpers";

export type State = {
  isOpen: boolean;
  hasUnsavedChanges: boolean;
};

export function GeneralNotificationsTab() {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => {
      return {
        ...data,
        ...partialData,
      };
    },
    { isOpen: false, hasUnsavedChanges: false }
  );

  const { plan } = usePlan();
  const planNotifications = plan?.notificationGroups ?? [];

  const mergeNotifications = mergePlanNotifications(
    plan?.notificationGroups ?? []
  );

  const mapInAdvanceToString = mergeNotifications.map((item) => {
    return {
      id: item.id,
      triggerEvent: item.triggerEvent,
      notificationGroupIds: item.notificationGroups.map((n) => n.id),
      inAdvance: item.inAdvance?.toString() ?? "0",
      unit: TimeUnits.minutes,
    };
  });

  const [planData, setPlanData] = useState<PlanData>({
    details: undefined,
    schedule_plan: undefined,
    schedule_type: ScheduleType.recurring,
    plan_settings: undefined,
    plan_status: undefined,
    general_notifications: { notificationGroupsGeneral: mapInAdvanceToString },
  });

  const success = () => {
    showSuccess(generateActionSuccessText("Plan")()("updated")());
    updateState({ isOpen: false });
  };

  const [updatePlan] = useUpdatePlan({
    onSuccess: success,
  });

  const openEditMode = useCallback(() => updateState({ isOpen: true }), []);
  const closeEditMode = useCallback(() => updateState({ isOpen: false }), []);

  const onSubmit = (formData: FormData) => {
    const formDataIds = getFormDataIds(formData?.notificationGroupsGeneral);

    const deleteNotificationGroupsFromList =
      getDeleteNotificationGroupsFromList(
        plan?.notificationGroups ?? [],
        formDataIds ?? []
      );

    const deleteNotificationGroupsFromNestedGroup =
      getDeleteNotificationGroupsFromNestedGroup(
        formData.notificationGroupsGeneral,
        plan?.notificationGroups ?? []
      );

    const notificationGroupsToDelete = [
      ...deleteNotificationGroupsFromList,
      ...deleteNotificationGroupsFromNestedGroup,
    ];

    const notificationsGroupsToUpdate = mapNotificationGroupsToUpdate(
      formData?.notificationGroupsGeneral,
      plan?.notificationGroups
    );

    const notifications = mapNotifications(planNotifications);
    const notificationGroups = mapNotificationGroups(
      notificationsGroupsToUpdate
    );

    if (areObjectsEqual(notifications, notificationGroups)) {
      closeEditMode();
      return;
    }

    updatePlan({
      id: plan?.id ?? "",
      notificationGroups: notificationsGroupsToUpdate,
      notificationGroupsToDelete: notificationGroupsToDelete,
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.GENERAL_NOTIFICATIONS]: {
        notificationGroupsGeneral: formData.notificationGroupsGeneral,
      },
    }));
  };

  return (
    <>
      <PlanWizardCtxProvider value={{ planData, setPlanData }}>
        <Box innerSpacing="spacing00">
          <GeneralNotificationTabBar
            openEditMode={openEditMode}
            closeEditMode={closeEditMode}
            isEditMode={state.isOpen}
            hasUnsavedChanges={state.hasUnsavedChanges}
          />
          <Switch>
            <Case condition={state.isOpen}>
              <PlanGeneralNotificationForm
                updateState={updateState}
                onSubmit={onSubmit}
              />
            </Case>
            <Case condition={!state.isOpen && isNotEmpty(planNotifications)}>
              <Row data-testid="notification-table">
                <Col xs={8} sm={8} md={12} lg={12}>
                  {mergeNotifications?.map((notification, index) => {
                    return (
                      <NotificationItem
                        item={notification}
                        index={index}
                        key={notification.id}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Case>
            <Case condition={!state.isOpen && isEmpty(planNotifications)}>
              <NoData hasIcon message="There are no General Notifications" />
            </Case>
          </Switch>
        </Box>
      </PlanWizardCtxProvider>
    </>
  );
}
