/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum TooltipColor {
  danger = "danger",
  notification = "notification",
  success = "success",
  warning = "warning",
  accent = "accent",
}
