/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { Input, Label } from "@nordcloud/gnui";
import { FormGroup, Metadata, MetadataSchema } from "~/components";
import { safeStr, stringRequired } from "~/components/Forms";
import { ResourceGroupData } from "../constants";
import { FormData, FormField } from "../formConfig";

export const DetailsSchema = z.object({
  [FormField.NAME]: stringRequired("Name"),
  [FormField.DESCRIPTION]: safeStr.optional(),
  [FormField.METADATA]: MetadataSchema.optional(),
});

type Props = {
  initialValues: ResourceGroupData;
  onSubmit: SubmitHandler<FormData>;
};

export function ResourceGroupDetails({ onSubmit, initialValues }: Props) {
  const formMethods = useForm({
    resolver: zodResolver(DetailsSchema),
    defaultValues: {
      [FormField.NAME]: initialValues?.details?.name ?? "",
      [FormField.DESCRIPTION]: initialValues?.details?.description ?? "",
      [FormField.METADATA]: initialValues?.details?.metadata ?? undefined,
    },
  });

  const { formState, register, handleSubmit } = formMethods;

  const submit: SubmitHandler<FormData> = (data) => {
    onSubmit({
      [FormField.NAME]: data.name,
      [FormField.DESCRIPTION]: data.description,
      [FormField.METADATA]: data.metadata,
    });
  };

  return (
    <>
      <Row justify="center">
        <Col sm={6} md={6} lg={8}>
          <Row>
            <Col>
              <FormProvider {...formMethods}>
                <form id="wizard_step_1" onSubmit={handleSubmit(submit)}>
                  <FormGroup error={formState.errors[FormField.NAME]}>
                    <Label name="Group name" htmlFor="name" required />
                    <Input id="name" {...register("name")} />
                  </FormGroup>
                  <FormGroup error={formState.errors[FormField.DESCRIPTION]}>
                    <Label name="Description" htmlFor="description" />
                    <Input id="description" {...register("description")} />
                  </FormGroup>
                  <Metadata />
                </form>
              </FormProvider>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
