/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import { FlexContainer, IndeterminateCheckbox } from "@nordcloud/gnui";
import { ResourceStatus } from "~/generated/graphql";
import {
  FlexColumnMax,
  LargeIconColumn,
  MediumIconColumn,
  SmallIconColumn,
} from "~/components/Tables/styles";
import { ROUTES } from "~/routing/routes";
import { ResourceDetailsStatus } from "../ResourceDetails/components/ResourceDetailsStatus";
import { Resource } from "../types/Resource";
import { Actions } from "./Actions";
import { NameCell } from "./NameCell";

const columnHelper = createColumnHelper<Resource>();

export function columns() {
  return [
    columnHelper.accessor(() => null, {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      meta: SmallIconColumn,
    }),
    columnHelper.accessor("state", {
      header: () => null,
      cell: ({ row }) => {
        return (
          <FlexContainer>
            <ResourceDetailsStatus
              os={row.original?.os}
              status={
                row.original?.state
                  ? row.original.state.status
                  : ResourceStatus.ConnectionLost
              }
              iconSize="md"
              boxes={false}
            />
          </FlexContainer>
        );
      },
      meta: LargeIconColumn,
    }),
    columnHelper.accessor("name", {
      header: () => "Resource Name / Type",
      cell: ({ row }) => {
        return (
          <NameCell
            to={ROUTES.resources.generateDetails(row.original?.id ?? "")}
            provider={row.original?.provider}
          >
            {row.original?.name}
          </NameCell>
        );
      },
      meta: FlexColumnMax,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const resourcesDetails = [
          {
            id: row.original?.id ?? "",
            name: row.original?.name ?? "",
            scanned: row.original?.scanned,
          },
        ];
        return <Actions {...{ resourcesDetails }} />;
      },
      meta: MediumIconColumn,
    }),
  ] as never;
}
