/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const RadioLabel = styled.label`
  cursor: pointer;
  padding: ${theme.spacing.spacing03} ${theme.spacing.spacing01};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;
