// Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.

import { ResourceCloudProvider, Resource } from "~/generated/graphql";
import { DetailData } from "~/components/Details/Detail";
import { stringRequired } from "~/components/Forms";
import { capitalize } from "~/tools";
import { useUpdateResource } from "../hooks/useUpdateResource/useUpdateResource";

export const HeaderPresetResource: (resource?: Resource) => DetailData[] = (
  resource
) => [
  {
    label: "Resource Name",
    value: resource?.name ?? resource?.id,
    editProps: {
      property: "name",
      updateHook: useUpdateResource,
      schema: {
        name: stringRequired("Name"),
      },
    },
  },
];

export const DetailsColumnFirst: (resource?: Resource) => DetailData[] = (
  resource
) => [
  {
    label: "Resource ID",
    value: resource?.id,
    clipboard: true,
  },
  {
    label: "Os type",
    value: capitalize(resource?.os?.type ?? "-"),
    clipboard: false,
  },
  {
    label: "Subtype",
    value: capitalize(resource?.os?.subType ?? "-"),
    clipboard: false,
  },
];

export const DetailsColumnFirstRowSecond: (
  resource?: Resource
) => DetailData[] = (resource) => [
  {
    label: "Provider",
    value: resource?.provider,
    clipboard: false,
  },
];

export const DetailsColumnSecondRowSecond: (
  resource?: Resource
) => DetailData[] = (resource) => [
  {
    label: "Region",
    value: resource?.region,
    clipboard: false,
  },
];

export const DetailsColumnSecond: (resource?: Resource) => DetailData[] = (
  resource
) => [
  {
    label: "IP Address",
    value: findMetadataByPhrase("PublicIpAddress", resource)?.replaceAll(
      '"',
      ""
    ),
    clipboard: false,
  },
  {
    label: "Instance Type",
    value: getInstanceType(resource)?.replaceAll('"', ""),
    clipboard: false,
  },
];

export const DetailsColumnFull: (resource?: Resource) => DetailData[] = (
  resource
) => {
  const provider = resource?.provider ?? "Other";
  const label = `${provider} ID`;
  return [
    {
      label,
      value: resource?.fullCloudResourceId,
      clipboard: true,
    },
    {
      label: "Description",
      value: resource?.description,
      editProps: {
        property: "description",
        updateHook: useUpdateResource,
        schema: {
          description: stringRequired("Description"),
        },
      },
    },
  ];
};

const getInstanceType = (resource?: Resource): string | undefined => {
  switch (resource?.provider) {
    case ResourceCloudProvider.Aws:
      return findMetadataByPhrase("InstanceType", resource);
    case ResourceCloudProvider.Azure:
      const properties = findMetadataByPhrase("properties", resource);
      return properties
        ? findProperty(properties, ["hardwareProfile", "vmSize"])
        : undefined;
    default:
      return undefined;
  }
};

const findMetadataByPhrase = (
  phrase: string,
  resource?: Resource
): string | undefined => {
  return resource?.cloudMetadata?.find((res) => res.key === phrase)?.value;
};

const findProperty = (jsonProperties: string, propPath: string[]): string => {
  return propPath.reduce(
    (acc, prop) =>
      typeof acc == "undefined" || acc === null ? acc : acc[prop],
    JSON.parse(jsonProperties)
  );
};
