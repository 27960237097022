/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer, Button, Text, theme } from "@nordcloud/gnui";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { renderActionSettingsForm } from "~/views/customActions/components/renderActionSettingsForm";
import { CustomActionSettings } from "~/views/customActions/CustomActionCreate/components/CustomActionCreateWizard/components";
import {
  CustomActionData,
  CustomActionField,
} from "~/views/customActions/CustomActionCreate/components/CustomActionCreateWizard/constants";
import { CustomActionWizardCtxProvider } from "~/views/customActions/CustomActionCreate/components/CustomActionCreateWizard/CustomActionProvider";
import { FormData } from "~/views/customActions/CustomActionCreate/components/CustomActionCreateWizard/formConfig";
import { useCustomAction } from "~/views/customActions/CustomActionDetails/CustomActionProvider";
import { useUpdateCustomAction } from "~/views/customActions/hooks/useUpdateCustomAction/useUpdateCustomAction";

export function ActionSettingsTab() {
  const { customAction } = useCustomAction();

  const [isEditMode, setEditMode] = useState(false);

  const defaultValues: CustomActionData = {
    [CustomActionField.DETAILS]: undefined,
    [CustomActionField.SETTINGS]: {
      ...customAction,
      ...customAction?.typeProperties,
    },
  };

  const [customActionData, setCustomActionData] =
    useState<CustomActionData>(defaultValues);

  const [updateCustomAction] = useUpdateCustomAction({
    onSuccess: () => {
      setEditMode(false);
      showSuccess(generateActionSuccessText("Custom Action")()("updated")());
    },
  });

  const onSubmit = (data: FormData) => updateCustomAction(data);

  return (
    <>
      <Box spacing="spacing04">
        <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
          <If condition={isEditMode}>
            <Then>
              <FlexContainer justifyContent="center">
                <Text ml="auto" tag="span" color={theme.color.text.error}>
                  There are unsaved changes.
                </Text>
                <Button
                  type="submit"
                  form="settings-form"
                  ml={theme.spacing.spacing08}
                >
                  Save
                </Button>
                <Button
                  ml={theme.spacing.spacing04}
                  severity="low"
                  icon="close"
                  onClick={() => setEditMode(false)}
                />
              </FlexContainer>
            </Then>
            <Else>
              <Button ml="auto" onClick={() => setEditMode(true)}>
                Edit Action
              </Button>
            </Else>
          </If>
        </Box>
        <CustomActionWizardCtxProvider
          value={{ customActionData, setCustomActionData }}
        >
          <If condition={isEditMode}>
            <Then>
              <CustomActionSettings
                onSubmit={onSubmit}
                initialValues={customActionData}
              />
            </Then>
            <Else>
              {renderActionSettingsForm({
                data: {
                  ...customAction,
                  ...customAction?.typeProperties,
                },
                type: customAction?.type,
              })}
            </Else>
          </If>
        </CustomActionWizardCtxProvider>
      </Box>
    </>
  );
}
