/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Modal, Text, theme, Input } from "@nordcloud/gnui";
import { FormGroup, Portal } from "~/components";
import { stringRequired } from "~/components/Forms";
import { useDisclosure } from "~/hooks";
import { Email } from "../../formConfig";

type Props = {
  onSubmit: (emails: Email[]) => void;
};

export const schema = z.object({
  emails: stringRequired("List of Emails"),
});

function extractEmails(input: string) {
  return input.replace(/(\s\s\s*)/g, "").split(",");
}

export function EmailAddMultiple({ onSubmit }: Props) {
  const { isOpen, open, close } = useDisclosure();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      emails: "",
    },
  });

  const submit: SubmitHandler<{ emails: string }> = (formData) => {
    const prepareEmails = extractEmails(formData.emails).map((item) => ({
      email: item.trim(),
    }));
    onSubmit(prepareEmails);
    close();
    setValue("emails", "");
  };

  return (
    <>
      <Button severity="low" icon="upload" type="button" onClick={open}>
        Add multiple Emails
      </Button>
      <Portal>
        <div>
          <Modal
            onClose={close}
            isOpen={isOpen}
            contentLabel="Add multiple Emails"
          >
            <Text mb={theme.spacing.spacing00}>Upload List of Emails</Text>
            <Text size="sm">
              Comma separate emails to send to multiple accounts
            </Text>
            <form>
              <FormGroup error={errors.emails}>
                <Input
                  css={{ flex: 1 }}
                  aria-label="provide emails"
                  data-testid="input-multiple-emails"
                  placeholder="e.g., johndoe@example.com, sarahsmith@example.com"
                  {...register("emails")}
                />
              </FormGroup>
              <Button onClick={handleSubmit(submit)} ml="auto">
                Add
              </Button>
            </form>
          </Modal>
        </div>
      </Portal>
    </>
  );
}
