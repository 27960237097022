/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { ResourcesTable } from "./components";

export function ResourcesPage() {
  const breadcrumbsLabels = [
    { label: "Dashboard", uri: "/" },
    { label: "Resources", uri: ROUTES.resources.index },
  ];

  return (
    <>
      <Row>
        <Col>
          <BreadcrumbsBox
            title="Resources"
            customBreadcrumbs={breadcrumbsLabels}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <ResourcesTable />
          </Box>
        </Col>
      </Row>
    </>
  );
}
