/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { Dropdown, Input, Label, Textarea } from "@nordcloud/gnui";
import { WebhookActionMethod } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { FormField, getLabel } from "../../../formConfig";
import { WebhookHeaders } from "./WebhookHeaders";

export function WebHookForm() {
  const { formState, register, control } = useFormContext();
  return (
    <>
      <FormGroup error={formState.errors[FormField.WEB_HOOK_URL]}>
        <Label
          htmlFor={FormField.WEB_HOOK_URL}
          name={getLabel(FormField.WEB_HOOK_URL)}
          required
        />
        <Input
          id={FormField.WEB_HOOK_URL}
          {...register(FormField.WEB_HOOK_URL)}
        />
      </FormGroup>
      <FormGroup error={formState.errors[FormField.WEBHOOK_METHOD]}>
        <Label name={getLabel(FormField.WEBHOOK_METHOD)} required />
        <Controller
          control={control}
          name={FormField.WEBHOOK_METHOD}
          render={({ field: { onChange, value } }) => {
            return (
              <Dropdown
                name="Select Method"
                options={options}
                onChange={onChange}
                value={value}
                minNumOfOptionsToShowSearchBox={10}
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup error={formState.errors[FormField.PAYLOAD]}>
        <Label
          htmlFor={FormField.PAYLOAD}
          name={`${getLabel(FormField.PAYLOAD)} (JSON)`}
          required
        />
        <Textarea id={FormField.PAYLOAD} {...register(FormField.PAYLOAD)} />
      </FormGroup>
      <WebhookHeaders />
    </>
  );
}

const options = Object.values(WebhookActionMethod);
