/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { FlexContainer, Tab, Tabs as TabContainer } from "@nordcloud/gnui";
import { ActionNotification } from "./ActionNotification";
import { AdvancedSettings } from "./AdvancedSettings";
import { GeneralSettings } from "./GeneralSettings";
import { TabIcon } from "./TabIcon";

export function ActionView() {
  const [tab, setTab] = useState(0);

  return (
    <>
      <FlexContainer alignItems="stretch" css={{ flex: 1, minWidth: "10rem" }}>
        <TabContainer step={tab} handleTab={setTab}>
          <Tab
            data-testid={"tab-general-action-settings"}
            label={
              <span>
                <TabIcon caption="General Action Settings" icon="settings" />
              </span>
            }
            width="auto"
            css={{ minHeight: "13rem" }}
          >
            <GeneralSettings />
          </Tab>
          <Tab
            data-testid={"tab-advanced-action-settings"}
            label={
              <TabIcon caption="Advanced Action Settings" icon="doubleGear" />
            }
            width="auto"
            css={{ minHeight: "13rem" }}
          >
            <AdvancedSettings />
          </Tab>
          <Tab
            data-testid={"tab-action-notifications"}
            label={<TabIcon caption="Action Notifications" icon="bell" />}
            width="auto"
            css={{ minHeight: "13rem" }}
          >
            <ActionNotification />
          </Tab>
        </TabContainer>
      </FlexContainer>
    </>
  );
}
