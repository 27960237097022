/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Input, Label } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { FormField } from "../../../formConfig";

export function SSMForm() {
  const { formState, register } = useFormContext();

  return (
    <>
      <FormGroup error={formState.errors[FormField.SSM_DOCUMENT_ID]}>
        <Label
          htmlFor={FormField.SSM_DOCUMENT_ID}
          name="SSM Document Identifier"
          required
        />
        <Input
          id={FormField.SSM_DOCUMENT_ID}
          {...register(FormField.SSM_DOCUMENT_ID)}
        />
      </FormGroup>
    </>
  );
}
