/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const IconColumn = styled.div`
  padding: ${theme.spacing.spacing01} ${theme.spacing.spacing02};
  height: 1.5rem;
  width: 1.5rem;
`;
