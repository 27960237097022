/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { Box, Button, FlexContainer, Heading, Text } from "@nordcloud/gnui";
import { DataDisplayWrapper } from "~/components";
import { NotificationGroupData } from "./constants";

type Props = {
  notificationGroup: NotificationGroupData;
  onEditDetails: () => void;
  onEditNotificationChannels: () => void;
};

export function Confirm({
  notificationGroup,
  onEditDetails,
  onEditNotificationChannels,
}: Props) {
  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Heading level={4}>Notification Group Details</Heading>
        <Box boxStyle="lightGrey" spacing="spacing04">
          <FlexContainer alignItems="start" justifyContent="space-between">
            <div>
              <Text size="sm" m={0} p={0}>
                Notification Group Name
              </Text>
              <Text>{notificationGroup.details?.name}</Text>
              <Text size="sm" m={0} p={0}>
                Notification Group Description
              </Text>

              <DataDisplayWrapper
                hasData={notificationGroup.details?.description !== ""}
              >
                {notificationGroup.details?.description}
              </DataDisplayWrapper>
            </div>
            <div>
              <Button
                aria-label="edit button"
                icon="edit"
                size="md"
                severity="low"
                onClick={onEditDetails}
              />
            </div>
          </FlexContainer>
        </Box>
        <Heading level={4}>Notification Group Settings</Heading>
        <Box boxStyle="lightGrey" spacing="spacing04">
          <FlexContainer alignItems="start" justifyContent="space-between">
            <div>
              <When
                condition={notificationGroup?.channels?.email_channels != null}
              >
                <>
                  <Text size="sm" m={0} p={0}>
                    Emails
                  </Text>
                  {notificationGroup?.channels?.email_channels?.map(
                    ({ email }) => (
                      <Text key={email} mt={1}>
                        {email}
                      </Text>
                    )
                  )}
                </>
              </When>
              <When
                condition={notificationGroup?.channels?.slack_channels != null}
              >
                <>
                  <Text size="sm" m={0} p={0}>
                    Slack Channels
                  </Text>
                  {notificationGroup?.channels?.slack_channels?.map(
                    ({ webhookUrl }) => (
                      <Text key={webhookUrl} mt={1}>
                        {webhookUrl}
                      </Text>
                    )
                  )}
                </>
              </When>
              <When
                condition={
                  notificationGroup?.channels?.pager_duty_channels != null
                }
              >
                <>
                  <Text size="sm" m={0} p={0}>
                    PagerDuty Channels
                  </Text>
                  {notificationGroup?.channels?.pager_duty_channels?.map(
                    ({ pagerDutyKey }) => (
                      <Text key={pagerDutyKey} mt={1}>
                        {pagerDutyKey}
                      </Text>
                    )
                  )}
                </>
              </When>
            </div>
            <div>
              <Button
                aria-label="edit button"
                icon="edit"
                size="md"
                severity="low"
                onClick={onEditNotificationChannels}
              />
            </div>
          </FlexContainer>
        </Box>
      </Col>
    </Row>
  );
}
