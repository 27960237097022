/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { DraggableProvided } from "react-beautiful-dnd";
import { When } from "react-if";
import { Button, ExtendedTooltip, SVGIcon, Text, theme } from "@nordcloud/gnui";
import { ExecutionStatus } from "~/generated/graphql";
import { IconWrapper } from "~/components/ToggleItemBox";
import { isNotNil } from "~/tools";
import { SvgName } from "~/tools/types";
import { getEventEntityStatus } from "~/views/events/helpers";
import {
  ActionBatchWrap,
  ActionBatchHeader,
  ActionBatchLabel,
} from "~/views/plans/styled";

type Props = {
  name?: string | JSX.Element;
  provided?: DraggableProvided;
  children: React.ReactNode;
  onDelete?: () => void;
  onAddAction?: () => void;
  onClick?: () => void;
  active?: boolean;
  editName?: string | JSX.Element;
  status?: ExecutionStatus;
};

export function ActionBatch({
  name,
  provided,
  children,
  onDelete,
  onAddAction,
  onClick,
  active,
  editName,
  status,
}: Props) {
  const statusDetails = getEventEntityStatus(status);
  return (
    <ActionBatchWrap
      active={active}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      onMouseDown={onClick}
    >
      <ActionBatchLabel>Action Batch</ActionBatchLabel>
      <ActionBatchHeader
        alignItems="center"
        gap={theme.spacing.spacing02}
        paddingLeft={`${theme.spacing.spacing04} !important`}
      >
        <When condition={isNotNil(provided)}>
          <div
            {...provided?.dragHandleProps}
            css={{ marginRight: `${theme.spacing.spacing03}` }}
          >
            <SVGIcon name="dragAndDrop" size="md" />
          </div>
        </When>
        <When condition={isNotNil(status)}>
          <IconWrapper>
            <ExtendedTooltip
              caption={statusDetails?.description}
              status={statusDetails?.tooltipColor}
              position="start"
            >
              <SVGIcon
                name={statusDetails.icon as SvgName}
                size="md"
                color={statusDetails.color()}
              />
            </ExtendedTooltip>
          </IconWrapper>
        </When>
        <Text
          weight="medium"
          tag="span"
          css={{
            marginRight: "auto",
          }}
        >
          {editName ?? name}
        </Text>

        <When condition={isNotNil(onAddAction)}>
          <Button
            icon="plus"
            size="md"
            severity="low"
            onClick={onAddAction}
            type="button"
          />

          <div css={{ paddingRight: `${theme.spacing.spacing03}` }}>
            <Button onClick={onDelete} icon="trash" size="md" severity="low" />
          </div>
        </When>
      </ActionBatchHeader>
      {children}
    </ActionBatchWrap>
  );
}
