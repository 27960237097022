/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useReducer } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { Box, Button, FlexContainer, Sidebar, theme } from "@nordcloud/gnui";
import { PlanSortFields, SortOrder } from "~/generated/graphql";
import { Mode } from "~/components";
import { FilterWrapper } from "~/components/SidebarWithMultiSelect/FilterWrapper";
import { SelectableListWithSearch } from "~/components/SidebarWithMultiSelect/SelectableListWithSearch";
import { useDisclosure, useQueryState } from "~/hooks";
import { useGetAllActions } from "~/hooks/useGetAllActions";
import { isNotEmpty, isNotNil } from "~/tools";
import {
  eventStatusCheckboxes,
  incomingEventStatusCheckboxes,
  QueryGetEvents,
} from "~/views/events/components/constants";
import { useGetPlans } from "~/views/plans/hooks";
import { EventType } from "../types";

type SidebarFooterProps = {
  onApply: () => void;
  onClear: () => void;
};

function SidebarFooter({ onApply, onClear }: SidebarFooterProps) {
  return (
    <Col>
      <Row>
        <Box spacing="spacing02" css={{ marginBottom: 0 }}>
          <FlexContainer justifyContent="start" alignItems="center">
            <Button
              mr={theme.spacing.spacing04}
              severity="low"
              onClick={onApply}
            >
              Apply
            </Button>
            <Button
              mr={theme.spacing.spacing04}
              severity="low"
              onClick={onClear}
            >
              Clear
            </Button>
          </FlexContainer>
        </Box>
      </Row>
    </Col>
  );
}

type State = {
  eventStatuses: string[];
  excludeEventStatuses: boolean;
  eventRelatedPlanIds: string[];
  excludeEventRelatedPlanIds: boolean;
  eventActionIds: string[];
  excludeEventActionIds: boolean;
};

export function EventFiltersSidebar() {
  const { isOpen, open, close } = useDisclosure();
  const { queryState, updateQueryState } = useQueryState<QueryGetEvents>();

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    {
      eventStatuses: queryState.eventStatuses,
      excludeEventStatuses: queryState.excludeEventStatuses,
      eventActionIds: queryState.eventActionIds,
      excludeEventActionIds: queryState.excludeEventActionIds,
      eventRelatedPlanIds: queryState.eventRelatedPlanIds,
      excludeEventRelatedPlanIds: queryState.excludeEventRelatedPlanIds,
    }
  );

  // synchronize eventStatuses as they are cleared on event type change
  useEffect(() => {
    updateState({ eventStatuses: queryState.eventStatuses });
  }, [queryState.eventStatuses, isOpen]);

  const clearEventStatusFilters = () => {
    updateState({ eventStatuses: [], excludeEventStatuses: false });
  };

  const clearEventActionsFilter = () => {
    updateState({ eventActionIds: [], excludeEventActionIds: false });
  };

  const clearEventRelatedPlanIdsFilter = () => {
    updateState({ eventRelatedPlanIds: [], excludeEventRelatedPlanIds: false });
  };

  const clearAllFilters = () => {
    updateState({
      eventStatuses: [],
      excludeEventStatuses: false,
      eventActionIds: [],
      excludeEventActionIds: false,
      eventRelatedPlanIds: [],
      excludeEventRelatedPlanIds: false,
    });

    updateQueryState({
      ...queryState,
      eventStatuses: [],
      excludeEventStatuses: false,
      eventActionIds: [],
      excludeEventActionIds: false,
      eventRelatedPlanIds: [],
      excludeEventRelatedPlanIds: false,
    });

    close();
  };

  const applyFilters = () => {
    updateQueryState({
      ...queryState,
      eventStatuses: state.eventStatuses,
      excludeEventStatuses: state.excludeEventStatuses,
      eventActionIds: state.eventActionIds,
      excludeEventActionIds: state.excludeEventActionIds,
      eventRelatedPlanIds: state.eventRelatedPlanIds,
      excludeEventRelatedPlanIds: state.excludeEventRelatedPlanIds,
    });

    close();
  };

  const { data } = useGetPlans({
    page: "1",
    limit: "1000",
    sort: {
      field: PlanSortFields.Name,
      order: SortOrder.Asc,
    },
  });

  const planCheckboxes = data.map((plan) => ({
    value: plan.id,
    label: plan.name,
  }));

  const { customActions, systemActions } = useGetAllActions();

  const eventActionCheckboxes = [
    ...systemActions.map((action) => ({
      value: action.id,
      label: action.name,
    })),
    ...customActions.map((action) => ({
      value: action.id,
      label: action.name,
      subLabel: "Custom Action",
    })),
  ];

  return (
    <>
      <Button
        mr={theme.spacing.spacing04}
        severity="low"
        icon="filter"
        onClick={open}
      >
        Filters
      </Button>
      <When condition={isOpen}>
        <Sidebar
          title="Filter Results"
          isOpen={isOpen}
          onClick={close}
          footer={
            <SidebarFooter onApply={applyFilters} onClear={clearAllFilters} />
          }
        >
          <FilterWrapper
            name="Event Status"
            isSelected={
              isNotNil(state.eventStatuses) && isNotEmpty(state.eventStatuses)
            }
            selectedOptions={state.eventStatuses ?? []}
            onClear={clearEventStatusFilters}
          >
            <SelectableListWithSearch
              allowModeChange
              selectedOptions={state.eventStatuses ?? []}
              selectableItems={
                queryState.eventsType === EventType.pastCurrent
                  ? eventStatusCheckboxes
                  : incomingEventStatusCheckboxes
              }
              isExcludeSelected={state.excludeEventStatuses}
              onMultiSelect={(statuses) =>
                updateState({ eventStatuses: statuses })
              }
              onModeChange={(mode) =>
                updateState({ excludeEventStatuses: mode === Mode.EXCLUDES })
              }
            />
          </FilterWrapper>
          <FilterWrapper
            name="Event Actions"
            isSelected={
              isNotNil(state.eventActionIds) && isNotEmpty(state.eventActionIds)
            }
            selectedOptions={state.eventActionIds ?? []}
            onClear={clearEventActionsFilter}
          >
            <SelectableListWithSearch
              allowModeChange
              selectedOptions={state.eventActionIds ?? []}
              selectableItems={eventActionCheckboxes}
              isExcludeSelected={state.excludeEventActionIds}
              onMultiSelect={(actionIds) =>
                updateState({ eventActionIds: actionIds })
              }
              onModeChange={(mode) =>
                updateState({ excludeEventActionIds: mode === Mode.EXCLUDES })
              }
            />
          </FilterWrapper>
          <FilterWrapper
            name="Event Related Plan"
            isSelected={
              isNotNil(state.eventRelatedPlanIds) &&
              isNotEmpty(state.eventRelatedPlanIds)
            }
            selectedOptions={state.eventRelatedPlanIds ?? []}
            onClear={clearEventRelatedPlanIdsFilter}
          >
            <SelectableListWithSearch
              allowModeChange
              selectedOptions={state.eventRelatedPlanIds ?? []}
              selectableItems={planCheckboxes}
              isExcludeSelected={state.excludeEventRelatedPlanIds}
              onMultiSelect={(planIds) =>
                updateState({ eventRelatedPlanIds: planIds })
              }
              onModeChange={(mode) =>
                updateState({
                  excludeEventRelatedPlanIds: mode === Mode.EXCLUDES,
                })
              }
            />
          </FilterWrapper>
        </Sidebar>
      </When>
    </>
  );
}
