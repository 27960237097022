/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { EventActionResourceReport } from "~/generated/graphql";

export type ReportState = {
  resources?: EventActionResourceReport[];
  searchQuery?: string;
  eventActionId: string;
  filterQuery: FilterType;
};

export enum FilterType {
  ALL = "ALL",
  INSTALLED = "INSTALLED",
  UPDATED = "UPDATED",
  AVAILABLE = "AVAILABLE",
  REMOVED = "REMOVED",
  FAILED = "FAILED",
}
