/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { columns } from "../constants";
import { StateItem } from "../types";

type UpdatedGroup = {
  checked: string;
  name: string;
};

export function usePermissionState(defaultState: StateItem[]) {
  const [state, dispatch] = useReducer(appReducer, defaultState);
  function updateState(index: number, updatedGroup: UpdatedGroup) {
    dispatch({
      type: Type.UPDATE,
      payload: { index, updatedGroup },
    });
  }
  function updateRowState(index: number, updatedGroup: UpdatedGroup) {
    dispatch({
      type: Type.UPDATE_ROW,
      payload: { index, updatedGroup },
    });
  }
  function updateColState(index: number, updatedGroup: UpdatedGroup) {
    dispatch({
      type: Type.UPDATE_COL,
      payload: { index, updatedGroup },
    });
  }
  function updateAllState(index: number, updatedGroup: UpdatedGroup) {
    dispatch({
      type: Type.UPDATE_ALL,
      payload: { index, updatedGroup },
    });
  }
  return { state, updateState, updateRowState, updateColState, updateAllState };
}

type Action = {
  type: string;
  payload: {
    index?: number;
    updatedGroup: UpdatedGroup;
  };
};

enum Type {
  UPDATE = "UPDATE",
  UPDATE_ROW = "UPDATE_ROW",
  UPDATE_COL = "UPDATE_COL",
  UPDATE_ALL = "UPDATE_ALL",
}

function appReducer(
  state: StateItem[],
  { type, payload: { index, updatedGroup } }: Action
) {
  switch (type) {
    case Type.UPDATE: {
      const editableRow = state.find(
        (item: { name: string; checked: string[] }) =>
          item.name === updatedGroup.name
      ) || { name: "", checked: [""] };
      const isSelected = editableRow.checked.includes(updatedGroup.checked);
      const unSelected = editableRow.checked.filter((resource: string) =>
        resource === updatedGroup.checked ? null : resource
      );
      const updateList = isSelected
        ? [...unSelected]
        : [updatedGroup.checked, ...unSelected];

      return state.map((item: StateItem, idx: number) => {
        return idx === index
          ? {
              name: updatedGroup.name,
              checked: updateList,
            }
          : item;
      });
    }
    case Type.UPDATE_ROW: {
      return state.map((item: StateItem, idx: number) => {
        const isSelected = columns.every((col) => item.checked.includes(col));
        const updateList = isSelected ? [""] : columns;

        return idx === index
          ? {
              name: updatedGroup.name,
              checked: updateList,
            }
          : item;
      });
    }
    case Type.UPDATE_COL: {
      return state.map((item: StateItem) => {
        const column = columns.findIndex((col) => col === updatedGroup.name);

        const isNotSelected = state.some((row) =>
          row.checked.every((el) => el !== updatedGroup.name)
        );

        const alreadySelected = [...item.checked].filter(
          (e) => e !== updatedGroup.name
        );

        const updateList = isNotSelected
          ? [...item.checked, columns[column]]
          : alreadySelected;

        return {
          name: item.name,
          checked: updateList,
        };
      });
    }
    case Type.UPDATE_ALL: {
      return state.map((item: StateItem) => {
        return {
          name: item.name,
          checked: [""],
        };
      });
    }

    default:
      return state;
  }
}
