/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useResourcesSearchLazyQuery } from "~/generated/graphql";
import { isEmpty } from "~/tools";

export function useResourcesSearch() {
  const [resourcesSearch, { data: resourcesData, loading, error }] =
    useResourcesSearchLazyQuery();

  const data = resourcesData?.resourcesSearch?.result ?? [];
  const count = resourcesData?.resourcesSearch?.pageInfo?.count ?? 0;
  const noData = isEmpty(data);

  return {
    resourcesSearch,
    data,
    loading,
    error,
    count,
    noData,
  } as const;
}
