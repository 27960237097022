/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import lodash from "lodash";
import {
  EventActionAttempt,
  EventActionAttemptReport,
  EventActionResourceReport,
  ResourceExecutionStatus,
  ResourceReportPackage,
} from "~/generated/graphql";
import { useGetEventActionReports } from "~/views/events/hooks/";
import { useReportState } from "./ReportProvider";
import { FilterType } from "./types";

type Props = {
  eventId: string;
  eventActionId: string;
  attempt?: EventActionAttempt;
};

export type ExtendedEventActionResource = EventActionResourceReport & {
  installed?: string[];
  available?: string[];
  removed?: string[];
  updated?: string[];
  failed?: string[];
};

type FilterReportResourcesArgs = {
  resources: EventActionResourceReport[];
  filter: FilterType;
  query?: string;
  attempt?: number;
};

const filterReportDataByAttempt = (
  data?: EventActionAttemptReport[],
  attemptValue?: EventActionAttempt
) => {
  if (attemptValue?.attempt === undefined) {
    return data;
  }
  return data?.filter((item) => item?.attempt === attemptValue?.attempt);
};

export function useGetFilteredReport({
  eventId,
  eventActionId,
  attempt,
}: Props) {
  const {
    data: reportData,
    loading,
    error,
  } = useGetEventActionReports({
    eventId,
    eventActionId,
  });
  const {
    reportState: { filterQuery, searchQuery },
    updateReportState,
  } = useReportState();

  useEffect(() => {
    const filtered = filterReportDataByAttempt(reportData, attempt);

    if (filtered) {
      const resources: EventActionResourceReport[] = filtered
        ?.map((item) => item.report.resources)
        .flat()
        .map((item) => {
          return {
            ...item,
            name: item?.name ?? "",
            resourceId: item?.resourceId ?? "",
            status: item?.status ?? ResourceExecutionStatus.Error,
            result: item?.result ?? {
              output: "",
              outputUri: "",
              installed: [],
              available: [],
              removed: [],
              updated: [],
              failed: [],
            },
          };
        });
      updateReportState({ resources: resources });
    }
  }, [attempt, reportData, updateReportState]);

  function filterReportResources({
    resources,
    filter,
    query,
  }: FilterReportResourcesArgs) {
    const filteredResourcesByStatus = filterByStatus(resources, filter);
    return filterByQuery(filteredResourcesByStatus, query);
  }

  const filterByQuery = (
    resources: EventActionResourceReport[],
    query: string | undefined
  ) => {
    const resourcesToReturn = lodash.cloneDeep(resources);

    if (!query) {
      return resourcesToReturn;
    }
    // user needs to input exact resourceId to avoid random matches
    const isResourceIdMatch = resources.some((resource) => {
      return resource.resourceId === query;
    });

    if (isResourceIdMatch) {
      return resourcesToReturn.filter((resource) => {
        return resource.resourceId === query;
      });
    }

    const isResourceNameMatch = resources.some((resource) => {
      return resource.name.toLowerCase().includes(query.toLowerCase());
    });

    if (isResourceNameMatch) {
      return resourcesToReturn.filter((resource) => {
        return resource.name.toLowerCase().includes(query.toLowerCase());
      });
    }

    const filteredByPackageName = resources.map((resource) => {
      const packageFilterByQuery = (q: string) => {
        return (pkg: ResourceReportPackage) => {
          return pkg.name.toLowerCase().includes(q.toLowerCase());
        };
      };

      return {
        ...resource,
        result: resource?.result
          ? {
              ...resource.result,
              installed:
                resource.result?.installed?.filter(
                  packageFilterByQuery(query)
                ) ?? [],
              available:
                resource.result?.available?.filter(
                  packageFilterByQuery(query)
                ) ?? [],
              removed:
                resource.result?.removed?.filter(packageFilterByQuery(query)) ??
                [],
              updated:
                resource.result?.updated?.filter(packageFilterByQuery(query)) ??
                [],
              failed:
                resource.result?.failed?.filter(packageFilterByQuery(query)) ??
                [],
            }
          : {
              output: "",
              outputUri: "",
              installed: [],
              available: [],
              removed: [],
              updated: [],
              failed: [],
            },
      };
    });

    return filteredByPackageName.filter(filterResourcesWithEmptyResultPackages);
  };

  const filterByStatus = (
    resources: EventActionResourceReport[],
    status?: FilterType
  ) => {
    const resourcesToReturn = lodash.cloneDeep(resources);

    if (!status?.length) {
      return resourcesToReturn;
    }

    switch (status) {
      case FilterType.ALL:
        return resourcesToReturn;

      case FilterType.INSTALLED:
        return resources
          .map((resource) => {
            return {
              ...resource,
              result: resource?.result
                ? {
                    ...resource.result,
                    installed: resource.result?.installed,
                    available: [],
                    removed: [],
                    updated: [],
                    failed: [],
                  }
                : {
                    output: "",
                    outputUri: "",
                    installed: [],
                    available: [],
                    removed: [],
                    updated: [],
                    failed: [],
                  },
            };
          })
          .filter(filterResourcesWithEmptyResultPackages);
      case FilterType.UPDATED:
        return resources
          .map((resource) => {
            return {
              ...resource,
              result: resource?.result
                ? {
                    ...resource.result,
                    installed: [],
                    available: [],
                    removed: [],
                    updated: resource.result?.updated,
                    failed: [],
                  }
                : {
                    output: "",
                    outputUri: "",
                    installed: [],
                    available: [],
                    removed: [],
                    updated: [],
                    failed: [],
                  },
            };
          })
          .filter(filterResourcesWithEmptyResultPackages);

      case FilterType.AVAILABLE:
        return resources
          .map((resource) => {
            return {
              ...resource,
              result: resource?.result
                ? {
                    ...resource.result,
                    installed: [],
                    available: resource.result?.available,
                    removed: [],
                    updated: [],
                    failed: [],
                  }
                : {
                    output: "",
                    outputUri: "",
                    installed: [],
                    available: [],
                    removed: [],
                    updated: [],
                    failed: [],
                  },
            };
          })
          .filter(filterResourcesWithEmptyResultPackages);

      case FilterType.REMOVED:
        return resources
          .map((resource) => {
            return {
              ...resource,
              result: resource?.result
                ? {
                    ...resource.result,
                    installed: [],
                    available: [],
                    removed: resource.result?.removed,
                    updated: [],
                    failed: [],
                  }
                : {
                    output: "",
                    outputUri: "",
                    installed: [],
                    available: [],
                    removed: [],
                    updated: [],
                    failed: [],
                  },
            };
          })
          .filter(filterResourcesWithEmptyResultPackages);

      case FilterType.FAILED:
        return resources
          .map((resource) => {
            return {
              ...resource,
              result: resource?.result
                ? {
                    ...resource.result,
                    installed: [],
                    available: [],
                    removed: [],
                    updated: [],
                    failed: resource.result?.failed,
                  }
                : {
                    output: "",
                    outputUri: "",
                    installed: [],
                    available: [],
                    removed: [],
                    updated: [],
                    failed: [],
                  },
            };
          })
          .filter(filterResourcesWithEmptyResultPackages);

      default:
        return resourcesToReturn;
    }
  };

  const filterResourcesWithEmptyResultPackages = (
    resource: EventActionResourceReport
  ) => {
    const allAreEmpty =
      lodash.isEmpty(resource.result.installed) &&
      lodash.isEmpty(resource.result.available) &&
      lodash.isEmpty(resource.result.removed) &&
      lodash.isEmpty(resource.result.updated) &&
      lodash.isEmpty(resource.result.failed);
    return !allAreEmpty;
  };

  const filteredData = filterReportDataByAttempt(reportData, attempt);

  const flatResults: ExtendedEventActionResource[] =
    filteredData
      ?.map((item) => item.report.resources)
      .flat()
      .map((item) => {
        return {
          ...item,
          name: item?.name ?? "",
          resourceId: item?.resourceId ?? "",
          status: item?.status ?? ResourceExecutionStatus.Error,
          result: item?.result ?? {
            output: "",
            outputUri: "",
            installed: [],
            available: [],
            removed: [],
            updated: [],
            failed: [],
          },
          installed: item?.result.installed?.map((i) => i.name),
          available: item?.result.available?.map((i) => i.name),
          removed: item?.result.removed?.map((i) => i.name),
          updated: item?.result.updated?.map((i) => i.name),
          failed: item?.result.failed?.map((i) => i.name),
        };
      }) ?? [];

  const reportResources = filterReportResources({
    resources: flatResults,
    filter: filterQuery,
    query: searchQuery,
    attempt: attempt?.attempt,
  });

  return { reportResources, loading, error };
}
