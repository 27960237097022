/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box, Button } from "@nordcloud/gnui";
import { StyledLink } from "~/components";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { NotificationGroupsTable } from "./components/NotificationGroupsTable";

const breadcrumbsLabels = [
  { label: "Dashboard", uri: "/" },
  { label: "Notification Groups", uri: ROUTES.notificationGroups.index },
];

export function NotificationGroupsPage() {
  return (
    <>
      <Row>
        <Col>
          <BreadcrumbsBox
            title="Notification Groups"
            customBreadcrumbs={breadcrumbsLabels}
          >
            <StyledLink to={ROUTES.notificationGroups.create}>
              <Button icon="plus">Create Notification Group</Button>
            </StyledLink>
          </BreadcrumbsBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <NotificationGroupsTable />
          </Box>
        </Col>
      </Row>
    </>
  );
}
