/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { FlexContainer, theme } from "@nordcloud/gnui";

export const ModalContent = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  max-height: 30vh;
`;

export const ModalFooter = styled(FlexContainer)`
  position: sticky;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  background-color: ${theme.color.background.ui01};
  width: 100%;
  padding: ${theme.spacing.spacing04} 0 0 0;
  z-index: ${theme.zindex.popup};
`;
