/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import {
  FilterComparisonOperator,
  EventsFilterByFields,
  IncomingEventsFilterByFields,
  PlansFilterByFields,
} from "~/generated/graphql";
import { isNotNil, isNotEmpty } from "~/tools";

export function createFilterByInput<
  T extends
    | EventsFilterByFields
    | IncomingEventsFilterByFields
    | PlansFilterByFields
>(field: T, values: string[], exclude: boolean) {
  return isNotNil(values) && isNotEmpty(values)
    ? [
        {
          field,
          operator: exclude
            ? FilterComparisonOperator.Ne
            : FilterComparisonOperator.Eq,
          values,
        },
      ]
    : [];
}
