/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";

export function ResourceCreate() {
  return (
    <>
      <BreadcrumbsBox
        title="Add Resource"
        customBreadcrumbs={[
          { label: "Dashboard", uri: "/" },
          { label: "Resources", uri: ROUTES.resources.index },
          { label: "Add Resource", uri: ROUTES.resources.create },
        ]}
      />
    </>
  );
}
