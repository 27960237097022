/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

enum Scope {
  RESOURCE = "RESOURCE",
  PLAN = "PLAN",
}

export function getScope(scope: string): string {
  switch (scope) {
    case Scope.RESOURCE:
      return "Resource Based";
    case Scope.PLAN:
      return "Plan Based";
    default:
      return "n/a";
  }
}
