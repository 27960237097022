/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import * as React from "react";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

type WrapProps = {
  isVisible: boolean;
};

const Wrap = styled.div<WrapProps>`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${theme.colors.danger};
    border-radius: 50%;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: ${theme.transition};
  }
`;

type Props = {
  children: React.ReactNode;
  isVisible?: boolean;
};

export function RedIndicator({ children, isVisible = false }: Props) {
  return (
    <span>
      <Wrap aria-label={isVisible ? "error" : ""} {...{ isVisible }}>
        {children}
      </Wrap>
    </span>
  );
}
