/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { EventActionAttempt } from "~/generated/graphql";
import { createCtx } from "~/tools";
import { EventEntity } from "~/views/events/types";

type Props = {
  eventAction?: EventEntity;
  attempt?: EventActionAttempt;
  setEventAction?: (eventAction: EventEntity) => void;
  setAttempt?: (attempt: EventActionAttempt) => void;
};

export const [useEventDetailsTab, EventDetailsTabCtxProvider] =
  createCtx<Props>();
