/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client";
import { When } from "react-if";
import { FlexContainer, Message, Text } from "@nordcloud/gnui";

type Props = {
  error?: ApolloError | Error;
  status?: "danger" | "notification";
  onClick?: () => void;
};

export function ErrorMessage({ error, status = "danger" }: Props) {
  return (
    <When condition={error != null}>
      <Message status={status} image="danger">
        <FlexContainer>
          <Text css={{ color: "inherit" }}>
            Sorry, something went wrong. Please try again or contact us using
            products@nordcloud.com.
            <br />
            {error?.message}
          </Text>
        </FlexContainer>
      </Message>
    </When>
  );
}
