/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { createCtx } from "~/tools";
import { NotificationGroupData } from "./constants";

type Props = {
  notificationGroupData?: NotificationGroupData;
  setNotificationGroupData: Dispatch<SetStateAction<NotificationGroupData>>;
};

export const [
  useNotificationGroupsWizard,
  NotificationGroupsWizardCtxProvider,
] = createCtx<Props>();
