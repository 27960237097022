/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { isEmpty } from "~/tools";
import { DeleteResourceGroup } from "./DeleteResourceGroup/DeleteResourceGroup";

type Props = {
  resourceGroupId: string;
  resourceGroupName?: string;
  onSuccess?: () => void;
  size?: "large" | "small";
};

export function Actions({
  resourceGroupId,
  resourceGroupName,
  size = "small",
}: Props) {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openBatchDeleteModal,
  } = useDisclosure(false);

  const noResourceGroupId = isEmpty(resourceGroupId ?? []);

  return (
    <>
      <PopoverControls
        disabled={noResourceGroupId}
        size={size}
        items={[
          {
            label: `Delete Resource Group`,
            onClick: openBatchDeleteModal,
          },
        ]}
      />
      <When condition={isDeleteModalOpen}>
        <DeleteResourceGroup
          {...{
            groupId: resourceGroupId,
            groupName: resourceGroupName,
            isDeleteModalOpen: isDeleteModalOpen,
          }}
          close={closeDeleteModal}
        />
      </When>
    </>
  );
}
