/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PlanField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import { PlanGeneralNotificationForm } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanGeneralNotificationForms";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";

type Props = { nextStep: () => void };

export function GeneralNotificationWrapper({ nextStep }: Props) {
  const { setPlanData } = usePlanWizard();

  const onSubmit = (formData: FormData) => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.GENERAL_NOTIFICATIONS]: {
        notificationGroupsGeneral: formData.notificationGroupsGeneral,
      },
    }));
  };

  return <PlanGeneralNotificationForm {...{ nextStep, onSubmit }} />;
}
