/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer } from "@nordcloud/gnui";
import { CloudAccountStatus } from "~/generated/graphql";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { Action } from "./Action";
import { CredentialEditSidebar } from "./CredentialEditSidebar";

type Props = {
  title: string;
  status?: CloudAccountStatus;
};

export function AccountDetailsBreadcrumbs({ title }: Props) {
  return (
    <BreadcrumbsBox
      title={title}
      customBreadcrumbs={[
        { label: "Home", uri: "/" },
        { label: "Cloud Accounts", uri: ROUTES.accounts.index },
        { label: title, uri: "" },
      ]}
    >
      <CredentialEditSidebar />
      <FlexContainer>
        <Action />
      </FlexContainer>
    </BreadcrumbsBox>
  );
}
