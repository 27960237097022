/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { Else, If, Then } from "react-if";
import styled from "styled-components";
import { Box, Button, FlexContainer, theme } from "@nordcloud/gnui";
import { Group } from "~/generated/graphql";
import { LoaderWrap } from "~/components";
import { ResourceGroupItem } from "~/components/resourceGroup/ResourceGroupItem";
import { ResourceGroupsNoData } from "~/components/resourceGroup/ResourceGroupsNoData";
import { isNotEmpty, isNotNil } from "~/tools";
import { useGetAllResourceGroups } from "~/views/resourceGroups/hooks";
import { PlanField } from "../../constants";
import { usePlanWizard } from "../../PlanProvider";
import { useStepState } from "../StepProvider";
import { AddResourceGroup } from "./AddResourceGroup";
import { updateEntities } from "./utils";

export function ActionResourceGroups() {
  const { stepState, updateStepState } = useStepState();
  const { setPlanData } = usePlanWizard();

  const { getGroups, data, loading, refetch } = useGetAllResourceGroups();
  const resourceGroups = stepState.selectedEntity?.resourceGroupIds
    ?.map((id) => data.find((group) => group.id === id))
    .filter(Boolean);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleRemove = (id: string) => {
    const updatedResourceGroupIds = {
      resourceGroupIds: stepState.selectedEntity?.resourceGroupIds?.filter(
        (groupId) => id !== groupId
      ),
    };
    const updatedEntities = updateEntities(stepState, updatedResourceGroupIds);

    updateStepState({
      entities: updatedEntities,
      selectedEntity: {
        ...stepState.selectedEntity,
        ...updatedResourceGroupIds,
      },
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: updatedEntities,
      },
    }));
  };

  return (
    <FlexContainer css={{ flex: 1 }} direction="column">
      <Box mb={theme.spacing.spacing04}>
        <AddResourceGroup refetch={refetch} />
      </Box>
      <If
        condition={isNotEmpty(stepState.selectedEntity?.resourceGroupIds ?? [])}
      >
        <Then>
          <LoaderWrap loading={loading} inContent>
            <Wrap>
              {resourceGroups?.filter(isNotNil).map((resourceGroup) => {
                return (
                  <ResourceGroupItem
                    key={resourceGroup.id}
                    resourceGroup={resourceGroup as Group}
                    actions={() => (
                      <Button
                        severity="low"
                        size="md"
                        icon="trash"
                        onClick={() => handleRemove(resourceGroup.id)}
                      />
                    )}
                  />
                );
              })}
            </Wrap>
          </LoaderWrap>
        </Then>
        <Else>
          <ResourceGroupsNoData />
        </Else>
      </If>
    </FlexContainer>
  );
}

const Wrap = styled.div`
  width: 100%;
  & > :not(:first-child) {
    margin-top: ${theme.spacing.spacing04};
  }
`;
