// Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.

import { CloudAccount } from "~/generated/graphql";
import { createCtx } from "~/tools/createCtx";

type Props = {
  cleanRowSelection?: () => void;
  cloudAccount?: CloudAccount;
};

export const [useCloudAccount, CloudAccountCtxProvider] = createCtx<Props>();
