// Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
import { Action } from "~/generated/graphql";
import { DetailData } from "~/components/Details/Detail";
import { stringRequired } from "~/components/Forms";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { useUpdateCustomAction } from "../../hooks/useUpdateCustomAction/useUpdateCustomAction";

const successMessage = () =>
  showSuccess(generateActionSuccessText("Custom Action")()("updated")());

export const HeaderPreset: (customAction?: Partial<Action>) => DetailData[] = (
  customAction
) => [
  {
    label: "Action Name",
    value: customAction?.name ?? customAction?.id,
    editProps: {
      property: "name",
      updateHook: useUpdateCustomAction,
      schema: {
        name: stringRequired("Name"),
      },
      onSuccess: successMessage,
    },
  },
];

export const DetailsFirstPreset: (
  customAction?: Partial<Action>
) => DetailData[] = (customAction) => [
  {
    label: "Custom Action ID",
    value: customAction?.id,
    clipboard: true,
  },
  {
    label: "Description",
    value: customAction?.description,
    editProps: {
      property: "description",
      updateHook: useUpdateCustomAction,
      schema: {
        description: stringRequired("Description"),
      },
      onSuccess: successMessage,
    },
  },
];
