/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useReducer, useState } from "react";
import {
  Button,
  FlexContainer,
  SVGIcon,
  Tab,
  Tabs,
  theme,
} from "@nordcloud/gnui";
import { WizardButton, WrapWizardButton } from "~/components";
import { useCreateCustomAction } from "~/views/customActions/hooks/useCreateCustomAction/useCreateCustomAction";
import { CustomActionDetails, CustomActionSettings } from "./components";
import { ConfirmAndFinalize } from "./ConfirmAndFinalize";
import { CustomActionData, defaultValues } from "./constants";
import { CustomActionWizardCtxProvider } from "./CustomActionProvider";

export type State = {
  currentStep: number;
  subStep: number;
  customActionId: string;
};

const defaultState = { currentStep: 0, subStep: 0, customActionId: "" };

export function CustomActionCreateWizard() {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    defaultState
  );

  const [customActionData, setCustomActionData] =
    useState<CustomActionData>(defaultValues);

  const handleSuccess = (id: string) =>
    updateState({ customActionId: id, subStep: 1 });

  const { createCustomAction, loading } = useCreateCustomAction({
    onSuccess: handleSuccess,
  });

  const handleCreateCustomAction = () =>
    createCustomAction({ ...customActionData });

  const nextStep = () => updateState({ currentStep: state.currentStep + 1 });
  const previousStep = () =>
    updateState({ currentStep: state.currentStep - 1 });
  const handleSteps = (key: number) => updateState({ currentStep: key });

  const buttonsStep1 = (
    <WrapWizardButton>
      <WizardButton type="submit" form="wizard_step_1">
        <FlexContainer gap={theme.spacing.spacing02}>
          Next Step
          <SVGIcon name="arrowRight" size="sm" />
        </FlexContainer>
      </WizardButton>
    </WrapWizardButton>
  );
  const buttonsStep2 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton type="submit" form="settings-form">
        <FlexContainer gap={theme.spacing.spacing02}>
          Next Step
          <SVGIcon name="arrowRight" size="sm" />
        </FlexContainer>
      </WizardButton>
    </WrapWizardButton>
  );

  const onAddNewCustomAction = () => {
    updateState({ currentStep: 0, subStep: 0 });
  };
  const onEditActionSettings = () => {
    updateState({ currentStep: 1, subStep: 0 });
  };

  const buttonsStep3 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton
        type="submit"
        onClick={handleCreateCustomAction}
        disabled={loading}
        initialState={loading ? "loading" : ""}
      >
        Create Action
      </WizardButton>
    </WrapWizardButton>
  );

  return (
    <CustomActionWizardCtxProvider
      value={{ customActionData, setCustomActionData }}
    >
      <Tabs step={state.currentStep} wizard handleTab={handleSteps}>
        <Tab disabled heading="Action Details" buttons={buttonsStep1}>
          <CustomActionDetails
            {...{ nextStep }}
            initialValues={customActionData}
          />
        </Tab>
        <Tab disabled heading="Action Settings" buttons={buttonsStep2}>
          <CustomActionSettings
            {...{ nextStep }}
            initialValues={customActionData}
          />
        </Tab>
        <Tab
          disabled
          heading="Confirm and Finalize"
          buttons={state.subStep === 0 && buttonsStep3}
        >
          <ConfirmAndFinalize
            customActionId={state.customActionId}
            customActionData={customActionData}
            onAddNewCustomAction={onAddNewCustomAction}
            onEditActionSettings={onEditActionSettings}
            step={state.subStep}
          />
        </Tab>
      </Tabs>
    </CustomActionWizardCtxProvider>
  );
}
