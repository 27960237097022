/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useCallback, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Case, Switch } from "react-if";
import { ZodType } from "zod";
import { ActionScope, ActionType } from "~/generated/graphql";
import { CustomActionData, CustomActionField } from "../../constants";
import { useCustomActionWizard } from "../../CustomActionProvider";
import { FormData, FormField } from "../../formConfig";
import {
  ActionScopeRadio,
  ActionTypeSelect,
  AwsForm,
  AzureForm,
  ScriptForm,
  SSMForm,
  WebHookForm,
} from "./components";
import {
  azureFunctionSchema,
  baseActionSchema,
  lambdaArnSchema,
  scriptFileSchema,
  ssmDocumentSchema,
  webHookSchema,
} from "./schema";

type Props = {
  nextStep?: () => void;
  initialValues: CustomActionData;
  onSubmit?: (data: FormData) => void;
};

export function CustomActionSettings({
  nextStep,
  initialValues,
  onSubmit,
}: Props) {
  const selectActionSchema = (actionType: string) => {
    switch (actionType) {
      case ActionType.AwsLambda:
        return lambdaArnSchema;
      case ActionType.AzureFunction:
        return azureFunctionSchema;
      case ActionType.SsmDocument:
        return ssmDocumentSchema;
      case ActionType.Webhook:
        return webHookSchema;
      case ActionType.Script:
        return scriptFileSchema;
      default:
        return baseActionSchema;
    }
  };

  const onTypeChange = useCallback((type: ActionType | string) => {
    setSchema(selectActionSchema(type));
  }, []);

  const onScopeChange = () => setSchema(baseActionSchema);
  const [currentSchema, setSchema] = useState<ZodType>(baseActionSchema);

  const formMethods = useForm({
    resolver: zodResolver(currentSchema),
    defaultValues: {
      [FormField.SCOPE]: initialValues.settings?.scope ?? ActionScope.Plan,
      [FormField.TYPE]: initialValues.settings?.type ?? undefined,
      [FormField.LAMBDA_ARN]: initialValues.settings?.lambdaArn ?? undefined,
      [FormField.FUNCTION_URL]:
        initialValues.settings?.functionUrl ?? undefined,
      [FormField.WEB_HOOK_URL]: initialValues.settings?.webhookUrl ?? undefined,
      [FormField.FUNCTION_METHOD]:
        initialValues.settings?.functionMethod ?? undefined,
      [FormField.WEBHOOK_METHOD]:
        initialValues.settings?.webhookMethod ?? undefined,
      [FormField.ACCESS_KEY_ID]:
        initialValues.settings?.accessKeyId ?? undefined,
      [FormField.SSM_DOCUMENT_ID]:
        initialValues.settings?.documentId ?? undefined,
      [FormField.PAYLOAD]: initialValues.settings?.payload ?? undefined,
      [FormField.HEADERS]: initialValues.settings?.headers ?? undefined,
      [FormField.SCRIPT_FILE]: initialValues.settings?.script ?? undefined,
    },
  });

  const { setCustomActionData } = useCustomActionWizard();
  const { handleSubmit, watch } = formMethods;
  const submit: SubmitHandler<FormData> = (formData) => {
    setCustomActionData((prevResourceData) => ({
      ...prevResourceData,
      [CustomActionField.SETTINGS]: {
        ...formData,
        script: watcher.script,
        functionMethod: watcher.functionMethod,
        webhookMethod: watcher.webhookMethod,
      },
    }));
    onSubmit?.({
      ...formData,
      script: watcher.script,
      functionMethod: watcher.functionMethod,
      webhookMethod: watcher.webhookMethod,
    });
    nextStep?.();
  };
  const watcher = watch();

  useEffect(() => {
    onTypeChange(watcher.type ?? ActionType.AwsLambda);
  }, [onTypeChange, watcher.type]);

  return (
    <>
      <Row justify="center">
        <Col sm={6} md={6} lg={8}>
          <Row>
            <Col>
              <FormProvider {...formMethods}>
                <form id="settings-form" onSubmit={handleSubmit(submit)}>
                  <ActionScopeRadio onScopeChange={onScopeChange} />
                  <ActionTypeSelect onTypeChange={onTypeChange} />
                  <Switch>
                    <Case condition={watcher.type === ActionType.AwsLambda}>
                      <AwsForm />
                    </Case>
                    <Case condition={watcher.type === ActionType.AzureFunction}>
                      <AzureForm />
                    </Case>
                    <Case condition={watcher.type === ActionType.SsmDocument}>
                      <SSMForm />
                    </Case>
                    <Case condition={watcher.type === ActionType.Script}>
                      <ScriptForm />
                    </Case>
                    <Case condition={watcher.type === ActionType.Webhook}>
                      <WebHookForm />
                    </Case>
                  </Switch>
                </form>
              </FormProvider>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
