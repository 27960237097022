/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { FlexContainer } from "@nordcloud/gnui";
import { AddEmailForm } from "./AddEmailForm";
import { EmailsTable } from "./EmailsTable";

export function EmailTab() {
  return (
    <>
      <Row>
        <Col>
          <FlexContainer justifyContent="flex-end">
            <AddEmailForm />
          </FlexContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <EmailsTable />
        </Col>
      </Row>
    </>
  );
}
