/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";

export const emailSchema = z.object({
  email: z
    .string()
    .min(1, { message: "This field has to be filled." })
    .email("This is not a valid email."),
});

export const slackSchema = z.object({
  webhookUrl: z.string().url({ message: "This field must be a valid URL." }),
});

export const pagerDutySchema = z.object({
  pagerDutyKey: z.string().length(32, {
    message: "The PagerDuty key must be exactly 32 characters long.",
  }),
});
