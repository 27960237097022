/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { PlanCreateWizard } from "./components";

export function PlanCreate() {
  return (
    <>
      <BreadcrumbsBox
        title="Create Plan"
        customBreadcrumbs={[
          { label: "Dashboard", uri: "/" },
          { label: "Plan", uri: ROUTES.plans.index },
          { label: "Create Plan", uri: ROUTES.plans.create },
        ]}
      />
      <PlanCreateWizard />
    </>
  );
}
