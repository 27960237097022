/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { KeyValueInput } from "~/generated/graphql";

export enum FormField {
  NAME = "name",
  DESCRIPTION = "description",
  METADATA = "metadata",
}

export type FormData = {
  [FormField.NAME]: string;
  [FormField.DESCRIPTION]: string;
  [FormField.METADATA]: KeyValueInput[] | undefined;
};
