/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { ExtendedTooltip, theme } from "@nordcloud/gnui";
import { TooltipText } from "~/components/Styled";

type TooltipProps = {
  tooltip?: ReactNode;
  description: string;
};

export function NextDateTooltip({ tooltip, description }: TooltipProps) {
  return (
    <ExtendedTooltip
      caption={tooltip && <>{tooltip}</>}
      hideTimeout={0}
      zIndex={theme.zindex.topoftheworld}
    >
      <TooltipText
        size="sm"
        tag="span"
        mr={theme.spacing.spacing01}
        weight="regular"
        color={theme.color.text.text02}
        css={{
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          textDecorationThickness: "1px",
          textDecorationColor: theme.color.text.text02,
          textUnderlineOffset: theme.spacing.spacing01,
        }}
      >
        {description}
      </TooltipText>
    </ExtendedTooltip>
  );
}
