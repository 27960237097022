/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import { FlexContainer, theme } from "@nordcloud/gnui";
import { FlexColumnMax, FlexColumnLarge } from "~/components/Tables/styles";
import { DeleteSlack } from "./DeleteSlack";
import { EditSlackForm } from "./EditSlackForm";

export type Slack = {
  id: string;
  webhookUrl: string;
};

const columnHelper = createColumnHelper<Slack>();

export function columns() {
  return [
    columnHelper.accessor(() => null, {
      header: "Slack URL",
      cell: ({ row }) => <div>{row.original.webhookUrl}</div>,
      meta: FlexColumnMax,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const { webhookUrl, id } = row.original;

        return (
          <FlexContainer
            justifyContent="space-between"
            gap={theme.spacing.spacing01}
          >
            <EditSlackForm slackId={id} defaultValue={webhookUrl} />
            <DeleteSlack webhookUrl={webhookUrl} channelId={id} />
          </FlexContainer>
        );
      },
      meta: FlexColumnLarge,
    }),
  ] as never;
}
