/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import {
  Box,
  Heading,
  Message,
  Text,
  theme,
  FlexContainer,
  Spacer,
  ExtendedTooltip,
} from "@nordcloud/gnui";
import { Resource } from "~/generated/graphql";
import { TooltipText } from "~/components";
import { Item } from "~/types";
import { ResourceSelect } from "~/views/resourceGroups/components/Resource";
import { Props, ResourceGroupField } from "../constants";

export function ResourceSelectForm({
  onSubmit,
  onDynamicSubmit,
  setResourceGroupData,
  resourceGroupData,
}: Props) {
  const [selectedResources, setSelectedResources] = useState<string[]>(
    resourceGroupData[ResourceGroupField.MANUAL_SELECTED_RESOURCES]
  );

  const [dynamicResources, setDynamicResources] = useState<Item[]>(
    resourceGroupData?.[ResourceGroupField.DYNAMIC_RESOURCES] ?? []
  );

  const [allResources, setAllResources] = useState<Resource[]>(
    resourceGroupData[ResourceGroupField.ALL_RESOURCES]
  );

  const [expression, setExpression] = useState<string | undefined>(
    resourceGroupData.expression
  );

  const handleFormSubmit = () => {
    const formData = {
      ...resourceGroupData,
      [ResourceGroupField.MANUAL_SELECTED_RESOURCES]: selectedResources,
      [ResourceGroupField.ALL_RESOURCES]: allResources,
    };
    onSubmit(formData);
  };

  const handleReorder = (ids: string[]) => {
    setSelectedResources(ids);
  };

  useEffect(() => {
    setResourceGroupData((prevResourceData) => {
      return {
        ...prevResourceData,
        [ResourceGroupField.MANUAL_SELECTED_RESOURCES]: selectedResources,
        [ResourceGroupField.DYNAMIC_RESOURCES]: dynamicResources,
        [ResourceGroupField.ALL_RESOURCES]: allResources,
        [ResourceGroupField.EXPRESSION]: expression,
      };
    });
  }, [
    allResources,
    dynamicResources,
    selectedResources,
    setResourceGroupData,
    expression,
  ]);

  return (
    <>
      <Row justify="center">
        <Col sm={6} md={6} lg={8}>
          <Heading level={3}>Add resources to resource group</Heading>
          <Text size="sm" mb={theme.spacing.spacing04}>
            Add resources manually together with expressions. Resources will be
            executed in the order they appear on the list. You can drag and drop
            resources however you want.
          </Text>

          <When condition={expression}>
            <Message status="notification" image="info">
              <FlexContainer direction="column" alignItems="start">
                <Text
                  color={theme.color.text.info}
                  mb={theme.spacing.spacing01}
                >
                  Expression Provided
                </Text>
                <ExtendedTooltip
                  caption={resourceGroupData.expression}
                  position="center"
                >
                  <TooltipText
                    size="md"
                    weight="regular"
                    color={theme.color.text.text01}
                  >
                    Show Expression
                  </TooltipText>
                </ExtendedTooltip>
              </FlexContainer>
            </Message>
            <Spacer height={theme.spacing.spacing03} />
          </When>

          <Box border="grey" padding={0}>
            <ResourceSelect
              {...{
                selectedResources,
                setSelectedResources,
                dynamicResources,
                setDynamicResources,
                allResources,
                setAllResources,
                resourceGroupData,
                handleReorder,
                setExpression,
              }}
              onSubmit={handleFormSubmit}
              onDynamicSubmit={onDynamicSubmit}
            />
          </Box>
        </Col>
      </Row>
    </>
  );
}
