/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { useHistory } from "react-router-dom";
import {
  Heading,
  Message,
  Spacer,
  SVGIcon,
  Box,
  FlexContainer,
  Button,
  Text,
  theme,
} from "@nordcloud/gnui";
import { InfoCard, DataDisplayWrapper } from "~/components";
import { ROUTES } from "~/routing/routes";
import { renderActionSettingsForm } from "~/views/customActions/components/renderActionSettingsForm";
import { Box as ColorBox } from "../../components/CustomActionCreateWizard/components/CustomActionDetails/ColorPicker/Box";
import { CustomActionData } from "./constants";

type Props = {
  customActionId: string;
  customActionData: CustomActionData;
  onAddNewCustomAction: () => void;
  step: number;
  onEditActionSettings: () => void;
};

export function ConfirmAndFinalize({
  customActionId,
  onAddNewCustomAction,
  step,
  onEditActionSettings,
  customActionData,
}: Props) {
  const history = useHistory();

  return (
    <>
      <When condition={step === 0}>
        <Row justify="center">
          <Col sm={6} md={6} lg={8}>
            <Heading marginBottom="spacing04" level={4}>
              Action Details
            </Heading>
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer alignItems="start" justifyContent="space-between">
                <div>
                  <Text size="sm" mb={0}>
                    Action Name
                  </Text>
                  <Text size="md">{customActionData.details?.name}</Text>
                  <Text size="sm" mb={0}>
                    Action Color
                  </Text>
                  <ColorBox
                    size={theme.spacing.spacing08}
                    color={customActionData.details?.color}
                  />
                  <Spacer height={theme.spacing.spacing04} />
                  <Text size="sm" mb={0}>
                    Description
                  </Text>
                  <DataDisplayWrapper
                    hasData={customActionData.details?.description !== ""}
                  >
                    {customActionData.details?.description}
                  </DataDisplayWrapper>
                </div>
                <div>
                  <Button
                    aria-label="edit button"
                    icon="edit"
                    size="md"
                    severity="low"
                    onClick={onAddNewCustomAction}
                  />
                </div>
              </FlexContainer>
            </Box>
            <Heading marginBottom="spacing04" level={4}>
              Action Settings
            </Heading>
            <Box boxStyle="lightGrey" spacing="spacing04">
              <FlexContainer alignItems="start" justifyContent="space-between">
                <div>
                  <div data-testid="scope">
                    <Text size="sm" mb={0}>
                      Scope
                    </Text>
                    <DataDisplayWrapper
                      hasData={customActionData.settings?.scope?.length !== 0}
                    >
                      {customActionData.settings?.scope}
                    </DataDisplayWrapper>
                  </div>
                  <Spacer height={theme.spacing.spacing04} />
                  <div data-testid="action-type">
                    <Text size="sm" mb={0}>
                      Action Type
                    </Text>
                    <DataDisplayWrapper
                      hasData={customActionData.settings?.type?.length !== 0}
                    >
                      {customActionData.settings?.type?.replace("_", " ")}
                    </DataDisplayWrapper>
                  </div>
                  <Spacer height={theme.spacing.spacing04} />

                  {renderActionSettingsForm({
                    data: customActionData.settings,
                    type: customActionData.settings?.type,
                  })}
                </div>
                <FlexContainer>
                  <Button
                    aria-label="edit button"
                    icon="edit"
                    size="md"
                    severity="low"
                    onClick={onEditActionSettings}
                  />
                </FlexContainer>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </When>
      <When condition={step === 1}>
        <Row justify="center">
          <Col sm={6} md={6} lg={8}>
            <Spacer height="1rem" />
            <Row>
              <Col>
                <Message status="success" image="success">
                  The Action has been created.
                </Message>
              </Col>
            </Row>
            <Spacer height="2rem" />
            <Row>
              <Col lg={4}>
                <InfoCard
                  align="center"
                  onClick={() => {
                    history.push(
                      ROUTES.customActions.generateDetails(customActionId)
                    );
                  }}
                >
                  <SVGIcon name="detailsView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    Action Details
                  </Heading>
                </InfoCard>
              </Col>
              <Col lg={4}>
                <InfoCard
                  align="center"
                  onClick={() => history.push(ROUTES.customActions.index)}
                >
                  <SVGIcon name="listView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    List of Actions
                  </Heading>
                </InfoCard>
              </Col>
              <Col lg={4}>
                <InfoCard align="center" onClick={onAddNewCustomAction}>
                  <SVGIcon name="wizardView" size="xl" />
                  <Heading marginTop="1rem" level={4}>
                    Add Another Action
                  </Heading>
                </InfoCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </When>
    </>
  );
}
