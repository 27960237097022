/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer } from "@nordcloud/gnui";
import { RolesSortFields, SortOrder, Role } from "~/generated/graphql";
import { LoaderWrap } from "~/components/Loader";
import { NoData } from "~/components/NoData";
import { Pagination } from "~/components/Pagination";
import { SortSelector } from "~/components/SortSelector";
import { TableWrapper } from "~/components/Tables";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotNil } from "~/tools";
import { roleColumns } from "~/views/permissions/Roles/components/RolesTableColumns";
import { useGetRoles } from "../hooks/useGetRoles/useGetRoles";
import { QuerySort } from "../types";

const sortOptions = [
  {
    value: RolesSortFields.Name,
    label: "Role Name",
  },
  {
    value: RolesSortFields.CreatedAt,
    label: "Date Added",
  },
  {
    value: RolesSortFields.UpdatedAt,
    label: "Date Updated",
  },
  {
    value: RolesSortFields.UpdatedBy,
    label: "Updated By",
  },
];

export function RolesTable() {
  const { queryState, updateQueryState } = useQueryState<QuerySort>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const handleSortDirection = (order: SortOrder) => {
    updateQueryState({
      ...queryState,
      order,
    });
  };

  const handleSortField = (field: RolesSortFields) => {
    updateQueryState({
      ...queryState,
      field,
    });
  };

  const { data, loading, count } = useGetRoles({
    page: queryState.page,
    limit: queryState.limit,
    sort: {
      field: queryState.field ?? RolesSortFields.Name,
      order: queryState.order ?? SortOrder.Asc,
    },
  });

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <SortSelector<RolesSortFields>
            options={sortOptions}
            value={queryState.field ?? RolesSortFields.Name}
            direction={queryState.order ?? SortOrder.Asc}
            onSelect={handleSortField}
            onSortChange={handleSortDirection}
          />
        </FlexContainer>
      </Box>
      <LoaderWrap loading={loading} inContent>
        <If condition={isEmpty(data)}>
          <Then>
            <NoData hasIcon message={"There are no roles."} />
          </Then>
          <Else>
            <>
              <TableWrapper<Partial<Role>>
                loading={loading}
                data={data.filter(isNotNil)}
                columns={roleColumns()}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                stripped
              />
              <Pagination count={count} />
            </>
          </Else>
        </If>
      </LoaderWrap>
    </>
  );
}
