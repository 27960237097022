/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MenuBarLink } from "~/layout/MenuBar/MenuItem";
import { Submenu, SubmenuTitle } from "~/layout/MenuBar/Submenu";
import { ROUTES } from "~/routing/routes";

export function PermissionsSubmenus() {
  return (
    <Submenu>
      <>
        <SubmenuTitle title="Permissions" />
        <ul>
          <li>
            <MenuBarLink route={ROUTES.permissions.users}>Users</MenuBarLink>
          </li>
          <li>
            <MenuBarLink route={ROUTES.permissions.apiKeys}>
              API Keys
            </MenuBarLink>
          </li>
          <li>
            <MenuBarLink route={ROUTES.permissions.roles}>Roles</MenuBarLink>
          </li>
        </ul>
      </>
    </Submenu>
  );
}
