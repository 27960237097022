/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { When } from "react-if";
import styled from "styled-components";
import {
  Box,
  Button,
  FlexContainer,
  Input,
  Label,
  Select,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { isUpcomingPlanNotification } from "~/views/plans/utils";
import { NotificationGroupSidebar } from "../NotificationGroupSidebar";
import { FormField, Option } from "../PlanCreateWizard/types";
import { planNotificationTriggersOptions } from "./constants";
import { InAdvance } from "./InAdvance";

type Props = {
  remove: () => void;
  index: number;
};

export function NotificationItemGeneral({ remove, index }: Props) {
  const { watch, setValue, control, formState, register } = useFormContext();

  const error = formState.errors[FormField.NOTIFICATION_GROUPS_GENERAL] as
    | FieldValues[]
    | undefined;

  const id = watch(
    `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[FormField.ID]}`
  );
  const triggerEvent = watch(
    `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
      FormField.TRIGGER_EVENT,
    ]}`
  );
  const notificationGroupsIds = watch(
    `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
      FormField.NOTIFICATION_GROUPS_IDS,
    ]}`
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    notificationGroupsIds ?? []
  );

  const handleChange = (name: FormField[], value?: string | string[]) => {
    const field = `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${name}`;
    setValue(field, value);
  };

  const notificationCount = index + 1;

  return (
    <Box
      data-testid={`box-general-notification`}
      mb={theme.spacing.spacing02}
      boxStyle="lightGrey"
    >
      <Headline>
        <Text tag="div" weight="medium">
          Notification #{notificationCount}
        </Text>
        <Button
          data-testid={`button-delete-general-notification`}
          severity="low"
          ml="auto"
          icon="trash"
          onClick={remove}
        />
      </Headline>
      <div css={{ marginTop: theme.spacing.spacing04 }}>
        <div css={{ display: "none" }}>
          <Input
            hidden
            defaultValue={id}
            {...register(
              `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
                FormField.ID,
              ]}`
            )}
          />
        </div>
        <Label name="Subject:" />
        <TextBox>Plan</TextBox>
        <Spacer height={theme.spacing.spacing04} />
        <FormGroup error={error?.[index]?.triggerEvent}>
          <Label name="Condition:" />
          <Controller
            control={control}
            name={`${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
              FormField.TRIGGER_EVENT,
            ]}`}
            render={() => {
              return (
                <Select
                  placeholder="Select Option"
                  options={planNotificationTriggersOptions}
                  onChange={(option: Option | null) =>
                    handleChange([FormField.TRIGGER_EVENT], option?.value)
                  }
                  value={planNotificationTriggersOptions.find(
                    (i) => i.value === triggerEvent
                  )}
                  isSearchable={false}
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup error={error?.[index]?.notificationGroupIds}>
          <NotificationGroupSidebar
            onSubmit={(ids) =>
              handleChange([FormField.NOTIFICATION_GROUPS_IDS], ids)
            }
            onClear={() => {
              return handleChange(
                [FormField.NOTIFICATION_GROUPS_IDS],
                undefined
              );
            }}
            {...{ selectedOptions, setSelectedOptions }}
          />
        </FormGroup>
        <When condition={isUpcomingPlanNotification(triggerEvent)}>
          <InAdvance {...{ handleChange, index }} />
        </When>
      </div>
    </Box>
  );
}

const Headline = styled(FlexContainer)`
  border-bottom: ${theme.borders.grey};
  margin: -${theme.spacing.spacing03} -${theme.spacing.spacing03} 0;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing03}
    ${theme.spacing.spacing02};
`;

const TextBox = styled.div`
  background: ${theme.color.field.default};
  padding: ${theme.spacing.spacing01} ${theme.spacing.spacing02};
  border: ${theme.borders.disabled};
  line-height: 1.6;
  border-radius: ${theme.radius.md};
  color: ${theme.color.text.text02};
`;
