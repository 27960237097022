/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, FlexContainer, theme } from "@nordcloud/gnui";
import { SearchInput } from "~/components";
import { maxSearchLength, searchDelay } from "~/components/SearchInput/const";
import { useSleep } from "~/hooks";
import { ReportDownload } from "../../ReportDownload";
import { useReportState } from "../ReportProvider";
import { ReportFilters } from "./ReportFilters";

const minSearchLength = 1;

export function ReportHeader() {
  const { reportState, updateReportState } = useReportState();
  const { sleep, cancelSleep } = useSleep();

  const onChange = async (query: string) => {
    cancelSleep();
    if (query.length !== 0) {
      await sleep(searchDelay);
    }
    updateReportState({ searchQuery: query });
  };

  return (
    <Box mb={theme.spacing.spacing04}>
      <FlexContainer mb={theme.spacing.spacing04}>
        <div css={{ flex: 1, marginRight: theme.spacing.spacing04 }}>
          <SearchInput
            placeholder="Type a Keyword (e.g., Resource Name, Resource ID, Update Name, etc.) "
            onSubmit={(query) => {
              cancelSleep();
              updateReportState({ searchQuery: query });
            }}
            onChange={onChange}
            minLength={minSearchLength}
            maxLength={maxSearchLength}
          />
        </div>
        <ReportDownload eventActionId={reportState.eventActionId} />
      </FlexContainer>
      <ReportFilters />
    </Box>
  );
}
