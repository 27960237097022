/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import { Else, If, Then } from "react-if";
import {
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Sidebar,
  theme,
} from "@nordcloud/gnui";
import { ActionScope, PlanActionType } from "~/generated/graphql";
import { LoaderWrap } from "~/components";
import { useGetAllActions } from "~/hooks/useGetAllActions";
import { SelectedPlanEntity } from "../../../types";
import { ActionItemEdit } from "../ActionItemEdit";
import {
  actionsType,
  batchActionSupportTooltip,
  sidebarTitleBatch,
  sidebarTitleSingle,
} from "../constant";
import { ExecutionMethod, State } from "../types";
import { AddActionBatchSidebarFooter } from "./components";

type Props = {
  executionMethod: ExecutionMethod;
  onApply: (actions: SelectedPlanEntity[]) => void;
  state: State;
  updateState: Dispatch<Partial<State>>;
};

export function AddActionsSidebar({
  executionMethod,
  onApply,
  state,
  updateState,
}: Props) {
  const { loading, customActions, systemActions } = useGetAllActions();

  const selectActions = (action: SelectedPlanEntity) => {
    const selectedActions = state.selectedActions.map(({ id }) => id);
    const actionId = action.id;
    if (selectedActions.includes(actionId)) {
      updateState({
        selectedActions: state.selectedActions.filter(
          ({ id }) => id !== actionId
        ),
      });
    } else {
      updateState({ selectedActions: [...state.selectedActions, action] });
    }
  };

  const actionsList = (actions: SelectedPlanEntity[]) =>
    actions?.map((action) => {
      const isDisabled =
        executionMethod === ExecutionMethod.batch &&
        action.scope === ActionScope.Plan;

      const actionItem = {
        id: action.id ?? "",
        name: action.name,
        description: action.description ?? "",
        color: action.color,
        actionsType: action.actionType,
      };

      return (
        <ActionItemEdit
          key={action.id}
          item={actionItem}
          isCheckbox
          isSelected={state.selectedActions
            .map(({ id }) => id)
            .includes(action.id ?? "")}
          onMouseDown={() => {
            !isDisabled && selectActions(action);
          }}
          disabled={isDisabled}
          tooltip={isDisabled ? batchActionSupportTooltip : undefined}
        />
      );
    });

  return (
    <Sidebar
      title={
        executionMethod === ExecutionMethod.single
          ? sidebarTitleSingle
          : sidebarTitleBatch
      }
      isOpen={state.isSidebarOpen}
      onClick={() => updateState({ isSidebarOpen: false })}
      footer={
        <AddActionBatchSidebarFooter
          state={state}
          clear={() => updateState({ selectedActions: [] })}
          onSubmit={onApply}
        />
      }
    >
      <LoaderWrap inContent loading={loading}>
        <FlexContainer mb={theme.spacing.spacing05}>
          <MultipleSelect size="small">
            {actionsType.map((action) => (
              <SelectButton
                key={action.value}
                name={action.value}
                value={action.value}
                labelText={action.label}
                onClick={() => updateState({ actionType: action.value })}
                isActive={state.actionType === action.value}
              />
            ))}
          </MultipleSelect>
        </FlexContainer>
        <If condition={state.actionType === PlanActionType.Custom}>
          <Then>{actionsList(customActions)}</Then>
          <Else>{actionsList(systemActions)}</Else>
        </If>
      </LoaderWrap>
    </Sidebar>
  );
}
