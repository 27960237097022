/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { If, Then, Else } from "react-if";
import { Link } from "react-router-dom";
import { FlexContainer } from "@nordcloud/gnui";

type Props = {
  name: string;
  link?: string;
};

export function NameWithLink({ name, link }: Props) {
  return (
    <FlexContainer
      justifyContent="center"
      alignItems="flex-start"
      direction="column"
    >
      <If condition={link}>
        <Then>
          <Link to={link ?? ""}>{name}</Link>
        </Then>
        <Else>
          <div>{name}</div>
        </Else>
      </If>
    </FlexContainer>
  );
}
