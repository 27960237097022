/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, ButtonProps } from "@nordcloud/gnui";

type StyledButtonProps = ButtonProps & {
  extraSize: "small" | "large";
};

type Props = {
  size: "small" | "large";
  disabled: boolean;
};

const StyledButton = styled(Button)<StyledButtonProps>`
  transform: ${(props) =>
    props.extraSize === "large" ? "scale(1.25)" : "inherit"};
  opacity: ${(props) => (props.extraSize === "large" ? "0.6" : "inherit")};
`;

export function PopoverTrigger({ size, disabled }: Props) {
  return (
    <StyledButton
      extraSize={size}
      disabled={disabled}
      data-testid="options-button"
      aria-label="options-button"
      severity="low"
      icon="menu"
      size="md"
    />
  );
}
