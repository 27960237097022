/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Button, ExtendedTooltip, theme } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { isEmpty, isNotEmpty } from "~/tools";
import { ActionLogModal } from "../../ActionLogModal";

type Props = {
  outputUri: string;
  modalLabel: string;
};

export function ActionLog({ outputUri, modalLabel }: Props) {
  const { isOpen, close, open } = useDisclosure(false);

  return (
    <>
      <ExtendedTooltip caption="Log Output" position="start">
        <Button
          css={{ marginRight: theme.spacing.spacing02 }}
          severity="low"
          icon="eventLog"
          onClick={open}
          disabled={isEmpty(outputUri)}
        />
      </ExtendedTooltip>
      <When condition={isNotEmpty(outputUri) && isOpen}>
        <ActionLogModal
          actionOutputUrl={outputUri}
          actionLogLabel={modalLabel}
          isOpen={isOpen}
          close={close}
        />
      </When>
    </>
  );
}
