/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { When } from "react-if";
import { z } from "zod";
import { Button, Input, Label, Sidebar, Spacer, theme } from "@nordcloud/gnui";
import { RbacRoleBindingType } from "~/generated/graphql";
import { FormGroup, stringRequired } from "~/components/Forms";
import { useDisclosure } from "~/hooks";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotEmpty, isNotNil } from "~/tools";
import { useCreateRoleBinding } from "~/views/permissions/hooks/useCreateRoleBinding/useCreateRoleBinding";
import { SelectRolesSidebar } from "../../components/SelectRolesSidebar";
import { FormData } from "../formConfig";

function refineRoles(el: string[]): boolean {
  return el.every((i) => i.length > 1);
}

export const schema = z.object({
  subject: stringRequired("Email"),
  roleIds: z
    .string()
    .array()
    .refine((val) => refineRoles(val), { message: "Role is required" }),
});

type Props = {
  users: string[];
};

export function UserCreate({ users }: Props) {
  const { isOpen, open, close } = useDisclosure();

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      subject: "",
      roleIds: [""],
    },
  });

  const watcher = watch();

  const clean = () => {
    clearErrors();
    reset({
      subject: "",
      roleIds: [""],
    });
    close();
    showSuccess(generateActionSuccessText("User")()("created")());
  };

  const [createRoleBinding, { loading }] = useCreateRoleBinding({
    onSuccess: clean,
  });

  const onSubmit = (formData: FormData) => {
    const isExist = users.includes(watcher.subject);

    if (isExist) {
      setError("subject", { message: "User exist" });
    }

    if (!isExist && isNotNil(formData.roleIds)) {
      Promise.all(
        formData.roleIds.map((id) =>
          createRoleBinding({
            roleId: id,
            subject: formData.subject ?? "",
            type: RbacRoleBindingType.User,
          })
        )
      );
    }
  };
  return (
    <>
      <Button icon="plus" onClick={open}>
        Add User
      </Button>
      <When condition={isOpen}>
        <Sidebar title="Add User" isOpen={isOpen} onClick={close}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup error={errors.subject}>
              <Label name="Email" htmlFor="subject" required />
              <Input id="subject" {...register("subject")} />
            </FormGroup>
            <FormGroup error={errors.roleIds}>
              <Label name="User Role" htmlFor="role" required />
              <SelectRolesSidebar
                ids={watcher.roleIds.filter(isNotEmpty)}
                onSelect={(selectedRoleIds: string[]) =>
                  setValue("roleIds", selectedRoleIds)
                }
                isLoading={loading}
                isError={errors.roleIds && "danger"}
              />
            </FormGroup>
            <Spacer height={theme.spacing.spacing02} />
            <Button initialState={loading ? "loading" : "success"}>
              Apply
            </Button>
          </form>
        </Sidebar>
      </When>
    </>
  );
}
