/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, Heading, Text, FlexContainer, theme } from "@nordcloud/gnui";
import { MetadataCreate } from "~/components";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";
import { usePlan } from "../../PlanProvider";
import { MetadataTable } from "./components/MetadataTable";

export function MetadataTab() {
  const { plan } = usePlan();

  const metadata = plan?.metadata ?? [];
  const planId = plan?.id ?? "";

  return (
    <>
      <Box spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <div>
            <Heading level={3}>Plan Metadata</Heading>
            <Text size="sm" color={theme.color.text.text02}>
              Metadata are tags with key-value labels. They help organize and
              manage resources.
            </Text>
          </div>
          <div>
            <MetadataCreate
              updateHook={useUpdatePlan}
              metadata={metadata}
              id={planId}
            />
          </div>
        </FlexContainer>
        <MetadataTable metadata={metadata} planId={planId} />
      </Box>
    </>
  );
}
