/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import {
  FormProvider,
  useFieldArray,
  useForm,
  SubmitHandler,
} from "react-hook-form";
import { v4 } from "uuid";
import { Button, Text } from "@nordcloud/gnui";
import { areObjectsEqual } from "~/utils";
import { NotificationItemGeneral } from "~/views/plans/PlanCreate/components/NotificationItemGeneral";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import {
  FormField,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { usePlanWizard } from "../PlanProvider";
import { schema } from "./schema";

type State = {
  isOpen: boolean;
  hasUnsavedChanges: boolean;
  hasChanges: boolean;
};

type Props = {
  nextStep?: () => void;
  onSubmit: (formData: FormData) => void;
  updateState?: Dispatch<Partial<State>>;
};

export function PlanGeneralNotificationForm({
  nextStep,
  onSubmit,
  updateState,
}: Props) {
  const { planData } = usePlanWizard();
  const defaultValues =
    planData?.general_notifications?.notificationGroupsGeneral?.map((item) => {
      return {
        [FormField.ID]: item.id,
        [FormField.TRIGGER_EVENT]: item?.triggerEvent,
        [FormField.NOTIFICATION_GROUPS_IDS]: item.notificationGroupIds,
        [FormField.IN_ADVANCE]: item.inAdvance,
        [FormField.UNIT]: item.unit,
      };
    });

  const formMethods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      [FormField.NOTIFICATION_GROUPS_GENERAL]: defaultValues ?? [],
    },
  });

  const { control, handleSubmit, watch } = formMethods;

  const currentState = watch(FormField.NOTIFICATION_GROUPS_GENERAL)?.map(
    ({ id: _, ...groupWithoutId }) => groupWithoutId
  );

  const previousState =
    planData?.general_notifications?.notificationGroupsGeneral?.map((item) => {
      return {
        [FormField.TRIGGER_EVENT]: item?.triggerEvent,
        [FormField.NOTIFICATION_GROUPS_IDS]: [
          ...(item.notificationGroupIds ?? []),
        ],
        [FormField.IN_ADVANCE]: item.inAdvance?.toString() ?? "0",
        [FormField.UNIT]: TimeUnits.minutes,
      };
    });

  const hasUnsavedChanges = !areObjectsEqual(currentState, previousState);

  useEffect(() => {
    updateState?.({ hasUnsavedChanges });
  }, [hasUnsavedChanges, updateState]);

  const submit: SubmitHandler<FormData> = (formData) => {
    onSubmit(formData);

    nextStep?.();
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: FormField.NOTIFICATION_GROUPS_GENERAL,
  });

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Row>
          <Col>
            <Text size="md" tag="h5">
              General Plan Notifications
            </Text>
            <Text size="sm">Set general plan notifications.</Text>
            <FormProvider {...formMethods}>
              <form
                id="planGeneralNotificationForm"
                data-testid="plan-general-notification"
                onSubmit={handleSubmit(submit)}
              >
                {fields.map((field, index) => {
                  return (
                    <NotificationItemGeneral
                      key={field.id}
                      index={index}
                      remove={() => remove(index)}
                    />
                  );
                })}
                <Button
                  severity="low"
                  icon="plus"
                  type="button"
                  data-testid="plan-general-notification-add"
                  onClick={() =>
                    append({
                      [FormField.ID]: v4(),
                      [FormField.TRIGGER_EVENT]: undefined,
                      [FormField.NOTIFICATION_GROUPS_IDS]: undefined,
                      [FormField.IN_ADVANCE]: "0",
                      [FormField.UNIT]: TimeUnits.minutes,
                    })
                  }
                >
                  Add Notification
                </Button>
              </form>
            </FormProvider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
