/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer } from "@nordcloud/gnui";
import { NoData } from "../NoData";

export function ResourceGroupsNoData() {
  return (
    <NoData
      hasIcon
      customCss={{ flexDirection: "column" }}
      message={
        <FlexContainer
          justifyContent="center"
          direction="column"
          css={{ textAlign: "center" }}
        >
          There are no resource groups in the action. <br />
          Please add resource groups.
        </FlexContainer>
      }
    />
  );
}
