/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { PlanEntityIncomingEventDetails } from "~/views/events/types";

export function IncomingEventActionBatchDetails({
  planActionBatch,
}: {
  planActionBatch?: PlanEntityIncomingEventDetails;
}) {
  return (
    <div css={{ minHeight: "19.3rem" }}>
      <Text weight="medium">Action Batch Details</Text>
      <Text size="sm" tag="div">
        Name
      </Text>
      <Text>{planActionBatch?.name}</Text>
    </div>
  );
}
