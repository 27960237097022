/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Text } from "@nordcloud/gnui";
import { ResourceCloudProvider } from "~/generated/graphql";

type Props = PropsWithChildren<{
  to: string;
  provider?: ResourceCloudProvider;
}>;

export function NameCell({ to, provider, children }: Props) {
  return (
    <>
      <Link to={to}>{children}</Link>
      <Text tag="div" size="sm">
        Compute: {getComputeDescription(provider)}
      </Text>
    </>
  );
}

export function getComputeDescription(provider?: ResourceCloudProvider) {
  switch (provider) {
    case ResourceCloudProvider.Aws:
      return "EC2";
    case ResourceCloudProvider.Azure:
      return "Azure Virtual Machine";
    case ResourceCloudProvider.Ibm:
      return "IBM Cloud Virtual Server";
    case ResourceCloudProvider.Gc:
      return "Google Compute Engine";
    case ResourceCloudProvider.Other:
      return "Other";
    default:
      return "";
  }
}
