/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { ResourcesSortFields, SortOrder } from "~/generated/graphql";
import { TableSearchInput } from "~/components/SearchInput";
import { minSearchLength } from "~/components/SearchInput/const";
import { useQueryState } from "~/hooks/useQueryState/useQueryState";
import { useResourcesSearch } from "~/views/resources/hooks/useResourcesSearch/useGetResources";
import { ResourceQuery } from "~/views/resources/types";
import { ResourceTableState } from "../const";

type Props = {
  setResourcesSearchPage: (resourcesPage: ResourceTableState) => void;
};

export function ResourceSearch({ setResourcesSearchPage }: Props) {
  const {
    queryState: { page, limit, field, order, searchPhrase },
  } = useQueryState<ResourceQuery>();

  const { resourcesSearch, data, count, loading } = useResourcesSearch();

  const handleSearch = (phrase: string) => {
    resourcesSearch({
      variables: {
        input: { phrase: phrase.trim() },
        page,
        limit,
        sort: {
          field: field ?? ResourcesSortFields.Name,
          order: order ?? SortOrder.Asc,
        },
      },
    });
  };

  useEffect(() => {
    if (searchPhrase && searchPhrase.length > minSearchLength) {
      handleSearch(searchPhrase);
    }
    setResourcesSearchPage({
      resources: data,
      count: count,
      loading: loading,
    });
  }, [count, loading, setResourcesSearchPage, page, field, order]);

  return (
    <TableSearchInput<ResourceQuery>
      onSubmit={handleSearch}
      placeholder={`Type a Keyword e.g. Resource Name, Resource ID, Windows, Linux, etc. (minimum ${minSearchLength} characters)`}
    />
  );
}
