/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Tab } from "@nordcloud/gnui";

export const StyledTab = styled(Tab)`
  width: 10rem;
`;
