/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button, ExtendedTooltip, Modal, theme } from "@nordcloud/gnui";
import { ExecutionStatus } from "~/generated/graphql";
import { ModalFooter } from "~/components/Styled";
import { useDisclosure } from "~/hooks";
import { useRerunEventAction } from "~/views/events/hooks/useRerunEventAction/useRerunEventAction";

type Props = {
  eventId: string;
  eventActionId?: string;
  eventStatus?: ExecutionStatus;
};

export function Rerun({ eventId, eventActionId, eventStatus }: Props) {
  const { isOpen, open, close } = useDisclosure();

  const { rerunEventAction, loading } = useRerunEventAction({
    onSuccess: close,
  });

  const handleRerun = () => {
    rerunEventAction(eventId ?? "", eventActionId ?? "");
  };

  const allowedRerunEventActionStatuses: ExecutionStatus[] = [
    ExecutionStatus.Failed,
    ExecutionStatus.PartialSuccess,
    ExecutionStatus.ApprovalTimeout,
    ExecutionStatus.Timeout,
  ];

  const isDisabled =
    !allowedRerunEventActionStatuses.includes(
      (eventStatus as ExecutionStatus) ?? ""
    ) || loading;

  return (
    <>
      <ExtendedTooltip caption="Re-run action" position="start">
        <Button onClick={open} icon="refresh" />
      </ExtendedTooltip>
      <Modal
        onClose={close}
        isOpen={isOpen}
        actionLabel="Re-run"
        contentLabel="Re-run action"
      >
        Are you sure you want to re-run the action?
        <ModalFooter
          mt={theme.spacing.spacing02}
          justifyContent="flex-end"
          gap={theme.spacing.spacing03}
        >
          <Button severity="low" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            initialState={loading ? "loading" : ""}
            onClick={handleRerun}
          >
            Re-Run
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
