/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "@nordcloud/gnui";
import { useGenerateDatesFromCron } from "~/views/plans/hooks";
import {
  PlanData,
  PlanField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import {
  CronScheduleState,
  FormField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { adjustInvalidCronMessage, convertToMinutes } from "../utils";

type Props = {
  state: CronScheduleState;
  updateState: Dispatch<Partial<CronScheduleState>>;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function CronDelayInput({ state, updateState, setPlanData }: Props) {
  const { control, setError, clearErrors } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    onSuccess: (dates) => {
      updateState({ cronNextDates: dates });
    },
  });

  const generateCronDates = async (value: number) => {
    const offsetInMinutes = convertToMinutes(value, state.offsetUnit);

    const result = await generateDatesFromCron({
      cron_expression: state.cronExpression,
      location: state.timezone,
      offset_in_minutes: offsetInMinutes,
    });

    const invalidCronMessage =
      result.data?.generateDatesFromCron.validationMsg ||
      result.data?.generateDatesFromCron.error;

    if (invalidCronMessage) {
      setError(FormField.SCHEDULE_CRON, {
        message: adjustInvalidCronMessage(invalidCronMessage),
      });

      return;
    }

    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_OFFSET]: offsetInMinutes,
      },
    }));

    clearErrors(FormField.SCHEDULE_CRON);
  };

  const handleChange = (delay: string) => {
    const offset = Number(delay);
    updateState({ offset: offset });
    if (state.cronExpression && state.timezone) {
      generateCronDates(offset);
    }
  };

  return (
    <Controller
      name={FormField.SCHEDULE_OFFSET}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Input
          type="number"
          id={FormField.SCHEDULE_OFFSET}
          name={FormField.SCHEDULE_OFFSET}
          value={(state.offset || value) ?? 0}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(Number(event.target.value));
            handleChange(event.target.value);
          }}
        />
      )}
    />
  );
}
