/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { Dropdown, Input, Label } from "@nordcloud/gnui";
import { AzureFunctionMethod } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { FormField, getLabel } from "../../../formConfig";

export function AzureForm() {
  const { control, formState, register } = useFormContext();
  return (
    <>
      <FormGroup error={formState.errors[FormField.FUNCTION_URL]}>
        <Label
          htmlFor={FormField.FUNCTION_URL}
          name={getLabel(FormField.FUNCTION_URL)}
          required
        />
        <Input
          id={FormField.FUNCTION_URL}
          {...register(FormField.FUNCTION_URL)}
        />
      </FormGroup>
      <FormGroup error={formState.errors[FormField.FUNCTION_METHOD]}>
        <Label name={FormField.FUNCTION_METHOD} required />
        <Controller
          control={control}
          name={FormField.FUNCTION_METHOD}
          render={({ field: { onChange, value } }) => {
            return (
              <Dropdown
                name="Select Method"
                options={options}
                onChange={onChange}
                value={value}
                minNumOfOptionsToShowSearchBox={10}
              />
            );
          }}
        />
      </FormGroup>
      <FormGroup error={formState.errors[FormField.ACCESS_KEY_ID]}>
        <Label
          htmlFor={FormField.ACCESS_KEY_ID}
          name={getLabel(FormField.ACCESS_KEY_ID)}
          required
        />
        <Input
          id={FormField.ACCESS_KEY_ID}
          {...register(FormField.ACCESS_KEY_ID)}
        />
      </FormGroup>
    </>
  );
}

const options = Object.values(AzureFunctionMethod);
