/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { v4 } from "uuid";
import { Table } from "@nordcloud/gnui";
import { Maybe, Resource } from "~/generated/graphql";
import { NoData } from "~/components";
import { isEmpty } from "~/tools";
import { ResourceRow } from "./ResourceRow";

type Props = {
  resources?: (Maybe<Resource> | undefined)[];
};

export function ResourcesTable({ resources }: Props) {
  const resourceItems = resources?.map((item) => ({
    keyId: v4(),
    ...item,
  }));

  return (
    <>
      <If condition={isEmpty(resources ?? "")}>
        <Then>
          <NoData hasIcon message={"There are no Resources."} />
        </Then>
        <Else>
          <Table hoverline striped>
            <Table.thead>
              <Table.tr>
                <Table.th css={{ width: "2%" }} />
                <Table.th>Resource Name/Type </Table.th>
              </Table.tr>
            </Table.thead>
            <Table.tbody>
              {resourceItems?.map((resource) => (
                <ResourceRow key={resource.keyId} resource={resource} />
              ))}
            </Table.tbody>
          </Table>
        </Else>
      </If>
    </>
  );
}
