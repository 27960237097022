/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, Input, Label, Sidebar, Spacer, theme } from "@nordcloud/gnui";
import { KeyValue } from "~/generated/graphql";
import { FormGroup } from "~/components/Forms";
import { Props } from "~/components/Metadata/MetadataTableForm/types";
import { useDisclosure } from "~/hooks";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { FormData, schema } from "./formConfig";

export function MetadataCreate<T>({ updateHook, metadata, id }: Props<T>) {
  const { isOpen, open, close } = useDisclosure();

  const [update] = updateHook({
    onSuccess: async () => {
      clean();
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      key: "",
      value: "",
    },
  });

  const clean = () => {
    clearErrors();
    reset({
      key: "",
      value: "",
    });
    close();
    showSuccess(generateActionSuccessText("Metadata")()("created")());
  };

  const onSubmit = (formData: FormData) => {
    const data = metadata?.map(({ key, value }: KeyValue) => ({ key, value }));

    update({
      id,
      metadata: [...data, formData],
    } as T);
  };
  return (
    <>
      <Button icon="plus" onClick={open} severity="low">
        Add Metadata
      </Button>
      <Sidebar title="Add Metadata" isOpen={isOpen} onClick={close}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup error={errors.key}>
            <Label name="Key" htmlFor="key" required />
            <Input id="key" {...register("key")} />
          </FormGroup>
          <FormGroup error={errors.value}>
            <Label name="Value" htmlFor="value" required />
            <Input id="value" {...register("value")} />
          </FormGroup>
          <Spacer height={theme.spacing.spacing02} />
          <Button initialState={"success"}>Apply</Button>
        </form>
      </Sidebar>
    </>
  );
}
