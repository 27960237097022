/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";
import { stringRequired } from "~/components/Forms";
import { isUpcomingPlanNotification } from "~/views/plans/utils";
import { FormField } from "../types";

export const schema = z.object({
  [FormField.NOTIFICATION_GROUPS_GENERAL]: z
    .object({
      [FormField.ID]: z.string(),
      [FormField.TRIGGER_EVENT]: stringRequired("Condition"),
      [FormField.NOTIFICATION_GROUPS_IDS]: z.string().array(),
      [FormField.IN_ADVANCE]: z.string().optional(),
      [FormField.UNIT]: z.string().optional(),
    })
    .refine(
      (data) => {
        if (isUpcomingPlanNotification(data.triggerEvent)) {
          return Number(data.inAdvance) > 0;
        } else {
          return true;
        }
      },
      {
        message: "In Advance has to been greater than 0",
        path: [FormField.IN_ADVANCE],
      }
    )
    .array()
    .optional(),
});
