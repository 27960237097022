/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApiKey } from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { ApiKeysEditSidebar } from "~/views/permissions/APIKeys/components/ApiKeysEditSidebar";
import { DeleteApiKeys } from "./DeleteApiKeys";

type Props = {
  ids: string[];
  rolesIds: string[];
  apiKey: ApiKey;
};

export function Actions({ ids, rolesIds, apiKey }: Props) {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useDisclosure(false);

  return (
    <>
      <ApiKeysEditSidebar apiKey={apiKey} initRolesIds={rolesIds} />
      <DeleteApiKeys
        {...{
          apiKeyIds: ids,
          rolesIds: rolesIds,
          isDeleteModalOpen: isDeleteModalOpen,
          openModal: openDeleteModal,
        }}
        close={closeDeleteModal}
      />
    </>
  );
}
