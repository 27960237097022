/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import dayjs from "dayjs";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Input, Text } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { useDateValues } from "~/hooks";
import { isEmpty } from "~/tools";
import { DateRange } from "~/types";
import { getDateToMinValue } from "~/utils/dates";
import { FilterHeader } from "./FilterHeader";
import { Mode } from "./types";

type Props = {
  onRangeSelect: (range: DateRange) => void;
  selectedRange?: Partial<DateRange>;
  label?: string;
};

export function SelectableDateRange({
  onRangeSelect,
  selectedRange,
  label,
}: Props) {
  const { endDate, setEndDate, startDate, setStartDate } =
    useDateValues(selectedRange);

  const handleStartDateSelection = (value: string) => {
    if (isEmpty(value)) {
      setStartDate(undefined);
      setEndDate(undefined);

      onRangeSelect({
        startDate: undefined,
        endDate: undefined,
      });

      return;
    }

    if (dayjs(endDate).isBefore(value)) {
      setEndDate(undefined);

      onRangeSelect({
        startDate: value,
        endDate: undefined,
      });

      return;
    }

    onRangeSelect({
      startDate: value,
      endDate: endDate as string,
    });
  };

  const handleEndDateSelection = (value: string) => {
    setEndDate(value);

    onRangeSelect({
      startDate,
      endDate: value,
    });
  };

  const defaultDate = dayjs();

  return (
    <>
      <FilterHeader defaultMode={Mode.INCLUDES} />
      <When condition={label}>
        <Text margin="0.5rem" marginBottom="0">
          {label}
        </Text>
      </When>
      <FlexContainer>
        <StartInputWrap>
          <Input
            aria-label="Start date"
            type="date"
            value={startDate ?? ""}
            min={getDateToMinValue(defaultDate)}
            max={defaultDate.format(dateFormat.shortDate)}
            onChange={(event) => handleStartDateSelection(event.target.value)}
            small
          />
        </StartInputWrap>

        <EndInputWrap>
          <Input
            aria-label="End date"
            type="date"
            value={endDate ?? ""}
            min={startDate}
            max={defaultDate.format(dateFormat.shortDate)}
            disabled={startDate === undefined}
            onChange={(event) => handleEndDateSelection(event.target.value)}
            small
          />
        </EndInputWrap>
      </FlexContainer>
    </>
  );
}

export const StartInputWrap = styled.div`
  width: 100%;
  padding: 0.5rem;
  padding-right: 0.25rem;
`;

export const EndInputWrap = styled.div`
  width: 100%;
  padding: 0.5rem;
  padding-left: 0.25rem;
`;
