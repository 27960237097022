/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { css } from "styled-components";
import { theme } from "@nordcloud/gnui";

export function getDroppableCss(hasMore: boolean, isDraggingOver: boolean) {
  return css({
    width: "100%",
    opacity: isDraggingOver ? theme.opacity : 1,
    marginBottom: hasMore ? 0 : theme.spacing.spacing08,
    "& > li": {
      listStyle: "none",
    },
  });
}
