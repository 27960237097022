/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  BatchActionFragment,
  useEventActionBatchStatusChangedSubscription,
} from "~/generated/graphql";
import { EntityType } from "../types";
import { TooltipIconStatus } from "./TooltipIconStatus";

type Props = {
  eventActionBatchId: string;
  eventActionBatchStatus?: string;
  batchActions?: Array<BatchActionFragment>;
  onEventActionBatchStatusChanged: () => void;
};

export function EventActionBatchStatus({
  eventActionBatchId,
  onEventActionBatchStatusChanged,
  eventActionBatchStatus,
  batchActions,
}: Props) {
  const { data } = useEventActionBatchStatusChangedSubscription({
    variables: {
      input: {
        id: eventActionBatchId,
      },
    },
    onData: onEventActionBatchStatusChanged,
  });

  return (
    <TooltipIconStatus
      status={data?.eventActionBatchStatusChanged ?? eventActionBatchStatus}
      type={EntityType.ACTION_BATCH}
      actions={batchActions}
    />
  );
}
