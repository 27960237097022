/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import slugify from "slugify";
import { Text, theme } from "@nordcloud/gnui";
import { KeyValue } from "~/generated/graphql";
import { DataDisplayWrapper, NoData } from "~/components";
import { getTextFromStringify, isEmpty, isNotEmpty } from "~/tools";
import { SystemActionNames } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";
import { separateConnectedWords } from "~/views/plans/utils";

export function AdvancedSettings() {
  const { planAction } = usePlan();
  const parameters = planAction?.inputParameters?.filter(
    (item) => item.key !== SystemActionNames.DELAY
  );

  if (isEmpty(parameters ?? [])) {
    return <NoData hasIcon message="There are no Advanced Action Settings" />;
  }

  return (
    <>
      <Text weight="medium">Advanced Action Settings</Text>
      <Text size="sm" tag="div" color={theme.color.text.text02}>
        Action Version
      </Text>
      <DataDisplayWrapper hasData={planAction?.action?.version !== null}>
        <Text tag="div" data-testid="div-action-version">
          {planAction?.action?.version}
        </Text>
        <Text size="sm" color={theme.color.text.text02}>
          {planAction?.action?.description}
        </Text>
      </DataDisplayWrapper>
      {parameters?.map((item: KeyValue) => {
        return (
          <div css={{ marginBottom: theme.spacing.spacing03 }} key={item.key}>
            <When condition={isNotEmpty(getTextFromStringify(item.value))}>
              <Text size="sm" tag="div" color={theme.color.text.text02}>
                {separateConnectedWords(item.key)}
              </Text>
              <Text
                data-testid={`text-${slugify(separateConnectedWords(item.key), {
                  lower: true,
                })}`}
              >
                {getTextFromStringify(item.value)}
              </Text>
            </When>
          </div>
        );
      })}
    </>
  );
}
