/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { useHistory } from "react-router-dom";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { DeleteNotificationGroup } from "./DeleteNotificationGroup/DeleteNotificationGroup";

type Props = {
  notificationGroupId: string;
  notificationGroupName: string;
  onSuccess?: () => void;
  size?: "large" | "small";
};

export function Actions({
  notificationGroupId,
  notificationGroupName,
  size = "small",
}: Props) {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useDisclosure(false);

  const history = useHistory();

  const onSuccess = () => history.push(ROUTES.notificationGroups.index);

  return (
    <>
      <PopoverControls
        disabled={false}
        size={size}
        items={[
          {
            label: `Delete Notification Group`,
            onClick: openDeleteModal,
          },
        ]}
      />
      <When condition={isDeleteModalOpen}>
        <DeleteNotificationGroup
          {...{
            notificationGroupId: notificationGroupId,
            notificationGroupName: notificationGroupName,
            isDeleteModalOpen: isDeleteModalOpen,
            onSuccess,
          }}
          close={closeDeleteModal}
        />
      </When>
    </>
  );
}
