/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { UpdatePlanInput } from "~/generated/graphql";
import { PlanExtended } from "../../PlanDetails/PlanProvider";
import { PlanFields } from "./types";

export function mapPlanToPlanUpdateInput(
  data: PlanFields | Partial<UpdatePlanInput>,
  plan: Partial<PlanExtended>
): UpdatePlanInput {
  return {
    id: plan.id ?? "",
  };
}

export function getUpdateObject(
  data: PlanFields | Partial<UpdatePlanInput>,
  plan: Partial<PlanExtended>
): UpdatePlanInput {
  const updateData = mapPlanToPlanUpdateInput(data, plan);
  return { ...updateData, ...data };
}
