/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PlanActionType } from "~/generated/graphql";
import { SelectedPlanEntity } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";

export type State = {
  isSidebarOpen: boolean;
  isModalOpen: boolean;
  name: string;
  actionType: PlanActionType;
  selectedActions: SelectedPlanEntity[];
  selectionType: ExecutionMethod;
};

export enum ExecutionMethod {
  single = "single",
  batch = "batch",
}
