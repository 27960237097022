/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import { Box, BrickLoader, FlexContainer } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ErrorWrap } from "~/components/Error";
import { LoaderWrap } from "~/components/Loader";
import { NoData } from "~/components/NoData";
import { ROUTES } from "~/routing/routes";
import { useGetResourceGroup } from "~/views/resourceGroups/hooks/useGetResourceGroup/useGetResourceGroup";
import { Action } from "./Action";
import { ResourceGroupInfo } from "./components/ResourceGroupInfo";
import { ResourceGroupCtxProvider } from "./ResourceGroupProvider";
import { Tabs } from "./Tabs/Tabs";
import { ResourceGroupDetailsParams } from "./types";
import { extractTagsExpression } from "./utils";

export function ResourceGroupDetails() {
  const [expression, setExpression] = useState<string | undefined>();
  const { resourceGroupId } = useParams<ResourceGroupDetailsParams>();

  const { resourceGroup, loading, error } = useGetResourceGroup({
    resourceGroupId,
  });

  const title = resourceGroup?.name ?? resourceGroupId;
  const breadcrumbs = [
    { label: "Home", uri: "/" },
    { label: "Resource Groups", uri: ROUTES.resourceGroups.index },
    { label: title, uri: "" },
  ];

  useEffect(() => {
    const exp = extractTagsExpression(resourceGroup);
    if (exp) {
      setExpression(exp);
    }
  }, [resourceGroup]);

  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <ResourceGroupCtxProvider
        value={{ resourceGroup, setExpression, expression }}
      >
        <LoaderWrap
          Component={() => <BrickLoader height="14rem" />}
          loading={loading}
          viewBox="0 0 10 4"
        >
          <Row>
            <Col>
              <BreadcrumbsBox title={title} customBreadcrumbs={breadcrumbs}>
                <FlexContainer>
                  <Action groupName={resourceGroup?.name} />
                </FlexContainer>
              </BreadcrumbsBox>
            </Col>
          </Row>
          <Box spacing="spacing02">
            <Row>
              <ResourceGroupInfo />
            </Row>
          </Box>
          <Tabs />
        </LoaderWrap>
      </ResourceGroupCtxProvider>
    </ErrorWrap>
  );
}
