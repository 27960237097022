/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { ExtendedTooltip, FlexContainer } from "@nordcloud/gnui";
import { ExecutionMethod } from "../types";
import { AddPlanEntity } from "./AddPlanEntity";

type Props = {
  severity?: "low";
};

const tooltipCaption = {
  single:
    "Add a single action to your plan. The action will start after the previous one finishes on all resources.",
  batch:
    "Create a batch of multiple actions to be executed simultaneously on each resource. The action in the batch will start after the previous one finishes on a resource.",
};

export function AddActionWrapper({ severity }: Props) {
  return (
    <FlexContainer>
      <Row justify="center">
        <Col align="center">
          <ExtendedTooltip caption={tooltipCaption.single} placement="bottom">
            <AddPlanEntity
              buttonSeverity={severity}
              executionMethod={ExecutionMethod.single}
            />
          </ExtendedTooltip>
        </Col>
        <Col align="center">
          <ExtendedTooltip caption={tooltipCaption.batch} placement="bottom">
            <AddPlanEntity
              buttonSeverity={severity}
              executionMethod={ExecutionMethod.batch}
            />
          </ExtendedTooltip>
        </Col>
      </Row>
    </FlexContainer>
  );
}
