/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Unless, When } from "react-if";
import styled from "styled-components";
import { BrickLoader, Text, theme } from "@nordcloud/gnui";
import { ExecutionPolicy } from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayBooleanProperties } from "~/views/events/components/DisplayResourceOperation";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { convertMinutesToTime } from "~/views/events/helpers";
import { SystemActionNames } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";

const generalActionSettingsHeadline = "General Action Settings";
const actionBatchSettingsHeadline = "Action Batch Settings";

export function GeneralSettings() {
  const { planAction } = usePlan();

  const isDelay = planAction?.action?.name === SystemActionNames.DELAY;
  const isActionBatch = isNotNil(planAction?.batchActions);
  const isBatchAction = !!planAction?.batchId;

  const executionPolicy = isBatchAction
    ? ExecutionPolicy.Anyway
    : planAction?.executionPolicy;
  const runInSequence = isBatchAction ? false : planAction?.runInSequence;

  if (planAction === undefined) {
    return <BrickLoader height="12.6rem" />;
  }

  if (isDelay) {
    return (
      <Wrap>
        <GeneralSettingsHeadline />
        <Text size="sm" tag="div">
          Action Delay
        </Text>
        <Text data-testid="text-action-delay" mb={theme.spacing.spacing01}>
          {convertMinutesToTime(Number(planAction?.windowDuration))}
        </Text>
      </Wrap>
    );
  }

  return (
    <Wrap>
      <GeneralSettingsHeadline
        headline={
          isActionBatch
            ? actionBatchSettingsHeadline
            : generalActionSettingsHeadline
        }
      />
      <DisplayExecutionPolicy policy={executionPolicy} />
      <DisplayBooleanProperties
        title="Resource Operation"
        condition={runInSequence}
        options={["Sequenced", "Simultaneous"]}
      />
      <Unless condition={isBatchAction}>
        <DisplayWindowDuration
          windowDuration={Number(planAction?.windowDuration)}
        />
        <When condition={planAction?.skipWindow}>
          <Text size="sm" tag="div">
            Ignore Window
          </Text>
          <Text data-testid="text-ignore-window" mb={theme.spacing.spacing01}>
            {convertSkipWindow(planAction?.skipWindow)}
          </Text>
        </When>
      </Unless>
    </Wrap>
  );
}

function GeneralSettingsHeadline({ headline }: { headline?: string }) {
  return (
    <Text weight="medium">{headline ?? generalActionSettingsHeadline}</Text>
  );
}

const Wrap = styled.div`
  min-height: 13rem;
`;
