/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Row } from "react-awesome-styled-grid";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { Input, Label } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { safeStr, stringRequired } from "~/components/Forms";
import { CustomActionData, CustomActionField } from "../../constants";
import { useCustomActionWizard } from "../../CustomActionProvider";
import { FormData, FormField } from "../../formConfig";
import { ColorPicker } from "./ColorPicker";

export const DetailsSchema = z.object({
  [FormField.NAME]: stringRequired("Name"),
  [FormField.COLOR]: stringRequired("Color"),
  [FormField.DESCRIPTION]: safeStr.optional(),
});

type Props = {
  nextStep: () => void;
  initialValues: CustomActionData;
};

export function CustomActionDetails({ nextStep, initialValues }: Props) {
  const formMethods = useForm({
    resolver: zodResolver(DetailsSchema),
    defaultValues: {
      [FormField.NAME]: initialValues.details?.name ?? "",
      [FormField.COLOR]: initialValues.details?.color ?? "",
      [FormField.DESCRIPTION]: initialValues.details?.description ?? "",
    },
  });

  const { setCustomActionData } = useCustomActionWizard();
  const { formState, register, handleSubmit, watch, setValue, clearErrors } =
    formMethods;

  const submit: SubmitHandler<FormData> = (formData) => {
    setCustomActionData((prevResourceData) => ({
      ...prevResourceData,
      [CustomActionField.DETAILS]: formData,
    }));
    nextStep();
  };

  const watcherColor = watch("color");
  return (
    <>
      <Row justify="center">
        <Col sm={6} md={6} lg={8}>
          <Row>
            <Col>
              <form id="wizard_step_1" onSubmit={handleSubmit(submit)}>
                <FormGroup error={formState.errors[FormField.NAME]}>
                  <Label name="Action Name" htmlFor="name" required />
                  <Input id="name" {...register("name")} />
                </FormGroup>
                <FormGroup error={formState.errors[FormField.COLOR]}>
                  <Label name="Action Color" required />
                  <ColorPicker
                    value={watcherColor}
                    error={formState.errors[FormField.COLOR]}
                    onChange={(value) => {
                      setValue(FormField.COLOR, value);
                      if (watcherColor != null) {
                        clearErrors("color");
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup error={formState.errors[FormField.DESCRIPTION]}>
                  <Label name="Description" htmlFor="description" />
                  <Input
                    id="description"
                    {...register("description")}
                    placeholder="Provide Description"
                  />
                </FormGroup>
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
