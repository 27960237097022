/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import {
  Box,
  Text,
  theme,
  Heading,
  FlexContainer,
  Spacer,
} from "@nordcloud/gnui";
import { CustomActionDetailsParams } from "~/views/customActions/CustomActionDetails/types";
import { PlansTable } from "~/views/plans/PlansTable/PlansTable";

export function ActionPlansTab() {
  const { customActionId } = useParams<CustomActionDetailsParams>();

  return (
    <>
      <Box spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <div>
            <Heading level={4}>Action Plans</Heading>
            <Text size="sm" color={theme.color.text.text02}>
              List of plans where this action is used.
            </Text>
          </div>
        </FlexContainer>
        <Spacer height={theme.spacing.spacing04} />
        <Row>
          <Col>
            <PlansTable customActionId={customActionId} />
          </Col>
        </Row>
      </Box>
    </>
  );
}
