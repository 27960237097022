/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CloudAccountCloudProvider } from "~/generated/graphql";
import { SvgName } from "~/tools/types";

export function getProviderIcon(
  provider: CloudAccountCloudProvider | string
): SvgName {
  switch (provider) {
    case "AWS":
      return "aws";
    case "AZURE":
      return "azure";
    default:
      return "aws";
  }
}
