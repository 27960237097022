/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import { Spacer, Text, theme } from "@nordcloud/gnui";
import { EventActionBatch } from "~/generated/graphql";
import { ROUTES } from "~/routing/routes";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { PlanEntityIncomingEventDetails } from "~/views/events/types";

export function GeneralActionBatchSettings({
  eventActionBatch,
  planId,
}: {
  eventActionBatch?: EventActionBatch | PlanEntityIncomingEventDetails;
  planId?: string;
}) {
  return (
    <>
      <Text weight="medium">General Action Batch Settings</Text>
      <DisplayExecutionPolicy policy={eventActionBatch?.executionPolicy} />
      <Text size="sm" tag="div">
        Resource Operation
      </Text>
      <Text>Simultaneous</Text>

      <DisplayWindowDuration
        windowDuration={eventActionBatch?.windowDuration}
      />
      <Text size="sm" tag="div">
        Ignore Window
      </Text>
      <Text mb={theme.spacing.spacing01}>
        {convertSkipWindow(eventActionBatch?.skipWindow)}
      </Text>

      <Spacer height={theme.spacing.spacing02} />
      <Link to={`${ROUTES.plans.index}/${planId}`}>
        Go to plan to edit settings
      </Link>
    </>
  );
}
