/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PlanNotificationTriggerType } from "~/generated/graphql";
import { capitalize } from "~/tools";

const planNotificationTriggers = [
  PlanNotificationTriggerType.Created,
  PlanNotificationTriggerType.Updated,
  PlanNotificationTriggerType.Deleted,
  PlanNotificationTriggerType.Start,
  PlanNotificationTriggerType.Successful,
  PlanNotificationTriggerType.PartiallySuccessful,
  PlanNotificationTriggerType.Failed,
  PlanNotificationTriggerType.Upcoming,
];

export const planNotificationTriggersOptions = planNotificationTriggers.map(
  (triggerType) => ({
    value: triggerType,
    label: getLabel(triggerType),
  })
);

export function getLabel(triggerType: PlanNotificationTriggerType) {
  return capitalize(triggerType).replace("_", " ");
}
