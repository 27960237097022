/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import { FlexColumnMax, MediumIconColumn } from "~/components/Tables/styles";
import { ROUTES } from "~/routing/routes";
import { Actions } from "./Actions";

export type ResourceGroups = {
  id: string;
  name: string;
};

const columnHelper = createColumnHelper<ResourceGroups>();

export function columns() {
  return [
    columnHelper.accessor((row) => row?.name, {
      header: "Resource Group Name",
      cell: ({ row }) => (
        <NameWithLink
          name={row.original.name}
          link={ROUTES.resourceGroups.generateDetails(row.original.id)}
        />
      ),
      meta: FlexColumnMax,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const resourceGroupId = row.original?.id;
        const resourceGroupName = row.original.name;
        return <Actions {...{ resourceGroupId, resourceGroupName }} />;
      },
      meta: MediumIconColumn,
    }),
  ] as never;
}
