/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useReducer, useState } from "react";
import { When } from "react-if";
import {
  Button,
  FlexContainer,
  SVGIcon,
  Tab,
  Tabs,
  theme,
} from "@nordcloud/gnui";
import { WizardButton, WrapWizardButton } from "~/components";
import { useCreateResourceGroup } from "~/views/resourceGroups/hooks";
import { Confirm } from "./Confirm";
import {
  defaultValues,
  ResourceGroupData,
  ResourceGroupField,
  ResourceMode,
} from "./constants";
import { ResourceGroupDetails } from "./ResourceGroupDetails";
import { ResourceSelectForm } from "./ResourceSelectForm";
import { Summary } from "./Summary";

type State = {
  currentStep: number;
  subStep: number;
  resourceGroupId: string;
};

const defaultState = { currentStep: 0, subStep: 0, resourceGroupId: "" };

export function ResourceGroupCreateWizard() {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    defaultState
  );
  const [resourceGroupData, setResourceGroupData] =
    useState<ResourceGroupData>(defaultValues);

  const nextStep = () => updateState({ currentStep: state.currentStep + 1 });
  const previousStep = () =>
    updateState({ currentStep: state.currentStep - 1 });
  const handleSteps = (key: number) => updateState({ currentStep: key });

  const handleSuccess = (id: string) =>
    updateState({ resourceGroupId: id, subStep: 1 });

  const { createGroup, loading } = useCreateResourceGroup({
    onSuccess: handleSuccess,
  });

  const handleCreateGroup = () => createGroup({ ...resourceGroupData });

  const handleResetState = () => {
    updateState(defaultState);
  };

  const buttonsStep2 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton onClick={nextStep}>
        <FlexContainer gap={theme.spacing.spacing02}>
          Next Step
          <SVGIcon name="arrowRight" size="sm" />
        </FlexContainer>
      </WizardButton>
    </WrapWizardButton>
  );
  const buttonsStep3 = (
    <WrapWizardButton>
      <Button
        icon="arrowLeft"
        severity="low"
        onClick={() => {
          previousStep();
        }}
      >
        Previous Step
      </Button>
      <WizardButton
        initialState={loading ? "loading" : ""}
        onClick={handleCreateGroup}
        type="submit"
      >
        Create Resource Group
      </WizardButton>
    </WrapWizardButton>
  );

  const handleWizardResourceManualSubmit = (formData: ResourceGroupData) => {
    setResourceGroupData(formData);
  };
  const handleWizardResourceDynamicSubmit = (formData: {
    expression: string;
  }) => {
    setResourceGroupData((prevResourceData) => ({
      ...prevResourceData,
      [ResourceGroupField.EXPRESSION]: formData.expression,
      [ResourceGroupField.RESOURCE_SELECTION_METHOD]: ResourceMode.DYNAMIC,
    }));
  };

  return (
    <Tabs step={state.currentStep} wizard handleTab={handleSteps}>
      <Tab
        disabled
        heading="Resource Group Details"
        buttons={
          <WrapWizardButton>
            <WizardButton type="submit" form="wizard_step_1">
              <FlexContainer gap={theme.spacing.spacing02}>
                Next Step
                <SVGIcon name="arrowRight" size="sm" />
              </FlexContainer>
            </WizardButton>
          </WrapWizardButton>
        }
      >
        <ResourceGroupDetails
          initialValues={resourceGroupData}
          onSubmit={(formData) => {
            setResourceGroupData((prevResourceData) => ({
              ...prevResourceData,
              [ResourceGroupField.DETAILS]: formData,
            }));
            nextStep();
          }}
        />
      </Tab>
      <Tab disabled heading="Add Resources" buttons={buttonsStep2}>
        <ResourceSelectForm
          resourceGroupData={resourceGroupData}
          onSubmit={handleWizardResourceManualSubmit}
          onDynamicSubmit={handleWizardResourceDynamicSubmit}
          {...{ setResourceGroupData: setResourceGroupData }}
        />
      </Tab>
      <Tab
        disabled
        heading="Confirm and Finalize"
        buttons={state.subStep === 0 && buttonsStep3}
      >
        <When condition={state.subStep === 0}>
          <Confirm
            resourceGroupData={resourceGroupData}
            onEditResources={() => updateState({ currentStep: 1 })}
            onEditDetails={() => updateState({ currentStep: 0 })}
          />
        </When>
        <When condition={state.subStep === 1}>
          <Summary
            resourceGroupId={state.resourceGroupId}
            onAddNewResourceGroup={handleResetState}
          />
        </When>
      </Tab>
    </Tabs>
  );
}
