/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ModalConfirm } from "@nordcloud/gnui";
import { RefetchOptions } from "~/views/events/helpers";
import { useRevokeIncomingEvent } from "~/views/events/hooks";

type Props = {
  isOpen: boolean;
  eventId: string;
  onClose: () => void;
  refetchQueries: RefetchOptions;
};

export function RevokeIncomingEvent({
  isOpen,
  onClose,
  eventId,
  refetchQueries,
}: Props) {
  const revokeIncomingEvent = useRevokeIncomingEvent({
    refetchQueries: refetchQueries,
  });

  const onRevokeIncomingEvent = async () => {
    onClose();
    await revokeIncomingEvent(eventId);
  };

  return (
    <ModalConfirm
      onClose={onClose}
      isOpen={isOpen}
      confirm={onRevokeIncomingEvent}
      actionLabel="Revoke Event"
      contentLabel={`Revoke Event?`}
    >
      {`Are you sure you want to revoke event?`}
    </ModalConfirm>
  );
}
