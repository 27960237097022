/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { isEmpty } from "~/tools";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";
import { NotificationItem } from "~/views/plans/PlanDetails/Tabs/NotificationItem";
import { mergePlanActionNotifications } from "../helpers";

export function ActionNotification() {
  const { planAction } = usePlan();
  const planActionNotifications = planAction?.notificationGroups ?? [];

  if (isEmpty(planActionNotifications)) {
    return (
      <NoData
        hasIcon
        message="There are no Resource and Action-based Notifications"
      />
    );
  }

  const mergedPlanActionNotifications = mergePlanActionNotifications(
    planActionNotifications
  );

  return (
    <>
      <Text weight="medium">Resource and Action-based Notifications</Text>
      {mergedPlanActionNotifications?.map((notification, index) => {
        return (
          <NotificationItem
            item={notification}
            index={index}
            key={notification.id}
          />
        );
      })}
    </>
  );
}
