/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { Dropdown, FlexContainer, Input, Label, theme } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { units } from "../PlanCreateWizard/PlanSettingsForms/components/ActionSettingsForm/options";
import { FormField } from "../PlanCreateWizard/types";

type Props = {
  handleChange: (
    name: FormField[],
    value?: string | string[],
    idToRemove?: string
  ) => void;
  index: number;
};

export function InAdvance({ handleChange, index }: Props) {
  const { control, register, formState } = useFormContext();
  const error = formState.errors[`notificationGroupsGeneral`] as
    | FieldValues[]
    | undefined;
  return (
    <>
      <Label required name="In Advance" htmlFor="inAdvance" />
      <FlexContainer mb={theme.spacing.spacing04} gap={theme.spacing.spacing03}>
        <div css={{ width: "10rem" }}>
          <FormGroup
            error={error?.[index]?.inAdvance}
            customCss={{ position: "relative" }}
          >
            <Input
              type="number"
              id="inAdvance"
              {...register(
                `notificationGroupsGeneral.${index}.inAdvance` as const
              )}
            />
          </FormGroup>
        </div>
        <div css={{ width: "10rem" }}>
          <FormGroup>
            <Controller
              control={control}
              name={`notificationGroupsGeneral.${index}.unit`}
              render={({ field: { value } }) => {
                return (
                  <Dropdown
                    name="Select Delay Unit"
                    options={units}
                    onChange={(val) => handleChange([FormField.UNIT], val)}
                    value={value}
                    minNumOfOptionsToShowSearchBox={10}
                  />
                );
              }}
            />
          </FormGroup>
        </div>
      </FlexContainer>
    </>
  );
}
