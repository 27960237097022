/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { FieldValues, useFormContext } from "react-hook-form";
import { Button, FlexContainer, Input, theme } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { isNotNil } from "~/tools";
import { Wrap, ErrorText } from "~/views/plans/styled";
import { PlanField } from "../../constants";
import { usePlanWizard } from "../../PlanProvider";
import { FormField, DateItem } from "../../types";
import { exchangeDate, exchangeTime } from "../utils";

type Props = {
  remove: () => void;
  index: number;
};
export function ExecutionDateItem({ remove, index }: Props) {
  const { setPlanData } = usePlanWizard();
  const { watch, formState, register, reset } = useFormContext();

  const executionDate = `${FormField.SCHEDULE_EXECUTION_DATES}.${index}.${FormField.EXECUTION_DATE}`;
  const dateValue = watch(executionDate);

  const executionTime = `${FormField.SCHEDULE_EXECUTION_DATES}.${index}.${FormField.EXECUTION_TIME}`;
  const timeValue = watch(executionTime);

  const handleRemove = () => {
    setPlanData((prevPlanData) => {
      const oldExecutionDates =
        prevPlanData[PlanField.SCHEDULE_PLAN]?.scheduleExecutionDates ?? [];

      const newExecutionDates = oldExecutionDates.filter((_, i) => i !== index);
      reset({
        [FormField.SCHEDULE_EXECUTION_DATES]: newExecutionDates,
      });
      return {
        ...prevPlanData,
        [PlanField.SCHEDULE_PLAN]: {
          ...prevPlanData[PlanField.SCHEDULE_PLAN],
          [FormField.SCHEDULE_EXECUTION_DATES]: newExecutionDates,
        },
      };
    });

    remove();
  };

  const handleChange = (
    name: FormField,
    value: string,
    dateModifier: (date: DateItem, modifier: string) => DateItem
  ) => {
    setPlanData((prevPlanData) => {
      const oldExecutionDates =
        prevPlanData[PlanField.SCHEDULE_PLAN]?.scheduleExecutionDates ?? [];

      const oldDate = oldExecutionDates[index];

      const newExecutionDates = [
        ...oldExecutionDates.slice(0, index),
        dateModifier(oldDate, value),
        ...oldExecutionDates.slice(index + 1),
      ];

      return {
        ...prevPlanData,
        [PlanField.SCHEDULE_PLAN]: {
          ...prevPlanData[PlanField.SCHEDULE_PLAN],
          [FormField.SCHEDULE_EXECUTION_DATES]: newExecutionDates,
        },
      };
    });
  };

  const fieldError = formState.errors[FormField.SCHEDULE_EXECUTION_DATES] as
    | FieldValues[]
    | undefined;
  const error =
    fieldError?.[index]?.[FormField.EXECUTION_DATE] ??
    fieldError?.[index]?.[FormField.EXECUTION_TIME];

  const status = isNotNil(error) ? "danger" : undefined;

  return (
    <FlexContainer
      alignItems="stretch"
      gap={theme.spacing.spacing04}
      justifyContent="space-between"
      css={{ width: "30rem" }}
    >
      <FlexContainer direction="column" css={{ flex: 1 }}>
        <Wrap gap={theme.spacing.spacing04}>
          <Input
            small
            placeholder="Select date"
            aria-label="Select date"
            type="date"
            {...register(`${executionDate}` as const)}
            value={dayjs(dateValue).format(dateFormat.shortDate)}
            onChange={(e) =>
              handleChange(
                FormField.EXECUTION_DATE,
                e.target.value,
                exchangeDate
              )
            }
            status={status}
          />
          <Input
            small
            placeholder="Select time"
            aria-label="Select time"
            type="time"
            {...register(`${executionTime}` as const)}
            onChange={(e) =>
              handleChange(
                FormField.EXECUTION_TIME,
                e.target.value,
                exchangeTime
              )
            }
            value={timeValue}
            status={status}
          />
          <ErrorText error={error} />
        </Wrap>
      </FlexContainer>
      <div>
        <Button
          data-testid={`button-delete-date`}
          severity="low"
          ml="auto"
          icon="trash"
          onClick={handleRemove}
        />
      </div>
    </FlexContainer>
  );
}
