/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { If, Then, Else } from "react-if";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";

type Props = {
  text: string;
  description: string;
  tooltip?: ReactNode;
};

export function TextDescriptionCell({ text, description, tooltip }: Props) {
  return (
    <FlexContainer
      justifyContent="flex-start"
      alignItems="flex-start"
      direction="column"
    >
      <div>{text}</div>
      <FlexContainer alignItems="center">
        <If condition={tooltip !== undefined}>
          <Then>{tooltip}</Then>
          <Else>
            <Text
              size="sm"
              tag="span"
              mr={theme.spacing.spacing01}
              weight="regular"
              color={theme.color.text.text02}
            >
              {description}
            </Text>
          </Else>
        </If>
      </FlexContainer>
    </FlexContainer>
  );
}
