/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Maybe,
  PlanNotificationGroup,
  PlanNotificationTriggerType,
} from "~/generated/graphql";
import {
  NotificationGroupItemGeneral,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import {
  convertToMinutes,
  isUpcomingPlanNotification,
} from "~/views/plans/utils";
import { PlanNotificationItemType, PlanNotification } from "../types";

export type ExistingNotification = {
  id?: string;
  notificationGroupId: string;
  triggerEvent: PlanNotificationTriggerType;
  inAdvance: number;
};

export function getFormDataIds(
  notificationGroupsGeneral?: NotificationGroupItemGeneral[]
): string[] {
  return (
    notificationGroupsGeneral
      ?.map(({ id }) => id)
      .filter((id): id is string => id !== undefined) ?? []
  );
}

export function getDeleteNotificationGroupsFromList(
  notificationGroups?: PlanNotificationGroup[],
  formDataIds?: string[]
): string[] {
  return (
    notificationGroups
      ?.filter((notification) => !formDataIds?.includes(notification.id))
      .map(({ id }) => id) ?? []
  );
}

export function mapNotifications(notifications: PlanNotificationGroup[]) {
  return notifications.map((notification) => ({
    notificationGroupId: notification.notificationGroup.id,
    triggerEvent: notification.triggerEvent,
    inAdvance: notification.inAdvance,
  }));
}

export function mapNotificationGroups(
  notificationsGroupsToUpdate: ExistingNotification[]
) {
  return notificationsGroupsToUpdate.map((notification) => ({
    notificationGroupId: notification.notificationGroupId,
    triggerEvent: notification.triggerEvent,
    inAdvance: notification.inAdvance,
  }));
}

export function getDeleteNotificationGroupsFromNestedGroup(
  generalNotifications?: NotificationGroupItemGeneral[],
  planNotificationGroups?: PlanNotificationGroup[]
) {
  return (
    generalNotifications
      ?.reduce<string[]>((acc, generalNotification) => {
        const matchingIds = planNotificationGroups?.reduce<string[]>(
          (accInner, planNotificationGroup) => {
            if (
              generalNotification.id === planNotificationGroup.id &&
              !generalNotification.notificationGroupIds?.includes(
                planNotificationGroup.notificationGroup.id
              )
            ) {
              return [...accInner, planNotificationGroup.id];
            }
            return accInner;
          },
          []
        );
        return [...acc, ...(matchingIds || [])];
      }, [])
      ?.filter((item) => item !== undefined) ?? [].flat()
  );
}

export function mapNotificationGroupsToUpdate(
  generalNotifications?: NotificationGroupItemGeneral[],
  planNotificationGroups?: Maybe<PlanNotificationGroup[]>
) {
  return (
    generalNotifications
      ?.map((generalNotification) => {
        return (
          generalNotification.notificationGroupIds
            ?.filter((item) => item !== undefined)
            ?.map((groupId) => {
              const findGroup = planNotificationGroups?.find(
                (item) => item.id === generalNotification.id
              )?.notificationGroup.id;

              const checkIsExist = findGroup === groupId;

              return {
                id: checkIsExist ? generalNotification.id : undefined,
                notificationGroupId: groupId,
                triggerEvent:
                  generalNotification.triggerEvent ??
                  PlanNotificationTriggerType.Start,
                inAdvance:
                  generalNotification.triggerEvent &&
                  isUpcomingPlanNotification(generalNotification.triggerEvent)
                    ? Number(
                        convertToMinutes(
                          generalNotification.inAdvance?.toString() ?? "",
                          generalNotification.unit ?? TimeUnits.minutes
                        )
                      )
                    : 0,
              };
            }) ?? []
        );
      })
      .flat() ?? []
  );
}

export function mergePlanNotifications(
  planNotificationGroups: PlanNotificationGroup[]
): PlanNotificationItemType[] {
  // map to GeneralNotificationItem[]
  const planNotifications = mapPlanNotifications(planNotificationGroups);

  // group by trigger type
  return planNotifications.reduce(
    (acc: PlanNotificationItemType[], currentItem) => {
      const existingIndex = acc.findIndex(
        (item) => item.triggerEvent === currentItem.triggerEvent
      );
      // trigger type not found in other notifications, return new group
      if (existingIndex === -1) {
        return [
          ...acc,
          {
            id: currentItem.id,
            scope: "Plan",
            triggerEvent: currentItem.triggerEvent,
            notificationGroups: [currentItem.notificationGroup],
            inAdvance: currentItem.inAdvance,
          },
        ];
      }
      // group with same trigger type notifications
      return acc.map((item, index) => {
        if (index === existingIndex) {
          return {
            ...item,
            notificationGroups: [
              ...item.notificationGroups,
              currentItem.notificationGroup,
            ],
          };
        }
        return item;
      });
    },
    []
  );
}

function mapPlanNotifications(
  planNotifications: PlanNotificationGroup[]
): PlanNotification[] {
  return planNotifications.map((item: PlanNotificationGroup) => ({
    id: item.id,
    triggerEvent: item.triggerEvent,
    notificationGroup: {
      id: item.notificationGroup?.id,
      name: item.notificationGroup?.name,
    },
    inAdvance: item.inAdvance ?? 0,
  }));
}
