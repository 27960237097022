/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { toast, ToastContent, ToastOptions } from "react-toastify";
import { DEFAULT_ERROR_MESSAGE } from "~/tools";

export const defaultOptions = {
  position: "top-right" as const,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const showSuccess = (
  content: ToastContent | string,
  options: ToastOptions = { autoClose: 2000 }
) =>
  toast.success(content, {
    ...defaultOptions,
    ...options,
    toastId: `${content}`,
  });

export const showError = (
  content: ToastContent = DEFAULT_ERROR_MESSAGE.general,
  options?: ToastOptions
) =>
  toast.error(content, {
    ...defaultOptions,
    ...options,
    toastId: `${content}`,
  });

export const showInfo = (
  content: ToastContent,
  options: ToastOptions = { autoClose: 2000 }
) =>
  toast.info(content, {
    ...defaultOptions,
    ...options,
  });
