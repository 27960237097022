/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { Box, Button, FlexContainer, Heading, Text } from "@nordcloud/gnui";
import { DataDisplayWrapper } from "~/components";
import { SidebarItem, useComponentsSelector } from "~/hooks";
import { mapResourceToSidebarOption } from "~/hooks/utils";
import { isEmpty, isNotEmpty, isNotNil } from "~/tools";
import { ResourceGroupData, ResourceGroupField } from "./constants";

type Props = {
  resourceGroupData: ResourceGroupData;
  onEditResources: () => void;
  onEditDetails: () => void;
};

export function Confirm({
  resourceGroupData,
  onEditDetails,
  onEditResources,
}: Props) {
  const [filteredOptions, setFilteredOptions] = useState<SidebarItem[]>([]);

  const { items, handleShowSearch } = useComponentsSelector(filteredOptions);

  useEffect(() => {
    const filtered =
      resourceGroupData.manual_selected_resources
        ?.map((item) =>
          resourceGroupData[ResourceGroupField.ALL_RESOURCES].find(
            (itm) => item === itm.id
          )
        )
        .filter(isNotNil)
        .map(mapResourceToSidebarOption) ?? [];
    setFilteredOptions(filtered);
    handleShowSearch(filtered);
  }, [handleShowSearch, resourceGroupData]);
  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Heading level={4}>Resource Group Details</Heading>
        <Box boxStyle="lightGrey" spacing="spacing04">
          <FlexContainer alignItems="start" justifyContent="space-between">
            <div>
              <Text size="sm" m={0} p={0}>
                Resource Group Name
              </Text>
              <Text>{resourceGroupData.details?.name}</Text>
              <Text size="sm" m={0} p={0}>
                Resource Group Description
              </Text>

              <DataDisplayWrapper
                hasData={resourceGroupData.details?.description !== ""}
              >
                {resourceGroupData.details?.description}
              </DataDisplayWrapper>
            </div>
            <div>
              <Button
                aria-label="edit button"
                icon="edit"
                size="md"
                severity="low"
                onClick={onEditDetails}
              />
            </div>
          </FlexContainer>
        </Box>
        <Heading level={4}>Group Resources</Heading>
        <Box boxStyle="lightGrey" spacing="spacing04">
          <FlexContainer alignItems="start" justifyContent="space-between">
            <div>
              <Text size="sm" m={0} p={0}>
                Selected Resources
              </Text>
              <When condition={!isEmpty(items["SELECTED_ITEMS"])}>
                {items["SELECTED_ITEMS"].map((item) => (
                  <Text key={item.id} mt={1}>
                    {item.name}
                  </Text>
                ))}
              </When>
              <Text size="sm" m={0} p={0}>
                Expression
              </Text>
              <DataDisplayWrapper
                hasData={isNotEmpty(resourceGroupData.expression ?? "")}
              >
                {resourceGroupData.expression}
              </DataDisplayWrapper>
            </div>
            <div>
              <Button
                aria-label="edit button"
                icon="edit"
                size="md"
                severity="low"
                onClick={onEditResources}
              />
            </div>
          </FlexContainer>
        </Box>
      </Col>
    </Row>
  );
}
