/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Action } from "~/generated/graphql";
import { createCtx } from "~/tools";

type Props = {
  customAction?: Partial<Action>;
};

export const [useCustomAction, CustomActionCtxProvider] = createCtx<Props>();
