/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldValues, useFormContext } from "react-hook-form";
import { Button, FlexContainer, Input, theme } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";
import { FormField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { Wrap, ErrorText } from "~/views/plans/styled";

type Props = {
  index: number;
  remove: () => void;
  disabled?: boolean;
};

export function ExecutionDateItemDetails({ remove, index, disabled }: Props) {
  const { register, formState } = useFormContext();

  const executionDate = `${FormField.SCHEDULE_EXECUTION_DATES}.${index}.${FormField.EXECUTION_DATE}`;
  const executionTime = `${FormField.SCHEDULE_EXECUTION_DATES}.${index}.${FormField.EXECUTION_TIME}`;

  const fieldError = formState.errors[FormField.SCHEDULE_EXECUTION_DATES] as
    | FieldValues[]
    | undefined;
  const error =
    fieldError?.[index]?.[FormField.EXECUTION_DATE] ??
    fieldError?.[index]?.[FormField.EXECUTION_TIME];

  const status = isNotNil(error) ? "danger" : undefined;

  return (
    <FlexContainer
      alignItems="stretch"
      gap={theme.spacing.spacing04}
      justifyContent="space-between"
    >
      <FlexContainer direction="column" css={{ flex: 1 }}>
        <Wrap>
          <Input
            small
            placeholder="Select date"
            aria-label="Select date"
            type="date"
            {...register(`${executionDate}` as const)}
            disabled={disabled}
            status={status}
          />
          <Input
            small
            placeholder="Select time"
            aria-label="Select time"
            type="time"
            {...register(`${executionTime}` as const)}
            disabled={disabled}
            status={status}
          />
          <ErrorText error={error} />
        </Wrap>
      </FlexContainer>
      <div>
        <Button
          data-testid={`button-delete-date`}
          severity="low"
          ml="auto"
          icon="trash"
          onClick={remove}
        />
      </div>
    </FlexContainer>
  );
}
