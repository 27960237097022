/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { MouseEvent } from "react";
import styled, { css } from "styled-components";
import { theme } from "@nordcloud/gnui";
import { isNotNil } from "~/tools";

type Props = {
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  variant?: "success" | "link";
  align?: string;
  border?: string;
};

export const InfoCard = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  background: ${theme.color.background.ui03};
  border: 1px solid ${theme.color.border.border01};
  box-sizing: border-box;
  padding: ${theme.spacing.spacing06};
  border-radius: ${theme.spacing.spacing01};
  flex: 1;
  margin: ${theme.spacing.spacing03};
  justify-content: center;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${handleStylesFromProps};

  .title {
    font-weight: bold;
    margin-bottom: ${theme.spacing.spacing02};
    font-size: ${theme.spacing.spacing05};
  }
`;

function handleStylesFromProps({ onClick, variant, align }: Props) {
  const isClickable = isNotNil(onClick) || variant === "link";

  return css({
    ...(variant === "success" && {
      background: theme.color.background.success,
      color: theme.color.text.text04,
      "& .title": {
        color: theme.color.text.text04,
      },
    }),
    ...(align === "center" && {
      alignItems: "center",
    }),
    ...(isClickable && {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.color.background.ui04,
        textDecoration: "none",
      },
    }),
  });
}
