/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Heading, Text, theme } from "@nordcloud/gnui";
import { NotificationGroupData, NotificationGroupField } from "../constants";
import { NotificationGroupSelect } from "./NotificationGroupsSelect";

type Props = {
  initialValues: NotificationGroupData;
  nextStep: () => void;
};

export function NotificationGroupsSelectFrom({
  nextStep,
  initialValues,
}: Props) {
  return (
    <>
      <Row justify="center">
        <Col sm={6} md={6} lg={8}>
          <Heading level={3}>Notifications</Heading>
          <Text size="sm" mb={theme.spacing.spacing04}>
            There are a few options to send notifications.
          </Text>
          <NotificationGroupSelect
            initialValues={initialValues?.[NotificationGroupField.CHANNELS]}
            nextStep={nextStep}
          />
        </Col>
      </Row>
    </>
  );
}
