/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { Else, If, Then } from "react-if";
import { v4 } from "uuid";
import { BrickLoader, FlexContainer } from "@nordcloud/gnui";
import {
  EventActionAttempt,
  EventActionResourceReport,
} from "~/generated/graphql";
import { ErrorWrap, LoaderWrap, NoData } from "~/components";
import { isEmpty } from "~/tools";
import { ResourceStatus } from "~/views/events/EventsDetails/components/ResourceStatus";
import { ReportHeader } from "./ReportHeader";
import { ReportStateCtxProvider } from "./ReportProvider";
import { FilterType, ReportState } from "./types";
import { useGetFilteredReport } from "./useGetFilteredReport";

type Props = {
  eventId: string;
  eventActionId: string;
  attempt?: EventActionAttempt;
};

function ReportBox({ eventId, eventActionId, attempt }: Props) {
  const { reportResources, loading, error } = useGetFilteredReport({
    eventId,
    eventActionId,
    attempt,
  });

  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <FlexContainer direction="column" grow={1}>
        <ReportHeader />
        <LoaderWrap loading={loading} Component={BrickLoader}>
          <If condition={isEmpty(reportResources)}>
            <Then>
              <FlexContainer grow={1} justifyContent="center">
                <NoData hasIcon message="There is no Report for this event." />
              </FlexContainer>
            </Then>
            <Else>
              {reportResources.map((resource: EventActionResourceReport) => {
                return (
                  <ResourceStatus
                    key={v4()}
                    eventActionResourceReport={resource}
                  />
                );
              })}
            </Else>
          </If>
        </LoaderWrap>
      </FlexContainer>
    </ErrorWrap>
  );
}

export function ReportView({ eventId, eventActionId, attempt }: Props) {
  const [reportState, updateReportState] = useReducer(
    (data: ReportState, partialData: Partial<ReportState>) => ({
      ...data,
      ...partialData,
    }),
    {
      resources: undefined,
      filterQuery: FilterType.ALL,
      eventActionId,
    }
  );
  return (
    <ReportStateCtxProvider value={{ reportState, updateReportState }}>
      <ReportBox {...{ eventActionId, eventId, attempt }} />
    </ReportStateCtxProvider>
  );
}
