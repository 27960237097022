/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box } from "@nordcloud/gnui";
import { ChangeDetails } from "~/components/ChangeDetails";
import { Details } from "~/components/Details";
import { NoData } from "~/components/NoData";
import { BorderWrapper } from "~/components/Styled";
import { useResourceGroup } from "~/views/resourceGroups/ResourceGroupDetails/ResourceGroupProvider";
import { DetailsFirstPreset, HeaderPreset } from "../presets";

export function ResourceGroupInfo() {
  const { resourceGroup } = useResourceGroup();

  if (resourceGroup === null) {
    return <NoData message="no data" />;
  }

  return (
    <Box spacing="spacing02">
      <Row>
        <Col xs={8} sm={8} md={5} lg={8}>
          <BorderWrapper
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div css={{ width: "100%" }}>
              <Details details={HeaderPreset(resourceGroup)} />
            </div>
          </BorderWrapper>
          <Row>
            <Col>
              <Details details={DetailsFirstPreset(resourceGroup)} />
            </Col>
          </Row>
        </Col>
        <Col xs={8} sm={8} md={3} lg={4}>
          <ChangeDetails details={resourceGroup?.changeDetails} />
        </Col>
      </Row>
    </Box>
  );
}
