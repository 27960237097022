/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FlexContainer, Sidebar, Text, theme } from "@nordcloud/gnui";
import { useCustomerLazyQuery } from "~/generated/graphql";
import { LoaderWrap } from "~/components";
import { ErrorMessage } from "~/components/Error";
import { saveCustomerInLS, useCustomer } from "~/context";
import { CustomerSelectorForm } from "~/context/CustomerSelector";
import { useCustomerSelectorForm } from "~/context/CustomerSelector/useCustomerSelectorForm";
import { useDisclosure } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { noop } from "~/tools";
import { Customer } from "~/types";

export function CustomerSwitcher() {
  const { customer, hasMoreCustomers, setCustomerValue } = useCustomer();

  const { isOpen, close, open } = useDisclosure(false);
  const [getCustomers, { data, loading, error }] = useCustomerLazyQuery();
  const client = useApolloClient();
  const history = useHistory();

  const chooseCustomer = async (c: Customer) => {
    saveCustomerInLS(c);
    setCustomerValue(c);
    close();
    history.push(ROUTES.index);
    client.stop();
    await client.resetStore();
  };

  const onClick = () => {
    open();
    getCustomers();
  };

  const customers: Customer[] = data?.customers.map((c) => {
    const { id, name, organizationName } = c;
    return {
      id,
      name,
      organizationName,
    };
  }) ?? [{ id: "", name: "", organizationName: "" }];

  const submitCustomer = (id: string, name: string, orgName: string) => {
    chooseCustomer({ id, name, organizationName: orgName });
  };

  const { onCustomerSelected, onSearchChange, resetSearch, filteredCustomers } =
    useCustomerSelectorForm({
      customers,
      onSubmit: submitCustomer,
    });

  const handleCustomerSelected = (
    id: string,
    name: string,
    orgName: string
  ) => {
    resetSearch();
    onCustomerSelected(id, name, orgName);
  };

  const handleCloseSidebar = () => {
    close();
    resetSearch();
  };

  return (
    <>
      <CustomerSwitcherWrapper
        selectable={hasMoreCustomers}
        onClick={hasMoreCustomers ? onClick : noop}
      >
        <FlexContainer direction="column" alignItems="start">
          <Text nowrap mb={0} color={theme.color.text.text02} size="sm">
            {customer?.organizationName}
          </Text>
          <Text
            nowrap
            mb={0}
            color={theme.color.interactive.link}
            size="md"
            weight="medium"
          >
            {customer?.name}
          </Text>
        </FlexContainer>
      </CustomerSwitcherWrapper>
      <Sidebar
        title="Switch Tenant"
        isOpen={isOpen}
        onClick={handleCloseSidebar}
      >
        <ErrorMessage error={error} />
        <LoaderWrap loading={loading} inContent>
          <CustomerSelectorForm
            customers={filteredCustomers}
            onCustomerSelected={handleCustomerSelected}
            onSearchChange={onSearchChange}
          />
        </LoaderWrap>
      </Sidebar>
    </>
  );
}

const CustomerSwitcherWrapper = styled.button<{ selectable: boolean }>`
  position: relative;
  display: flex;
  height: 3rem;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: ${theme.radiusDefault};
  justify-content: center;
  column-gap: ${theme.spacing.spacing02};
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing03};
  transition: background-color 0.2s ease-in-out;
  cursor: ${({ selectable }) => (selectable ? "pointer" : "default")};
  &:after {
    content: "";
    position: absolute;
    top: ${theme.spacing.spacing01};
    right: 0;
    bottom: ${theme.spacing.spacing01};
    width: 1px;
    background-color: ${theme.color.border.border01};
  }

  &:hover {
    background-color: ${({ selectable }) =>
      selectable ? theme.color.interactive.secondaryHover : undefined};
  }
`;
