/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { useHistory } from "react-router-dom";
import { Heading, Message, Spacer, SVGIcon } from "@nordcloud/gnui";
import { InfoCard } from "~/components";
import { ROUTES } from "~/routing/routes";

type Props = {
  resourceGroupId: string;
  onAddNewResourceGroup: () => void;
};

export function Summary({ resourceGroupId, onAddNewResourceGroup }: Props) {
  const history = useHistory();

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        <Spacer height="1rem" />
        <When condition={() => true}>
          <Row>
            <Col>
              <Message status="success" image="success">
                Resource group has been added
              </Message>
            </Col>
          </Row>
          <Spacer height="2rem" />
        </When>
        <Row>
          <Col lg={4}>
            <InfoCard
              align="center"
              onClick={() => {
                history.push(
                  ROUTES.resourceGroups.generateDetails(resourceGroupId)
                );
              }}
              data-testid="Details"
            >
              <SVGIcon name="detailsView" size="xl" />
              <Heading marginTop="1rem" level={4}>
                Resource Group Details
              </Heading>
            </InfoCard>
          </Col>
          <Col lg={4}>
            <InfoCard
              align="center"
              onClick={() => history.push(ROUTES.resourceGroups.index)}
              data-testid="List"
            >
              <SVGIcon name="listView" size="xl" />
              <Heading marginTop="1rem" level={4}>
                List of Resource Groups
              </Heading>
            </InfoCard>
          </Col>
          <Col lg={4}>
            <InfoCard
              align="center"
              onClick={onAddNewResourceGroup}
              data-testid="Add another"
            >
              <SVGIcon name="wizardView" size="xl" />
              <Heading marginTop="1rem" level={4}>
                Add Another Resource Group
              </Heading>
            </InfoCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
