/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box } from "@nordcloud/gnui";
import { Details, NoData, ChangeDetails, BorderWrapper } from "~/components";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";
import { DetailsFirstPreset, DetailsSecondPreset } from "../presets";
import { Tabs } from "./Tabs/Tabs";

export function NotificationGroupInfo() {
  const { data } = useNotificationGroup();
  if (data === undefined) {
    return <NoData message="no data" />;
  }

  return (
    <>
      <Box spacing="spacing02">
        <Row>
          <Col xs={8} sm={8} md={5} lg={8}>
            <BorderWrapper
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div css={{ width: "100%" }}>
                <Details details={DetailsFirstPreset(data)} />
              </div>
            </BorderWrapper>
            <Row>
              <Col>
                <Details details={DetailsSecondPreset(data)} />
              </Col>
            </Row>
          </Col>
          <Col xs={8} sm={8} md={3} lg={4}>
            <ChangeDetails details={data?.changeDetails} />
          </Col>
        </Row>
      </Box>
      <Box spacing="spacing02" padding={0}>
        <Row>
          <Col>
            <Tabs />
          </Col>
        </Row>
      </Box>
    </>
  );
}
