/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  Box,
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  theme,
} from "@nordcloud/gnui";
import { EventActionResourceReport } from "~/generated/graphql";
import { getResourceStatus } from "~/components/resourceGroup/utils";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import { ROUTES } from "~/routing/routes";
import { LogsOutputButton } from "./LogOutputButton";
import { ResourceDetails } from "./ResourceDetails";

type Props = {
  eventActionResourceReport: EventActionResourceReport;
};

export function ResourceStatus({ eventActionResourceReport }: Props) {
  const result = eventActionResourceReport.result;
  const logOutputLabel = `${eventActionResourceReport.resourceId} - ${eventActionResourceReport.name}`;

  return (
    <FlexContainer direction="column" css={{ width: "100%" }}>
      <Box css={{ borderRadius: 0 }}>
        <FlexContainer justifyContent="space-between">
          <div css={{ marginRight: theme.spacing.spacing03 }}>
            <ExtendedTooltip
              caption={
                getResourceStatus([eventActionResourceReport.status])
                  .description
              }
              status={
                getResourceStatus([eventActionResourceReport.status])
                  .tooltipColor
              }
              position="start"
            >
              <SVGIcon
                name={
                  getResourceStatus([eventActionResourceReport.status]).icon
                }
                color={getResourceStatus([
                  eventActionResourceReport.status,
                ]).color()}
              />
            </ExtendedTooltip>
          </div>
          <div css={{ flex: 1 }}>
            <NameWithLink
              link={ROUTES.resources.generateDetails(
                eventActionResourceReport.resourceId ?? ""
              )}
              name={
                eventActionResourceReport.name ??
                eventActionResourceReport.resourceId
              }
            />
          </div>
          <LogsOutputButton
            outputUri={result.outputUri}
            actionLogLabel={logOutputLabel}
            asIcon
          />
        </FlexContainer>
      </Box>
      <When condition={result !== null}>
        <Box
          boxStyle="lightGrey"
          css={{ borderRadius: 0 }}
          mb={theme.spacing.spacing04}
        >
          <ResourceDetails {...{ result }} />
        </Box>
      </When>
    </FlexContainer>
  );
}
