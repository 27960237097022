/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ScheduleType } from "./types";

export const scheduleTypeOptions = [
  {
    name: "Recurring",
    label: "Define the timing and frequency for executing the plan.",
    value: ScheduleType.recurring,
  },
  {
    name: "Specified dates",
    label: "The plan will run at the specified dates and times.",
    value: ScheduleType.specifiedDates,
  },
  {
    name: "On Demand",
    label: "The plan can be manually initiated as needed.",
    value: ScheduleType.onDemand,
  },
];
