/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { CustomActionCreateWizard } from "./components";

export function CustomActionCreate() {
  return (
    <>
      <BreadcrumbsBox
        title="Create Action"
        customBreadcrumbs={[
          { label: "Dashboard", uri: "/" },
          { label: "Custom Actions", uri: ROUTES.customActions.index },
          { label: "Create Action", uri: ROUTES.customActions.create },
        ]}
      />
      <CustomActionCreateWizard />
    </>
  );
}
