/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";

type MenuBarLinkProps = PropsWithChildren<{
  route: string;
}>;

export function MenuBarLink({ route, children }: MenuBarLinkProps) {
  const { pathname } = useLocation();
  const isSelected = checkIsSelected(pathname, route);

  return (
    <StyledLink
      to={route}
      className={isSelected ? "selected" : undefined}
      data-testid={`menu-button-${route.substring(1)}`}
    >
      {children}
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  color: ${theme.color.text.text01};
  text-decoration: none;
  width: 100%;

  &:hover,
  &:active,
  &.selected {
    border-radius: ${theme.radius.md};
    color: ${theme.color.text.text01};
    text-decoration: none;
  }

  &:hover {
    background-color: ${theme.color.interactive.secondary};
  }

  &:active {
    background-color: ${theme.color.interactive.secondaryActive};
  }

  &.selected {
    background-color: ${theme.color.interactive.secondaryHover};
  }
`;

function checkIsSelected(
  pathname: string,
  path: string,
  exact = path === ROUTES.index
) {
  if (exact) {
    return pathname === path;
  }

  return pathname.includes(path);
}
