/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import {
  EventDetailResourceGroupFragment,
  EventStatus,
  IncomingEventStatus,
  Maybe,
} from "~/generated/graphql";
import { isEmpty } from "~/tools";
import { ResourceGroupItem } from "./ResourceGroupItem";
import { ResourceGroupsNoData } from "./ResourceGroupsNoData";

type Props = {
  resourceGroups?: Maybe<EventDetailResourceGroupFragment[]>;
  status?: EventStatus | IncomingEventStatus;
};

export function ResourceGroupSection({ resourceGroups, status }: Props) {
  if (isEmpty(resourceGroups ?? [])) {
    return <ResourceGroupsNoData />;
  }
  return (
    <Wrap>
      {resourceGroups?.map((resourceGroup) => {
        return (
          <ResourceGroupItem
            key={resourceGroup.id}
            resourceGroup={resourceGroup}
            status={status}
          />
        );
      })}
    </Wrap>
  );
}

const Wrap = styled.div`
  & > :not(:first-child) {
    margin-top: ${theme.spacing.spacing04};
  }
`;
