/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer } from "@nordcloud/gnui";
import { ApiKeysSortFields, SortOrder, ApiKey } from "~/generated/graphql";
import { LoaderWrap } from "~/components/Loader";
import { NoData } from "~/components/NoData";
import { Pagination } from "~/components/Pagination";
import { SortSelector } from "~/components/SortSelector";
import { TableWrapper } from "~/components/Tables";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotNil } from "~/tools";
import { apiKeysColumns } from "~/views/permissions/APIKeys/components/ApiKeysTableColumns";
import { useGetApiKeys } from "../hooks/useGetApiKeys/useGetApiKeys";
import { QuerySort } from "../types/QuerySort";

const sortOptions = [
  {
    value: ApiKeysSortFields.Name,
    label: "API Key Name",
  },
  {
    value: ApiKeysSortFields.CreatedAt,
    label: "Date Added",
  },
  {
    value: ApiKeysSortFields.UpdatedAt,
    label: "Date Updated",
  },
  {
    value: ApiKeysSortFields.UpdatedBy,
    label: "Updated By",
  },
];

export function ApiKeysTable() {
  const { queryState, updateQueryState } = useQueryState<QuerySort>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const handleSortDirection = (order: SortOrder) => {
    updateQueryState({
      ...queryState,
      order,
    });
  };

  const handleSortField = (field: ApiKeysSortFields) => {
    updateQueryState({
      ...queryState,
      field,
    });
  };

  const { data, loading, count } = useGetApiKeys({
    page: queryState.page,
    limit: queryState.limit,
    sort: {
      field: queryState.field ?? ApiKeysSortFields.Name,
      order: queryState.order ?? SortOrder.Asc,
    },
  });

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <SortSelector<ApiKeysSortFields>
            options={sortOptions}
            value={queryState.field ?? ApiKeysSortFields.Name}
            direction={queryState.order ?? SortOrder.Asc}
            onSelect={handleSortField}
            onSortChange={handleSortDirection}
          />
        </FlexContainer>
      </Box>
      <LoaderWrap loading={loading} inContent>
        <If condition={isEmpty(data)}>
          <Then>
            <NoData hasIcon message={"There are no API Keys."} />
          </Then>
          <Else>
            <>
              <TableWrapper<Partial<ApiKey>>
                loading={loading}
                data={data.filter(isNotNil) as ApiKey[]}
                columns={apiKeysColumns()}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                stripped
              />
              <Pagination count={count} />
            </>
          </Else>
        </If>
      </LoaderWrap>
    </>
  );
}
