/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, Heading, Text, FlexContainer, theme } from "@nordcloud/gnui";
import { MetadataCreate } from "~/components";
import { useUpdateResourceGroup } from "~/views/resourceGroups/hooks";
import { MetadataTable } from "~/views/resourceGroups/ResourceGroupDetails/Tabs/MetadataTab/components/MetadataTable";
import { useResourceGroup } from "../../ResourceGroupProvider";

export function MetadataTab() {
  const { resourceGroup } = useResourceGroup();

  const metadata = resourceGroup?.metadata ?? [];
  const resourceGroupId = resourceGroup?.id ?? "";

  return (
    <>
      <Box spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <div>
            <Heading level={3}>Resource Group Metadata</Heading>
            <Text size="sm" color={theme.color.text.text02}>
              Metadata are tags with key-value labels. They helps organize and
              manage resources.
            </Text>
          </div>
          <div>
            <MetadataCreate
              updateHook={useUpdateResourceGroup}
              metadata={metadata}
              id={resourceGroupId}
            />
          </div>
        </FlexContainer>
        <MetadataTable metadata={metadata} resourceGroupId={resourceGroupId} />
      </Box>
    </>
  );
}
