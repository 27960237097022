/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export enum FormField {
  NAME = "name",
  DESCRIPTION = "description",
  EMAIL_CHANNELS = "email_channels",
  SLACK_CHANNELS = "slack_channels",
  PAGER_DUTY_CHANNELS = "pager_duty_channels",
}

export type Email = {
  email: string;
};

export type Slack = {
  webhookUrl: string;
};

export type PagerDuty = {
  pagerDutyKey: string;
};

export type FormData = {
  [FormField.NAME]?: string;
  [FormField.DESCRIPTION]?: string;
  [FormField.EMAIL_CHANNELS]?: Email[];
  [FormField.SLACK_CHANNELS]?: Slack[];
  [FormField.PAGER_DUTY_CHANNELS]?: PagerDuty[];
};
