/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Action,
  UpdateActionInput,
  ActionPropertyAwsLambda,
  ActionPropertyAzureFunction,
  ActionPropertySsmDocument,
  ActionPropertyWebhook,
  ActionType,
} from "~/generated/graphql";
import { FormData } from "../../CustomActionCreate/components/CustomActionCreateWizard/formConfig";

// eslint-disable-next-line complexity
const getTypeProperties = (
  data: FormData,
  customAction: Partial<Action> | undefined
) => {
  const customActionProps = customAction?.typeProperties;
  switch (data.type ?? customAction?.type) {
    case ActionType.AwsLambda:
      const awsLambdaActionProps = customActionProps as ActionPropertyAwsLambda;
      return {
        awsLambda: {
          lambdaArn: data.lambdaArn ?? awsLambdaActionProps?.lambdaArn,
        },
      };
    case ActionType.AzureFunction:
      const azureFunctionAction =
        customActionProps as ActionPropertyAzureFunction;
      return {
        azureFunction: {
          accessKeyId: data.accessKeyId ?? azureFunctionAction?.accessKeyId,
          functionUrl: data.functionUrl ?? azureFunctionAction?.functionUrl,
          functionMethod:
            data.functionMethod ?? azureFunctionAction?.functionMethod,
        },
      };
    case ActionType.Script:
      if (data.script !== undefined) {
        return {
          script: {
            file: data.script,
          },
        };
      }
      return undefined;
    case ActionType.SsmDocument:
      const ssmDocumentAction = customActionProps as ActionPropertySsmDocument;
      return {
        ssmDocument: {
          documentId: data.documentId ?? ssmDocumentAction?.documentId,
        },
      };
    case ActionType.Webhook:
      const webhookAction = customActionProps as ActionPropertyWebhook;
      return {
        webhook: {
          webhookUrl: data.webhookUrl ?? webhookAction?.webhookUrl,
          payload: data.payload ?? webhookAction?.payload,
          headers:
            data.headers ??
            webhookAction?.headers.map(({ key, value }) => ({
              key,
              value,
            })),
          webhookMethod: data.webhookMethod ?? webhookAction?.webhookMethod,
        },
      };
    default:
      throw new Error("unsupported action type");
  }
};

export function mapCustomActionToCustomActionPutInput(
  data: FormData,
  customAction?: Partial<Action>
): UpdateActionInput {
  const typeProperties = getTypeProperties(data, customAction);
  return {
    id: customAction?.id ?? "",
    type: data.type ?? customAction?.type,
    scope: data.scope ?? customAction?.scope,
    typeProperties: typeProperties
      ? {
          ...typeProperties,
        }
      : undefined,
    parameterDefinitions: customAction?.parameterDefinitions?.map((param) => {
      return {
        name: param.name,
        type: param.type,
        required: param.required,
      };
    }),
  };
}

export function getUpdateObject(
  data: FormData,
  customAction?: Partial<Action>
): UpdateActionInput {
  const putCustomAction = mapCustomActionToCustomActionPutInput(
    data,
    customAction
  );

  return {
    ...putCustomAction,
    ...{ name: data.name, description: data.description },
  };
}
