/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CronExpression } from "cron-parser";
import dayjs from "dayjs";
import { dateFormat } from "~/constants";

export const removePastDates = (dates: string[]) =>
  dates.filter((date) => dayjs(date).isAfter(dayjs()));

export function getNextDate(interval: CronExpression, format: string) {
  return dayjs(interval.next().toString()).format(format);
}

export function getDateToMinValue(
  defaultDate: dayjs.Dayjs,
  startDate?: string
) {
  return startDate
    ? dayjs(startDate).subtract(1, "year").format(dateFormat.shortDate)
    : defaultDate.subtract(1, "year").format(dateFormat.shortDate);
}
