/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Input, Label, Spacer, theme } from "@nordcloud/gnui";
import { FormGroup, stringRequired } from "~/components/Forms";
import { useUpdateCloudAccount } from "~/views/accounts/hooks";
import { CloudAccountFields } from "~/views/accounts/hooks/useUpdateCloudAccount/types";

export const schema = z.object({
  appId: stringRequired("App Id"),
  password: stringRequired("Password"),
  tenantId: stringRequired("Tenant Id"),
});

export function AzureForm() {
  const { handleSubmit, register, formState, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      appId: "",
      password: "",
      tenantId: "",
    },
  });

  const [updateCloudAccount, loading] = useUpdateCloudAccount({
    onSuccess: () => {
      close();
      reset();
    },
  });

  const onSubmit = (data: CloudAccountFields) => updateCloudAccount(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup error={formState.errors["appId"]}>
        <Label name="Provide App ID" htmlFor="appId" required />
        <Input
          id="appId"
          placeholder="e.g. 291bba3f-e0a5-47bc-a099-3dbcb2a50a05"
          {...register("appId")}
        />
      </FormGroup>
      <FormGroup error={formState.errors["password"]}>
        <Label name="Provide Password" htmlFor="password" required />
        <Input
          type="password"
          id="password"
          placeholder=""
          {...register("password")}
        />
      </FormGroup>
      <FormGroup error={formState.errors["tenantId"]}>
        <Label name="Provide Tenant Id" htmlFor="tenantId" required />
        <Input
          id="tenantId"
          placeholder="e.g. 291bba3f-e0a5-47bc-a099-3dbcb2a50a05"
          {...register("tenantId")}
        />
      </FormGroup>
      <Spacer height={theme.spacing.spacing02} />
      <Button initialState={loading ? "loading" : "success"}>Apply</Button>
    </form>
  );
}
