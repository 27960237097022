/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { ListLoader, Text } from "@nordcloud/gnui";
import {
  FilterComparisonOperator,
  IncomingEventsFilterByFields,
  IncomingEventsFilterByInput,
} from "~/generated/graphql";
import { LoaderWrap } from "~/components/Loader";
import { NoData } from "~/components/NoData";
import { Pagination } from "~/components/Pagination";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotEmpty } from "~/tools";
import { createFilterByInput } from "~/utils/createFilterByInput";
import { useGetIncomingEvents } from "../hooks/useGetIncomingEvents/useGetIncomingEvents";
import { GridColumns } from "../styled";
import { QueryGetEvents } from "./constants";
import { EventsLoader } from "./EventLoader";
import { IncomingEventItem } from "./IncomingEventItem";

const prepareFilterBy = (
  queryState: QueryGetEvents
): IncomingEventsFilterByInput[] => {
  const filterBy = [
    {
      field: IncomingEventsFilterByFields.StartTime,
      operator: FilterComparisonOperator.Ge,
      values: [queryState.startDateUTC ?? ""],
    },
    {
      field: IncomingEventsFilterByFields.StartTime,
      operator: FilterComparisonOperator.Le,
      values: [queryState.endDateUTC ?? ""],
    },
  ];

  const eventStatusFilter = createFilterByInput<IncomingEventsFilterByFields>(
    IncomingEventsFilterByFields.Status,
    queryState.eventStatuses,
    queryState.excludeEventStatuses
  );

  const eventRelatedPlanIdsFilter =
    createFilterByInput<IncomingEventsFilterByFields>(
      IncomingEventsFilterByFields.PlanId,
      queryState.eventRelatedPlanIds,
      queryState.excludeEventRelatedPlanIds
    );

  const eventActionIdsFilter =
    createFilterByInput<IncomingEventsFilterByFields>(
      IncomingEventsFilterByFields.ActionId,
      queryState.eventActionIds,
      queryState.excludeEventActionIds
    );

  return [
    ...filterBy,
    ...eventStatusFilter,
    ...eventRelatedPlanIdsFilter,
    ...eventActionIdsFilter,
  ];
};

export function IncomingEventsTable() {
  const { queryState } = useQueryState<QueryGetEvents>();

  const { data, loading, count } = useGetIncomingEvents({
    page: queryState.page ?? "1",
    limit: queryState.limit,
    sort: {
      field: queryState.sortField,
      order: queryState.order,
    },
    filter: {
      filterBy: prepareFilterBy(queryState),
      phrase: isNotEmpty(queryState.searchPhrase?.trim() || "")
        ? queryState.searchPhrase
        : undefined,
    },
  });

  const wrongRangeInput = dayjs(queryState.startDateUTC).isAfter(
    queryState.endDateUTC
  );

  return (
    <>
      <LoaderWrap
        loading={loading}
        Component={() => (
          <ListLoader>
            <EventsLoader />
          </ListLoader>
        )}
      >
        <If condition={isEmpty(data)}>
          <Then>
            <If condition={wrongRangeInput}>
              <Then>
                <NoData
                  hasIcon
                  message={
                    "The date range is incorrect. Please ensure that the 'startDate' and 'endDate' parameters in the link are correctly specified."
                  }
                />
              </Then>
              <Else>
                <NoData hasIcon message={"There are no Events."} />
              </Else>
            </If>
          </Then>
          <Else>
            <>
              <Row>
                <Col>
                  <GridColumns hasPadding>
                    <div />
                    <div />
                    <Text size="sm" m={0} color="text04">
                      Event Name
                    </Text>
                    <Text size="sm" m={0} color="text04">
                      Start Time (local time)
                    </Text>
                    <Text size="sm" m={0} color="text04">
                      End Time (local time)
                    </Text>
                    <Text size="sm" m={0} color="text04">
                      Event Duration
                    </Text>
                  </GridColumns>
                </Col>
              </Row>
              {data.map((event) => (
                <IncomingEventItem key={event.id} event={event} />
              ))}
              <Pagination count={count} />
            </>
          </Else>
        </If>
      </LoaderWrap>
    </>
  );
}
