/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ReactJson from "react-json-view";
import { theme } from "@nordcloud/gnui";
import { MediumIconColumn, SmallIconColumn } from "~/components/Tables/styles";
import { isValidJSONObject } from "~/utils/json";

export type KeyValue = {
  id: string;
  key: string;
  value: string;
};

const columnHelper = createColumnHelper<KeyValue>();

export function columns(isLight: boolean) {
  return [
    columnHelper.accessor("key", {
      header: "key",
      cell: ({ row }) => {
        return (
          <div
            css={{
              paddingTop: theme.spacing.spacing01,
              paddingBottom: theme.spacing.spacing01,
            }}
          >
            {row.original.key}
          </div>
        );
      },
      meta: SmallIconColumn,
    }),
    columnHelper.accessor("value", {
      header: "value",
      cell: ({ row }) => {
        return isValidJSONObject(row.original.value) ? (
          <ReactJson
            src={JSON.parse(row.original.value)}
            iconStyle="square"
            name={row.original.key}
            displayDataTypes={false}
            quotesOnKeys={false}
            style={isLight ? {} : { backgroundColor: "none" }}
            theme={isLight ? "rjv-default" : "grayscale"}
            collapsed
          />
        ) : (
          <div
            css={{
              paddingTop: theme.spacing.spacing01,
              paddingBottom: theme.spacing.spacing01,
            }}
          >
            {removeFirstAndLastQuotes(row.original.value)}
          </div>
        );
      },
      meta: MediumIconColumn,
    }),
  ] as ColumnDef<Partial<KeyValue>>[];
}

function removeFirstAndLastQuotes(inputString: string): string {
  if (
    inputString.length >= 2 &&
    inputString.startsWith('"') &&
    inputString.endsWith('"')
  ) {
    return inputString.slice(1, -1);
  }
  return inputString;
}
