/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon, Box, theme, ExtendedTooltip, Text } from "@nordcloud/gnui";
import { getPlanInfo } from "../../utils";

type Props = {
  status: boolean;
  scheduleCron: string;
  scheduleExecutionDates: string[];
};

export function PlanDetailsStatus({
  status,
  scheduleCron,
  scheduleExecutionDates,
}: Props) {
  const planStatus = getPlanInfo(status, scheduleCron, scheduleExecutionDates);
  return (
    <>
      <Wrap>
        <ExtendedTooltip
          status={planStatus.status}
          caption={
            <Text tag="span" size="sm" color={theme.color.text.text04}>
              {planStatus.caption}
            </Text>
          }
          position="start"
        >
          <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
            <SVGIcon
              size="lg"
              color={planStatus.status}
              aria-label={`icon ${planStatus.iconName}`}
              name={planStatus.iconName}
            />
          </Box>
        </ExtendedTooltip>
      </Wrap>
    </>
  );
}

const Wrap = styled.div`
  margin-right: ${theme.spacing.spacing03};
`;
