/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import { createCtx } from "~/tools";
import { StepState } from "../types";

type ProviderProps = {
  stepState: StepState;
  updateStepState: Dispatch<Partial<StepState>>;
};

export const [useStepState, StepStateCtxProvider] = createCtx<ProviderProps>();
