/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import { FlexContainer, theme } from "@nordcloud/gnui";
import { FlexColumnMax, FlexColumnLarge } from "~/components/Tables/styles";
import { DeletePagerDuty } from "./DeletePagerDuty";
import { EditPagerDutyForm } from "./EditPagerDutyForm";

export type PagerDuty = {
  id: string;
  pagerDutyKey: string;
};

const columnHelper = createColumnHelper<PagerDuty>();

export function columns() {
  return [
    columnHelper.accessor(() => null, {
      header: "PagerDuty Key",
      cell: ({ row }) => <div>{row.original.pagerDutyKey}</div>,
      meta: FlexColumnMax,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const { id, pagerDutyKey } = row.original;

        return (
          <FlexContainer
            justifyContent="space-between"
            gap={theme.spacing.spacing01}
          >
            <EditPagerDutyForm
              pagerDutyKeyId={id}
              defaultValue={pagerDutyKey}
            />
            <DeletePagerDuty pagerDutyKey={pagerDutyKey} pagerDutyKeyId={id} />
          </FlexContainer>
        );
      },
      meta: FlexColumnLarge,
    }),
  ] as never;
}
