/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { CloudAccountCloudProvider } from "~/generated/graphql";
import { awsRegionsOptions } from "~/constants";
import {
  FormDataAws,
  FormDataAzure,
  schemaAws,
  schemaAzure,
} from "~/views/accounts/AccountCreate/components/formConfig";

export function useAccountCreate() {
  const [provider, setProvider] = useState<CloudAccountCloudProvider | null>(
    null
  );

  const methodsAws = useForm<FormDataAws>({
    resolver: zodResolver(schemaAws),
    defaultValues: {
      regions: awsRegionsOptions,
    },
  });
  const {
    watch: watchAws,
    handleSubmit: handleSubmitAws,
    reset: resetAws,
  } = methodsAws;

  const { providerId, roleExternalId, roleArn, regions } = watchAws();

  const methodsAzure = useForm<FormDataAzure>({
    resolver: zodResolver(schemaAzure),
  });
  const {
    watch: watchAzure,
    handleSubmit: handleSubmitAzure,
    reset: resetAzure,
  } = methodsAzure;
  const { appId, subscriptionId, tenantId } = watchAzure();

  const awsCredentials = useMemo(
    () => [
      {
        label: "Account ID",
        value: providerId,
      },
      {
        label: "Role External ID",
        value: roleExternalId,
      },
      {
        label: "Role ARN",
        value: roleArn,
      },
      {
        label: "Regions",
        value: regions.map(({ value }) => value).join(", "),
      },
    ],
    [providerId, roleExternalId, roleArn, regions]
  );

  const azureCredentials = useMemo(
    () => [
      {
        label: "Subscription ID",
        value: subscriptionId,
      },
      {
        label: "App ID",
        value: appId,
      },
      {
        label: "Tenant",
        value: tenantId,
      },
    ],
    [subscriptionId, appId, tenantId]
  );

  const resetForms = () => {
    resetAws();
    resetAzure();
  };

  const accountCredentials =
    CloudAccountCloudProvider.Aws === provider
      ? awsCredentials
      : azureCredentials;

  return {
    accountCredentials,
    resetForms,
    handleSubmitAws,
    handleSubmitAzure,
    provider,
    setProvider,
    methodsAws,
    methodsAzure,
  };
}
