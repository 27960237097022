/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect } from "react";
import { CronData } from "~/views/plans/hooks/useGenerateDatesFromCron/useGenerateDatesFromCron";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";
import { ScheduleType } from "~/views/plans/types";
import { planScheduleType } from "../components/helpers";

type Props = {
  generateDatesFromCron: (data: CronData) => void;
};

const useRegenerateCronDates = ({ generateDatesFromCron }: Props) => {
  const { plan } = usePlan();

  const scheduleType = planScheduleType({
    scheduleExecutionDates: plan?.scheduleExecutionDates ?? [],
    cronExpression: plan?.scheduleCron ?? "",
  });

  const regenerateCronDates = useCallback(() => {
    const data = {
      cron_expression: plan?.scheduleCron ?? "",
      location: plan?.scheduleTimezone ?? "",
      offset_in_minutes: plan?.scheduleOffset,
    };

    generateDatesFromCron(data);
  }, [
    generateDatesFromCron,
    plan?.scheduleCron,
    plan?.scheduleOffset,
    plan?.scheduleTimezone,
  ]);

  useEffect(() => {
    if (scheduleType === ScheduleType.recurring) {
      regenerateCronDates();
    }
  }, [regenerateCronDates, scheduleType]);

  return { regenerateCronDates };
};

export { useRegenerateCronDates };
