/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Spacer, Text, theme } from "@nordcloud/gnui";
import { ActionType } from "~/generated/graphql";
import { DataDisplayWrapper } from "~/components";
import { isNotEmpty } from "~/tools";
import { CustomActionData } from "../CustomActionCreate/components/CustomActionCreateWizard/constants";

type Props = {
  data?:
    | (CustomActionData["settings"] & { s3FileUrl?: string })
    | (CustomActionData["details"] & { s3FileUrl?: string });
  type?: ActionType;
};

export function renderActionSettingsForm({ data, type }: Props) {
  switch (type) {
    case ActionType.AwsLambda:
      return (
        <div data-testid="lambda-arn">
          <Text size="sm" mb={0}>
            Lambda ARN
          </Text>
          <DataDisplayWrapper hasData={data?.lambdaArn !== undefined}>
            {data?.lambdaArn}
          </DataDisplayWrapper>
        </div>
      );
    case ActionType.AzureFunction:
      return (
        <>
          <div data-testid="function-url">
            <Text size="sm" mb={0}>
              Function URL
            </Text>
            <DataDisplayWrapper hasData={data?.functionUrl !== undefined}>
              {data?.functionUrl}
            </DataDisplayWrapper>
          </div>
          <Spacer height={theme.spacing.spacing04} />
          <div data-testid="function-method">
            <Text size="sm" mb={0}>
              Method
            </Text>
            <DataDisplayWrapper hasData={data?.functionMethod !== undefined}>
              {data?.functionMethod}
            </DataDisplayWrapper>
          </div>
          <Spacer height={theme.spacing.spacing04} />
          <div data-testid="function-access-key">
            <Text size="sm" mb={0}>
              Access Key ID
            </Text>
            <DataDisplayWrapper hasData={data?.accessKeyId !== undefined}>
              {data?.accessKeyId}
            </DataDisplayWrapper>
          </div>
        </>
      );
    case ActionType.Script:
      const filename =
        data?.script?.name ?? data?.s3FileUrl?.replace(/^.*[\\\/]/, "");
      const isFile = data?.script ?? data?.s3FileUrl;
      return (
        <>
          <div data-testid="action-type">
            <Text size="sm" mb={0}>
              Action Type
            </Text>
            <DataDisplayWrapper hasData={data?.type !== undefined}>
              {data?.type}
            </DataDisplayWrapper>
          </div>
          <Spacer height={theme.spacing.spacing04} />
          <div data-testid="script-file">
            <Text size="sm" mb={0}>
              Script File
            </Text>
            <DataDisplayWrapper hasData={isFile !== undefined}>
              {filename}
            </DataDisplayWrapper>
          </div>
        </>
      );
    case ActionType.SsmDocument:
      return (
        <>
          <div data-testid="ssm-document-identifier">
            <Text size="sm" mb={0}>
              SSM Document Identifier
            </Text>
            <DataDisplayWrapper hasData={data?.documentId !== undefined}>
              {data?.documentId}
            </DataDisplayWrapper>
          </div>
        </>
      );
    case ActionType.Webhook:
      return (
        <>
          <div data-testid="webhook-url">
            <Text size="sm" mb={0}>
              Webhook URL
            </Text>
            <DataDisplayWrapper hasData={data?.webhookUrl !== undefined}>
              {data?.webhookUrl}
            </DataDisplayWrapper>
          </div>
          <Spacer height={theme.spacing.spacing04} />
          <div data-testid="webhook-method">
            <Text size="sm" mb={0}>
              Method
            </Text>
            <DataDisplayWrapper hasData={data?.webhookMethod !== undefined}>
              {data?.webhookMethod}
            </DataDisplayWrapper>
          </div>
          <Spacer height={theme.spacing.spacing04} />
          <div data-testid="webhook-payload">
            <Text size="sm" mb={0}>
              Payload
            </Text>
            <DataDisplayWrapper hasData={data?.payload !== undefined}>
              {data?.payload}
            </DataDisplayWrapper>
          </div>
          <Spacer height={theme.spacing.spacing04} />
          <div data-testid="webhook-headers">
            <Text size="sm" mb={0}>
              Headers
            </Text>
            <DataDisplayWrapper hasData={isNotEmpty(data?.headers ?? [])}>
              {data?.headers?.map(
                ({ key, value }: { key: string; value: string }) => (
                  <div key={key}>
                    {key}={value}
                  </div>
                )
              )}
            </DataDisplayWrapper>
          </div>
        </>
      );
    default:
      return null;
  }
}
