/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { When } from "react-if";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { useToggle } from "~/hooks";
import { ClearButton, ExpandIcon, FilterItemWrapper } from "./styles";
import { getSelectionsText } from "./utils";

type Props = {
  children: ReactNode;
  name: string;
  onClear: () => void;
  isSelected?: boolean;
  selectedOptions?: string[];
  isExpandedInitially?: boolean;
};

export function FilterWrapper({
  children,
  name,
  onClear,
  isSelected = false,
  selectedOptions,
  isExpandedInitially,
}: Props) {
  const [isExpanded, toggleIsExpanded] = useToggle(
    isExpandedInitially ?? false
  );

  return (
    <Box
      boxStyle="lightGrey"
      marginTop={theme.spacing.spacing02}
      padding={0}
      css={{
        "&:hover": {
          backgroundColor: !isExpanded
            ? theme.color.background.ui04
            : undefined,
        },
      }}
    >
      <FilterItemWrapper
        justifyContent="space-between"
        isFilterSelected={isSelected}
      >
        <FlexContainer justifyContent="center" alignItems="center">
          <ExpandIcon
            name="chevronDown"
            title="chevronDown"
            isExpanded={isExpanded}
            onClick={toggleIsExpanded}
          />
          <Text size="md" m={0} color="text04">
            {name}
          </Text>
        </FlexContainer>
        <FlexContainer>
          <When condition={isSelected}>
            <Text size="sm" color={theme.color.text.text02} mb={0}>
              {getSelectionsText(selectedOptions)}
            </Text>
            <ClearButton severity="low" onClick={onClear}>
              Clear
            </ClearButton>
          </When>
        </FlexContainer>
      </FilterItemWrapper>
      <When condition={isExpanded}>
        <div>{children}</div>
      </When>
    </Box>
  );
}
