/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { When } from "react-if";
import { PopoverControls } from "~/components";
import { isEmpty } from "~/tools";
import { DeletePlan } from "./DeletePlan";
import { RunPlan } from "./RunPlan";
import { UpdatePlanStatus } from "./UpdatePlanStatus";

type Props = {
  planId: string;
  planName: string;
  isPlanActive: boolean;
  size?: "large" | "small";
  displayPlanList?: boolean;
};

export function Actions({
  planId,
  planName,
  isPlanActive,
  size = "small",
  displayPlanList = false,
}: Props) {
  const noPlanId = isEmpty(planId ?? []);

  type State = {
    isDeleteModalOpen: boolean;
    isRunPlanModalOpen: boolean;
    isUpdatePlanStatusModalOpen: boolean;
  };

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => {
      return {
        ...data,
        ...partialData,
      };
    },
    {
      isDeleteModalOpen: false,
      isRunPlanModalOpen: false,
      isUpdatePlanStatusModalOpen: false,
    }
  );

  return (
    <>
      <PopoverControls
        disabled={noPlanId}
        size={size}
        items={[
          {
            label: `Run Plan Now`,
            onClick: () => updateState({ isRunPlanModalOpen: true }),
            disabled: !isPlanActive,
          },
          {
            label: `Delete Plan`,
            onClick: () => updateState({ isDeleteModalOpen: true }),
          },
          {
            label: isPlanActive ? `Deactivate Plan` : `Activate Plan`,
            onClick: () => updateState({ isUpdatePlanStatusModalOpen: true }),
          },
        ]}
      />
      <When condition={state.isRunPlanModalOpen}>
        <RunPlan
          planId={planId}
          planName={planName}
          isOpen={state.isRunPlanModalOpen}
          onClose={() => updateState({ isRunPlanModalOpen: false })}
        />
      </When>
      <When condition={state.isDeleteModalOpen}>
        <DeletePlan
          planId={planId}
          isDeleteModalOpen={state.isDeleteModalOpen}
          onClose={() => updateState({ isDeleteModalOpen: false })}
        />
      </When>
      <When condition={state.isUpdatePlanStatusModalOpen}>
        <UpdatePlanStatus
          planId={planId}
          isPlanActive={isPlanActive}
          isDeactivateModalOpen={state.isUpdatePlanStatusModalOpen}
          displayPlanList={displayPlanList}
          onClose={() => updateState({ isUpdatePlanStatusModalOpen: false })}
        />
      </When>
    </>
  );
}
