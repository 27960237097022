// Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
import { NotificationGroup } from "~/generated/graphql";
import { DetailData } from "~/components/Details/Detail";
import { stringRequired } from "~/components/Forms";
import { useUpdateNotificationGroup } from "~/views/notificationGroups/hooks/useUpdateNotificationGroup/useUpdateNotificationGroup";

export const DetailsFirstPreset: (data?: NotificationGroup) => DetailData[] = (
  data
) => [
  {
    label: "Name",
    value: data?.name,
    editProps: {
      property: "name",
      updateHook: useUpdateNotificationGroup,
      schema: {
        name: stringRequired("Name"),
      },
    },
  },
];

export const DetailsSecondPreset: (data?: NotificationGroup) => DetailData[] = (
  data
) => [
  {
    label: "Notification Group ID",
    value: data?.id,
  },
  {
    label: "Description",
    value: data?.description,
    editProps: {
      property: "description",
      updateHook: useUpdateNotificationGroup,
      schema: {
        description: stringRequired("Description"),
      },
    },
  },
];
