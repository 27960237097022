/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ApolloError } from "@apollo/client/errors";
import { Button, ModalConfirm } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { showError, showInfo, showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { useScanCloudAccount } from "~/views/accounts/hooks/useScanCloudAccount/useScanCloudAccount";

const scanInProgressMsg =
  "The Account is currently under scanning. Operations are temporarily unavailable. Please try again later.";
const tooManyScanRequestsMsg =
  "Cloud account scanning could not be initiated. Please try again later.";
const scanUnexpectedErrorMsg =
  "An unexpected error occurred during a scan. Please try again later.";

const infoCloseTime = 6 * 1000;
const tooManyRequestsHttpStatus = "429";

type Props = {
  accountId: string;
};

export function ForceScan({ accountId }: Props) {
  const {
    isOpen: isForceScanModalOpen,
    close: closeForceScanModal,
    open: openForceScanModal,
  } = useDisclosure(false);

  const [scanCloudAccount, loading] = useScanCloudAccount({
    onSuccess: () =>
      showSuccess(
        generateActionSuccessText("Scan Cloud Account")()("started")()
      ),
    onScanInProgress: () =>
      showInfo(scanInProgressMsg, { autoClose: infoCloseTime }),
    onError: onError,
  });

  const onForceScanning = async () => {
    closeForceScanModal();
    await scanCloudAccount(accountId);
  };

  return (
    <>
      <Button
        icon="refresh"
        severity="low"
        onClick={openForceScanModal}
        disabled={loading}
        initialState={loading ? "loading" : "refresh"}
      >
        Force Scanning
      </Button>
      <ModalConfirm
        onClose={closeForceScanModal}
        isOpen={isForceScanModalOpen}
        confirm={onForceScanning}
        actionLabel="Scan Now"
        contentLabel="Scan Cloud Account?"
      >
        Are you sure you want to scan Cloud Account now?
      </ModalConfirm>
    </>
  );
}

const onError = (error: ApolloError) => {
  const graphQLError = error.graphQLErrors[0];

  if (graphQLError?.extensions?.code === tooManyRequestsHttpStatus) {
    showError(tooManyScanRequestsMsg);

    return;
  }

  showError(scanUnexpectedErrorMsg);
};
