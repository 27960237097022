/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text, theme } from "@nordcloud/gnui";
import { StyledLink } from "~/components";
import { ROUTES } from "~/routing/routes";

type Props = {
  resourceId: string;
  resourceName?: string;
};

export function NameWithId({ resourceId, resourceName }: Props) {
  return (
    <>
      <StyledLink to={`${ROUTES.resources.index}/${resourceId}`}>
        {resourceName}
      </StyledLink>
      <Text size="sm" color={theme.color.text.text02}>
        {resourceId}
      </Text>
    </>
  );
}
