/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useHistory, useParams } from "react-router-dom";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { DeleteCustomAction } from "../components/DeleteCustomAction/DeleteCustomAction";
import { CustomActionDetailsParams } from "./types";

export function Action() {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useDisclosure(false);

  const { customActionId } = useParams<CustomActionDetailsParams>();

  const history = useHistory();

  const onSuccess = () => history.push(ROUTES.customActions.index);
  return (
    <>
      <PopoverControls
        size="large"
        disabled={false}
        items={[
          {
            label: "Delete Custom Action",
            onClick: openDeleteModal,
          },
        ]}
      />
      <DeleteCustomAction
        {...{
          actionId: customActionId,
          isDeleteModalOpen,
          onSuccess,
        }}
        close={closeDeleteModal}
      />
    </>
  );
}
