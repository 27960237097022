/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import {
  BatchAction,
  ExecutionPolicy,
  IncomingEventStatus,
} from "~/generated/graphql";
import { NoData } from "~/components";
import { ToggleItemBox } from "~/components/ToggleItemBox/ToggleItemBox";
import { isNonEmpty, sortByOrder } from "~/tools";
import { getEventEntityStatus } from "~/views/events/helpers";
import { PlanEntityIncomingEventDetails } from "~/views/events/types";
import { ActionBatch } from "~/views/plans/components/ActionBatch";
import { ActionItemWrap, WrapMax } from "~/views/plans/styled";
import { useEvent } from "../../EventProvider";
import { IncomingEventActionBatchBox } from "./components/ActionBox/IncomingEventActionBatchBox";
import { IncomingEventActionBox } from "./components/ActionBox/IncomingEventActionBox";

type Props = {
  status?: IncomingEventStatus;
};

export function IncomingEventDetailsTab({ status }: Props) {
  const { event } = useEvent();

  const planActions = event?.plan?.planActions ?? [];
  const planActionBatches = event.plan?.planActionBatches ?? [];

  const planEntities = [...planActions, ...planActionBatches].map((entity) => {
    const { __typename, ...rest } = entity;
    return {
      ...rest,
      entityType: __typename,
      batchActions: entity.batchActions?.map((batchAction: BatchAction) => {
        const { __typename: batchActionTypeName, ...batchActionRest } =
          batchAction;
        return {
          ...batchActionRest,
          entityType: batchActionTypeName,
          executionPolicy: ExecutionPolicy.Anyway,
        };
      }),
    };
  });

  const [planEntity, setPlanEntity] = useState<PlanEntityIncomingEventDetails>(
    planEntities[0]
  );
  const sortedPlanEntities =
    sortByOrder<PlanEntityIncomingEventDetails>(planEntities);

  const isBatch = (entityType: string) => entityType === "PlanActionBatch";

  return (
    <Box innerSpacing="spacing00">
      <If condition={isNonEmpty(planEntities)}>
        <Then>
          <Row>
            <Col xs={4} sm={8} md={3} lg={3}>
              <FlexContainer direction="column" justifyContent="flex-start">
                <Text width="100%" align="left">
                  Actions
                </Text>
                {sortedPlanEntities?.map(
                  (item: PlanEntityIncomingEventDetails) => {
                    return (
                      <If key={item.id} condition={isBatch(item.entityType)}>
                        <Then>
                          <WrapMax
                            css={{ paddingTop: `${theme.spacing.spacing03}` }}
                          >
                            <ActionBatch
                              name={item.name}
                              onClick={() => {
                                setPlanEntity(item);
                              }}
                              active={item.id === planEntity.id}
                              status={event?.status}
                            >
                              {item.batchActions?.map((batchAction) => {
                                return (
                                  <ToggleItemBox
                                    key={batchAction.id}
                                    item={{
                                      id: batchAction.id,
                                      icon: getEventEntityStatus(status)?.icon,
                                      iconColor:
                                        getEventEntityStatus(status)?.color(),
                                      tooltip:
                                        getEventEntityStatus(status)
                                          ?.description,
                                      name: batchAction.name,
                                      color: batchAction.action?.color,
                                      onMouseDown: () => {
                                        setPlanEntity(batchAction);
                                      },
                                    }}
                                    active={batchAction.id === planEntity.id}
                                  />
                                );
                              })}
                            </ActionBatch>
                          </WrapMax>
                        </Then>
                        <Else>
                          <WrapMax>
                            <ActionItemWrap>
                              <ToggleItemBox
                                item={{
                                  id: item.id,
                                  icon: getEventEntityStatus(status)?.icon,
                                  iconColor:
                                    getEventEntityStatus(status)?.color(),
                                  tooltip:
                                    getEventEntityStatus(status)?.description,
                                  color: item.action?.color ?? "grey",
                                  name: item.name,
                                  onMouseDown: () => {
                                    setPlanEntity(item);
                                  },
                                }}
                                active={item.id === planEntity.id}
                              />
                            </ActionItemWrap>
                          </WrapMax>
                        </Else>
                      </If>
                    );
                  }
                )}
              </FlexContainer>
            </Col>
            <Col xs={4} sm={8} md={5} lg={9}>
              <Box boxStyle="grey" innerSpacing="spacing04">
                <FlexContainer alignItems="flex-start">
                  <If condition={isBatch(planEntity?.entityType)}>
                    <Then>
                      <IncomingEventActionBatchBox
                        planActionBatch={planEntity}
                        planId={event?.plan?.id}
                      />
                    </Then>
                    <Else>
                      <IncomingEventActionBox
                        planEntity={planEntity}
                        planId={event?.plan?.id}
                        status={event?.status}
                      />
                    </Else>
                  </If>
                </FlexContainer>
              </Box>
            </Col>
          </Row>
        </Then>
        <Else>
          <NoData hasIcon message="There are no Actions" />
        </Else>
      </If>
    </Box>
  );
}
