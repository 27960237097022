/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { LazyQueryExecFunction } from "@apollo/client";
import { Row, createColumnHelper } from "@tanstack/react-table";
import { Text, theme } from "@nordcloud/gnui";
import {
  Exact,
  GetEventQuery,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { ExpandIcon } from "~/components";
import { MediumIconColumn, SmallIconColumn } from "~/components/Tables/styles";
import { isNil } from "~/tools";
import { EventEntity } from "../types";
import { EventActionStatus } from "./EvenActionStatus";
import { EventActionBatchStatus } from "./EventActionBatchStatus";
import { getResourceStatusesCount, getTotalResourceCount } from "./utils";

const entityRow = createColumnHelper<EventEntity>();

export function eventEntitiesColumns({
  refreshEvent,
}: {
  refreshEvent: LazyQueryExecFunction<
    GetEventQuery,
    Exact<{
      id: string;
    }>
  >;
}) {
  const resourcesCount = (row: Row<EventEntity>) => {
    return (
      getResourceStatusesCount(row.original.resourceStates ?? [], [
        ...Object.values(ResourceExecutionStatus),
      ]) ??
      getTotalResourceCount(row.original.resourceGroups) ??
      "-"
    );
  };

  return [
    entityRow.accessor(() => null, {
      header: "",
      id: "dummy",
      meta: SmallIconColumn,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <ExpandIcon
            name="chevronDown"
            title="chevronDown"
            isExpanded={row.getIsExpanded()}
            onClick={row.getToggleExpandedHandler()}
          />
        ) : null;
      },
    }),
    entityRow.accessor(() => null, {
      header: "",
      id: "state",
      cell: ({ row }) => {
        return (
          <div
            style={{
              paddingLeft: `${row.depth * 4}rem`,
            }}
          >
            {row.getCanExpand() ? (
              <EventActionBatchStatus
                eventActionBatchId={row.original.id}
                eventActionBatchStatus={row.original?.status}
                batchActions={row.original?.actions}
                onEventActionBatchStatusChanged={refreshEvent}
              />
            ) : (
              <EventActionStatus
                eventActionId={row.original.id}
                eventActionStatus={row.original.globalState?.status}
                onEventActionStatusChanged={refreshEvent}
              />
            )}
          </div>
        );
      },
      meta: MediumIconColumn,
    }),
    entityRow.accessor(() => null, {
      header: "Action Name",
      cell: ({ row }) => {
        return (
          <div
            style={{
              paddingLeft: `${row.depth * 2.5}rem`,
            }}
          >
            {row.original.name}
          </div>
        );
      },
      meta: {
        css: {
          width: "100%",
          minWidth: "20rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    entityRow.accessor(() => null, {
      header: "Number of resources",
      cell: ({ row }) => {
        if (isNil(row.original.resourceStates)) {
          return;
        }
        return <div>{resourcesCount(row)}</div>;
      },
      meta: {
        css: { minWidth: "8.75rem", padding: theme.spacing.spacing00 },
      },
    }),
    entityRow.accessor(() => null, {
      header: "Successful",
      cell: ({ row }) => {
        if (isNil(row.original.resourceStates)) {
          return;
        }
        return (
          <Text color="success" mb={0}>
            {getResourceStatusesCount(row.original.resourceStates, [
              ResourceExecutionStatus.Success,
              ResourceExecutionStatus.ManualSuccess,
            ]) ?? "-"}
          </Text>
        );
      },
      meta: {
        css: { minWidth: "8.75rem", padding: theme.spacing.spacing00 },
      },
    }),
    entityRow.accessor(() => null, {
      header: "Unsuccessful",
      cell: ({ row }) => {
        if (isNil(row.original.resourceStates)) {
          return;
        }
        const isInProgress =
          getResourceStatusesCount(row.original.resourceStates, [
            ResourceExecutionStatus.InProgress,
            ResourceExecutionStatus.Pending,
          ]) ?? 0;
        const unsuccessfulCount = getResourceStatusesCount(
          row.original.resourceStates,
          [
            ResourceExecutionStatus.Failed,
            ResourceExecutionStatus.Error,
            ResourceExecutionStatus.Timeout,
            ResourceExecutionStatus.NotInTimeWindow,
          ]
        );
        if (isInProgress > 0) {
          return (
            <Text color="danger" mb={0}>
              {"0"}
            </Text>
          );
        }
        return (
          <Text color="danger" mb={0}>
            {unsuccessfulCount ?? "-"}
          </Text>
        );
      },
      meta: {
        css: { minWidth: "8.75rem", padding: theme.spacing.spacing03 },
      },
    }),
  ] as never;
}
