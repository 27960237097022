/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useMemo, useReducer, useState } from "react";
import { Else, If, Then } from "react-if";
import { Box, Button, FlexContainer, theme } from "@nordcloud/gnui";
import { Maybe, Resource } from "~/generated/graphql";
import { useComponentsSelector } from "~/hooks";
import { mapResourceToSidebarOption } from "~/hooks/utils";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotEmpty } from "~/tools";
import { Item, ColumnType, SelectionMethod } from "~/types";
import { ResourceDragDrop } from "~/views/resourceGroups/components/Resource/components";
import { ResourceSidebar } from "~/views/resourceGroups/components/Resource/components/ResourceSidebar";
import { ResourceExpression } from "~/views/resourceGroups/components/Resource/ResourceExpression";
import { useUpdateResourceGroup } from "~/views/resourceGroups/hooks";
import { useResourceGroup } from "../../ResourceGroupProvider";
import { ResourcesTable } from "./components/ResourcesTable";

export type State = {
  isEditMode: boolean;
  selectedResources: string[];
  dynamicResources: Item[];
};

export function ResourcesTab() {
  const {
    resourceGroup: resourceGroupData,
    expression,
    setExpression,
  } = useResourceGroup();

  const [allResources, setAllResources] = useState<Resource[]>([]);

  const resourceGroup = {
    id: resourceGroupData?.id,
    resourceSelectors: resourceGroupData?.resourceSelectors?.filter(
      (resource) => !resource.tagsExpression
    ),
  };

  const expressionSelector = resourceGroupData?.resourceSelectors?.find(
    (resource) => resource.tagsExpression
  );

  const prepareResources =
    resourceGroup?.resourceSelectors?.map(
      ({ resource }) => resource?.id ?? ""
    ) ?? [];

  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({ ...data, ...partialData }),
    {
      isEditMode: false,
      selectedResources: prepareResources,
      dynamicResources: [],
    }
  );

  const dynamicResources = expression
    ? resourceGroupData?.resourceSelectors?.find(
        (resource) => resource.dynamicResources
      )?.dynamicResources ?? []
    : [];

  const manualResources =
    resourceGroup?.resourceSelectors?.map(({ resource }) => resource) ?? [];

  const resourceTableItems = [...manualResources, ...dynamicResources];

  const [selectedOptions, setSelectedOptions] = useState([
    ...state.selectedResources,
  ]);

  const selectors = useMemo(() => {
    return allResources.length !== 0
      ? allResources
          .filter((resource) => selectedOptions.includes(resource.id))
          .map(mapToItem)
      : resourceGroup?.resourceSelectors
          ?.map(({ resource }) => mapToItem(resource))
          .filter((item) => selectedOptions.includes(item.id)) ?? [];
  }, [allResources, selectedOptions, resourceGroup?.resourceSelectors]);

  const handleSidebarSubmit = (ids: string[]) => {
    updateState({ selectedResources: ids });
    const manuallySelectedResources = allResources
      .map(mapResourceToSidebarOption)
      .filter((item) => ids.includes(item.id));

    const resources = [
      ...manuallySelectedResources,
      ...state.dynamicResources,
    ].filter((r) => isNotEmpty(r.id));

    handleShowSearch(resources);
  };

  const {
    items,
    handleShowItems,
    handleDelete,
    handleShowSearch,
    handleDragEnd,
  } = useComponentsSelector(selectors);

  const handleRemoveItem = (item: Item) => {
    updateState({
      selectedResources: state.selectedResources.filter((id) => id !== item.id),
    });

    handleDelete(item);
  };

  const [update, loading] = useUpdateResourceGroup({
    onSuccess: () => {
      updateState({
        isEditMode: false,
      });

      showSuccess(generateActionSuccessText("Resource Group")()("updated")());
    },
  });

  const handleEdit = () => {
    updateState({ isEditMode: true });
    handleShowItems();
  };

  const handleDynamicUpdate = (dynamicItems: Item[]) => {
    handleShowSearch([...selectors, ...dynamicItems]);
    updateState({ dynamicResources: dynamicItems });
  };

  const handleReorder = (ids: string[]) => {
    setSelectedOptions(ids);
  };

  const handleSave = () => {
    const selectorIds = selectedOptions
      ?.map((item) => {
        const existed = resourceGroup?.resourceSelectors?.find(
          (i) => i.resource?.id === item
        );

        return {
          id: existed?.id ?? null,
          resourceId: existed?.resource?.id ?? item,
        };
      })
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.resourceId === item.resourceId)
      );

    const addOrder = selectorIds?.map((resourceId, i) => {
      return {
        resourceId: resourceId?.resourceId,
        order: i + 1,
        id: resourceId?.id ?? null,
      };
    });

    const prepareExpression = {
      order: 1,
      tagsExpression: expression,
    };

    const resourceSelectors =
      isNotEmpty(expression ?? "") &&
      expression !== expressionSelector?.tagsExpression
        ? [...addOrder, prepareExpression]
        : addOrder;

    const selectorsToDelete =
      resourceGroup?.resourceSelectors
        ?.map((item) => ({
          id: item?.id ?? "",
          resourceId: item.resource?.id ?? "",
        }))
        ?.filter((item) => !selectedOptions.includes(item.resourceId))
        .map(({ id }) => id) ?? [];

    const expressionSelectorToDelete =
      expressionSelector?.tagsExpression !== expression
        ? expressionSelector?.id
        : "";

    const resourceSelectorsToDelete = [
      ...selectorsToDelete,
      ...(expressionSelectorToDelete ? [expressionSelectorToDelete] : []),
    ];

    update({
      id: resourceGroup?.id ?? "",
      resourceSelectors: resourceSelectors,
      resourceSelectorsToDelete: resourceSelectorsToDelete,
    });
  };

  useEffect(() => {
    const ids = items.SELECTED_ITEMS.map((item) => item.id).filter(
      (id) => !id.match(/dynamic/g)
    );
    setSelectedOptions(ids);
  }, [items.SELECTED_ITEMS, setSelectedOptions]);

  const handleWizardResourceDynamicSubmit = (formData: {
    expression: string;
  }) => {
    setExpression?.(formData.expression);
  };

  const handleCancelEdit = () => {
    setSelectedOptions(prepareResources);
    updateState({ isEditMode: false });
  };

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer>
          <If condition={state.isEditMode}>
            <Then>
              <ResourceSidebar
                handleManualSubmit={handleSidebarSubmit}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setAllResources={setAllResources}
              />
              <ResourceExpression
                defaultValue={expression}
                handleDynamicUpdate={handleDynamicUpdate}
                onSubmit={handleWizardResourceDynamicSubmit}
                {...{ setExpression }}
              />
              <Button
                ml={theme.spacing.spacing04}
                mr={theme.spacing.spacing04}
                onClick={handleSave}
                disabled={loading}
              >
                Save
              </Button>
              <Button severity="low" icon="close" onClick={handleCancelEdit} />
            </Then>
            <Else>
              <Button ml="auto" onClick={handleEdit}>
                Edit Resources
              </Button>
            </Else>
          </If>
        </FlexContainer>
      </Box>
      <If condition={state.isEditMode}>
        <Then>
          <ResourceDragDrop
            {...{
              items,
              handleDragEnd,
              handleRemoveItem,
              handleReorder,
            }}
          />
        </Then>
        <Else>
          <ResourcesTable resources={resourceTableItems} />
        </Else>
      </If>
    </>
  );
}

function mapToItem(resource: Maybe<Resource> | undefined): Item {
  return {
    name: resource?.name ?? "",
    id: resource?.id ?? "",
    columns: [
      {
        data: [resource?.os?.subType ?? "", resource?.os?.type ?? ""],
        type: ColumnType.os,
      },
      {
        data: [resource?.name ?? "", resource?.id ?? ""],
        type: ColumnType.text,
      },
      {
        data: [SelectionMethod.manual],
        type: ColumnType.selectionMethod,
      },
    ],
  };
}
