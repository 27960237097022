/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Customer } from "~/types";

export const enum LocalStorageKeys {
  NAME = "fleetCustomerName",
  ID = "fleetCustomerId",
  ORG_NAME = "fleetCustomerOrgName",
}

export function saveCustomerInLS(customer: Customer) {
  localStorage.setItem(LocalStorageKeys.ID, customer.id);
  localStorage.setItem(LocalStorageKeys.NAME, customer.name);
  localStorage.setItem(LocalStorageKeys.ORG_NAME, customer.organizationName);
}

export function removeCustomerFromLS() {
  localStorage.removeItem(LocalStorageKeys.ID);
  localStorage.removeItem(LocalStorageKeys.NAME);
  localStorage.removeItem(LocalStorageKeys.ORG_NAME);
}

export function getCustomerFromLS() {
  const id = localStorage.getItem(LocalStorageKeys.ID);
  const name = localStorage.getItem(LocalStorageKeys.NAME);
  const organizationName = localStorage.getItem(LocalStorageKeys.ORG_NAME);

  return { id, name, organizationName };
}
