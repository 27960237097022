/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FormData } from "./formConfig";

export enum CustomActionField {
  DETAILS = "details",
  SCOPE = "scope",
  SETTINGS = "settings",
}

export type CustomActionData = {
  [CustomActionField.DETAILS]: FormData | undefined;
  [CustomActionField.SETTINGS]: FormData | undefined;
};

export const defaultValues: CustomActionData = {
  [CustomActionField.DETAILS]: undefined,
  [CustomActionField.SETTINGS]: undefined,
};
