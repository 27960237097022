/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { FlexContainer } from "@nordcloud/gnui";
import { AddSlackForm } from "./AddSlackForm";
import { SlackTable } from "./SlackTable";

export function SlackTab() {
  return (
    <>
      <Row>
        <Col>
          <FlexContainer justifyContent="flex-end">
            <AddSlackForm />
          </FlexContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <SlackTable />
        </Col>
      </Row>
    </>
  );
}
