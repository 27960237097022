/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createCtx } from "~/tools";
import { Customer } from "~/types";

type Props = {
  setCustomerValue: (c: Customer) => void;
  customer: Customer;
  hasMoreCustomers: boolean;
};

export const [useCustomer, CustomerCtxProvider] = createCtx<Props>();
