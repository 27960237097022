/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

type Props = {
  hasPadding?: boolean;
};

export const GridColumns = styled.div<Props>`
  display: grid;
  grid-template-columns: 2rem 3rem minmax(8rem, 55%) 15rem 15rem 8.5rem 11rem 4rem;
  padding: ${(props) => (props.hasPadding ? theme.spacing.spacing03 : "0")};
`;
