/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { v4 } from "uuid";
import {
  PlanActionNotificationTriggerType,
  PlanNotificationTriggerType,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";
import {
  FormField,
  DateItem,
  TimeUnits,
} from "./PlanCreate/components/PlanCreateWizard/types";

export const convertToMinutes = (value: string, unit: TimeUnits): number => {
  const toNumber = parseInt(value);

  switch (unit) {
    case TimeUnits.minutes:
      return toNumber;

    case TimeUnits.hours:
      return Math.floor(toNumber * 60);

    case TimeUnits.days:
      return Math.floor(toNumber * 24 * 60);

    case TimeUnits.weeks:
      return Math.floor(toNumber * 7 * 24 * 60);

    default:
      return toNumber;
  }
};

export function separateConnectedWords(text: string): string {
  const separatedWords = text
    .split("")
    .map((char, index) => {
      if (char === char.toUpperCase() && index > 0) {
        return " " + char;
      }
      return char;
    })
    .join("");

  return separatedWords.trim();
}

export const convertMinutesToHours = (value?: number) => {
  if (!value) {
    return 0;
  }
  return value / 60;
};

export function convertMinutesToReadableTime(minutes: number): string {
  if (minutes < 0) {
    return "Invalid input";
  }

  const oneMinute = 1;
  const oneHour = 60 * oneMinute;
  const oneDay = 24 * oneHour;
  const oneWeek = 7 * oneDay;

  const weeks = Math.floor(minutes / oneWeek);
  const days = Math.floor((minutes % oneWeek) / oneDay);
  const hours = Math.floor((minutes % oneDay) / oneHour);
  const remainingMinutes = minutes % oneHour;

  return (
    (weeks > 0 ? `${weeks} ${pluralize("week", weeks)} ` : "") +
    (days > 0 ? `${days} ${pluralize("day", days)} ` : "") +
    (hours > 0 ? `${hours} ${pluralize("hour", hours)} ` : "") +
    (remainingMinutes > 0
      ? `${remainingMinutes} ${pluralize("minute", remainingMinutes)}`
      : "")
  ).trim();
}

const pluralize = (input: string, count: number) => {
  return `${input}${count > 1 ? "s" : ""}`;
};

export function getValidDates(dates: DateItem[]) {
  return dates?.filter((date) =>
    dayjs(`${date.executionDate} ${date.executionTime}`).isValid()
  );
}

export function makeDateItemTimezoneAware(
  date: DateItem,
  timezone?: string
): DateItem {
  return convertDateStringToDateItem(
    dayjs(`${date.executionDate} ${date.executionTime}`)
      .tz(timezone)
      .toISOString()
  );
}

export function convertDateStringToDateItem(date: string, dateItemId?: string) {
  const dateObject = dayjs(date);
  return {
    id: dateItemId ?? v4(),
    executionDate: dateObject.format(dateFormat.shortDate),
    executionTime: dateObject.format(dateFormat.hourMinute),
  };
}

export function convertDateItemToDateString(
  date: DateItem,
  format?: string
): string {
  // Get valid date string or empty string
  const dateObject = dayjs(
    `${date[FormField.EXECUTION_DATE]} ${date[FormField.EXECUTION_TIME]}`
  );
  if (dateObject.isValid()) {
    return format
      ? dateObject.format(format)
      : dateObject.format(dateFormat.fullDate);
  }
  return "";
}

export const validateMinThreeHoursDiff = (formDataDates: DateItem[]) => {
  return formDataDates.map((dateItem1, i) => {
    return formDataDates.slice(i + 1).some((dateItem2) => {
      const date1 = dayjs(
        `${dateItem1.executionDate} ${dateItem1.executionTime}`
      );
      const date2 = dayjs(
        `${dateItem2.executionDate} ${dateItem2.executionTime}`
      );

      const diffInHours = Math.abs(date2.diff(date1, "h"));
      return diffInHours < 3;
    });
  });
};

export const validateDateTime = (
  formDataDates: { date: string; time: string }[] | undefined,
  TZ: string,
  initialDates?: string[]
) => {
  return formDataDates?.map((dateItem) => {
    const executionDateTime = dayjs(`${dateItem.date} ${dateItem.time}`);

    const isMatch = initialDates?.some((initialDate) => {
      return (
        dayjs(initialDate).tz(TZ).format(dateFormat.fullDate) ===
        executionDateTime.format(dateFormat.fullDate)
      );
    });

    if (isMatch) {
      return false;
    }

    const date = dayjs(`${dateItem.date} ${dateItem.time}`);
    const now = dayjs().tz(TZ);
    return date.isBefore(now.format(dateFormat.fullDate));
  });
};

type PlanInfo = {
  iconName: "playFilled" | "stopFilled" | "infoFilled";
  caption: "Active" | "Inactive" | "On demand";
  status: "success" | "danger" | "notification";
};

export const getPlanInfo = (
  enabled: boolean,
  cronExpression: string,
  scheduleExecutionDates: string[]
): PlanInfo => {
  if (!enabled) {
    return { iconName: "stopFilled", caption: "Inactive", status: "danger" };
  }

  const isScheduled = cronExpression || isNotEmpty(scheduleExecutionDates);

  return isScheduled
    ? { iconName: "playFilled", caption: "Active", status: "success" }
    : { iconName: "infoFilled", caption: "On demand", status: "notification" };
};

export function isUpcomingPlanNotification(trigger: string) {
  return (
    trigger === PlanNotificationTriggerType.Upcoming ||
    trigger === PlanNotificationTriggerType.EventsIncoming
  );
}

export const isAdvanceTrigger = (
  notificationTrigger: PlanActionNotificationTriggerType | string | undefined
) => {
  return [
    `${PlanActionNotificationTriggerType.Upcoming}`,
    `${PlanActionNotificationTriggerType.NotApproved}`,
  ].includes(`${notificationTrigger}`);
};

export function capitalizeFirstLetterAndLowercaseRest(text: string): string {
  return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
}
