/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const dateFormat = {
  dayMonthYear: "D MMM YYYY",
  dayMonthYearHourMinute: "D MMM YYYY, HH:mm",
  fullDate: "YYYY-MM-DDTHH:mm:ss",
  shortDate: "YYYY-MM-DD",
  datePickerShortDate: "DD/MM/YYYY",
  hourMinute: "HH:mm",
} as const;
