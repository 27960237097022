/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { RolesTable } from "~/views/permissions/Roles/components/RolesTable";
import { RoleCreate } from "./RoleCreate";

export function RolesPage() {
  const breadcrumbsLabels = [
    { label: "Dashboard", uri: "/" },
    { label: "Roles", uri: ROUTES.permissions.index },
  ];

  return (
    <>
      <Row>
        <Col>
          <BreadcrumbsBox title="Roles" customBreadcrumbs={breadcrumbsLabels}>
            <RoleCreate />
          </BreadcrumbsBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <RolesTable />
          </Box>
        </Col>
      </Row>
    </>
  );
}
