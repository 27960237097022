/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ResourceStatus } from "~/generated/graphql";

export function getResourceStatusIconName(status: ResourceStatus) {
  if (status === ResourceStatus.ConnectionLost) {
    return "cloudOffline";
  } else {
    return "cloud";
  }
}

export function getResourceStatusMessage(status: ResourceStatus) {
  if (status === ResourceStatus.ConnectionLost) {
    return "Lost Connection";
  } else {
    return "Online";
  }
}

export function getResourceColorContext(status: ResourceStatus) {
  if (status === ResourceStatus.ConnectionLost) {
    return "danger";
  } else {
    return "success";
  }
}
