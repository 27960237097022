/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import { Box, Text, theme, Table } from "@nordcloud/gnui";
import { KeyValue } from "~/generated/graphql";
import { NoData } from "~/components";
import { isNonEmpty } from "~/tools";
import { useEvent } from "../../EventProvider";

export function MetadataTab() {
  const { event } = useEvent();

  return (
    <>
      <Box spacing="spacing04">
        <Text mb={0}>Event Metadata</Text>
        <Text size="sm" color={theme.color.text.text02}>
          Metadata are tags with key-value labels. They help organize and manage
          resources.
        </Text>
        <If condition={isNonEmpty(event?.metadata ?? [])}>
          <Then>
            <Table hoverline striped>
              <Table.thead>
                <Table.tr>
                  <Table.th>Key</Table.th>
                  <Table.th>Value</Table.th>
                  <Table.th width="15%" />
                </Table.tr>
              </Table.thead>
              <Table.tbody>
                {event?.metadata?.map((meta: KeyValue) => (
                  <Table.tr key={meta.key}>
                    <Table.td>{meta.key}</Table.td>
                    <Table.td>{meta.value}</Table.td>
                  </Table.tr>
                ))}
              </Table.tbody>
            </Table>
          </Then>
          <Else>
            <NoData hasIcon message="There are no Metadata" />
          </Else>
        </If>
      </Box>
    </>
  );
}
