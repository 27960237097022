/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Box, Tab, Tabs as TabContainer } from "@nordcloud/gnui";
import {
  Channels,
  filterWebhookChannelByType,
  getEmailsChannels,
} from "~/views/notificationGroups/components/helpers";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";
import { TabCounter } from "../TabCounter";
import { EmailTab } from "./EmailTab";
import { PagerDutyTab } from "./PagerDutyTab";
import { SlackTab } from "./SlackTab";

export function Tabs() {
  const { data } = useNotificationGroup();
  const [currentStep, setCurrentStep] = useState(0);
  const { emailsCount } = getEmailsChannels(data?.channels ?? []);

  const { webhookChannelCount: slackCount } = filterWebhookChannelByType(
    data?.channels ?? [],
    Channels.SLACK
  );

  const { webhookChannelCount: pagerDutyCount } = filterWebhookChannelByType(
    data?.channels ?? [],
    Channels.PAGER_DUTY
  );

  return (
    <Box spacing="spacing02" css={{ padding: 0 }}>
      <TabContainer step={currentStep} handleTab={setCurrentStep}>
        <Tab
          label={<TabCounter label="Email" count={emailsCount} />}
          width="12rem"
        >
          <EmailTab />
        </Tab>
        <Tab
          label={<TabCounter label="Slack" count={slackCount} />}
          width="12rem"
        >
          <SlackTab />
        </Tab>

        <Tab
          label={<TabCounter label="PagerDuty" count={pagerDutyCount} />}
          width="12rem"
        >
          <PagerDutyTab />
        </Tab>
      </TabContainer>
    </Box>
  );
}
