/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createCtx } from "~/tools";

/* eslint-disable @typescript-eslint/no-explicit-any */
type Props = {
  event?: any;
};

export const [useEvent, EventCtxProvider] = createCtx<Props>();
