/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import {
  Button,
  FlexContainer,
  Input,
  Label,
  Modal,
  theme,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { stringRequired } from "~/components/Forms";
import { SelectedPlanEntity } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { State } from "../../types";

enum FormField {
  NAME = "name",
}

type FormData = {
  name: string;
};

const maxPlanActionNameLength = 100;

export const schema = z.object({
  [FormField.NAME]: stringRequired("Action batch name")
    .trim()
    .min(1, {
      message: `Action batch name is required`,
    })
    .max(100, {
      message: `Too long name (max: ${maxPlanActionNameLength} characters).`,
    }),
});

type Props = {
  state: State;
  updateState: Dispatch<Partial<State>>;
  applyEntities: (actions: SelectedPlanEntity[], batchName?: string) => void;
};

export function ActionBatchModal({ state, updateState, applyEntities }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    updateState({
      name: data.name,
      isModalOpen: false,
      isSidebarOpen: false,
    });
    applyEntities(state.selectedActions, data.name);
    reset();
  };

  return (
    <Modal
      onClose={() => updateState({ isModalOpen: false })}
      isOpen={state.isModalOpen}
      contentLabel="Save New Action Batch"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup error={errors[FormField.NAME]}>
          <Label name="Action Batch Name" htmlFor={FormField.NAME} required />
          <Input
            {...register(FormField.NAME)}
            id={FormField.NAME}
            placeholder="e.g. Multiple Actions, Set 1, etc"
          />
        </FormGroup>
        <FlexContainer justifyContent="flex-end" gap={theme.spacing.spacing04}>
          <Button
            severity="low"
            type="button"
            onClick={() => updateState({ isModalOpen: false })}
          >
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </FlexContainer>
      </form>
    </Modal>
  );
}
