/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Channel } from "~/generated/graphql";

export enum Channels {
  SLACK = "SLACK",
  PAGER_DUTY = "PAGER_DUTY",
}

export enum ChannelsTypes {
  EMAIL = "EmailChannel",
  WEBHOOK = "WebhookChannel",
}

export function filterWebhookChannelByType(channels: Channel[], type: string) {
  const webhookChannel = channels.filter((channel) =>
    channel?.typeProperties?.__typename === ChannelsTypes.WEBHOOK
      ? channel.typeProperties.type === type
      : ""
  );
  return { webhookChannel, webhookChannelCount: webhookChannel.length };
}

export function getEmails(channels: Channel[]): string[] {
  return channels
    .filter(
      (channel) => channel?.typeProperties?.__typename === ChannelsTypes.EMAIL
    )
    .map((channel) => {
      return channel?.typeProperties?.__typename === ChannelsTypes.EMAIL
        ? channel?.typeProperties?.email
        : "";
    });
}

export function getEmailsChannels(channels: Channel[]) {
  const emailChannels =
    channels?.filter(
      (channel: Channel) =>
        channel?.typeProperties?.__typename === ChannelsTypes.EMAIL
    ) ?? [];

  return { emailChannels, emailsCount: emailChannels.length };
}
