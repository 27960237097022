/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  PlanSortFields,
  SortOrder,
  PlanStatuses,
  PlanScheduleOptions,
} from "~/generated/graphql";

export type QueryGetPlans = {
  field: PlanSortFields;
  order: SortOrder;
  searchPhrase?: string;
  startDateUTC?: string;
  endDateUTC?: string;
  planStatuses: string[];
  excludePlanStatuses: boolean;
  planActionIds: string[];
  excludePlanActionIds: boolean;
  scheduleOptions: string[];
  excludeScheduleOptions: boolean;
};

export const sortOptions = [
  {
    value: PlanSortFields.Name,
    label: "Plan Name",
  },
  {
    value: PlanSortFields.CreatedAt,
    label: "Date Added",
  },
  {
    value: PlanSortFields.UpdatedAt,
    label: "Date Updated",
  },
  {
    value: PlanSortFields.UpdatedBy,
    label: "Updated By",
  },
];

export const planStatusCheckboxes = [
  {
    value: PlanStatuses.Active,
    label: "Active",
  },
  {
    value: PlanStatuses.Inactive,
    label: "Inactive",
  },
  {
    value: PlanStatuses.OnDemand,
    label: "On demand",
  },
];

export const scheduleOptionsCheckboxes = [
  {
    value: PlanScheduleOptions.Recurring,
    label: "Recurring",
  },
  {
    value: PlanScheduleOptions.SpecifiedDates,
    label: "Specified Dates",
  },
  {
    value: PlanScheduleOptions.OnDemand,
    label: "On Demand",
  },
];
