/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import dayjs from "dayjs";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import {
  Box,
  FlexContainer,
  theme,
  ExtendedTooltip,
  Text,
} from "@nordcloud/gnui";
import {
  Event,
  EventStatus,
  ExecutionStatus,
  useEventStatusChangedSubscription,
  useGetEventLazyQuery,
} from "~/generated/graphql";
import { ExpandIcon } from "~/components";
import { TooltipText } from "~/components/Styled/TooltipText";
import { TableWrapper } from "~/components/Tables";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import { dateFormat } from "~/constants";
import { useToggle } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { isNotEmpty, sortItem } from "~/tools";
import {} from "~/views/events/components/utils";
import {
  RefetchOptions,
  convertMinutesToTime,
  getNumberOfApprovalRequiredEntities,
  getAllEventActionsFlat,
} from "~/views/events/helpers";
import { GridColumns } from "../styled";
import { EntityType, EventEntity } from "../types";
import { ApproveEvent } from "./ApproveEvent/ApproveEvent";
import { eventEntitiesColumns } from "./EventActionsColumns";
import { TooltipIconStatus } from "./TooltipIconStatus";

type Props = {
  event: Event;
};

export function EventItem({ event }: Props) {
  const [getData, { data }] = useGetEventLazyQuery({
    variables: { id: event.id },
    fetchPolicy: "network-only",
  });

  const eventUpdated = data?.event ?? event;

  const [isExpanded, toggleIsExpanded] = useToggle();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data: eventStatusData } = useEventStatusChangedSubscription({
    variables: {
      input: {
        id: event.id,
      },
    },
    onData: getData,
  });

  const endTimeTZ = dayjs(event?.estimatedEndTime ?? "")
    .tz(event?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHourMinute);

  const startTimeTZ = dayjs(event?.startTime ?? "")
    .tz(event?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHourMinute);

  const duration = dayjs(event?.estimatedEndTime).diff(
    event?.startTime,
    "minutes"
  );

  const approveActions = eventUpdated?.actions?.filter(
    (eventAction) =>
      eventAction.globalState?.status === ExecutionStatus.ApprovalRequired
  );

  const approveBatches = eventUpdated?.actionBatches?.filter(
    (eventActionBatch) =>
      eventActionBatch.status === ExecutionStatus.ApprovalRequired
  );

  const eventEntities = [
    ...(event.actionBatches ?? []),
    ...(event.actions ?? []),
  ].map((entity) => {
    return {
      ...entity,
      subRows: entity.__typename === "EventActionBatch" ? entity.actions : [],
    };
  });

  const sortedEventEntities = sortItem(
    eventEntities,
    (a, b) => (a.order ?? 0) - (b.order ?? 0)
  );
  const hasEventEntities = isNotEmpty(eventEntities);
  const isEventInProgress = eventUpdated?.status === EventStatus.InProgress;

  return (
    <Box spacing="spacing02" boxStyle="lightGrey" data-testid="event-row">
      <Row data-testid={event?.name}>
        <Col>
          <GridColumns>
            <FlexContainer justifyContent="center" alignItems="center">
              <When condition={hasEventEntities}>
                <ExpandIcon
                  name="chevronDown"
                  title="chevronDown"
                  isExpanded={isExpanded}
                  onClick={toggleIsExpanded}
                />
              </When>
            </FlexContainer>
            <TooltipIconStatus
              status={
                eventStatusData?.eventStatusChanged ?? eventUpdated?.status
              }
              actionLength={getNumberOfApprovalRequiredEntities(eventUpdated)}
              type={EntityType.EVENT}
              actions={getAllEventActionsFlat(eventUpdated)}
            />
            <NameWithLink
              name={event?.name ?? ""}
              link={ROUTES.events.generateDetails(event?.id ?? "")}
            />
            <FlexContainer alignItems="center">
              <ExtendedTooltip
                caption={
                  <>
                    Plan Time: {startTimeTZ}
                    <br />
                    Timezone: {event?.scheduleTimezone}
                  </>
                }
              >
                <TooltipText
                  size="md"
                  tag="span"
                  mr={theme.spacing.spacing01}
                  weight="regular"
                  color={theme.color.text.text01}
                >
                  {dayjs(event?.startTime).format(
                    dateFormat.dayMonthYearHourMinute
                  )}
                </TooltipText>
              </ExtendedTooltip>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <ExtendedTooltip
                caption={
                  <>
                    Plan Time: {endTimeTZ}
                    <br />
                    Timezone: {event?.scheduleTimezone}
                    <br />
                    End Time will appear when the event is complete
                  </>
                }
              >
                <TooltipText
                  size="md"
                  tag="span"
                  mr={theme.spacing.spacing01}
                  weight="regular"
                  color={theme.color.text.text01}
                >
                  <If condition={isEventInProgress}>
                    <Then>Awaiting completion</Then>
                    <Else>
                      {dayjs(event?.estimatedEndTime).format(
                        dateFormat.dayMonthYearHourMinute
                      )}
                    </Else>
                  </If>
                </TooltipText>
              </ExtendedTooltip>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <Text
                size="md"
                tag="span"
                mr={theme.spacing.spacing01}
                weight="regular"
                color={theme.color.text.text01}
              >
                {convertMinutesToTime(duration)}
              </Text>
            </FlexContainer>
            <If
              condition={
                isNotEmpty(approveActions ?? []) ||
                isNotEmpty(approveBatches ?? [])
              }
            >
              <Then>
                <FlexContainer>
                  <ApproveEvent
                    eventId={event?.id}
                    hasIcon
                    actions={approveActions}
                    batches={approveBatches}
                    refetchQueries={RefetchOptions.EVENTS}
                  />
                </FlexContainer>
              </Then>
              <Else>
                <div />
              </Else>
            </If>
          </GridColumns>
          <When condition={isExpanded}>
            <TableWrapper<EventEntity>
              loading={false}
              data={sortedEventEntities}
              columns={eventEntitiesColumns({ refreshEvent: getData })}
              rowSelection={rowSelection}
              selectedRowsHandler={() => null}
              setRowSelection={setRowSelection}
              stripped
            />
          </When>
        </Col>
      </Row>
    </Box>
  );
}
