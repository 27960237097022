/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, Input, Label, Sidebar, theme } from "@nordcloud/gnui";
import { ChannelType, WebhookChannelType } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { useDisclosure } from "~/hooks";
import {
  PAGER_DUTY_KEY_EXAMPLE,
  PAGER_DUTY_URL,
} from "~/views/notificationGroups/constants";
import { useUpdateNotificationGroup } from "~/views/notificationGroups/hooks/useUpdateNotificationGroup/useUpdateNotificationGroup";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";
import { pagerDutySchema } from "~/views/notificationGroups/notificationGroupDetails/schema";

export function AddPagerDutyForm() {
  const { data } = useNotificationGroup();
  const { isOpen, open, close } = useDisclosure();

  const onSubmit = (pagerDutyData: { pagerDutyKey: string }) => {
    updateNotificationGroup({
      id: data?.id,
      channels: [
        {
          type: ChannelType.Webhook,
          typeProperties: {
            webhook: {
              method: "POST",
              type: WebhookChannelType.PagerDuty,
              url: PAGER_DUTY_URL,
              payload: JSON.stringify({
                routing_key: pagerDutyData.pagerDutyKey,
              }),
            },
          },
        },
      ],
    });
  };

  const [updateNotificationGroup] = useUpdateNotificationGroup({
    onSuccess: () => {
      close();
      reset();
    },
  });

  const { handleSubmit, formState, register, reset } = useForm({
    resolver: zodResolver(pagerDutySchema),
    defaultValues: {
      id: data?.id,
      pagerDutyKey: "",
    },
  });

  const error = formState.errors;

  return (
    <>
      <Button icon="plus" severity="low" onClick={open}>
        Add PagerDuty Key
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Sidebar
          isOpen={isOpen}
          title="Add PagerDuty Key"
          onClick={close}
          footer={
            <div
              css={{
                margin: `${theme.spacing.spacing04} ${theme.spacing.spacing01}`,
              }}
            >
              <Button
                icon="checkmark"
                ml={theme.spacing.spacing04}
                type="submit"
              >
                Apply
              </Button>
            </div>
          }
        >
          <FormGroup error={error?.pagerDutyKey}>
            <Label name="PagerDuty Key" htmlFor="pagerDutyKey" />
            <Input
              type="text"
              aria-label="provide PagerDuty Key"
              placeholder={PAGER_DUTY_KEY_EXAMPLE}
              id="pagerDutyKey"
              {...register("pagerDutyKey")}
            />
          </FormGroup>
        </Sidebar>
      </form>
    </>
  );
}
