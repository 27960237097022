/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import {
  ExtendedTooltip,
  SVGIcon,
  IndeterminateCheckbox,
  FlexContainer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import {
  SmallIconColumn,
  MediumIconColumn,
  FlexColumn,
  MediumColumn,
} from "~/components/Tables/styles";
import { dateFormat } from "~/constants/dateFormats";
import { ROUTES } from "~/routing/routes";
import { getProviderIcon } from "~/utils";
import {
  getAccountStatusIconName,
  getAccountColorContext,
  getAccountStatusMessage,
} from "../utils";
import { Actions } from "./Actions";

export type CloudAccount = {
  status: string;
  provider: string;
  name: string;
  providerId: string;
  environment?: string | null;
  id: string;
  changeDetails: {
    createdAt: string;
  };
};

const columnHelper = createColumnHelper<CloudAccount>();

export function columns() {
  return [
    columnHelper.accessor(() => null, {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      meta: SmallIconColumn,
    }),
    columnHelper.accessor("status", {
      header: () => null,
      cell: (cell) => (
        <FlexContainer justifyContent="center" alignItems="center">
          <ExtendedTooltip
            status={getAccountColorContext(cell.getValue())}
            caption={
              <Text tag="span" size="sm" color={theme.color.text.text04}>
                {getAccountStatusMessage(cell.getValue())}
              </Text>
            }
            position="start"
          >
            <FlexContainer justifyContent="center" alignItems="center">
              <SVGIcon
                size="md"
                color={getAccountColorContext(cell.getValue())}
                name={getAccountStatusIconName(cell.getValue())}
              />
            </FlexContainer>
          </ExtendedTooltip>
        </FlexContainer>
      ),
      meta: SmallIconColumn,
    }),
    columnHelper.accessor("provider", {
      header: () => null,
      cell: (cell) => (
        <FlexContainer justifyContent="center" alignItems="center">
          <ExtendedTooltip caption={cell.getValue()} hideTimeout={0}>
            <FlexContainer justifyContent="center" alignItems="center">
              <SVGIcon size="md" name={getProviderIcon(cell.getValue())} />
            </FlexContainer>
          </ExtendedTooltip>
        </FlexContainer>
      ),
      meta: SmallIconColumn,
    }),
    columnHelper.accessor("name", {
      header: () => "Cloud Account Name",
      cell: ({ row }) => (
        <NameWithLink
          name={row.original.name}
          link={ROUTES.accounts.generateDetails(row.original?.id ?? "")}
        />
      ),
      meta: FlexColumn,
    }),
    columnHelper.accessor("providerId", {
      header: () => "ID",
      meta: FlexColumn,
    }),
    columnHelper.accessor("environment", {
      header: () => "Environment",
      meta: FlexColumn,
    }),
    columnHelper.accessor((row) => row?.changeDetails?.createdAt, {
      header: "Added",
      cell: (cell) => dayjs(cell.getValue()).format(dateFormat.dayMonthYear),
      meta: MediumColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const cloudAccountsIds = [row.original?.id];
        return <Actions {...{ cloudAccountsIds }} />;
      },
      meta: MediumIconColumn,
    }),
  ] as never;
}
