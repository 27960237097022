/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { GraphQLUpload } from "graphql-upload-minimal";
import {
  ActionScope,
  ActionType,
  AzureFunctionMethod,
  KeyValueInput,
  WebhookActionMethod,
} from "~/generated/graphql";

export enum FormField {
  NAME = "name",
  DESCRIPTION = "description",
  COLOR = "color",
  SCOPE = "scope",
  TYPE = "type",
  CLOUD_PROVIDER = "cloudProvider",
  OS_TYPE = "osType",
  LAMBDA_ARN = "lambdaArn",
  FUNCTION_URL = "functionUrl",
  FUNCTION_METHOD = "functionMethod",
  WEBHOOK_METHOD = "webhookMethod",
  ACCESS_KEY_ID = "accessKeyId",
  SSM_DOCUMENT_ID = "documentId",
  WEB_HOOK_URL = "webhookUrl",
  PAYLOAD = "payload",
  HEADERS = "headers",
  SCRIPT_FILE = "script",
}

export type FormData = {
  [FormField.NAME]?: string;
  [FormField.DESCRIPTION]?: string | null;
  [FormField.COLOR]?: string;
  [FormField.SCOPE]?: ActionScope;
  [FormField.TYPE]?: ActionType;
  [FormField.CLOUD_PROVIDER]?: [];
  [FormField.OS_TYPE]?: [];
  [FormField.LAMBDA_ARN]?: string;
  [FormField.FUNCTION_URL]?: string;
  [FormField.FUNCTION_METHOD]?: AzureFunctionMethod;
  [FormField.WEBHOOK_METHOD]?: WebhookActionMethod;
  [FormField.ACCESS_KEY_ID]?: string;
  [FormField.SSM_DOCUMENT_ID]?: string;
  [FormField.WEB_HOOK_URL]?: string;
  [FormField.PAYLOAD]?: string;
  [FormField.HEADERS]?: KeyValueInput[];
  [FormField.SCRIPT_FILE]?: typeof GraphQLUpload;
};

export const getLabel = (field: FormField) =>
  field
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (firstChar) => firstChar.toUpperCase())
    .replace(/Url|Arn|Id/gi, (match) => match.toUpperCase());
