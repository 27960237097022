/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Else, If, Then, When } from "react-if";
import { Box, ExtendedTooltip, Spacer, Text, theme } from "@nordcloud/gnui";
import { TooltipText } from "~/components";
import { dateFormat } from "~/constants";
import { convertMinutesToTime } from "~/views/events/helpers";

type Props = {
  timeDetails: {
    scheduleTimezone?: string;
    startTime?: string;
    estimatedEndTime?: string;
  };
  isEventInProgress?: boolean;
};

export function TimeDetails({ timeDetails, isEventInProgress }: Props) {
  const startTimeTZ = dayjs(timeDetails?.startTime ?? "")
    .tz(timeDetails?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHourMinute);

  const endTimeTZ = dayjs(timeDetails?.estimatedEndTime ?? "")
    .tz(timeDetails?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHourMinute);

  const duration = dayjs(timeDetails?.estimatedEndTime).diff(
    timeDetails?.startTime,
    "minutes"
  );

  return (
    <Box boxStyle="lightGrey" css={{ flex: 1 }}>
      <div data-testid="event-start-time">
        <Text tag="div" size="sm" color={theme.color.text.text02}>
          Start Time (local time)
        </Text>
        <ExtendedTooltip
          caption={
            <>
              Event Time: {startTimeTZ}
              <br />
              Timezone: {timeDetails?.scheduleTimezone}
            </>
          }
          hideTimeout={0}
        >
          <TooltipText
            size="md"
            tag="span"
            mr={theme.spacing.spacing01}
            weight="regular"
            color={theme.color.text.text01}
            css={{ textDecorationStyle: "initial" }}
          >
            {dayjs(timeDetails?.startTime).format(
              dateFormat.dayMonthYearHourMinute
            )}
          </TooltipText>
        </ExtendedTooltip>
      </div>
      <Spacer height={theme.spacing.spacing04} />
      <div data-testid="event-end-time">
        <Text tag="div" size="sm" color={theme.color.text.text02}>
          End Time (local time)
        </Text>
        <ExtendedTooltip
          caption={
            <>
              Event Time: {endTimeTZ}
              <br />
              Timezone: {timeDetails?.scheduleTimezone}
              <br />
              End Time will appear when the event is complete
            </>
          }
          hideTimeout={0}
        >
          <TooltipText
            size="md"
            tag="span"
            mr={theme.spacing.spacing01}
            weight="regular"
            color={theme.color.text.text01}
            css={{ textDecorationStyle: "initial" }}
          >
            <If condition={isEventInProgress}>
              <Then>Awaiting completion</Then>
              <Else>
                {dayjs(timeDetails?.estimatedEndTime).format(
                  dateFormat.dayMonthYearHourMinute
                )}
              </Else>
            </If>
          </TooltipText>
        </ExtendedTooltip>
      </div>
      <Spacer height={theme.spacing.spacing04} />
      <When condition={!isEventInProgress}>
        <div data-testid="event-duration">
          <Text tag="div" size="sm" color={theme.color.text.text02}>
            Event Duration
          </Text>
          <Text tag="div">{convertMinutesToTime(duration)}</Text>
        </div>
      </When>
    </Box>
  );
}
