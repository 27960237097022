/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

function EventsLoader() {
  return (
    <>
      <rect x="1%" y="23" rx="0" ry="0" width="1%" height="15" />
      <rect x="3%" y="10" rx="0" ry="0" width="3%" height="40" />
      <rect x="7%" y="15" rx="5" ry="5" width="36%" height="15" />
      <rect x="7%" y="39" rx="5" ry="5" width="33%" height="9" />
      <rect x="45%" y="15" rx="5" ry="5" width="23%" height="15" />
      <rect x="69%" y="15" rx="5" ry="5" width="13%" height="15" />
      <rect x="83%" y="15" rx="5" ry="5" width="12%" height="15" />
      <rect x="97%" y="10" rx="0" ry="0" width="3%" height="40" />
    </>
  );
}

export { EventsLoader };
