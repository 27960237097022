/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, FlexContainer, MultipleSelect, theme } from "@nordcloud/gnui";

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div<{ isVisible: boolean }>`
  position: absolute;
  z-index: ${theme.zindex.dropdown};
  transition: ${theme.transition};
  margin-top: ${theme.spacing.spacing01};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "none")};
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
`;

export const ApplyWrap = styled(FlexContainer)`
  padding: ${theme.spacing.spacing04};
  border-top: ${theme.borders.grey};

  > button {
    justify-content: center;
    flex: 1;
  }
`;

export const InputWrap = styled.div`
  flex: 1;

  label {
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.medium};
  }
`;

export const ClearButton = styled(Button)`
  border: none;
  border-radius: unset;
  padding: 0 ${theme.spacing.spacing02};
  /* stylelint-disable */
  &:hover {
    border-right-top-radius: ${theme.radius.sm};
    border-right-bottom-radius: ${theme.radius.sm};
  }
  /* stylelint-enable */
`;

export const StyledMultiSelect = styled(MultipleSelect)`
  position: relative;

  > li > button {
    min-width: 6rem;
  }
`;
