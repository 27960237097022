/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { RoleEdit } from "./RoleEdit";
import { RolesDelete } from "./RolesDelete";

type State = {
  isDeleteModalOpen: boolean;
  isEditSidebarOpen: boolean;
};

type Props = {
  rolesIds: string[];
};

export function Actions({ rolesIds }: Props) {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({
      ...data,
      ...partialData,
    }),
    { isDeleteModalOpen: false, isEditSidebarOpen: false }
  );

  return (
    <>
      <RoleEdit
        {...{
          roleIds: rolesIds,
          isOpen: state.isEditSidebarOpen,
          openSidebar: () => updateState({ isEditSidebarOpen: true }),
          close: () => updateState({ isEditSidebarOpen: false }),
        }}
      />

      <RolesDelete
        {...{
          roleIds: rolesIds,
          isDeleteModalOpen: state.isDeleteModalOpen,
          openModal: () => updateState({ isDeleteModalOpen: true }),
        }}
        close={() => updateState({ isDeleteModalOpen: false })}
      />
    </>
  );
}
