/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { DataDisplayWrapper } from "~/components";

type Props = {
  title: string;
  condition?: boolean | null;
  options: [string, string];
};

export function DisplayBooleanProperties({ title, condition, options }: Props) {
  return (
    <>
      <Text size="sm" tag="div">
        {title}
      </Text>
      <DataDisplayWrapper hasData={condition != null && options.length === 2}>
        <Text data-testid={`label-${title}`}>
          {condition ? options[0] : options[1]}
        </Text>
      </DataDisplayWrapper>
    </>
  );
}
