/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { v4 } from "uuid";
import { Text } from "@nordcloud/gnui";
import { NextDateTooltip } from "~/views/plans/components/NextDateTooltip";

type Props = {
  dates: string[];
};

export function CronNextDates({ dates }: Props) {
  const firstDate = dates[0];

  if (firstDate) {
    const firstInterval = `Next: ${firstDate}`;

    return (
      <Text data-testid="next-date-cron" tag="div" size="sm">
        <Tooltip tooltip={dates} description={firstInterval} />
      </Text>
    );
  }

  return null;
}

type TooltipProps = {
  tooltip: string[];
  description: string;
};

export function Tooltip({ tooltip, description }: TooltipProps) {
  const displayTooltip = tooltip?.map((item: string) => {
    return (
      <Text key={v4()} size="sm" mb={0} color="text01">
        Next: {item}
      </Text>
    );
  });

  return <NextDateTooltip tooltip={displayTooltip} description={description} />;
}
