/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";
import { stringRequired } from "~/components/Forms";
import { FormField } from "./PlanCreate/components/PlanCreateWizard/types";
import { ScheduleType } from "./types";

const maxPlanActionNameLength = 100;

export const PlanEntityNameSchema = stringRequired("Name")
  .trim()
  .min(1, {
    message: `Name is required`,
  })
  .max(100, {
    message: `Name is too long (max: ${maxPlanActionNameLength} characters).`,
  });

export const cronScheduleSchema = z.object({
  [FormField.SCHEDULE_CRON]: stringRequired("Schedule Cron"),
  [FormField.SCHEDULE_OFFSET]: z.preprocess((input) => {
    const processed = z
      .string()
      .regex(/^-?[0-9]\d*(\.\d+)?$/)
      .transform(Number)
      .safeParse(input);
    return processed.success ? processed.data : input;
  }, z.number().int()),
  [FormField.SCHEDULE_OFFSET_UNIT]: stringRequired("Unit"),
  [FormField.SCHEDULE_TIMEZONE]: stringRequired("Schedule Timezone"),
});

export const specifiedDateSchema = z.object({
  [FormField.EXECUTION_DATE]: stringRequired("Date"),
  [FormField.EXECUTION_TIME]: stringRequired("Time"),
});

export const specifiedDatesScheduleSchema = z.object({
  [FormField.SCHEDULE_EXECUTION_DATES]: z
    .array(specifiedDateSchema)
    .min(1, { message: "At least one date is required" }),
  [FormField.SCHEDULE_TIMEZONE]: stringRequired("Schedule Timezone"),
});

export const selectScheduleSchema = (scheduleType: ScheduleType) => {
  switch (scheduleType) {
    case ScheduleType.recurring:
      return cronScheduleSchema;
    case ScheduleType.specifiedDates:
      return specifiedDatesScheduleSchema;
    default:
      return z.object({});
  }
};
