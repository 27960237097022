/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import { Box, BrickLoader, FlexContainer } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ErrorWrap } from "~/components/Error";
import { LoaderWrap } from "~/components/Loader";
import { NoData } from "~/components/NoData";
import { ROUTES } from "~/routing/routes";
import { CustomActionInfo } from "~/views/customActions/CustomActionDetails/components/CustomActionInfo";
import { CustomActionDetailsParams } from "~/views/customActions/CustomActionDetails/types";
import { useGetCustomAction } from "../hooks/useGetCustomAction/useGetCustomAction";
import { Action } from "./Action";
import { CustomActionCtxProvider } from "./CustomActionProvider";
import { Tabs } from "./Tabs/Tabs";

export function CustomActionDetails() {
  const { customActionId } = useParams<CustomActionDetailsParams>();

  const { customAction, loading, error } = useGetCustomAction({
    customActionId,
  });

  const title = customAction?.name ?? customActionId;

  const breadcrumbs = [
    { label: "Home", uri: "/" },
    { label: "Custom Actions", uri: ROUTES.customActions.index },
    { label: title, uri: "" },
  ];
  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <CustomActionCtxProvider value={{ customAction }}>
        <LoaderWrap
          Component={() => <BrickLoader height="14rem" />}
          loading={loading}
          viewBox="0 0 10 4"
        >
          <Row>
            <Col>
              <BreadcrumbsBox title={title} customBreadcrumbs={breadcrumbs}>
                <FlexContainer>
                  <Action />
                </FlexContainer>
              </BreadcrumbsBox>
            </Col>
          </Row>
          <Box spacing="spacing02">
            <Row>
              <CustomActionInfo />
            </Row>
          </Box>
          <Tabs />
        </LoaderWrap>
      </CustomActionCtxProvider>
    </ErrorWrap>
  );
}
