/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z, ZodType } from "zod";
import { getDuplicates } from "~/tools";
import { NotificationGroupType } from "./types";

const emailVal = z.object({
  email: z
    .string()
    .min(1, { message: "This field has to be filled." })
    .email("This is not a valid email."),
});

export const emailSchema = z
  .object({ email_channels: emailVal.array() })
  .superRefine((val, ctx) => {
    if (
      val.email_channels.map(({ email }) => email).length !==
      new Set(val.email_channels.map(({ email }) => email)).size
    ) {
      const duplicates = getDuplicates(
        val.email_channels.map(({ email }) => email)
      );

      val.email_channels
        .map(({ email }) => email)
        .forEach((item) => {
          ctx.addIssue({
            path: [`email_channels.${duplicates.get(item)?.[1]}.email`],
            code: z.ZodIssueCode.custom,
            message: `No duplicates emails allowed.`,
          });
        });
    }
  });

export const slackSchema = z
  .object({
    slack_channels: z
      .object({
        webhookUrl: z
          .string()
          .url({ message: "This field must be a valid URL." }),
      })
      .array(),
  })
  .superRefine((val, ctx) => {
    if (
      val.slack_channels.map(({ webhookUrl }) => webhookUrl).length !==
      new Set(val.slack_channels.map(({ webhookUrl }) => webhookUrl)).size
    ) {
      const duplicates = getDuplicates(
        val.slack_channels.map(({ webhookUrl }) => webhookUrl)
      );

      val.slack_channels
        .map(({ webhookUrl }) => webhookUrl)
        .forEach((item) => {
          ctx.addIssue({
            path: [`slack_channels.${duplicates.get(item)?.[1]}.webhookUrl`],
            code: z.ZodIssueCode.custom,
            message: `No duplicates webhookUrl allowed.`,
          });
        });
    }
  });

const pagerDutyVal = z.object({
  pagerDutyKey: z.string().length(32, {
    message: "The PagerDuty key must be exactly 32 characters long.",
  }),
});

export const pagerDutySchema = z
  .object({ pager_duty_channels: pagerDutyVal.array() })
  .superRefine((val, ctx) => {
    if (
      val.pager_duty_channels.map(({ pagerDutyKey }) => pagerDutyKey).length !==
      new Set(val.pager_duty_channels.map(({ pagerDutyKey }) => pagerDutyKey))
        .size
    ) {
      const duplicates = getDuplicates(
        val.pager_duty_channels.map(({ pagerDutyKey }) => pagerDutyKey)
      );

      val.pager_duty_channels
        .map(({ pagerDutyKey }) => pagerDutyKey)
        .forEach((item) => {
          ctx.addIssue({
            path: [
              `pager_duty_channels.${duplicates.get(item)?.[1]}.pagerDutyKey`,
            ],
            code: z.ZodIssueCode.custom,
            message: `No duplicates PagerDuty Key allowed.`,
          });
        });
    }
  });

export const commonSchema = emailSchema.and(slackSchema).and(pagerDutySchema);

export const selectSchema = (
  selectedTypes: (NotificationGroupType | undefined)[],
  handleSetSchema: (schema: ZodType) => void
) => {
  const includesAll = (types: NotificationGroupType[]) =>
    types.every((type) => selectedTypes.includes(type));

  switch (true) {
    case includesAll([
      NotificationGroupType.email,
      NotificationGroupType.slack,
      NotificationGroupType.pagerDuty,
    ]):
      return handleSetSchema(commonSchema);
    case includesAll([
      NotificationGroupType.email,
      NotificationGroupType.slack,
    ]):
      return handleSetSchema(slackSchema.and(emailSchema));
    case includesAll([
      NotificationGroupType.email,
      NotificationGroupType.pagerDuty,
    ]):
      return handleSetSchema(emailSchema.and(pagerDutySchema));
    case includesAll([
      NotificationGroupType.slack,
      NotificationGroupType.pagerDuty,
    ]):
      return handleSetSchema(pagerDutySchema.and(slackSchema));
    case includesAll([NotificationGroupType.email]):
      return handleSetSchema(emailSchema);
    case includesAll([NotificationGroupType.slack]):
      return handleSetSchema(slackSchema);
    case includesAll([NotificationGroupType.pagerDuty]):
      return handleSetSchema(pagerDutySchema);
  }
};
