/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Table, FlexContainer } from "@nordcloud/gnui";
import { KeyValue, Maybe } from "~/generated/graphql";
import { MetadataDelete, MetadataEdit, NoData } from "~/components";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";

type Props = {
  metadata?: Maybe<Array<KeyValue>>;
  planId: string;
};

export function MetadataTable({ metadata, planId }: Props) {
  if (metadata?.length === 0) {
    return <NoData hasIcon message="There are no metadata." />;
  }

  return (
    <>
      <Table hoverline striped>
        <Table.thead>
          <Table.tr>
            <Table.th>Key</Table.th>
            <Table.th>Value</Table.th>
            <Table.th width="15%" />
          </Table.tr>
        </Table.thead>
        <Table.tbody>
          {metadata?.map((meta) => (
            <Table.tr key={meta.key}>
              <Table.td>{meta.key}</Table.td>
              <Table.td>{meta.value}</Table.td>
              <Table.td>
                <FlexContainer justifyContent="flex-end">
                  <MetadataEdit
                    updateHook={useUpdatePlan}
                    metadata={metadata}
                    id={planId}
                    selected={meta}
                  />
                  <MetadataDelete
                    updateHook={useUpdatePlan}
                    metadata={metadata}
                    id={planId}
                    selected={meta}
                  />
                </FlexContainer>
              </Table.td>
            </Table.tr>
          ))}
        </Table.tbody>
      </Table>
    </>
  );
}
