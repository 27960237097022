/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import download from "downloadjs";
import { When } from "react-if";
import { BrickLoader, Button, Modal, theme } from "@nordcloud/gnui";
import { Maybe, useGetPresignedUrlLazyQuery } from "~/generated/graphql";
import { LoaderWrap } from "~/components";
import { ModalContent, ModalFooter } from "~/components/Styled";
import { showError } from "~/services/toast";
import { isNotEmpty, isEmpty, isNotNil } from "~/tools";
import { ActionOutput } from "./ActionBox/components/ActionOutput";

type Props = {
  isOpen: boolean;
  close: () => void;
  actionLogLabel?: string;
  actionOutputUrl?: Maybe<string>;
};

export function ActionLogModal({
  isOpen,
  close,
  actionLogLabel,
  actionOutputUrl,
}: Props) {
  const unknownFetchErrorMsg = "Unknown fetch error";

  const label = actionLogLabel
    ? `Action Log Output for ${actionLogLabel}`
    : "Action Log Output";

  const [getPresignedUrl, { data }] = useGetPresignedUrlLazyQuery();
  const [fetchError, setFetchError] = useState("");
  const [actionOutput, setActionOutput] = useState("");

  useEffect(() => {
    const fetchOutput = async () => {
      if (isNotNil(data) && isNotNil(data.presignedEventActionOutputUrl)) {
        try {
          const response = await fetch(data.presignedEventActionOutputUrl);
          if (response.status === 200) {
            const out = await response.text();
            setActionOutput(out);
            setFetchError("");
          }
        } catch (err) {
          const error = err?.message ?? unknownFetchErrorMsg;
          setFetchError(error);
          setActionOutput("");
          showError(error);
        }
      }
    };

    const getActionOutput = async () => {
      actionOutputUrl &&
        getPresignedUrl({
          variables: {
            url: actionOutputUrl,
          },
        });
      fetchOutput();
    };

    getActionOutput();
  }, [actionOutputUrl, data, getPresignedUrl]);

  const onClickDownloadLog = () => {
    download(actionOutput, `${actionLogLabel}.log`, "text/plain");
  };

  return (
    <Modal
      onClose={close}
      isOpen={isOpen}
      contentLabel={label}
      contentMaxHeight={"80vh"}
      modalMinWidth={actionOutput?.length > 5000 ? "90vw" : "32rem"}
    >
      <ModalContent
        css={{
          maxHeight: "70vh",
        }}
      >
        <When condition={isEmpty(actionOutput) && isEmpty(fetchError)}>
          <LoaderWrap loading Component={BrickLoader}>
            <></>
          </LoaderWrap>
        </When>
        <When condition={isNotEmpty(fetchError)}>
          {"Unable to show data. Please try to download a file."}
        </When>
        <When condition={isNotEmpty(actionOutput)}>
          <ActionOutput actionOutput={actionOutput} />
        </When>
      </ModalContent>
      <ModalFooter
        mt={theme.spacing.spacing02}
        justifyContent="flex-end"
        gap={theme.spacing.spacing03}
      >
        <Button icon="download" onClick={onClickDownloadLog} severity="low">
          Download File
        </Button>
      </ModalFooter>
    </Modal>
  );
}
