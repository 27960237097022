/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { validate as validateUUID } from "uuid";
import { z } from "zod";
import { Role } from "~/generated/graphql";

export const isRoleEditable = (role: Role) => {
  const {
    changeDetails: { createdBy },
  } = role;
  return isAPIKey(createdBy) || isEmailAddress(createdBy);
};

const isAPIKey = (apiKey: string) => {
  return validateUUID(apiKey);
};

const isEmailAddress = (email: string) => {
  return z.string().email().safeParse(email).success;
};
