/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ExecutionPolicy, SkipWindowType } from "~/generated/graphql";
import { TimeUnits } from "../../../types";

export const units = [
  {
    label: "minute(s)",
    value: TimeUnits.minutes,
  },
  {
    label: "hour(s)",
    value: TimeUnits.hours,
  },
  {
    label: "day(s)",
    value: TimeUnits.days,
  },
  {
    label: "week(s)",
    value: TimeUnits.weeks,
  },
];

export const executionPolicy = [
  {
    label: "Run Anyway",
    value: ExecutionPolicy.Anyway,
    description:
      "Action will run regardless of previous action's success or failure.",
    disabled: false,
  },
  {
    label: "Success or Approval",
    value: ExecutionPolicy.SuccessOrApproval,
    description:
      "Action will continue automatically if previous action was successful. If failed, then manual approval will be required.",
    disabled: false,
  },
  {
    label: "Approval Required",
    value: ExecutionPolicy.Approval,
    description: "Manual approval required to start the action.",
    disabled: false,
  },
];

export const resourceOperation = [
  {
    label: "Simultaneous",
    value: false,
    description: "This action will run all resources at the same time.",
  },
  {
    label: "Sequenced",
    value: true,
    description: "This action will run resources one after another.",
  },
];

export const skipWindow = [
  {
    label: "Always",
    value: SkipWindowType.Always,
    description:
      "Irrespective of success, the window duration is skipped after completing actions for all resource groups.",
  },
  {
    label: "Never",
    value: SkipWindowType.Never,
    description:
      "By default, the next action will start according to the window duration.",
  },
  {
    label: "On Success",
    value: SkipWindowType.OnSuccess,
    description:
      "When the action is applied successfully to resource groups, the action window is automatically skipped.",
  },
];
