/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Channel } from "~/generated/graphql";
import { NoData } from "~/components/NoData";
import { TableWrapper } from "~/components/Tables";
import { isEmpty, isNotNil } from "~/tools";
import {
  Channels,
  ChannelsTypes,
  filterWebhookChannelByType,
} from "~/views/notificationGroups/components/helpers";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";
import { columns } from "./PagerDutyTableColumns";

export function PagerDutyTable() {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data } = useNotificationGroup();
  if (data === undefined) {
    return <NoData message="no data" />;
  }

  const { webhookChannel } = filterWebhookChannelByType(
    data?.channels ?? [],
    Channels.PAGER_DUTY
  );

  const pagerDutyData = webhookChannel.map((channel) => ({
    id: channel.id,
    payload:
      channel.typeProperties.__typename === ChannelsTypes.WEBHOOK
        ? channel.typeProperties.payload ?? ""
        : "",
  }));

  const routingKeys = extractRoutingKeys(pagerDutyData);

  return (
    <If condition={isEmpty(webhookChannel)}>
      <Then>
        <NoData hasIcon message={"There are no PagerDuty Channels."} />
      </Then>
      <Else>
        <TableWrapper<Partial<Channel>>
          loading={false}
          data={routingKeys.filter(isNotNil)}
          columns={columns()}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          stripped
        />
      </Else>
    </If>
  );
}

function extractRoutingKeys(
  pagerDutyItems: { id: string; payload: string }[]
): { id: string; pagerDutyKey: string }[] {
  return pagerDutyItems.map((item) => {
    const payloadObj = JSON.parse(item.payload);

    return {
      id: item.id,
      pagerDutyKey: payloadObj.routing_key,
    };
  });
}
