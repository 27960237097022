/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { When } from "react-if";
import { FlexContainer } from "@nordcloud/gnui";
import { Role } from "~/generated/graphql";
import {
  ActionsColumn,
  FlexColumn,
  FlexColumnLarge,
  MediumColumn,
} from "~/components/Tables/styles";
import { dateFormat } from "~/constants/dateFormats";
import { isRoleEditable } from "~/views/permissions/utils";
import { Actions } from "./Actions";

const columnHelper = createColumnHelper<Role>();

export function roleColumns() {
  return [
    columnHelper.accessor((row) => row?.name, {
      header: "Role Name",
      cell: (cell) => (
        <FlexContainer justifyContent="flex-start" alignItems="center">
          {cell.getValue()}
        </FlexContainer>
      ),
      meta: FlexColumn,
    }),
    columnHelper.accessor((row) => row?.description, {
      header: "Description",
      cell: (cell) => <div>{cell.getValue()}</div>,
      meta: FlexColumnLarge,
    }),
    columnHelper.accessor((row) => row?.changeDetails?.createdAt, {
      header: "Created",
      cell: (cell) => dayjs(cell.getValue()).format(dateFormat.dayMonthYear),
      meta: MediumColumn,
    }),
    columnHelper.accessor((row) => row?.changeDetails?.updatedAt, {
      header: "Last Updated",
      cell: (cell) => dayjs(cell.getValue()).format(dateFormat.dayMonthYear),
      meta: MediumColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const roleId = row.original.id;
        return (
          <When condition={isRoleEditable(row.original)}>
            <FlexContainer justifyContent="space-around">
              <Actions rolesIds={[roleId]} />
            </FlexContainer>
          </When>
        );
      },
      meta: ActionsColumn,
    }),
  ] as never;
}
