/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { FlexContainer, Message, Text, theme } from "@nordcloud/gnui";
import {
  EventAction,
  EventActionBatch,
  ExecutionStatus,
} from "~/generated/graphql";
import { inflect, isNonEmpty } from "~/tools";
import { useEvent } from "../../EventsDetails/EventProvider";
import { RefetchOptions } from "../../helpers";
import { ApproveEvent } from "./ApproveEvent";

export function ApproveEventMessage() {
  const { event } = useEvent();

  const actions = event?.actions?.filter(
    (eventAction: EventAction) =>
      eventAction.globalState?.status === ExecutionStatus.ApprovalRequired
  );

  const batches = event?.actionBatches?.filter(
    (batch: EventActionBatch) =>
      batch.status === ExecutionStatus.ApprovalRequired
  );

  return (
    <When condition={isNonEmpty(actions) || isNonEmpty(batches)}>
      <Message
        color={theme.color.support.purple}
        borderColor={theme.color.support.purple}
        image="warning"
        css={{
          marginBottom: theme.spacing.spacing02,
          paddingBottom: theme.spacing.spacing04,
        }}
      >
        <div>
          <Text
            mb={theme.spacing.spacing02}
            weight="medium"
            color={theme.color.support.purple}
          >
            This event is pending approval
          </Text>
          <Text color={theme.color.text.text02}>
            This event has unapproved{" "}
            {inflect("action")(
              Math.max(actions?.length ?? 0, batches?.length ? 2 : 0)
            )}
          </Text>
          <FlexContainer>
            <ApproveEvent
              actions={actions}
              batches={batches}
              eventId={event?.id}
              refetchQueries={RefetchOptions.EVENT}
            />
          </FlexContainer>
        </div>
      </Message>
    </When>
  );
}
