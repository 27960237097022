/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { ResourceGroupCreateWizard } from "./components/ResourceGroupCreateWizard";

export function ResourceGroupCreate() {
  return (
    <>
      <BreadcrumbsBox
        title="Create Resource Group"
        customBreadcrumbs={[
          { label: "Dashboard", uri: "/" },
          { label: "Resource Groups", uri: ROUTES.resourceGroups.index },
          { label: "Create Resource Group", uri: ROUTES.resourceGroups.create },
        ]}
      />
      <ResourceGroupCreateWizard />
    </>
  );
}
