/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { BrickLoader } from "@nordcloud/gnui";
import { useEventStatusChangedSubscription } from "~/generated/graphql";
import { ErrorWrap, BreadcrumbsBox, LoaderWrap } from "~/components";
import { NoData } from "~/components/NoData";
import { ROUTES } from "~/routing/routes";
import { ApproveEventMessage } from "../components";
import { useGetEvent } from "../hooks";
import { EventInfo } from "./components";
import { EventCtxProvider } from "./EventProvider";
import { EventDetailsTab } from "./Tabs/Details/EventDetailsTab";
import { Tabs } from "./Tabs/Tabs";

type Props = {
  eventId: string;
};
export function EventDetails({ eventId }: Props) {
  const { event, loading, error } = useGetEvent({
    eventId,
  });

  const { data } = useEventStatusChangedSubscription({
    variables: {
      input: {
        id: eventId,
      },
    },
  });

  const title = event?.name ?? eventId;

  const eventUpdate = {
    ...event,
    status: data?.eventStatusChanged ?? event?.status,
  };

  const breadcrumbs = [
    { label: "Home", uri: "/" },
    { label: "Events", uri: ROUTES.events.index },
    { label: title, uri: "" },
  ];

  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <EventCtxProvider value={{ event: eventUpdate }}>
        <LoaderWrap
          Component={() => <BrickLoader height="14rem" />}
          loading={loading}
          viewBox="0 0 10 4"
        >
          <Row>
            <Col>
              <BreadcrumbsBox title={title} customBreadcrumbs={breadcrumbs} />
            </Col>
          </Row>
          <ApproveEventMessage />
          <Row>
            <Col>
              <EventInfo />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs detailsTab={<EventDetailsTab />} />
            </Col>
          </Row>
        </LoaderWrap>
      </EventCtxProvider>
    </ErrorWrap>
  );
}
