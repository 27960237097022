/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Text, theme } from "@nordcloud/gnui";

export const TooltipText = styled(Text)`
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-thickness: 1px;
  text-decoration-color: ${theme.color.text.text02};
  text-underline-offset: ${theme.spacing.spacing01};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
