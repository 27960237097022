/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { Button, FlexContainer } from "@nordcloud/gnui";

export const WrapWizardButton = styled(FlexContainer)`
  position: relative;
  flex-grow: 1;
  min-height: 3rem;
`;
export const WizardButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
