/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { defaultActionSettingsValues } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import {
  FormField,
  StepState,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";

export const useFormDefaultValues = (stepState: StepState) => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      [FormField.UNIT]:
        stepState.selectedEntity?.unit ?? defaultActionSettingsValues.unit,
      [FormField.EXECUTION_POLICY]:
        stepState.selectedEntity?.executionPolicy ??
        defaultActionSettingsValues.executionPolicy,
      [FormField.RUN_IN_SEQUENCE]:
        stepState.selectedEntity?.runInSequence ??
        defaultActionSettingsValues.runInSequence,
      [FormField.SKIP_WINDOW]:
        stepState.selectedEntity?.skipWindow ??
        defaultActionSettingsValues.skipWindow,
    },
  });
  const { reset } = formMethods;

  useEffect(() => {
    reset({
      [FormField.UNIT]: stepState.selectedEntity?.unit,
      [FormField.EXECUTION_POLICY]: stepState.selectedEntity?.executionPolicy,
      [FormField.RUN_IN_SEQUENCE]: stepState.selectedEntity?.runInSequence,
      [FormField.WINDOW_DURATION]: stepState.selectedEntity?.windowDuration,
      [FormField.SKIP_WINDOW]: stepState.selectedEntity?.skipWindow,
    });
  }, [reset, stepState.selectedEntity]);

  return formMethods;
};
