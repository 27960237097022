/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { ExtendedTooltip } from "@nordcloud/gnui";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText, inflect, isNotEmpty } from "~/tools";
import { BindingRolesDelete } from "./BindingRolesDelete";
import { UserEdit } from "./UserEdit";

type Props = {
  roleIds: string[];
  email: string;
  systemRoleNames: string[];
};

export function Actions({ roleIds, email, systemRoleNames }: Props) {
  const prepareIds = roleIds.map((roleId) => `user__${email}__${roleId}`);
  const hasSystemRoles = isNotEmpty(systemRoleNames);
  const systemRolesTooltip = systemRoleNames.join(", ");
  const caption = hasSystemRoles
    ? `System ${inflect("role")(
        systemRoleNames?.length
      )} assigned (${systemRolesTooltip}). Not allowed to delete.`
    : null;

  return (
    <>
      <When condition={isNotEmpty(roleIds)}>
        <UserEdit roleIds={roleIds} email={email} />
      </When>
      <ExtendedTooltip showTimeout={0} caption={caption}>
        <BindingRolesDelete
          roleIds={prepareIds}
          onSuccess={() => {
            showSuccess(generateActionSuccessText(`User`)()("removed")());
          }}
          hasSystemRoles={hasSystemRoles}
        />
      </ExtendedTooltip>
    </>
  );
}
