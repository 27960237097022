/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box } from "@nordcloud/gnui";
import { PlanEntityIncomingEventDetails } from "~/views/events/types";
import { IncomingEventActionBatchSettingsSection } from "./components/IncomingEventActionBatchSettingsSection";

type Props = {
  planActionBatch?: PlanEntityIncomingEventDetails;
  planId?: string;
};

export function IncomingEventActionBatchBox({
  planActionBatch,
  planId,
}: Props) {
  return (
    <Box innerSpacing="spacing00" css={{ height: "100%" }}>
      <IncomingEventActionBatchSettingsSection
        planActionBatch={planActionBatch}
        {...{ planId }}
      />
    </Box>
  );
}
