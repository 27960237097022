/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Resource } from "~/generated/graphql";
import { createCtx } from "~/tools";

type Props = {
  cleanRowSelection?: () => void;
  resource?: Resource;
};

export const [useResource, ResourceCtxProvider] = createCtx<Props>();
