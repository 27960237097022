/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, FormProvider, useForm } from "react-hook-form";
import { When } from "react-if";
import { Input, Label, Checkbox, Text, theme, Select } from "@nordcloud/gnui";
import { ActionParameterType, KeyValue } from "~/generated/graphql";
import { NoData } from "~/components";
import { isString } from "~/components/Details/utils";
import { isEmpty, isNil, isNonEmpty, isNotEmpty } from "~/tools";
import { isValidJSONObject } from "~/utils/json";
import {
  defaultActionSettingsValues,
  PlanField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { useStepState } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/StepProvider";
import {
  FormField,
  Option,
  SelectedPlanEntity,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { separateConnectedWords } from "~/views/plans/utils";
import { buildOptions, updateEntities } from "../utils";
import { SelectActionVersion } from "./SelectActionVersion";

type Props = {
  nextStep?: () => void;
  setTab: (key: number) => void;
};

export function AdvancedSettingsForm({ nextStep, setTab }: Props) {
  const { stepState, updateStepState } = useStepState();

  const { setPlanData } = usePlanWizard();

  const formMethods = useForm<FormData>({
    defaultValues: defaultActionSettingsValues,
  });

  const { control, handleSubmit } = formMethods;

  function findValueByKey(
    key: string,
    opt?: Option[]
  ): string | string[] | undefined {
    const parameter = (stepState.selectedEntity?.inputParameters ?? []).find(
      (item) => item.key === key
    );

    if (Array.isArray(opt)) {
      return opt.find((o: Option) => o.value === parameter?.value)?.value;
    } else {
      return parameter?.value;
    }
  }

  function findValueByKeyOp(key: string, opt?: Option[]): Option[] | undefined {
    const parameter = (stepState.selectedEntity?.inputParameters ?? []).find(
      (item) => item.key === key
    );

    const parameterValue =
      parameter?.value && isValidJSONObject(parameter.value)
        ? JSON.parse(parameter.value)
        : parameter?.value;
    if (isNil(parameterValue)) {
      return;
    }
    return (
      opt?.filter((o: Option) => parameterValue.includes(o.value)) ?? undefined
    );
  }

  const handleSetPlanData = (newActions: SelectedPlanEntity[]) =>
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: [...(newActions ?? [])],
      },
    }));

  const getParamsWithNonEmptyValues = (params: KeyValue[]) => {
    return params.filter((param) => isNotEmpty(param.value));
  };

  const replaceInputParameterValue = (
    key: string,
    inputValue?: string | string[]
  ) => {
    const parameterIndex = (
      stepState.selectedEntity?.inputParameters ?? []
    ).findIndex((item) => item.key === key);

    const value = isString(inputValue)
      ? inputValue
      : JSON.stringify(inputValue);

    return parameterIndex !== -1
      ? [
          ...(stepState.selectedEntity?.inputParameters ?? []).slice(
            0,
            parameterIndex
          ),
          { key, value },
          ...(stepState.selectedEntity?.inputParameters ?? []).slice(
            parameterIndex + 1
          ),
        ]
      : [
          ...(stepState.selectedEntity?.inputParameters?.filter(
            (item) => item.key !== key
          ) ?? []),
          { key, value },
        ];
  };

  function addOrUpdateInputParameterKeyValue(
    key: string,
    inputValue?: string | string[]
  ): void {
    const inputParams = replaceInputParameterValue(key, inputValue);
    const inputParamsWithNonEmptyValues =
      getParamsWithNonEmptyValues(inputParams);

    const updatedEntities = updateEntities(stepState, {
      inputParameters: inputParamsWithNonEmptyValues,
    });

    updateStepState({
      entities: updatedEntities,
      selectedEntity: {
        ...stepState.selectedEntity,
        inputParameters: inputParamsWithNonEmptyValues,
      },
    });

    handleSetPlanData(updatedEntities);
  }

  const handleChange = (value: string, name: string) =>
    addOrUpdateInputParameterKeyValue(name, value);

  if (isEmpty(stepState.selectedEntity?.parameterDefinitions ?? [])) {
    return (
      <>
        <SelectActionVersion />
        <NoData hasIcon message="There are no Advanced Action Settings" />
      </>
    );
  }

  const isDefaultCheck = (value: string, defaultValue?: string | null) => {
    if (findValueByKey(value)) {
      return findValueByKey(value) === "true";
    }
    return JSON.parse(defaultValue ?? "false");
  };

  const submit = () => {
    if (stepState.selectedEntity?.notificationGroups) {
      setTab(2);
    } else {
      nextStep?.();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form id="planSettingsForm" onSubmit={handleSubmit(submit)}>
        <SelectActionVersion />
        {stepState.selectedEntity?.parameterDefinitions?.map((item) => {
          const options = buildOptions(item.allowedValues ?? []);
          const defaultValue = options?.find(
            (o: Option) => o.value === item.defaultValue
          );
          const allowedValue = options?.find(
            (o: Option) => o.value === item.allowedValues?.[0]
          );

          const val =
            findValueByKeyOp(item.name, options) ??
            defaultValue ??
            allowedValue;

          return (
            <div
              key={item.name}
              css={{ marginBottom: theme.spacing.spacing04 }}
            >
              <Label
                name={separateConnectedWords(item.name)}
                css={{ marginBottom: 0 }}
                required={item.required}
              />
              <Text mb={theme.spacing.spacing02} size="sm">
                {item.description}
              </Text>
              <When
                condition={
                  isNonEmpty(item.allowedValues ?? []) &&
                  item.type === ActionParameterType.List
                }
              >
                <Controller
                  control={control}
                  name={FormField.PLAN_ENTITIES}
                  render={() => {
                    return (
                      <Select
                        isMulti
                        name={`Select ${separateConnectedWords(item.name)}`}
                        options={options}
                        onChange={(o) => {
                          const value = o?.map((i) => i.value);
                          addOrUpdateInputParameterKeyValue(item.name, value);
                        }}
                        value={val}
                        isSearchable={false}
                      />
                    );
                  }}
                />
              </When>
              <When
                condition={
                  isNonEmpty(item.allowedValues ?? []) &&
                  item.type === ActionParameterType.String
                }
              >
                <Controller
                  control={control}
                  name={FormField.PLAN_ENTITIES}
                  render={() => {
                    return (
                      <Select
                        name={`Select ${separateConnectedWords(item.name)}`}
                        options={options}
                        onChange={(o: Option | null) => {
                          addOrUpdateInputParameterKeyValue(
                            item.name,
                            o?.value
                          );
                        }}
                        value={val}
                        isSearchable={false}
                      />
                    );
                  }}
                />
              </When>
              <When
                condition={
                  isEmpty(item.allowedValues ?? []) &&
                  item.type !== ActionParameterType.Bool
                }
              >
                <Controller
                  control={control}
                  name={FormField.PLAN_ENTITIES}
                  render={() => {
                    return (
                      <Input
                        value={
                          findValueByKey(item.name) ?? item.defaultValue ?? ""
                        }
                        name={separateConnectedWords(item.name)}
                        id={item.name}
                        onChange={(e) =>
                          handleChange(e.target.value, item.name)
                        }
                      />
                    );
                  }}
                />
              </When>
              <When condition={item.type === ActionParameterType.Bool}>
                <Controller
                  control={control}
                  name={FormField.PLAN_ENTITIES}
                  render={() => {
                    return (
                      <Checkbox
                        id={item.name}
                        labelText={separateConnectedWords(item.name)}
                        checked={isDefaultCheck(item.name, item.defaultValue)}
                        onChange={(e) => {
                          return handleChange(
                            e.target.checked.toString(),
                            item.name
                          );
                        }}
                      />
                    );
                  }}
                />
              </When>
            </div>
          );
        })}
      </form>
    </FormProvider>
  );
}
