/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NotificationGroupItem } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import {
  PlanActionNotificationGroupBase,
  PlanActionNotification,
  PlanActionNotificationItemType,
} from "../../../types";

export function mergePlanActionNotifications(
  notificationItems: NotificationGroupItem[]
): PlanActionNotificationItemType[] {
  const planActionNotifications = mapPlanActionNotifications(notificationItems);

  return mergeNotificationGroups(planActionNotifications);
}

// Merges notification groups by combining notifications that share the same scope,
// inAdvance timing & matching trigger events. It ensures that notification groups
// are not duplicated and merges them when necessary.
function mergeNotificationGroups(
  planActionNotifications: PlanActionNotification[]
): PlanActionNotificationItemType[] {
  return planActionNotifications.reduce(
    (
      acc: PlanActionNotificationItemType[],
      currentItem: PlanActionNotification
    ) => {
      const existingIndex = acc.findIndex((item) => {
        const sameScopeAndInAdvance =
          item.scope === currentItem.scope &&
          item.inAdvance === currentItem.inAdvance;

        if (!sameScopeAndInAdvance) {
          return false;
        }

        const triggerEventMatch =
          item?.triggerEvent === currentItem.triggerEvent;

        // Check if the notification group is already included
        return (
          triggerEventMatch &&
          !isNotificationGroupIncluded(
            item.notificationGroups,
            currentItem.notificationGroup
          )
        );
      });

      // If no matching item is found, create a new notification and add it to the accumulator
      if (existingIndex === -1) {
        return [
          ...acc,
          {
            id: currentItem.id,
            triggerEvent: currentItem.triggerEvent,
            scope: currentItem.scope,
            notificationGroups: [currentItem.notificationGroup],
            inAdvance: currentItem.inAdvance,
          },
        ];
      }

      // If a matching item is found, merge the current item's notification group into it
      return acc.map((item, index) => {
        if (index === existingIndex) {
          const mergedNotificationGroups = [
            ...item.notificationGroups,
            currentItem.notificationGroup,
          ];

          const uniqueNotificationGroups = filterUniqueNotificationGroups(
            mergedNotificationGroups
          );

          return {
            ...item,
            notificationGroups: uniqueNotificationGroups,
          };
        }

        return item;
      });
    },
    []
  );
}

function mapPlanActionNotifications(
  notifications: NotificationGroupItem[]
): PlanActionNotification[] {
  return notifications.map((item: NotificationGroupItem) => ({
    id: item.id,
    triggerEvent: item.triggerEvent,
    scope: item.scope,
    notificationGroup: {
      id: item.notificationGroup?.id,
      name: item.notificationGroup?.name,
    },
    inAdvance: item.inAdvance ?? 0,
  }));
}

function isNotificationGroupIncluded(
  notificationGroups: PlanActionNotificationGroupBase[],
  notificationGroup: PlanActionNotificationGroupBase
): boolean {
  return notificationGroups.every(
    (group) =>
      group.id === notificationGroup.id && group.name === notificationGroup.name
  );
}

function filterUniqueNotificationGroups(
  notificationGroups: PlanActionNotificationGroupBase[]
): PlanActionNotificationGroupBase[] {
  return notificationGroups.filter(
    (group, idx, arr) =>
      arr.findIndex((g) => g.id === group.id && g.name === group.name) === idx
  );
}
