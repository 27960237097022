/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Maybe } from "~/generated/graphql";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { isNil } from "~/tools";
import { ActionLogModal } from "../../ActionLogModal";
import { ChangeResourceStatusSidebar } from "./ChangeResourceStatusSidebar";

type EventResourceActionProps = {
  actionOutputUrl?: Maybe<string>;
  resourceName: string;
  changeStatusDetails: {
    eventId: string;
    eventActionId: string;
    eventActionResourceStateId: string;
  };
  isInProgress: boolean;
};

export function EventResourceGroupAction({
  actionOutputUrl,
  resourceName,
  changeStatusDetails,
  isInProgress,
}: EventResourceActionProps) {
  const {
    isOpen: isActionLogModalOpen,
    close: closeActionLogModal,
    open: openActionLogModal,
  } = useDisclosure(false);

  const {
    isOpen: isChangeStatusSidebarOpen,
    close: closeChangeStatusSidebar,
    open: openChangeStatusSidebar,
  } = useDisclosure(false);

  return (
    <>
      <PopoverControls
        size="small"
        disabled={false}
        items={[
          {
            label: "Action Log",
            onClick: openActionLogModal,
            disabled: isNil(actionOutputUrl),
          },
          {
            label: "Change status",
            onClick: openChangeStatusSidebar,
            disabled: isInProgress,
          },
        ]}
      />
      <When condition={isActionLogModalOpen}>
        <ActionLogModal
          actionLogLabel={resourceName}
          actionOutputUrl={actionOutputUrl}
          isOpen={isActionLogModalOpen}
          close={closeActionLogModal}
        />
      </When>
      <ChangeResourceStatusSidebar
        isOpen={isChangeStatusSidebarOpen}
        open={openChangeStatusSidebar}
        close={closeChangeStatusSidebar}
        resourceName={resourceName}
        changeStatusDetails={changeStatusDetails}
      />
    </>
  );
}
