/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon } from "@nordcloud/gnui";

type StyledSVGIconProps = {
  pathColors?: Record<number, string>;
};

const generatePathStyles = (pathColors?: Record<number, string>): string => {
  if (!pathColors) {
    return "";
  }
  return Object.entries(pathColors)
    .map(
      ([index, color]) => `path:nth-of-type(${+index + 1}) { fill: ${color}; }`
    )
    .join("\n");
};

export const StyledSVGIcon = styled(SVGIcon)<StyledSVGIconProps>`
  ${({ pathColors }) => generatePathStyles(pathColors)}
`;
