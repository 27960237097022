/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { theme } from "@nordcloud/gnui";

export const DropItem = styled.li`
  display: flex;
  align-items: center;
  justify-items: center;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing01};
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  &:last-of-type {
    border-top: 1px solid ${theme.color.border.border01};
  }
`;

export const Username = styled.div`
  font-size: ${theme.fontSizes.sm};
  font-weight: bold;
  padding-bottom: ${theme.spacing.spacing01};
  border-bottom: 1px solid ${theme.color.border.border01};
  max-width: 100%;
  word-wrap: break-word;
`;

const StyledBtn = css`
  flex: 1;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-items: center;
  border: none;
  color: ${theme.color.text.text01};
  font-size: 0.85rem;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: ${theme.color.text.text01};
  }
`;

export const DropBtn = styled.button`
  ${StyledBtn}
`;

export const DropLink = styled(Link)`
  ${StyledBtn}
`;

export const DropList = styled.ul`
  border-radius: ${theme.radiusDefault};
  padding: ${theme.spacing.spacing02};
  margin-bottom: 0;
`;

export const IconWrap = styled.div`
  display: flex;
  margin-right: ${theme.spacing.spacing04};
`;

export const DropdownWrapStyle = styled.div`
  position: relative;
  cursor: pointer;
  z-index: ${theme.zindex.masked};
`;

export const BoxWrap = styled.div`
  min-width: 0;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing04};
  color: ${theme.color.text.text01};
  background: ${theme.color.background.ui01};
`;

export const ShowDropDown = styled.div`
  background: ${theme.color.background.ui01};
  transition: ${theme.transition};
  position: absolute;
  opacity: 0;
  visibility: hidden;
  border-bottom-right-radius: ${theme.radiusDefault};
  border-bottom-left-radius: ${theme.radiusDefault};
  top: 100%;
  right: 0rem;
  width: 14rem;
  z-index: 100;

  ${/* sc-selector */ DropdownWrapStyle}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const ArrowWrap = styled.div`
  display: block;
  transition: ${theme.transition};
  ${/* sc-selector */ DropdownWrapStyle}:hover & {
    transform: rotate(180deg);
  }
`;
