/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { Group } from "~/generated/graphql";
import { createCtx } from "~/tools";

type Props = {
  resourceGroup?: Partial<Group>;
  setExpression?: Dispatch<SetStateAction<string | undefined>>;
  expression?: string;
};

export const [useResourceGroup, ResourceGroupCtxProvider] = createCtx<Props>();
