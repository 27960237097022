/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useGetCloudAccountsQuery,
  CloudAccountsSortInput,
} from "~/generated/graphql";

type Props = {
  page: string;
  limit: string;
  sort?: CloudAccountsSortInput;
};

export function useGetCloudAccounts({ page, limit, sort }: Props) {
  const {
    data: cloudAccountsData,
    loading,
    error,
    refetch,
  } = useGetCloudAccountsQuery({
    variables: { page: Number(page), limit: Number(limit), sort },
  });
  const data = cloudAccountsData?.cloudAccounts?.result ?? [];
  const count = cloudAccountsData?.cloudAccounts?.pageInfo?.count ?? 0;

  return { data, loading, error, count, refetch } as const;
}
