/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Resource } from "~/generated/graphql";
import { ColumnType, SelectionMethod } from "~/types";

export const mapResourceToSidebarOption = (resource: Resource) => {
  return {
    name: resource?.name ?? "",
    id: resource?.id ?? "",
    tag: SelectionMethod.manual,
    columns: [
      {
        data: [resource?.os?.subType ?? "", resource?.os?.type ?? ""],
        type: ColumnType.os,
      },
      {
        data: [resource?.name ?? "", resource?.id ?? ""],
        type: ColumnType.text,
      },
      {
        data: [SelectionMethod.manual],
        type: ColumnType.selectionMethod,
      },
    ],
  };
};
