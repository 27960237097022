/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  PlanActionNotificationGroupScope,
  PlanActionNotificationTriggerType,
} from "~/generated/graphql";
import { capitalize } from "~/tools";

const planActionNotificationTriggers = [
  PlanActionNotificationTriggerType.Start,
  PlanActionNotificationTriggerType.Successful,
  PlanActionNotificationTriggerType.PartiallySuccessful,
  PlanActionNotificationTriggerType.Failed,
  PlanActionNotificationTriggerType.Upcoming,
  PlanActionNotificationTriggerType.NotApproved,
];

const planActionResourceNotificationTriggers = [
  PlanActionNotificationTriggerType.ResourcesStart,
  PlanActionNotificationTriggerType.ResourcesSuccessful,
  PlanActionNotificationTriggerType.ResourcesFailed,
  PlanActionNotificationTriggerType.ResourcesError,
  PlanActionNotificationTriggerType.ResourcesNotInTimeWindow,
  PlanActionNotificationTriggerType.ResourcesTimeout,
];

export const optionsScope = Object.values(PlanActionNotificationGroupScope).map(
  (scope) => ({
    value: scope,
    label: capitalize(scope),
  })
);

export const optionsTriggerResource =
  planActionResourceNotificationTriggers.map((trigger) => {
    return {
      value: trigger,
      label: getLabelText(trigger),
    };
  });

export const optionsTriggerAction = planActionNotificationTriggers.map(
  (trigger) => ({
    value: trigger,
    label: getLabelText(trigger),
  })
);

export function getLabelText(
  trigger?: PlanActionNotificationTriggerType
): string {
  if (!trigger) {
    return "";
  }

  const triggerLabel = trigger
    .replaceAll("_", " ")
    .replace("RESOURCES", "")
    .trim();

  return capitalize(triggerLabel);
}
