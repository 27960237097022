/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PropsWithChildren, ReactNode } from "react";
import { Col } from "react-awesome-styled-grid";
import { Link } from "react-router-dom";
import { Box, Breadcrumbs, FlexContainer } from "@nordcloud/gnui";
import { HeadlineWrap, StyledHeading, StyledRow } from "./styles";
import { ComponentLabel, ComponentLabelProps } from "./types";
import { useBreadcrumbs } from "./useBreadcrumbs";

type Props = PropsWithChildren<{
  title?: string;
  status?: ReactNode;
  labels?: ComponentLabel[];
  customBreadcrumbs?: ComponentLabelProps[];
}>;

export function BreadcrumbsBox({
  title,
  status,
  children,
  labels,
  customBreadcrumbs,
}: Props) {
  const { displayTitle, breadcrumbs } = useBreadcrumbs({ title, labels });

  return (
    <Box spacing="spacing02">
      <StyledRow>
        <Col>
          <HeadlineWrap>
            <StyledHeading data-testid="display-title" level={2}>
              {displayTitle}
            </StyledHeading>
            {status}
          </HeadlineWrap>
          <Breadcrumbs
            list={customBreadcrumbs || breadcrumbs}
            Component={Link}
          />
        </Col>
        <Col>
          <FlexContainer justifyContent="flex-end">{children}</FlexContainer>
        </Col>
      </StyledRow>
    </Box>
  );
}
