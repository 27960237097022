/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { FlexContainer, Radio, Text, theme } from "@nordcloud/gnui";
import { ActionScope } from "~/generated/graphql";
import { RadioLabel } from "~/components/Styled";
import { FormField } from "../../../formConfig";

const actionType = [
  {
    name: "Plan Based",
    label:
      "This action can be an AWS Lambda or Azure function. It is executed only once, regardless of the resource configuration in your plan.",
    scope: ActionScope.Plan,
  },
  {
    name: "Resource Based",
    label:
      "This action type requires resource groups and will be applied to every resource.",
    scope: ActionScope.Resource,
  },
];

type Props = {
  onScopeChange: () => void;
};

export function ActionScopeRadio({ onScopeChange }: Props) {
  return (
    <>
      <Text mb={0}>Action Scope</Text>
      <FlexContainer columnGap="3rem" justifyContent="space-between">
        {actionType.map(({ name, label, scope }) => (
          <RadioCheckbox
            key={name}
            name={name}
            label={label}
            scope={scope}
            onScopeChange={onScopeChange}
          />
        ))}
      </FlexContainer>
    </>
  );
}

type RadioCheckboxProps = {
  name: string;
  label: string;
  scope: string;
  onScopeChange: () => void;
};

function RadioCheckbox({
  name,
  label,
  scope,
  onScopeChange,
}: RadioCheckboxProps) {
  const { resetField } = useFormContext();
  return (
    <div css={{ width: "50%" }}>
      <RadioLabel htmlFor={name}>
        <FlexContainer direction="column">
          <Controller
            name={FormField.SCOPE}
            render={({ field: { onChange, value } }) => (
              <Radio
                id={name}
                labelText={name}
                value={scope}
                onChange={() => {
                  onScopeChange();
                  resetField(FormField.TYPE, { defaultValue: "" });
                  onChange(scope);
                }}
                checked={scope === value}
              />
            )}
          />
          <Text
            tag="span"
            size="sm"
            ml="1.75rem"
            css={{
              textTransform: "capitalize",
              marginTop: `-${theme.spacing.spacing03}`,
            }}
          >
            {label}
          </Text>
        </FlexContainer>
      </RadioLabel>
    </div>
  );
}
