/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Text } from "@nordcloud/gnui";
import { DataDisplayWrapper } from "~/components";
import { convertMinutesToTime } from "~/views/events/helpers";

type Props = {
  windowDuration?: number;
};

export function DisplayWindowDuration({ windowDuration }: Props) {
  return (
    <>
      <Text size="sm" tag="div">
        Window Duration
      </Text>
      <DataDisplayWrapper hasData={windowDuration !== null}>
        <Text data-testid={"label-window-duration"}>
          {convertMinutesToTime(Number(windowDuration))}
        </Text>
      </DataDisplayWrapper>
    </>
  );
}
