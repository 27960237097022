/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Resource, UpdateResourceInput } from "~/generated/graphql";
import { ResourceFields } from "./types";

export function mapResourceToResourcePutInput(
  resource: Resource
): UpdateResourceInput {
  return {
    id: resource.id,
  };
}

export function getUpdateObject(
  data: ResourceFields,
  resource: Resource
): UpdateResourceInput {
  const putResource = mapResourceToResourcePutInput(resource);

  return { ...putResource, ...data };
}
