/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Button,
  Input,
  Label,
  Sidebar,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  RoleRule,
  RoleRuleInput,
  useGetRoleLazyQuery,
} from "~/generated/graphql";
import { FormGroup } from "~/components";
import { ErrorWrap } from "~/components/Error";
import { LoaderWrap } from "~/components/Loader";
import { initialState } from "../constants";
import { usePermissionState } from "../hooks/usePermissionState";
import { useUpdateRole } from "../hooks/useUpdateRole";
import { FormData } from "../RoleCreate/formConfig";
import { StateItem } from "../types";
import { prepareData } from "../utils";
import { PermissionSelect } from "./PermissionSelect";
import { schema } from "./schema";

type Props = {
  isOpen: boolean;
  roleIds: string[];
  openSidebar: () => void;
  close: () => void;
};

export function RoleEdit({ isOpen, openSidebar, roleIds, close }: Props) {
  const [getRole, { data, loading: roleLoading, error }] = useGetRoleLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "network-only",
  });

  const role = {
    id: data?.role?.id ?? "",
    name: data?.role?.name ?? "",
    description: data?.role?.description ?? "",
  };

  const rules =
    data?.role?.rules
      ?.map((rule: RoleRule) => {
        const actions = rule.actions;
        return rule.resources.map((r: string) => {
          return {
            name: r,
            checked: actions,
          };
        });
      })
      .flat() ?? initialState;

  const actions = rules.map(({ name }) => name);
  const filterRules = initialState.filter(
    ({ name }) => !actions.includes(name)
  );

  const editInitialState = [...filterRules, ...rules];

  const roleId = roleIds[0];

  return (
    <>
      <Button
        aria-label="edit button"
        data-testid="edit button"
        icon="edit"
        size="md"
        severity="low"
        onClick={() => {
          openSidebar();
          getRole({ variables: { roleId: roleId } });
        }}
      />

      <Sidebar width="43rem" title="Edit Role" isOpen={isOpen} onClick={close}>
        <ErrorWrap error={error} />
        <LoaderWrap inContent loading={roleLoading}>
          <EditSidebar
            role={role}
            close={close}
            editInitialState={editInitialState}
          />
        </LoaderWrap>
      </Sidebar>
    </>
  );
}

type PropsEditSidebar = {
  role: {
    id: string;
    name: string;
    description: string;
  };
  close: () => void;
  editInitialState: StateItem[];
};

function EditSidebar({ role, close, editInitialState }: PropsEditSidebar) {
  const { state, updateState, updateRowState, updateColState } =
    usePermissionState(editInitialState);

  const { handleSubmit, register, formState, reset, setValue } = useForm({
    resolver: zodResolver(schema),
    defaultValues: role,
  });

  const [updateRole, loading] = useUpdateRole({
    onSuccess: () => {
      close();
      reset();
    },
  });

  const onSubmit = (formData: FormData) => {
    const input = {
      id: role.id ?? "",
      name: formData.name ?? "",
      description: formData.description,
      rules: prepareData(state) as RoleRuleInput[],
    };

    return updateRole(input);
  };

  useEffect(() => {
    setValue("name", role.name);
    setValue("description", role.description);
  }, [role.name, role.description, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup error={formState.errors["name"]}>
        <Label name="Name" htmlFor="name" required />
        <Input id="name" {...register("name")} />
      </FormGroup>
      <FormGroup error={formState.errors["description"]}>
        <Label name="Description" htmlFor="description" />
        <Input id="description" {...register("description")} />
      </FormGroup>
      <FormGroup>
        <Label name="Permissions" css={{ marginBottom: 0 }} />
        <Text size="sm" color={theme.color.text.text02}>
          Select permissions for the role.
        </Text>
        <PermissionSelect
          state={state}
          onUpdate={updateState}
          onUpdateRow={updateRowState}
          onUpdateCol={updateColState}
        />
      </FormGroup>
      <Spacer height={theme.spacing.spacing02} />
      <Button initialState={loading ? "loading" : "success"}>Apply</Button>
    </form>
  );
}
