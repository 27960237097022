/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

const StyledSVG = styled.svg`
  width: 131px;
  height: 40px;
  fill: ${theme.color.text.text01};
`;
export function Logo() {
  return (
    <StyledSVG>
      <path d="M2.2,12.94c-.09,0-.16-.03-.22-.08-.06-.06-.08-.13-.08-.22V4.84c0-.09,.03-.16,.08-.22,.06-.06,.13-.08,.22-.08h1.27c.09,0,.16,.03,.22,.08,.06,.06,.08,.13,.08,.22v2.74l2.47-2.81s.09-.1,.16-.15c.08-.05,.18-.08,.31-.08h1.45c.07,0,.13,.03,.18,.08,.05,.05,.07,.11,.07,.17,0,.06-.02,.1-.05,.14l-3.08,3.62,3.31,3.98s.05,.08,.05,.14-.02,.12-.07,.17c-.05,.05-.11,.08-.18,.08h-1.5c-.14,0-.25-.03-.32-.1-.07-.06-.12-.11-.14-.13l-2.65-3.07v3c0,.09-.03,.16-.08,.22s-.13,.08-.22,.08h-1.27Z" />
      <path d="M9.83,12.94c-.09,0-.16-.03-.22-.08s-.08-.13-.08-.22V4.72c0-.09,.03-.16,.08-.22,.06-.06,.13-.08,.22-.08h1.2c.08,0,.15,.03,.2,.08,.06,.06,.08,.13,.08,.22v7.92c0,.09-.03,.16-.08,.22s-.12,.08-.2,.08h-1.2Z" />
      <path d="M14.67,13.06c-.42,0-.8-.08-1.14-.25s-.6-.39-.8-.67c-.2-.28-.3-.59-.3-.94,0-.57,.23-1.02,.69-1.34s1.07-.55,1.82-.67l1.57-.23v-.22c0-.31-.07-.55-.22-.72-.14-.17-.4-.25-.76-.25-.26,0-.48,.05-.64,.16-.16,.1-.28,.24-.37,.41-.06,.1-.16,.16-.28,.16h-1.14c-.09,0-.16-.03-.2-.08-.05-.05-.07-.11-.06-.19,0-.14,.05-.3,.16-.48,.1-.18,.26-.37,.48-.55,.22-.18,.49-.33,.83-.45s.75-.18,1.24-.18,.94,.06,1.3,.18c.36,.12,.65,.29,.86,.5s.38,.47,.48,.75c.1,.28,.16,.59,.16,.92v3.71c0,.09-.03,.16-.08,.22-.06,.06-.13,.08-.22,.08h-1.18c-.08,0-.15-.03-.2-.08s-.08-.13-.08-.22v-.44c-.1,.15-.24,.29-.42,.43-.18,.13-.39,.24-.63,.32s-.53,.12-.86,.12Zm.48-1.25c.26,0,.5-.06,.71-.17s.37-.28,.49-.52c.12-.23,.18-.52,.18-.88v-.2l-1.12,.18c-.43,.07-.75,.18-.95,.32-.2,.14-.3,.32-.3,.53,0,.15,.05,.28,.14,.4,.09,.11,.21,.2,.37,.25s.31,.08,.48,.08Z" />
      <path d="M20.08,12.94c-.08,0-.15-.03-.2-.08s-.08-.13-.08-.22V7c0-.08,.03-.15,.08-.21,.06-.06,.12-.09,.2-.09h1.18c.08,0,.15,.03,.21,.09s.09,.13,.09,.21v.48c.2-.25,.45-.44,.74-.58,.29-.14,.63-.2,1.01-.2h.52c.08,0,.15,.03,.2,.08,.06,.06,.08,.13,.08,.22v1.03c0,.09-.03,.16-.08,.22s-.12,.08-.2,.08h-1.06c-.35,0-.63,.1-.83,.29-.2,.2-.3,.47-.3,.83v3.18c0,.09-.03,.16-.08,.22-.06,.06-.13,.08-.22,.08h-1.26Z" />
      <path d="M25.27,5.74c-.08,0-.15-.03-.2-.08s-.08-.13-.08-.22v-.96c0-.09,.03-.16,.08-.22,.06-.06,.12-.09,.2-.09h1.26c.09,0,.16,.03,.22,.09s.09,.13,.09,.22v.96c0,.09-.03,.16-.09,.22-.06,.06-.13,.08-.22,.08h-1.26Zm.04,7.2c-.08,0-.15-.03-.2-.08s-.08-.13-.08-.22V7c0-.09,.03-.16,.08-.22,.06-.06,.12-.08,.2-.08h1.2c.09,0,.16,.03,.22,.08,.06,.06,.08,.13,.08,.22v5.64c0,.09-.03,.16-.08,.22s-.13,.08-.22,.08h-1.2Z" />
      <path d="M31.3,12.94c-.5,0-.92-.08-1.27-.25-.35-.16-.62-.42-.8-.76-.18-.34-.27-.78-.27-1.3v-2.52h-.94c-.09,0-.16-.03-.22-.08s-.08-.13-.08-.22v-.82c0-.09,.03-.16,.08-.22,.06-.06,.13-.08,.22-.08h.94v-1.98c0-.09,.03-.16,.09-.22,.06-.06,.13-.08,.21-.08h1.18c.09,0,.16,.03,.22,.08s.08,.13,.08,.22v1.98h1.5c.08,0,.15,.03,.2,.08,.06,.06,.08,.13,.08,.22v.82c0,.09-.03,.16-.08,.22s-.12,.08-.2,.08h-1.5v2.38c0,.3,.05,.54,.16,.71,.11,.17,.3,.26,.57,.26h.88c.08,0,.15,.03,.2,.08,.06,.06,.08,.12,.08,.2v.89c0,.09-.03,.16-.08,.22s-.12,.08-.2,.08h-1.04Z" />
      <path d="M34.82,15.22c-.06,0-.12-.02-.17-.07-.05-.05-.08-.11-.08-.18,0-.02,0-.04,.01-.07,0-.03,.02-.06,.04-.1l.94-2.24-2.3-5.44c-.02-.06-.04-.12-.04-.16,0-.07,.04-.13,.08-.19s.11-.08,.18-.08h1.16c.1,0,.18,.03,.23,.08s.08,.11,.11,.16l1.49,3.72,1.52-3.72c.03-.06,.07-.12,.12-.17,.05-.05,.12-.07,.23-.07h1.14c.07,0,.13,.03,.19,.08,.05,.05,.08,.11,.08,.17,0,.04-.02,.1-.05,.17l-3.4,7.86c-.02,.06-.06,.12-.11,.17s-.13,.07-.23,.07h-1.13Z" />
      <path d="M2.23,32.32c-.15,0-.27-.05-.36-.15-.09-.1-.14-.22-.14-.35v-13c0-.15,.05-.27,.14-.36s.21-.14,.36-.14H11.42c.15,0,.27,.05,.36,.14,.09,.09,.14,.21,.14,.36v2.02c0,.15-.05,.27-.14,.36s-.21,.14-.36,.14H5.25v2.94h5.78c.15,0,.27,.05,.36,.15,.09,.1,.14,.22,.14,.37v2c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15H5.25v4.52c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15H2.23Z" />
      <path d="M14.37,32.32c-.15,0-.27-.05-.36-.15-.09-.1-.14-.22-.14-.35v-13.2c0-.15,.05-.27,.14-.36,.09-.09,.21-.14,.36-.14h2.36c.15,0,.27,.05,.36,.14s.14,.21,.14,.36v13.2c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15h-2.36Z" />
      <path d="M24.39,32.52c-1.57,0-2.83-.43-3.76-1.28s-1.42-2.12-1.46-3.8v-.7c.04-1.05,.28-1.96,.71-2.71,.43-.75,1.03-1.33,1.8-1.72s1.66-.59,2.69-.59c1.17,0,2.14,.23,2.91,.7,.77,.47,1.34,1.1,1.73,1.9s.58,1.71,.58,2.74v.48c0,.15-.05,.27-.14,.36s-.21,.14-.36,.14h-6.4v.14c.01,.39,.08,.74,.21,1.06,.13,.32,.31,.57,.56,.76,.25,.19,.55,.28,.91,.28,.27,0,.49-.04,.67-.13s.33-.19,.45-.3,.21-.21,.28-.29c.12-.15,.22-.24,.29-.27,.07-.03,.19-.05,.35-.05h2.48c.13,0,.24,.04,.33,.12s.12,.19,.11,.32c-.01,.23-.13,.5-.34,.82-.21,.32-.52,.64-.93,.95-.41,.31-.92,.57-1.53,.77-.61,.2-1.33,.3-2.14,.3Zm-1.7-6.46h3.38v-.04c0-.44-.06-.82-.19-1.13s-.32-.56-.58-.73c-.26-.17-.57-.26-.93-.26s-.65,.09-.9,.26c-.25,.17-.45,.42-.58,.73-.13,.31-.2,.69-.2,1.13v.04Z" />
      <path d="M36.27,32.52c-1.57,0-2.83-.43-3.76-1.28s-1.42-2.12-1.46-3.8v-.7c.04-1.05,.28-1.96,.71-2.71,.43-.75,1.03-1.33,1.8-1.72s1.66-.59,2.69-.59c1.17,0,2.14,.23,2.91,.7,.77,.47,1.34,1.1,1.73,1.9s.58,1.71,.58,2.74v.48c0,.15-.05,.27-.14,.36s-.21,.14-.36,.14h-6.4v.14c.01,.39,.08,.74,.21,1.06,.13,.32,.31,.57,.56,.76,.25,.19,.55,.28,.91,.28,.27,0,.49-.04,.67-.13s.33-.19,.45-.3,.21-.21,.28-.29c.12-.15,.22-.24,.29-.27,.07-.03,.19-.05,.35-.05h2.48c.13,0,.24,.04,.33,.12s.12,.19,.11,.32c-.01,.23-.13,.5-.34,.82-.21,.32-.52,.64-.93,.95-.41,.31-.92,.57-1.53,.77-.61,.2-1.33,.3-2.14,.3Zm-1.7-6.46h3.38v-.04c0-.44-.06-.82-.19-1.13s-.32-.56-.58-.73c-.26-.17-.57-.26-.93-.26s-.65,.09-.9,.26c-.25,.17-.45,.42-.58,.73-.13,.31-.2,.69-.2,1.13v.04Z" />
      <path d="M48.64,32.32c-.85,0-1.59-.14-2.21-.41s-1.1-.7-1.43-1.28c-.33-.58-.5-1.33-.5-2.25v-3.84h-1.56c-.15,0-.27-.05-.37-.14-.1-.09-.15-.21-.15-.36v-1.62c0-.15,.05-.27,.15-.36,.1-.09,.22-.14,.37-.14h1.56v-3.3c0-.15,.05-.27,.15-.36,.1-.09,.22-.14,.35-.14h2.32c.15,0,.27,.05,.36,.14s.14,.21,.14,.36v3.3h2.5c.15,0,.27,.05,.37,.14,.1,.09,.15,.21,.15,.36v1.62c0,.15-.05,.27-.15,.36-.1,.09-.22,.14-.37,.14h-2.5v3.56c0,.45,.09,.81,.26,1.08,.17,.27,.47,.4,.88,.4h1.54c.15,0,.27,.05,.36,.14,.09,.09,.14,.21,.14,.36v1.74c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15h-1.86Z" />
      <path d="M62.88,32.52c-1.25,0-2.33-.21-3.24-.62-.91-.41-1.61-1.02-2.11-1.82s-.78-1.79-.83-2.98c-.01-.55-.02-1.13-.02-1.76s0-1.23,.02-1.8c.05-1.16,.33-2.15,.84-2.96,.51-.81,1.21-1.43,2.12-1.84,.91-.41,1.98-.62,3.22-.62,.88,0,1.69,.11,2.44,.33,.75,.22,1.4,.54,1.96,.96s1,.93,1.31,1.52c.31,.59,.48,1.26,.49,2.01,.01,.12-.02,.22-.11,.3-.09,.08-.19,.12-.31,.12h-2.7c-.17,0-.31-.04-.4-.11-.09-.07-.17-.21-.24-.41-.19-.71-.49-1.19-.91-1.45-.42-.26-.94-.39-1.55-.39-.73,0-1.31,.2-1.74,.61-.43,.41-.66,1.08-.7,2.03-.04,1.09-.04,2.21,0,3.36,.04,.95,.27,1.62,.7,2.03,.43,.41,1.01,.61,1.74,.61,.61,0,1.13-.13,1.56-.4s.73-.75,.9-1.44c.05-.2,.13-.34,.23-.41,.1-.07,.24-.11,.41-.11h2.7c.12,0,.22,.04,.31,.12,.09,.08,.12,.18,.11,.3-.01,.75-.18,1.42-.49,2.01-.31,.59-.75,1.1-1.31,1.52s-1.21,.74-1.96,.96c-.75,.22-1.56,.33-2.44,.33Z" />
      <path d="M75.88,32.52c-1.12,0-2.06-.18-2.83-.54-.77-.36-1.35-.88-1.76-1.55-.41-.67-.64-1.48-.69-2.41-.01-.27-.02-.57-.02-.91s0-.64,.02-.89c.05-.95,.29-1.75,.72-2.42,.43-.67,1.02-1.18,1.79-1.54,.77-.36,1.69-.54,2.77-.54s2.02,.18,2.79,.54c.77,.36,1.36,.87,1.79,1.54,.43,.67,.67,1.47,.72,2.42,.01,.25,.02,.55,.02,.89s0,.64-.02,.91c-.05,.93-.28,1.74-.69,2.41-.41,.67-.99,1.19-1.76,1.55-.77,.36-1.72,.54-2.85,.54Zm0-2.44c.6,0,1.04-.18,1.31-.54,.27-.36,.43-.9,.47-1.62,.01-.2,.02-.47,.02-.8s0-.6-.02-.8c-.04-.71-.2-1.24-.47-1.61-.27-.37-.71-.55-1.31-.55s-1,.18-1.29,.55c-.29,.37-.44,.9-.47,1.61-.01,.2-.02,.47-.02,.8s0,.6,.02,.8c.03,.72,.18,1.26,.47,1.62,.29,.36,.72,.54,1.29,.54Z" />
      <path d="M83.68,32.32c-.15,0-.27-.05-.36-.15-.09-.1-.14-.22-.14-.35v-9.4c0-.15,.05-.27,.14-.36,.09-.09,.21-.14,.36-.14h2.3c.15,0,.27,.05,.37,.14,.1,.09,.15,.21,.15,.36v.76c.33-.41,.78-.76,1.34-1.04,.56-.28,1.22-.42,1.98-.42s1.46,.17,2.05,.52c.59,.35,1.06,.85,1.4,1.52,.34,.67,.51,1.49,.51,2.48v5.58c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15h-2.52c-.13,0-.25-.05-.35-.15-.1-.1-.15-.22-.15-.35v-5.46c0-.63-.15-1.11-.45-1.45-.3-.34-.74-.51-1.33-.51-.55,0-.98,.17-1.31,.51-.33,.34-.49,.82-.49,1.45v5.46c0,.13-.05,.25-.15,.35s-.22,.15-.35,.15h-2.5Z" />
      <path d="M101.26,32.32c-.85,0-1.59-.14-2.21-.41s-1.1-.7-1.43-1.28c-.33-.58-.5-1.33-.5-2.25v-3.84h-1.56c-.15,0-.27-.05-.37-.14-.1-.09-.15-.21-.15-.36v-1.62c0-.15,.05-.27,.15-.36,.1-.09,.22-.14,.37-.14h1.56v-3.3c0-.15,.05-.27,.15-.36,.1-.09,.22-.14,.35-.14h2.32c.15,0,.27,.05,.36,.14s.14,.21,.14,.36v3.3h2.5c.15,0,.27,.05,.37,.14,.1,.09,.15,.21,.15,.36v1.62c0,.15-.05,.27-.15,.36-.1,.09-.22,.14-.37,.14h-2.5v3.56c0,.45,.09,.81,.26,1.08,.17,.27,.47,.4,.88,.4h1.54c.15,0,.27,.05,.36,.14,.09,.09,.14,.21,.14,.36v1.74c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15h-1.86Z" />
      <path d="M105.78,32.32c-.15,0-.27-.05-.36-.15-.09-.1-.14-.22-.14-.35v-9.4c0-.13,.05-.25,.14-.35,.09-.1,.21-.15,.36-.15h2.3c.15,0,.27,.05,.37,.15,.1,.1,.15,.22,.15,.35v.8c.35-.41,.77-.73,1.27-.96s1.06-.34,1.69-.34h.88c.15,0,.27,.05,.36,.14s.14,.21,.14,.36v2.06c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15h-1.94c-.55,0-.97,.15-1.27,.45-.3,.3-.45,.72-.45,1.27v5.12c0,.13-.05,.25-.15,.35s-.22,.15-.37,.15h-2.48Z" />
      <path d="M119.2,32.52c-1.12,0-2.06-.18-2.83-.54-.77-.36-1.35-.88-1.76-1.55-.41-.67-.64-1.48-.69-2.41-.01-.27-.02-.57-.02-.91s0-.64,.02-.89c.05-.95,.29-1.75,.72-2.42,.43-.67,1.02-1.18,1.79-1.54,.77-.36,1.69-.54,2.77-.54s2.02,.18,2.79,.54c.77,.36,1.36,.87,1.79,1.54,.43,.67,.67,1.47,.72,2.42,.01,.25,.02,.55,.02,.89s0,.64-.02,.91c-.05,.93-.28,1.74-.69,2.41-.41,.67-.99,1.19-1.76,1.55-.77,.36-1.72,.54-2.85,.54Zm0-2.44c.6,0,1.04-.18,1.31-.54,.27-.36,.43-.9,.47-1.62,.01-.2,.02-.47,.02-.8s0-.6-.02-.8c-.04-.71-.2-1.24-.47-1.61-.27-.37-.71-.55-1.31-.55s-1,.18-1.29,.55c-.29,.37-.44,.9-.47,1.61-.01,.2-.02,.47-.02,.8s0,.6,.02,.8c.03,.72,.18,1.26,.47,1.62,.29,.36,.72,.54,1.29,.54Z" />
      <path d="M127,32.32c-.15,0-.27-.05-.36-.15-.09-.1-.14-.22-.14-.35v-13.2c0-.15,.05-.27,.14-.36,.09-.09,.21-.14,.36-.14h2.36c.15,0,.27,.05,.36,.14s.14,.21,.14,.36v13.2c0,.13-.05,.25-.14,.35-.09,.1-.21,.15-.36,.15h-2.36Z" />
    </StyledSVG>
  );
}
