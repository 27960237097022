/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { NotificationGroup } from "~/generated/graphql";
import { createCtx } from "~/tools";

type Props = {
  data?: NotificationGroup;
};

export const [useNotificationGroup, NotificationGroupCtxProvider] =
  createCtx<Props>();
