/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ToggleItemBox } from "~/components";
import { PlanActionTypeExtended, usePlan } from "../../PlanProvider";

export function ActionItem({
  entity,
  onMouseDown,
  editName,
}: {
  entity: PlanActionTypeExtended;
  onMouseDown: () => void;
  editName?: string | JSX.Element;
}) {
  const { planAction } = usePlan();

  return (
    <ToggleItemBox
      item={{
        id: entity.id ?? "",
        color: entity?.action?.color ?? "",
        name: editName ?? "",
        onMouseDown,
      }}
      active={planAction?.listId === entity.listId}
    />
  );
}
