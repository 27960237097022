/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";

export const BorderWrapper = styled.div`
  border-bottom: 1px solid ${theme.color.border.border01};
  margin-bottom: ${theme.spacing.spacing04};
`;
