/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Input, theme, FlexContainer } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { EditButtons } from "~/components/Details/InlineEdit/EditButtons";
import { getFirstItem } from "~/tools";
import { PlanEntityNameSchema } from "~/views/plans/schemas";
import { PlanField } from "../../../constants";
import { usePlanWizard } from "../../../PlanProvider";
import { PlanEntity, SelectedPlanEntity } from "../../../types";
import { useStepState } from "../../StepProvider";
import { updateEntities } from "../utils";

export function PlanActionNameInput({
  item,
  handlePlanErrors,
  onEditComplete,
}: {
  item: PlanEntity;
  handlePlanErrors: () => void;
  onEditComplete: () => void;
}) {
  const {
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    handleSubmit,
    register,
    watch,
  } = useFormContext();

  const { setPlanData } = usePlanWizard();

  const { stepState, updateStepState } = useStepState();

  const updateSelectedAction = (updateObject: Partial<SelectedPlanEntity>) => {
    const updatedEntities = updateEntities(stepState, updateObject);
    updateStepState({
      entities: updatedEntities,
    });
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: updatedEntities,
      },
    }));
  };

  const handleActionError = (name: string, listId?: string) => {
    if (!listId) {
      return;
    }

    const isValid = PlanEntityNameSchema.safeParse(name);
    if (!isValid.success) {
      setError(listId, {
        message: getFirstItem(isValid.error.issues).message,
      });
    } else {
      clearErrors(listId);
    }
  };

  const onSubmit = (data: { [key: string]: string }) => {
    if (item.listId) {
      const newName = data[item.listId].trim();
      updateSelectedAction({ name: newName });
    }
    handlePlanErrors();
    onEditComplete();
  };

  if (!item.listId) {
    return null;
  }

  const currentName = watch(item.listId);

  return (
    <FormGroup
      error={errors[item.listId]}
      customCss={{
        marginBottom: theme.spacing.spacing03,
        marginTop: theme.spacing.spacing03,
      }}
    >
      <FlexContainer alignItems="center">
        <Input
          id={item.listId}
          defaultValue={item.name}
          {...register(item.listId, {
            onChange: (e) => {
              handleActionError(e.target.value, item.listId);
              handlePlanErrors();
            },
            onBlur: () => {
              trigger();
            },
          })}
          small
        />
        <EditButtons
          onClose={onEditComplete}
          onSave={() => {
            if (currentName !== item.name) {
              handleSubmit(onSubmit)();
            } else {
              onEditComplete();
            }
          }}
        />
      </FlexContainer>
    </FormGroup>
  );
}
