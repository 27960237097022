/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";
import { safeStr, stringRequired } from "~/components/Forms";

export const schema = z.object({
  name: stringRequired("Name").max(128, { message: "Name is too long" }),
  description: safeStr.optional(),
});
