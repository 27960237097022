/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Button } from "@nordcloud/gnui";
import { useDisclosure } from "~/hooks";
import { DeleteCustomAction } from "./DeleteCustomAction/DeleteCustomAction";

type Props = {
  actionId: string;
  onSuccess?: () => void;
  size?: "large" | "small";
};

export function Action({ actionId }: Props) {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useDisclosure(false);

  return (
    <>
      <Button
        size="md"
        severity="low"
        aria-label="delete custom action"
        icon="trash"
        onClick={openDeleteModal}
      />
      <When condition={isDeleteModalOpen}>
        <DeleteCustomAction
          {...{
            actionId: actionId,
            isDeleteModalOpen: isDeleteModalOpen,
          }}
          close={closeDeleteModal}
        />
      </When>
    </>
  );
}
