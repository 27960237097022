/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import {
  MediumIconColumn,
  FlexColumnLarge,
  FlexColumn,
  SmallColumn,
} from "~/components/Tables/styles";
import { ROUTES } from "~/routing/routes";
import { Action } from "./Action";
import { getScope } from "./types";

export type CustomActions = {
  id: string;
  name: string;
  color: string;
  scope: string;
  description: string;
};

const columnHelper = createColumnHelper<CustomActions>();

export function columns() {
  return [
    columnHelper.accessor(() => null, {
      id: "color",
      header: () => null,
      cell: ({ row }) => {
        return (
          <FlexContainer justifyContent="center" alignItems="center">
            <ColorBox
              css={{ alignItems: "center" }}
              color={row.original?.color}
            />
          </FlexContainer>
        );
      },
      meta: MediumIconColumn,
    }),
    columnHelper.accessor((row) => row?.name, {
      header: "Action Name",
      cell: ({ row }) => (
        <NameWithLink
          name={row.original.name}
          link={ROUTES.customActions.generateDetails(row.original.id)}
        />
      ),
      meta: FlexColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "description",
      header: "Description",
      cell: ({ row }) => {
        return <Text>{row.original.description}</Text>;
      },
      meta: FlexColumnLarge,
    }),
    columnHelper.accessor(() => null, {
      id: "scope",
      header: "Scope",
      cell: ({ row }) => {
        return <Text>{getScope(row.original.scope)}</Text>;
      },
      meta: SmallColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const actionId = row.original?.id;

        return <Action {...{ actionId }} />;
      },
      meta: MediumIconColumn,
    }),
  ] as never;
}

const ColorBox = styled.div`
  background: ${(props) => (props.color ? props.color : theme.colors.darks[4])};
  width: ${theme.spacing.spacing06};
  height: ${theme.spacing.spacing06};
  border-radius: ${theme.spacing.spacing01};
`;
