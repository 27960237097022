/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
  FieldValues,
} from "react-hook-form";
import { Button, Input, Text, theme, FlexContainer } from "@nordcloud/gnui";
import { FormGroup } from "../../Forms/FormGroup";

type Props = {
  index: number;
  remove: UseFieldArrayRemove;
};

function MetadataItem({ index, remove }: Props) {
  const { register, formState } = useFormContext();
  const error = formState.errors[`metadata`] as FieldValues[] | undefined;
  return (
    <>
      <FlexContainer
        justifyContent="flex-start"
        alignContent="flex-start"
        gap="1rem"
      >
        <FormGroup error={error?.[index]?.key}>
          <Input
            aria-label="provide key"
            placeholder="Provide Tag Key"
            {...register(`metadata.${index}.key` as const)}
          />
        </FormGroup>
        <FormGroup error={error?.[index]?.value}>
          <Input
            aria-label="provide value"
            placeholder="Provide Tag Value"
            {...register(`metadata.${index}.value` as const)}
          />
        </FormGroup>
        <Button
          onClick={() => remove(index)}
          type="button"
          severity="low"
          icon="trash"
          mb={theme.spacing.spacing05}
        />
      </FlexContainer>
    </>
  );
}

export function Metadata() {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "metadata",
  });

  return (
    <>
      <Text mb={0}>Metadata</Text>
      <Text
        size="sm"
        color={theme.color.text.text02}
        mb={theme.spacing.spacing01}
      >
        Metadata are tags with key-value labels. They help organize and manage
        resources.
      </Text>
      {fields.map((field, index) => {
        return <MetadataItem key={field.id} {...{ index, remove }} />;
      })}
      <Button
        onClick={() => append({ key: "", value: "" })}
        type="button"
        severity="low"
        icon="plus"
      >
        Add Metadata
      </Button>
    </>
  );
}
