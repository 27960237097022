/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import {
  Box,
  Button,
  FlexContainer,
  StickyBar,
  Text,
  theme,
} from "@nordcloud/gnui";

type Props = {
  openEditMode: () => void;
  closeEditMode: () => void;
  isEditMode: boolean;
  hasUnsavedChanges: boolean;
};

export function GeneralNotificationTabBar({
  openEditMode,
  closeEditMode,
  isEditMode,
  hasUnsavedChanges,
}: Props) {
  const handleEditMode = () => {
    openEditMode();
  };

  return (
    <>
      <StickyBar css={{ backgroundColor: "transparent" }}>
        <Row>
          <Col>
            <Box boxStyle="lightGrey" mb={theme.spacing.spacing06}>
              <FlexContainer justifyContent="flex-end">
                <If condition={isEditMode}>
                  <Then>
                    <FlexContainer>
                      <When condition={hasUnsavedChanges}>
                        <Text
                          mr={theme.spacing.spacing08}
                          tag="span"
                          color={theme.color.text.error}
                        >
                          There are unsaved changes.
                        </Text>
                      </When>
                      <Button
                        type="submit"
                        form="planGeneralNotificationForm"
                        icon="save"
                      >
                        Save
                      </Button>
                      <Button
                        severity="low"
                        ml={theme.spacing.spacing04}
                        icon="close"
                        onClick={closeEditMode}
                      />
                    </FlexContainer>
                  </Then>
                  <Else>
                    <Button icon="edit" onClick={handleEditMode}>
                      Edit Notifications
                    </Button>
                  </Else>
                </If>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </StickyBar>
    </>
  );
}
