/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "@nordcloud/gnui";
import { useGenerateDatesFromCron } from "~/views/plans/hooks";
import {
  PlanData,
  PlanField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import {
  adjustInvalidCronMessage,
  convertToMinutes,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanScheduleForms/utils";
import {
  CronScheduleState,
  FormField,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";

type Props = {
  state: CronScheduleState;
  updateState: Dispatch<Partial<CronScheduleState>>;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function CronExpressionInput({
  state,
  updateState,
  setPlanData,
}: Props) {
  const { control, setError, clearErrors } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    onSuccess: (dates) => {
      updateState({ cronNextDates: dates });
    },
  });

  const offsetInMinutes = convertToMinutes(state.offset, state.offsetUnit);

  const generateCronDates = async (value: string) => {
    const result = await generateDatesFromCron({
      cron_expression: value,
      location: state.timezone,
      offset_in_minutes: offsetInMinutes,
    });

    const invalidCronMessage =
      result.data?.generateDatesFromCron.validationMsg ||
      result.data?.generateDatesFromCron.error;

    if (invalidCronMessage) {
      setError(FormField.SCHEDULE_CRON, {
        message: adjustInvalidCronMessage(invalidCronMessage),
      });

      return;
    }

    clearErrors(FormField.SCHEDULE_CRON);
  };

  const handleChange = (cronExpression: string) => {
    updateState({ cronExpression: cronExpression });

    if (cronExpression) {
      generateCronDates(cronExpression);
    }

    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_CRON]: cronExpression,
      },
    }));
  };

  return (
    <Controller
      name={FormField.SCHEDULE_CRON}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Input
          type="text"
          id={FormField.SCHEDULE_CRON}
          name={FormField.SCHEDULE_CRON}
          value={state.cronExpression || value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
            handleChange(event.target.value);
          }}
          placeholder={CRON_INPUT_PLACEHOLDER}
        />
      )}
    />
  );
}

const CRON_INPUT_PLACEHOLDER = "Cron expression...";
