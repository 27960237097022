/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box } from "@nordcloud/gnui";
import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { ApiKeysCreateSidebar } from "~/views/permissions/APIKeys/components/ApiKeysCreateSidebar";
import { ApiKeysTable } from "~/views/permissions/APIKeys/components/ApiKeysTable";

export function APIKeysPage() {
  const breadcrumbsLabels = [
    { label: "Dashboard", uri: "/" },
    { label: "API Keys", uri: ROUTES.permissions.apiKeys },
  ];

  return (
    <>
      <Row>
        <Col>
          <BreadcrumbsBox
            title="API Keys"
            customBreadcrumbs={breadcrumbsLabels}
          >
            <ApiKeysCreateSidebar />
          </BreadcrumbsBox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Box>
            <ApiKeysTable />
          </Box>
        </Col>
      </Row>
    </>
  );
}
