/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch } from "react";
import { createCtx } from "~/tools";
import { ReportState } from "./types";

type ProviderProps = {
  reportState: ReportState;
  updateReportState: Dispatch<Partial<ReportState>>;
};

export const [useReportState, ReportStateCtxProvider] =
  createCtx<ProviderProps>();
