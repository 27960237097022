/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme, Text } from "@nordcloud/gnui";

export const SwitchLabel = styled.div`
  margin-left: ${theme.spacing.spacing04};
`;

export const InputHeadline = styled(Text)`
  margin: ${theme.spacing.spacing06} 0 ${theme.spacing.spacing00}
    ${theme.spacing.spacing03};
`;
