/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Table,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  EventDetailResourceGroupFragment,
  EventStatus,
  IncomingEventStatus,
} from "~/generated/graphql";
import { StyledTd } from "~/components/Tables/styles";
import { ROUTES } from "~/routing/routes";
import { isNotNil } from "~/tools";
import { getEventStatus } from "~/views/events/helpers";
import { useGetResourceGroup } from "~/views/resourceGroups/hooks";
import { OSCell } from "~/views/resources/components/OSCell";

type Props = {
  resourceGroup: EventDetailResourceGroupFragment;
  status?: EventStatus | IncomingEventStatus;
  actions?: () => JSX.Element;
};

export function ResourceGroupItem({ resourceGroup, actions, status }: Props) {
  const { resourceGroup: group } = useGetResourceGroup({
    resourceGroupId: resourceGroup.id,
  });

  const staticResources =
    group?.resourceSelectors?.flatMap((item) => item?.resource ?? []) ?? [];

  const dynamicResources =
    group?.resourceSelectors?.flatMap((item) => item?.dynamicResources ?? []) ??
    [];

  const resources = [...staticResources, ...dynamicResources];

  return (
    <Box innerSpacing="spacing00">
      <StyledBox padding={theme.spacing.spacing03}>
        <FlexContainer>
          <When condition={isNotNil(status)}>
            <ExtendedTooltip
              caption={getEventStatus(status)?.description}
              position="start"
            >
              <SVGIcon
                name={getEventStatus(status)?.icon}
                css={{ marginLeft: "0", minWidth: theme.spacing.spacing04 }}
                color={getEventStatus(status)?.color()}
              />
            </ExtendedTooltip>
          </When>
          <Text tag="div" weight="medium" ml={theme.spacing.spacing03}>
            <Link
              to={`${ROUTES.resourceGroups.generateDetails(resourceGroup.id)}`}
            >
              {resourceGroup?.name}
            </Link>
            <Text tag="div" size="sm" color={theme.color.text.text02}>
              {resourceGroup.id}
            </Text>
          </Text>
          <div css={{ marginLeft: "auto" }}>{actions?.()}</div>
        </FlexContainer>
      </StyledBox>
      <Table>
        <Table.tbody>
          {resources?.map((resource) => {
            const os = {
              subType: resource?.os?.subType ?? "",
              type: resource?.os?.type ?? "",
            };

            return (
              <Table.tr key={resource?.id}>
                <StyledTd css={{ width: "2%" }}>
                  <When condition={isNotNil(status)}>
                    <ExtendedTooltip
                      caption={getEventStatus(status)?.description}
                    >
                      <SVGIcon
                        name={getEventStatus(status)?.icon}
                        color={getEventStatus(status)?.color()}
                      />
                    </ExtendedTooltip>
                  </When>
                </StyledTd>
                <StyledTd css={{ width: "2%" }}>
                  <OSCell os={os} />
                </StyledTd>
                <Table.td>
                  <FlexContainer direction="column" alignItems="flex-start">
                    <Link
                      to={`${ROUTES.resources.generateDetails(
                        resource?.id ?? ""
                      )}`}
                    >
                      {resource?.name}
                    </Link>
                    <Text size="sm" color={theme.color.text.text02}>
                      {resource?.name}
                    </Text>
                  </FlexContainer>
                </Table.td>
              </Table.tr>
            );
          })}
        </Table.tbody>
      </Table>
    </Box>
  );
}

const StyledBox = styled(Box)`
  border-bottom: 2px solid ${theme.color.border.border01};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;
