/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import {
  Box,
  Button,
  FlexContainer,
  Modal,
  StickyBar,
  theme,
} from "@nordcloud/gnui";
import {
  BatchAction,
  ExecutionPolicy,
  PlanAction,
  PlanActionNotificationTriggerType,
  PlanActionType,
} from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { showError, showSuccess } from "~/services/toast";
import {
  generateActionSuccessText,
  isNotEmpty,
  isNotNil,
  sortByOrder,
} from "~/tools";
import { useUpdatePlan } from "~/views/plans/hooks/useUpdatePlan/useUpdatePlan";
import {
  PlanField,
  maxInAdvance,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import {
  SelectedAction,
  SelectedPlanEntity,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { convertToMinutes, isAdvanceTrigger } from "~/views/plans/utils";
import { PlanActionTypeExtended, usePlan } from "../../PlanProvider";
import {
  mapPlanActionBatchToPlanEntity,
  mapPlanActionToPlanEntity,
} from "./utils";

type Props = {
  openEditMode: () => void;
  closeEditMode: () => void;
  isEditMode: boolean;
};

export function DetailsTabBar({
  openEditMode,
  closeEditMode,
  isEditMode,
}: Props) {
  const { plan, setPlanAction, planAction } = usePlan();

  const planActions = plan?.planActions ?? [];
  const planActionBatches = plan?.planActionBatches ?? [];

  const planActionsIds = planActions.map((item) => item.id);
  const planActionBatchesIds = planActionBatches.map((item) => item.id);

  const { setPlanData, planData, setSelectedEntity, selectedEntity } =
    usePlanWizard();

  type Ordered = SelectedPlanEntity & { order: number };

  const isActionBatch = (entity: Ordered) => isNotNil(entity?.batchActions);

  const editedPlanEntities = planData?.[PlanField.PLAN_SETTINGS]?.planEntities;

  const orderedPlanActionEntities: Ordered[] =
    editedPlanEntities?.map((entity, index) => {
      return {
        ...entity,
        order: index + 1,
      };
    }) ?? [];

  const editedPlanActions = orderedPlanActionEntities?.filter(
    (entity) => !isActionBatch(entity)
  );
  const editedPlanActionBatches = orderedPlanActionEntities?.filter(
    (entity) => {
      return isActionBatch(entity);
    }
  );

  const { isOpen, open, close } = useDisclosure();

  const editedPlanEntitiesIds =
    editedPlanEntities?.map((entity) => entity.id) ?? [];

  const success = () => {
    showSuccess(generateActionSuccessText("Plan")()("updated")());
  };

  const [updatePlan] = useUpdatePlan({
    onSuccess: success,
  });

  type MapCommonPlanActionFieldsProps = {
    entity: Ordered | SelectedAction;
    order: number;
    entityBeforeUpdate?: PlanAction | BatchAction;
    newEntity?: SelectedPlanEntity;
  };

  const mapCommonPlanActionFields = ({
    entity,
    order,
    entityBeforeUpdate,
    newEntity,
  }: MapCommonPlanActionFieldsProps) => {
    const resourceGroupIds = entity?.resourceGroupIds;

    const addResourceGroup = resourceGroupIds?.filter(
      (groupId: string) =>
        !entityBeforeUpdate?.resourceGroups
          ?.map((groupItem) => groupItem.id)
          .includes(groupId)
    );

    const deleteResourceGroup = entityBeforeUpdate?.resourceGroups
      ?.map((groupItem) => groupItem.id)
      ?.filter((groupId: string) => !resourceGroupIds?.includes(groupId));

    const inputParams = entity?.inputParameters?.map(({ key, value }) => ({
      key,
      value,
    }));

    const editableNotificationGroupIds =
      entityBeforeUpdate?.notificationGroups?.map((groupItem) => groupItem.id);

    const newNotificationGroupIds = entity?.notificationGroups?.map(
      (groupItem) => groupItem.id
    );

    const deleteNotificationGroupsFromList =
      editableNotificationGroupIds?.filter(
        (groupId: string) => !newNotificationGroupIds?.includes(groupId)
      ) ?? [];

    const deleteNotificationGroupsFromNestedGroup: string[] =
      entity?.notificationGroups
        ?.reduce<string[]>((acc, item) => {
          const matchingIds = entityBeforeUpdate?.notificationGroups?.reduce<
            string[]
          >((accInner, notification) => {
            if (
              item.id === notification.id &&
              !item.notificationGroupIds?.includes(
                notification.notificationGroup.id
              ) &&
              JSON.stringify(item) !== JSON.stringify(notification)
            ) {
              return [...accInner, notification.id];
            }
            return accInner;
          }, []);
          return [...acc, ...(matchingIds || [])];
        }, [])
        ?.filter((item) => item !== undefined) ?? [].flat();

    const deleteNotificationGroups = [
      ...deleteNotificationGroupsFromList,
      ...deleteNotificationGroupsFromNestedGroup,
    ];

    const notificationGroups = entity?.notificationGroups
      ?.map((group) => {
        const inAdvance = isAdvanceTrigger(group.triggerEvent)
          ? convertToMinutes(
              `${group.inAdvance ?? ""}`,
              group.inAdvanceUnit ?? TimeUnits.minutes
            )
          : 0;
        return (
          group.notificationGroupIds
            ?.filter((item) => item !== undefined)
            ?.map((groupId) => ({
              id: group.id ? group.id : undefined,
              notificationGroupId: groupId,
              scope: group.scope,
              triggerEvent:
                group.triggerEvent ??
                PlanActionNotificationTriggerType.ResourcesError,
              // TODO move maxSignedInt to schema validation
              inAdvance: inAdvance > maxInAdvance ? maxInAdvance : inAdvance,
            })) ?? []
        );
      })
      .flat();

    return {
      id: newEntity ? undefined : entity?.id,
      action: newEntity && {
        actionId: newEntity?.id ?? "",
        actionType: newEntity?.actionType ?? PlanActionType.System,
      },
      name: entity.name,
      order: order,
      inputParameters: inputParams,
      resourceGroupIds: addResourceGroup ?? undefined,
      resourceGroupIdsToDelete: deleteResourceGroup ?? undefined,

      notificationGroups: notificationGroups,
      notificationGroupsToDelete: deleteNotificationGroups ?? [],
    };
  };

  const mapPlanEntityToActionBatch = (actionBatch: Ordered) => {
    const newPlanActionBatches = editedPlanActionBatches?.filter(
      (entityItem) => !planActionBatchesIds.includes(entityItem.id ?? "")
    );

    const newActionBatch = newPlanActionBatches?.find(
      (item) => item.id === actionBatch?.id
    );

    const oldPlanActionBatch = planActionBatches?.find(
      (item) => item.id === actionBatch.id
    );

    const oldBatchActions = oldPlanActionBatch?.batchActions ?? [];

    const oldBatchActionIds = oldBatchActions.map((item) => item.id) ?? [];

    const editedBatchActions = actionBatch?.batchActions ?? [];
    const editedBatchActionsIds =
      editedBatchActions.map((item) => item.id) ?? [];

    const deletedBatchActions = oldBatchActionIds.filter(
      (batchActionId) => !editedBatchActionsIds.includes(batchActionId)
    );

    return {
      id: newActionBatch ? undefined : actionBatch?.id,
      name: actionBatch.name,
      order: actionBatch.order,
      windowDuration: convertToMinutes(
        actionBatch.windowDuration?.toString() ?? "",
        actionBatch?.unit ?? TimeUnits.minutes
      ),
      skipWindow: actionBatch.skipWindow,
      executionPolicy: actionBatch.executionPolicy,
      batchActions: actionBatch.batchActions?.map(
        (batchAction, batchActionIndex) => {
          const batchActionBeforeUpdate = oldBatchActions?.find(
            (item) => item.id === batchAction?.id
          );

          const newBatchActions = editedBatchActions?.filter(
            (entityItem) => !oldBatchActionIds.includes(entityItem.id ?? "")
          );

          const newBatchAction = newBatchActions?.find(
            (item) => item.id === batchAction?.id
          );

          return mapCommonPlanActionFields({
            entity: batchAction,
            order: batchActionIndex + 1,
            entityBeforeUpdate: batchActionBeforeUpdate,
            newEntity: newBatchAction,
          });
        }
      ),
      batchActionsToDelete: deletedBatchActions,
    };
  };

  const mapPlanEntityToPlanAction = (entity: Ordered) => {
    const planActionBeforeUpdate = planActions?.find(
      (item) => item.id === entity?.id
    );

    const newPlanActions = editedPlanActions?.filter(
      (entityItem) => !planActionsIds.includes(entityItem.id ?? "")
    );

    const newPlanAction = newPlanActions?.find(
      (item) => item.id === entity?.id
    );

    return {
      ...mapCommonPlanActionFields({
        entity,
        order: entity.order,
        entityBeforeUpdate: planActionBeforeUpdate,
        newEntity: newPlanAction,
      }),
      executionPolicy: entity?.executionPolicy,
      runInSequence: entity.runInSequence,
      skipWindow: entity.skipWindow,
      windowDuration: convertToMinutes(
        entity.windowDuration?.toString() ?? "",
        entity?.unit ?? TimeUnits.minutes
      ),
    };
  };

  const updatedActions =
    editedPlanActions?.map((entity) => {
      return mapPlanEntityToPlanAction(entity);
    }) ?? [];

  const updatedActionBatches = editedPlanActionBatches?.map((entity) => {
    return mapPlanEntityToActionBatch(entity);
  });

  type UpdatedActionsType = typeof updatedActions;

  const changeFirstActionPolicyFromSuccessOrApproval = (
    actions: UpdatedActionsType
  ) => {
    if (actions && actions.length > 0) {
      const firstAction = actions[0];
      const isFirstEntity = firstAction.order === 1;
      if (
        isFirstEntity &&
        firstAction?.executionPolicy === ExecutionPolicy.SuccessOrApproval
      ) {
        const modifiedFirstAction = {
          ...firstAction,
          executionPolicy: ExecutionPolicy.Anyway,
        };
        return [modifiedFirstAction, ...actions.slice(1)];
      }
    }
    return actions;
  };

  const handlePlanErrors = () => {
    showError(
      <div>
        {"Please verify plan settings:"}
        {(planData?.[PlanField.ERRORS] ?? []).map((error) => {
          return <div key={error}> {`- ${error}`}</div>;
        })}
      </div>
    );
  };

  const editablePlanActions =
    plan?.planActions?.map(mapPlanActionToPlanEntity) ?? [];

  const editablePlanActionBatches =
    plan?.planActionBatches?.map(mapPlanActionBatchToPlanEntity) ?? [];

  const editablePlanEntities = sortByOrder<PlanActionTypeExtended>([
    ...editablePlanActions,
    ...editablePlanActionBatches,
  ]);

  const setSelectedActionOnClose = () => {
    const editableEntitiesFlat = editablePlanEntities.flatMap((entity) => {
      if (entity.batchActions) {
        return [entity, ...entity.batchActions];
      }
      return entity;
    });

    if (
      !editableEntitiesFlat?.map((i) => i?.id).includes(selectedEntity ?? "")
    ) {
      setSelectedEntity?.(
        editablePlanEntities[editablePlanEntities.length - 1]?.listId
      );
    }
  };

  const deletedPlanActions = planActionsIds.filter(
    (actionId) => !editedPlanEntitiesIds.includes(actionId)
  );

  const deletedPlanActionBatches = planActionBatchesIds.filter(
    (actionBatchId) => !editedPlanEntitiesIds.includes(actionBatchId)
  );

  const handleSavePlan = () => {
    if (isNotEmpty(planData?.[PlanField.ERRORS] ?? [])) {
      handlePlanErrors();
      return;
    }
    const modifiedFirstAction =
      changeFirstActionPolicyFromSuccessOrApproval(updatedActions);

    updatePlan({
      id: plan?.id ?? "",
      planActions: modifiedFirstAction,
      planActionBatches: updatedActionBatches,
      planActionsToDelete: deletedPlanActions,
      planActionBatchesToDelete: deletedPlanActionBatches,
    });

    setSelectedActionOnClose();
    closeEditMode();
  };

  const handleDiscardChanges = () => {
    setSelectedActionOnClose();
    closeEditMode();
    close();
  };

  const handleSaveChanges = () => {
    handleSavePlan();
    closeEditMode();
    close();
  };

  const handleEditMode = () => {
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planEntities: editablePlanEntities,
      },
    }));

    setPlanAction?.(planAction);

    openEditMode();
  };

  return (
    <>
      <StickyBar css={{ backgroundColor: "transparent" }}>
        <Row>
          <Col>
            <Box boxStyle="lightGrey" mb={theme.spacing.spacing06}>
              <FlexContainer justifyContent="flex-end">
                <If condition={isEditMode}>
                  <Then>
                    <FlexContainer>
                      <Button
                        form="planSettingsForm"
                        icon="save"
                        onClick={handleSavePlan}
                      >
                        Save
                      </Button>
                      <Button
                        severity="low"
                        ml={theme.spacing.spacing04}
                        icon="close"
                        onClick={open}
                      />
                    </FlexContainer>
                  </Then>
                  <Else>
                    <Button icon="edit" onClick={handleEditMode}>
                      Edit Plan
                    </Button>
                  </Else>
                </If>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </StickyBar>
      <Modal
        onClose={close}
        isOpen={isOpen}
        contentLabel={"Unsaved changes"}
        actions={[
          {
            order: 0,
            onAction: handleDiscardChanges,
            label: "Discard Changes",
            severity: "low",
          },
          {
            order: 1,
            onAction: handleSaveChanges,
            label: "Save",
            severity: "high",
          },
        ]}
      >
        There could be unsaved changes!
      </Modal>
    </>
  );
}
