/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Dropdown } from "@nordcloud/gnui";
import { FormField, TimeUnits } from "../../../types";
import { convertStringToTimeUnit } from "../utils";
import { units } from "./options";

type Props = {
  handleChange: (
    formUnit: FormField.UNIT,
    onChange: () => void,
    val: string
  ) => void;
  setDurationUnit: Dispatch<SetStateAction<TimeUnits>>;
};

export function DropDownUnit({ handleChange, setDurationUnit }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={FormField.UNIT}
      render={({ field: { onChange, value } }) => {
        const handleUnitChange = (val: string) => {
          const timeUnit = convertStringToTimeUnit(val);

          if (timeUnit !== undefined) {
            handleChange(FormField.UNIT, onChange, timeUnit);
            setDurationUnit(timeUnit);
          }
        };

        return (
          <Dropdown
            name="Select Delay Unit"
            options={units}
            onChange={handleUnitChange}
            value={value}
            minNumOfOptionsToShowSearchBox={10}
          />
        );
      }}
    />
  );
}
