/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon, Box, theme, ExtendedTooltip, Text } from "@nordcloud/gnui";
import { getProviderIcon } from "~/utils";
import {
  getAccountColorContext,
  getAccountStatusIconName,
  getAccountStatusMessage,
} from "~/views/accounts/utils";

type Props = {
  provider: string;
  status: string;
};

export function AccountDetailsStatus({ provider, status }: Props) {
  return (
    <>
      <Wrap>
        <ExtendedTooltip
          status={getAccountColorContext(status)}
          caption={
            <Text tag="span" size="sm" color={theme.color.text.text04}>
              {getAccountStatusMessage(status)}
            </Text>
          }
          position="start"
        >
          <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
            <SVGIcon
              size="lg"
              color={getAccountColorContext(status)}
              name={getAccountStatusIconName(status)}
            />
          </Box>
        </ExtendedTooltip>
      </Wrap>
      <Wrap>
        <ExtendedTooltip caption={provider} position="start">
          <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
            <SVGIcon size="lg" name={getProviderIcon(provider ?? "")} />
          </Box>
        </ExtendedTooltip>
      </Wrap>
    </>
  );
}

const Wrap = styled.div`
  margin-right: ${theme.spacing.spacing03};
`;
