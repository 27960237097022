/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const AWS_INFO_ONBOARDING = `
For on-boarding process, each account needs to have an IAM role created with read only permissions and trust relationship set to Nordcloud’s AWS account. For your convenience, please use provided Cloudformation template. There is no requirement for deployment region, but we recommend eu-west-1.`;

export const AZURE_INFO_ONBOARDING = `To on-board a subscription you need to create a Service Principal.`;
export const AZURE_DOWNLOAD_SCRIPT_ERR = `Azure onboarding script could not be generated.`;
export const AZURE_SUB_PLACEHOLDER = "291bba3f-e0a5-47bc-a099-3dbcb2a50a05";

export const URL_AWS_CONSOLE = "https://console.aws.amazon.com/console/home";
export const URL_AZURE_CONSOLE = "https://portal.azure.com";

export const AWS_INFO_EXTERNAL_ID = `For security reasons External ID is forced to be a tenant ID and shouldn't be modified by the user.`;
export const URL_AWS_EXTERNAL_IDS =
  "https://aws.amazon.com/blogs/security/how-to-use-external-id-when-granting-access-to-your-aws-resources/";
export const AWS_DOWNLOAD_TEMPLATE_ERR = `AWS onboarding template could not be generated.`;
