/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { Text, theme } from "@nordcloud/gnui";

type Props = {
  children: ReactNode;
};

export function Description({ children }: Props) {
  return (
    <Text
      tag="div"
      mb={theme.spacing.spacing04}
      css={{ lineHeight: theme.spacing.spacing04 }}
      size="sm"
      color={theme.color.text.text02}
    >
      {children}
    </Text>
  );
}
