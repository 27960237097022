/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Container } from "react-awesome-styled-grid";
import { Switch } from "react-router-dom";
import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import { MenuBar, Header, Footer } from "~/layout";
import { RumRoute as Route } from "~/services/datadog";
import {
  ApiDocsPage,
  AccountsPage,
  AccountDetails,
  Dashboard,
  AccountCreate,
  ResourcesPage,
  ResourceGroupsPage,
  ResourceDetails,
  RolesPage,
  APIKeysPage,
  ResourceGroupCreate,
  ResourceGroupDetails,
  UsersPage,
  ResourceCreate,
  CustomActionsPage,
  CustomActionDetails,
  CustomActionCreate,
  PlansPage,
  PlanCreate,
  PlanDetails,
  NotificationGroupsPage,
  NotificationGroupDetails,
  NotificationGroupsCreate,
  EventsPage,
  EventDetailsWrapper,
} from "~/views";
import { ROUTES } from "./routes";

const layoutHorizontalSpacing = theme.spacing.spacing04;

export function GlobalSwitch() {
  return (
    <Container
      fluid
      css={{
        flex: 1,
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: layoutHorizontalSpacing,
      }}
    >
      <ApplicationGrid>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <MenuBar />
        <ApplicationWrapper>
          <Switch>
            <Route exact path={ROUTES.index} component={Dashboard} />
            <Route exact path={ROUTES.api} component={ApiDocsPage} />
            <Route exact path={ROUTES.events.index} component={EventsPage} />
            <Route
              exact
              path={ROUTES.events.details}
              component={EventDetailsWrapper}
            />
            <Route
              exact
              path={ROUTES.resources.create}
              component={ResourceCreate}
            />
            <Route
              exact
              path={ROUTES.resources.index}
              component={ResourcesPage}
            />
            <Route
              exact
              path={ROUTES.resources.details}
              component={ResourceDetails}
            />
            <Route
              exact
              path={ROUTES.accounts.create}
              component={AccountCreate}
            />
            <Route
              exact
              path={ROUTES.accounts.index}
              component={AccountsPage}
            />
            <Route
              exact
              path={ROUTES.accounts.details}
              component={AccountDetails}
            />
            <Route exact path={ROUTES.plans.index} component={PlansPage} />
            <Route exact path={ROUTES.plans.create} component={PlanCreate} />
            <Route exact path={ROUTES.plans.details} component={PlanDetails} />
            <Route
              exact
              path={ROUTES.customActions.create}
              component={CustomActionCreate}
            />
            <Route
              exact
              path={ROUTES.customActions.index}
              component={CustomActionsPage}
            />
            <Route
              exact
              path={ROUTES.customActions.details}
              component={CustomActionDetails}
            />

            <Route
              exact
              path={ROUTES.permissions.roles}
              component={RolesPage}
            />
            <Route
              exact
              path={ROUTES.permissions.apiKeys}
              component={APIKeysPage}
            />
            <Route
              exact
              path={ROUTES.permissions.users}
              component={UsersPage}
            />
            <Route
              exact
              path={ROUTES.resourceGroups.create}
              component={ResourceGroupCreate}
            />
            <Route
              exact
              path={ROUTES.resourceGroups.index}
              component={ResourceGroupsPage}
            />
            <Route
              exact
              path={ROUTES.resourceGroups.details}
              component={ResourceGroupDetails}
            />
            <Route
              exact
              path={ROUTES.notificationGroups.create}
              component={NotificationGroupsCreate}
            />
            <Route
              exact
              path={ROUTES.notificationGroups.index}
              component={NotificationGroupsPage}
            />
            <Route
              exact
              path={ROUTES.notificationGroups.details}
              component={NotificationGroupDetails}
            />
          </Switch>
        </ApplicationWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </ApplicationGrid>
    </Container>
  );
}

// cache menu bar & layout dimensions
const menuWidth = theme.spacing.spacing07;
const menuHorizontalPadding = theme.spacing.spacing03;

// `1st calc`: we need to add menuHorizontalPadding twice because we have
// paddingLeft and paddingRight on the menu element

// `2nd calc`: we need to add layoutHorizontalSpacing additionally to mitigate
// issue with right padding on global application container which is wrongly
// interpreted by the grid
const ApplicationGrid = styled.div`
  display: grid;
  gap: ${theme.spacing.spacing04} ${layoutHorizontalSpacing};
  grid-template-columns:
    calc(${menuWidth} + 2 * ${menuHorizontalPadding})
    calc(
      100% -
        (
          ${menuWidth} + 2 * ${menuHorizontalPadding} +
            ${layoutHorizontalSpacing}
        )
    );
  grid-template-areas:
    ". header"
    ". application"
    ". footer";
  width: 100%;
`;

const HeaderWrapper = styled.div`
  margin: 0 -${theme.spacing.spacing04} 0 -${layoutHorizontalSpacing};
  grid-area: header;
`;

const ApplicationWrapper = styled.div`
  grid-area: application;
  width: 100%;

  @media screen and (min-width: 2500px) {
    margin: 0 auto;
    max-width: 2500px;
  }
`;

const FooterWrapper = styled.div`
  grid-area: footer;
  padding-top: ${theme.spacing.spacing05};
`;
