/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import styled from "styled-components";
import { Box, theme } from "@nordcloud/gnui";
import { Details } from "~/components/Details";
import { NoData } from "~/components/NoData";
import { BorderWrapper } from "~/components/Styled";
import { useCustomAction } from "~/views/customActions/CustomActionDetails/CustomActionProvider";
import { DetailsFirstPreset, HeaderPreset } from "./presets";

export function CustomActionInfo() {
  const { customAction } = useCustomAction();
  if (customAction === null) {
    return <NoData message="no data" />;
  }

  return (
    <Box spacing="spacing02">
      <Row>
        <Col xs={8} sm={8} md={5} lg={8}>
          <BorderWrapper
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Wrap>
              <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
                <ColorBox color={customAction?.color} />
              </Box>
            </Wrap>
            <div css={{ width: "100%" }}>
              <Details details={HeaderPreset(customAction)} />
            </div>
          </BorderWrapper>
          <Row>
            <Col>
              <Details details={DetailsFirstPreset(customAction)} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Box>
  );
}

const Wrap = styled.div`
  margin-right: ${theme.spacing.spacing03};
`;

const ColorBox = styled.div`
  height: ${theme.spacing.spacing07};
  width: ${theme.spacing.spacing07};
  background: ${(props) => (props.color ? props.color : theme.colors.darks[4])};
  border-radius: ${theme.spacing.spacing01};
`;
