/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then } from "react-if";
import styled from "styled-components";
import {
  SVGIcon,
  Box,
  theme,
  ExtendedTooltip,
  Text,
  SVGIconProps,
} from "@nordcloud/gnui";
import { ResourceStatus } from "~/generated/graphql";
import { getOsIcon } from "~/views/resources/components/OSCell";
import { Resource } from "~/views/resources/types/Resource";
import {
  getResourceColorContext,
  getResourceStatusIconName,
  getResourceStatusMessage,
} from "~/views/resources/utils";

type Props = {
  os?: Resource["os"];
  status: ResourceStatus;
  iconSize?: SVGIconProps["size"];
  boxes?: boolean;
};

export function ResourceDetailsStatus({ os, status, iconSize, boxes }: Props) {
  const toolTipText = os?.subType || os?.type || "No Data Available";
  const icon = os?.subType || os?.type || "cloudQuestionMark";

  return (
    <>
      <Wrap>
        <ExtendedTooltip
          status={getResourceColorContext(status)}
          caption={
            <Text tag="span" size="sm" color={theme.color.text.text04}>
              {getResourceStatusMessage(status)}
            </Text>
          }
          position="start"
        >
          <IconBox
            icon={getResourceStatusIconName(status)}
            iconSize={iconSize}
            color={getResourceColorContext(status)}
            boxEnabled={boxes}
          />
        </ExtendedTooltip>
      </Wrap>
      <Wrap>
        <ExtendedTooltip caption={toolTipText} position="start">
          <IconBox
            icon={getOsIcon(icon)}
            iconSize={iconSize}
            boxEnabled={boxes}
          />
        </ExtendedTooltip>
      </Wrap>
    </>
  );
}

type IconBoxProps = {
  icon: SVGIconProps["name"];
  iconSize: SVGIconProps["size"];
  color?: SVGIconProps["color"];
  boxEnabled?: boolean;
};

function IconBox({ icon, iconSize, color, boxEnabled }: IconBoxProps) {
  const svgIcon = <SVGIcon size={iconSize ?? "lg"} name={icon} color={color} />;

  return (
    <If condition={boxEnabled}>
      <Then>
        <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
          {svgIcon}
        </Box>
      </Then>
      <Else>{svgIcon}</Else>
    </If>
  );
}

const Wrap = styled.div`
  margin-right: ${theme.spacing.spacing03};
`;
