/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { If, Then, Else } from "react-if";
import { Box, Tab, Tabs as TabContainer, Text, theme } from "@nordcloud/gnui";
import { NoData } from "~/components";
import { TableWrapper } from "~/components/Tables";
import { useGNUITheme } from "~/hooks/useGNUITheme";
import { isEmpty, isNotNil } from "~/tools";
import { useResource } from "~/views/resources/ResourceProvider";
import { columns, KeyValue } from "./KeyValueColumns";

export function Tabs() {
  const [currentStep, setCurrentStep] = useState(0);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { isLight } = useGNUITheme();

  const { resource } = useResource();
  const metadata =
    resource?.cloudMetadata
      ?.filter(isNotNil)
      .filter((item) => item.key.toLowerCase() !== "tags") ?? [];
  const tags = resource?.tags?.filter(isNotNil) ?? [];

  return (
    <Box spacing="spacing02" css={{ padding: 0 }}>
      <TabContainer step={currentStep} handleTab={setCurrentStep}>
        <Tab heading="Metadata">
          <Text mb={0}>Cloud Metadata</Text>
          <Text size="sm" color={theme.color.text.text02}>
            Cloud Metadata is descriptive information associated with cloud
            resources, providing details about attributes, configuration, and
            relationships.
          </Text>
          <If condition={isEmpty(metadata)}>
            <Then>
              <NoData hasIcon message={"There are no Metadata."} />
            </Then>
            <Else>
              <TableWrapper<Partial<KeyValue>>
                loading={false}
                data={metadata}
                columns={columns(isLight)}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                stripped
              />
            </Else>
          </If>
        </Tab>
        <Tab heading="Tags">
          <Text mb={0}>Tags</Text>
          <Text size="sm" color={theme.color.text.text02}>
            Tags are custom labels or identifiers used to categorize and
            organize resources for better management and resource grouping in a
            cloud computing environment.
          </Text>
          <If condition={isEmpty(tags)}>
            <Then>
              <NoData hasIcon message={"There are no Tags."} />
            </Then>
            <Else>
              <TableWrapper<Partial<KeyValue>>
                loading={false}
                data={tags}
                columns={columns(isLight)}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                stripped
              />
            </Else>
          </If>
        </Tab>
      </TabContainer>
    </Box>
  );
}
