/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Text, theme } from "@nordcloud/gnui";
import { ChangeDetails } from "~/components/ChangeDetails";
import { Details } from "~/components/Details";
import { NoData } from "~/components/NoData";
import { BorderWrapper } from "~/components/Styled";
import { isNil } from "~/tools";
import { planScheduleType } from "~/views/plans/PlanDetails/components/helpers";
import { ScheduleType } from "../../types";
import { usePlan } from "../PlanProvider";
import { PlanDetailsStatus } from "./PlanDetailsStatus";
import {
  DetailsColumnFirst,
  DetailsColumnSecond,
  HeaderPresetPlan,
} from "./presets";
import { ScheduleDetails } from "./ScheduleDetails";

export function PlanInfo() {
  const { plan } = usePlan();

  if (isNil(plan)) {
    return <NoData message="no data" />;
  }

  const scheduleType = planScheduleType({
    scheduleExecutionDates: plan.scheduleExecutionDates ?? [],
    cronExpression: plan.scheduleCron ?? "",
  });

  return (
    <>
      <Col xs={8} sm={8} md={5} lg={8}>
        <BorderWrapper
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PlanDetailsStatus
            status={plan.enabled ?? false}
            scheduleCron={plan.scheduleCron ?? ""}
            scheduleExecutionDates={plan.scheduleExecutionDates ?? []}
          />

          <div css={{ width: "100%" }}>
            <Details details={HeaderPresetPlan(plan)} />
          </div>
        </BorderWrapper>
        <Row>
          <Col>
            <Details details={DetailsColumnFirst(plan)} />
          </Col>
          <Col css={{ minWidth: "50%", justifyContent: "space-between" }}>
            <If condition={scheduleType === ScheduleType.onDemand}>
              <Then>
                <Text tag="div" size="sm" color={theme.color.text.text02}>
                  Plan Timezone
                </Text>
                <Text>N/A</Text>
              </Then>
              <Else>
                <Details details={DetailsColumnSecond(plan)} />
              </Else>
            </If>
            <ScheduleDetails />
          </Col>
        </Row>
      </Col>
      <Col xs={8} sm={8} md={3} lg={4}>
        <ChangeDetails details={plan.changeDetails} />
      </Col>
    </>
  );
}
