/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  ExtendedTooltip,
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Text,
  theme,
} from "@nordcloud/gnui";
import { getTitleText } from "~/tools";
import { useReportState } from "../ReportProvider";
import { FilterType } from "../types";

export function ReportFilters() {
  const { reportState, updateReportState } = useReportState();

  const prepareData = reportState.resources
    ?.map(({ result }) => {
      const {
        __typename: IgnoredTypename,
        outputUri: IgnoredOutputUri,
        output: IgnoredOutput,
        ...rest
      } = result;

      return Object.keys(rest).map((i, index) => ({
        label: i,
        count: Object.values(rest)[index]?.length ?? 0,
      }));
    })
    .flat();

  const ALL = "all";

  const countAll = prepareData
    ?.reduce(
      (total, current) => {
        const existingItem = total.find(
          (entry) => entry.label === current.label
        );
        if (existingItem) {
          return total.map((item) =>
            item.label === current.label
              ? { ...item, count: item.count + current.count }
              : item
          );
        } else {
          return [...total, { label: current.label, count: current.count }];
        }
      },
      [{ label: ALL, count: 0 }]
    )
    .map((i, idx, arr) =>
      i.label === ALL
        ? {
            label: ALL,
            count: arr.reduce((total, current) => total + current.count, 0),
          }
        : i
    ) ?? [{ label: ALL, count: 0 }];

  const reportFilters = [
    {
      label: ALL,
      value: FilterType.ALL,
      count: 0,
    },
    {
      label: "installed",
      value: FilterType.INSTALLED,
      description: "New package installed",
      count: 0,
    },
    {
      label: "updated",
      value: FilterType.UPDATED,
      description: "Existing package has been updated with the new version",
      count: 0,
    },
    {
      label: "available",
      value: FilterType.AVAILABLE,
      description:
        "Package is available for installation (currently not installed)",
      count: 0,
    },
    {
      label: "removed",
      value: FilterType.REMOVED,
      description: "Package was uninstalled",
      count: 0,
    },
    {
      label: "failed",
      value: FilterType.FAILED,
      description:
        "An error occurred while trying to install/update the package",
      count: 0,
    },
  ];

  const mergedFilters = reportFilters?.map((data) => {
    const entryData = countAll.find(
      (mergeData) => mergeData.label === data.label
    );
    return entryData ? { ...data, ...entryData } : data;
  });

  return (
    <>
      <FlexContainer grow={1}>
        <Text
          size="sm"
          weight="medium"
          tag="div"
          color={theme.color.text.text02}
          mr={theme.spacing.spacing02}
        >
          Filter by Update Status:
        </Text>
        <MultipleSelect size="small">
          {mergedFilters.map((filter) => (
            <ExtendedTooltip
              caption={filter?.description}
              position="start"
              key={filter.value}
            >
              <SelectButton
                name={filter.value}
                value={filter.value}
                labelText={`${getTitleText(filter.label)} ${filter.count}`}
                isActive={reportState.filterQuery === filter.value}
                onClick={() => updateReportState({ filterQuery: filter.value })}
              />
            </ExtendedTooltip>
          ))}
        </MultipleSelect>
      </FlexContainer>
    </>
  );
}
