/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ZodType } from "zod";

import { NotificationGroupData, NotificationGroupField } from "../constants";
import { FormData, FormField } from "../formConfig";
import { useNotificationGroupsWizard } from "../NotificationGroupsProvider";
import { EmailForm } from "./EmailForm";
import { PagerDutyForm } from "./PagerDutyForm";
import { commonSchema, selectSchema } from "./schema";
import { SlackForm } from "./SlackForm";
import { NotificationGroupType } from "./types";

type Props = {
  nextStep: () => void;
  initialValues: NotificationGroupData[NotificationGroupField.CHANNELS];
};

export function NotificationGroupSelect({ nextStep, initialValues }: Props) {
  const [currentSchema, setSchema] = useState<ZodType>(commonSchema);

  const handleSetSchema = (schema: ZodType) => setSchema(schema);

  const [types, setType] = useState<NotificationGroupType[]>([
    ...(initialValues?.email_channels || []).map(
      () => NotificationGroupType.email
    ),
    ...(initialValues?.slack_channels || []).map(
      () => NotificationGroupType.slack
    ),
    ...(initialValues?.pager_duty_channels || []).map(
      () => NotificationGroupType.pagerDuty
    ),
  ]);

  const formMethods = useForm({
    resolver: zodResolver(currentSchema),
    defaultValues: {
      [FormField.EMAIL_CHANNELS]: initialValues?.email_channels ?? [
        { email: "" },
      ],
      [FormField.SLACK_CHANNELS]: initialValues?.slack_channels ?? [
        { webhookUrl: "" },
      ],
      [FormField.PAGER_DUTY_CHANNELS]: initialValues?.pager_duty_channels ?? [
        { pagerDutyKey: "" },
      ],
    },
  });
  const { handleSubmit, reset } = formMethods;

  const onTypeChange = (
    isChecked: boolean,
    channelType: NotificationGroupType
  ) => {
    setType((prevState) => {
      const updatedState = isChecked
        ? [...prevState, channelType]
        : prevState.filter((t) => t !== channelType);
      selectSchema(updatedState, handleSetSchema);

      return updatedState;
    });

    if (!isChecked && channelType === NotificationGroupType.email) {
      reset({ email_channels: [{ email: "" }] });
    }

    if (!isChecked && channelType === NotificationGroupType.slack) {
      reset({ slack_channels: [{ webhookUrl: "" }] });
    }

    if (!isChecked && channelType === NotificationGroupType.pagerDuty) {
      reset({ pager_duty_channels: [{ pagerDutyKey: "" }] });
    }
  };

  const { setNotificationGroupData } = useNotificationGroupsWizard();

  const submit: SubmitHandler<FormData> = (formData) => {
    setNotificationGroupData((prevNotificationGroupData) => ({
      ...prevNotificationGroupData,
      [NotificationGroupField.CHANNELS]: formData,
    }));
    nextStep();
  };

  return (
    <FormProvider {...formMethods}>
      <form id="notificationChanelForm" onSubmit={handleSubmit(submit)}>
        <EmailForm {...{ onTypeChange, types }} />
        <SlackForm {...{ onTypeChange, types }} />
        <PagerDutyForm {...{ onTypeChange, types }} />
      </form>
    </FormProvider>
  );
}
