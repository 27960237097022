/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { inflect, isEmpty } from "~/tools";
import { DeleteCloudAccount } from "./DeleteCloudAccount/DeleteCloudAccount";

type Props = {
  cloudAccountsIds: string[];
  onSuccess?: () => void;
  size?: "large" | "small";
};

export function Actions({ cloudAccountsIds, size = "small" }: Props) {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openBatchDeleteModal,
  } = useDisclosure(false);

  const noCloudAccountsIds = isEmpty(cloudAccountsIds ?? []);

  return (
    <>
      <PopoverControls
        disabled={noCloudAccountsIds}
        size={size}
        items={[
          {
            label: `Delete ${inflect("Account")(cloudAccountsIds?.length)}`,
            onClick: openBatchDeleteModal,
          },
        ]}
      />
      <When condition={isDeleteModalOpen}>
        <DeleteCloudAccount
          {...{
            accountsIds: cloudAccountsIds,
            isDeleteModalOpen: isDeleteModalOpen,
          }}
          close={closeDeleteModal}
        />
      </When>
    </>
  );
}
