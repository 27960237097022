/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  BrickLoader,
  theme,
  Heading,
  FlexContainer,
  Spacer,
} from "@nordcloud/gnui";
import {
  ChangeDetails,
  Details,
  ErrorWrap,
  LoaderWrap,
  NoData,
  BorderWrapper,
} from "~/components";
import { ResourcesTable } from "~/views/resources/components";
import { CloudAccountCtxProvider } from "../CloudAccountProvider";
import { useGetCloudAccount } from "../hooks";
import {
  AccountDetailsStatus,
  AccountDetailsBreadcrumbs,
  AccountRouteParams,
  ForceScan,
  LastScanStatus,
} from "./components";
import {
  DetailsColumnSecond,
  DetailsFirstPreset,
  HeaderPreset,
} from "./presets";

const TIME_TO_RELOAD = 2500;

export function AccountDetails() {
  const { accountId } = useParams<AccountRouteParams>();

  const { cloudAccount, error, loading, refetch } = useGetCloudAccount({
    accountId,
  });

  if (cloudAccount?.regionsStatuses?.length === 0) {
    setTimeout(() => {
      refetch();
    }, TIME_TO_RELOAD);
  }

  if (cloudAccount === null) {
    return <NoData message="no data" />;
  }

  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <CloudAccountCtxProvider value={{ cloudAccount }}>
        <LoaderWrap
          Component={() => <BrickLoader height="14rem" />}
          loading={loading}
          viewBox="0 0 10 4"
        >
          <Row>
            <Col>
              <AccountDetailsBreadcrumbs title={cloudAccount?.name ?? ""} />
            </Col>
          </Row>
          <Box spacing="spacing02">
            <Row>
              <Col xs={8} sm={8} md={5} lg={8}>
                <BorderWrapper
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccountDetailsStatus
                    provider={cloudAccount?.provider ?? ""}
                    status={cloudAccount?.status ?? ""}
                  />
                  <div css={{ width: "100%" }}>
                    <Details details={HeaderPreset(cloudAccount)} />
                  </div>
                </BorderWrapper>
                <Row>
                  <Col>
                    <Details details={DetailsFirstPreset(cloudAccount)} />
                  </Col>
                  <Col
                    css={{ minWidth: "50%", justifyContent: "space-between" }}
                  >
                    <Details details={DetailsColumnSecond(cloudAccount)} />
                  </Col>
                </Row>
              </Col>
              <Col xs={8} sm={8} md={3} lg={4}>
                <ChangeDetails
                  removeBorderBottomRadius
                  details={cloudAccount?.changeDetails}
                />
                <LastScanStatus
                  lastScanEndDate={cloudAccount?.lastScanEndDate}
                />
              </Col>
            </Row>
          </Box>
          <Row>
            <Col>
              <Box>
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FlexContainer direction="column" alignItems="flex-start">
                    <Heading level={4}>List of Resources</Heading>
                    <HeadingDescription>
                      This is a dynamic list of resources from your cloud
                      account.
                    </HeadingDescription>
                  </FlexContainer>
                  <ForceScan accountId={accountId} />
                </FlexContainer>
                <Spacer height={theme.spacing.spacing04} />
                <ResourcesTable providerId={cloudAccount?.providerId} />
              </Box>
            </Col>
          </Row>
        </LoaderWrap>
      </CloudAccountCtxProvider>
    </ErrorWrap>
  );
}

const HeadingDescription = styled(FlexContainer)`
  word-break: break-all;
  color: ${theme.color.text.text02};
  font-size: ${theme.fontSizes.sm};
  line-height: ${theme.spacing.spacing04};
  margin-bottom: ${theme.spacing.spacing04};
`;
