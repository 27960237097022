/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { RbacAction, RbacResource } from "~/generated/graphql";
import { StateItem } from "./types";

export const rows = Object.values(RbacResource).filter(
  (item) => item !== RbacResource.Any
);

export const columns = [
  RbacAction.View,
  RbacAction.Create,
  RbacAction.Update,
  RbacAction.Delete,
  RbacAction.Execute,
];

export const initialState: StateItem[] = rows.map((item) => ({
  name: item,
  checked: [""],
}));
