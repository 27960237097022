/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useParams } from "react-router-dom";
import { BrickLoader } from "@nordcloud/gnui";
import { useGetEvent } from "../hooks";
import { EventDetails } from "./EventDetails";
import { IncomingEventDetails } from "./IncomingEventDetails";
import { EventDetailsParams } from "./types";

export function EventDetailsWrapper() {
  const { eventId } = useParams<EventDetailsParams>();
  const { event, loading, error } = useGetEvent({
    eventId,
  });

  if (loading) {
    return <BrickLoader height="14rem" />;
  }

  if (error?.toString().includes("Resource not found")) {
    return <IncomingEventDetails eventId={eventId} />;
  }

  if (event) {
    return <EventDetails eventId={eventId} />;
  }
  return null;
}
