/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function getFirstItem<T>(list: T[]): T {
  return list[0];
}

type NonEmptyArray<T> = readonly [T, ...(readonly T[])];

export const isNonEmpty = <T>(
  array: readonly T[] | undefined
): array is NonEmptyArray<T> => array != null && array.length > 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const firstOf = <V extends readonly any[]>(
  array: V
): V[number] | undefined => {
  return isNonEmpty(array) ? array[0] : undefined;
};

export function getLastItem<T>(list: T[]): T {
  // @ts-expect-error ignored temporary after migration to TypeScript 4.9
  return list.at(-1);
}

export function sortItem<T>(
  array: T[],
  compareFunction?: (a: T, b: T) => number
): T[] {
  // eslint-disable-next-line fp/no-mutating-methods
  return [...array].sort(compareFunction);
}

export function getDuplicates<T>(input: T[]): Map<T, number[]> {
  return input.reduce((output, element, idx) => {
    const recordedDuplicates = output.get(element);
    if (recordedDuplicates) {
      output.set(element, [...recordedDuplicates, idx]);
    } else if (input.lastIndexOf(element) !== idx) {
      output.set(element, [idx]);
    }

    return output;
  }, new Map<T, number[]>());
}

export const hasTruthyValue = (array: boolean[]): boolean => {
  return array?.some((value) => Boolean(value));
};

export const sortByOrder = <T extends { order?: number }>(
  entities: T[]
): T[] => {
  return sortItem(entities, (a, b) => (a.order ?? 0) - (b.order ?? 0));
};
