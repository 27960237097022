/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { FlexContainer } from "@nordcloud/gnui";
import { AddPagerDutyForm } from "./AddPagerDutyForm";
import { PagerDutyTable } from "./PagerDutyTable";

export function PagerDutyTab() {
  return (
    <>
      <Row>
        <Col>
          <FlexContainer justifyContent="flex-end">
            <AddPagerDutyForm />
          </FlexContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <PagerDutyTable />
        </Col>
      </Row>
    </>
  );
}
