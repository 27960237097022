/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import {
  ItemsContainer,
  ItemsSection,
  MenuItem,
  NavigationBar,
} from "@nordcloud/gnui";
import { ROUTES } from "~/routing/routes";
import { MenuBarLink } from "./MenuItem";
import { PermissionsSubmenus } from "./submenus/PermissionsSubmenu";

enum MenuIcons {
  dashboard = "dashboard",
  events = "eventSchedule",
  resources = "resource",
  resourcesGroups = "application",
  plans = "production",
  customActions = "thunder",
  cloudAccounts = "cloud",
  notificationGroups = "bellSettings",
  permissions = "userAccess",
}

export function MenuBar() {
  const menuItems = [
    {
      caption: "Dashboard",
      icon: MenuIcons.dashboard,
      route: ROUTES.index,
    },
    {
      caption: "Events",
      icon: MenuIcons.events,
      route: ROUTES.events.index,
    },
    {
      caption: "Resources",
      icon: MenuIcons.resources,
      route: ROUTES.resources.index,
    },
    {
      caption: "Resource Groups",
      icon: MenuIcons.resourcesGroups,
      route: ROUTES.resourceGroups.index,
    },

    {
      caption: "Plans",
      icon: MenuIcons.plans,
      route: ROUTES.plans.index,
    },
    {
      caption: "Custom actions",
      icon: MenuIcons.customActions,
      route: ROUTES.customActions.index,
    },
    {
      caption: "Cloud accounts",
      icon: MenuIcons.cloudAccounts,
      route: ROUTES.accounts.index,
    },
    {
      caption: "Notification Groups",
      icon: MenuIcons.notificationGroups,
      route: ROUTES.notificationGroups.index,
    },
    {
      caption: "Permissions",
      icon: MenuIcons.permissions,
      route: ROUTES.permissions.roles,
      children: <PermissionsSubmenus />,
    },
  ];

  return (
    <NavigationBar>
      <ItemsContainer>
        <ItemsSection>
          {menuItems.map((item) => (
            <MenuBarLink key={item.route} route={item.route}>
              <MenuItem caption={item.caption} icon={item.icon}>
                {item.children}
              </MenuItem>
            </MenuBarLink>
          ))}
        </ItemsSection>
      </ItemsContainer>
    </NavigationBar>
  );
}
