/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { When } from "react-if";
import {
  Box,
  Input,
  FlexContainer,
  Switch,
  Text,
  Button,
  theme,
  Label,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components/Forms";
import { PAGER_DUTY_KEY_EXAMPLE } from "~/views/notificationGroups/constants";
import { InputHeadline, SwitchLabel } from "../styles";
import { NotificationGroupType } from "../types";

type Props = {
  onTypeChange: (
    isChecked: boolean,
    channelType: NotificationGroupType
  ) => void;
  types: NotificationGroupType[];
};

export function PagerDutyForm({ types, onTypeChange }: Props) {
  const { control, formState, register } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "pager_duty_channels",
  });

  const error = formState.errors[`pager_duty_channels`] as
    | FieldValues[]
    | undefined;

  const isSelected =
    types.find((t) => t === NotificationGroupType.pagerDuty) ===
    NotificationGroupType.pagerDuty;
  return (
    <Box boxStyle="lightGrey" mb={theme.spacing.spacing04}>
      <FlexContainer data-testid="form-pager_duty">
        <Switch
          onChange={(e) =>
            onTypeChange(e.target.checked, NotificationGroupType.pagerDuty)
          }
          checked={isSelected}
        />
        <SwitchLabel>
          <Text tag="div">PagerDuty</Text>
          <Text tag="div" size="sm" color={theme.color.text.text02}>
            You can also send notifications via Pagerduty by adding the
            PagerDuty Key.
          </Text>
        </SwitchLabel>
      </FlexContainer>
      <When condition={isSelected}>
        <InputHeadline size="sm" tag="div">
          <FlexContainer>
            <Label
              name="PagerDuty Key"
              required
              css={{ color: theme.color.text.text02 }}
            />
          </FlexContainer>
        </InputHeadline>
        {fields.map((field, index) => {
          return (
            <FlexContainer
              key={field.id}
              css={{ borderTop: theme.borders.grey }}
              alignItems="flex-start"
            >
              <FormGroup
                error={error?.[index]?.pagerDutyKey}
                customCss={{
                  padding: `${theme.spacing.spacing05} ${theme.spacing.spacing03} 0`,
                  width: "100%",
                }}
              >
                <Input
                  key={field.id}
                  aria-label="provide PagerDuty key"
                  placeholder={PAGER_DUTY_KEY_EXAMPLE}
                  {...register(
                    `pager_duty_channels.${index}.pagerDutyKey` as const
                  )}
                />
              </FormGroup>
              <Button
                onClick={() => remove(index)}
                disabled={fields.length === 1}
                severity="low"
                icon="trash"
                mt={theme.spacing.spacing05}
              />
            </FlexContainer>
          );
        })}
        <FlexContainer mt={theme.spacing.spacing07}>
          <Button
            severity="low"
            icon="plus"
            type="button"
            onClick={() => append({ pagerDutyKey: "" })}
          >
            Add Key
          </Button>
        </FlexContainer>
      </When>
    </Box>
  );
}
