/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { format } from "timeago.js";
import { FlexContainer, Tag, Text, theme, SVGIcon } from "@nordcloud/gnui";
import { ApiKey } from "~/generated/graphql";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import {
  ActionsColumn,
  FlexColumn,
  FlexColumnMax,
  MediumIconColumn,
  SmallColumn,
} from "~/components/Tables/styles";
import { dateFormat } from "~/constants/dateFormats";
import { Actions } from "./Actions";

const columnHelper = createColumnHelper<ApiKey>();

export function apiKeysColumns() {
  return [
    columnHelper.accessor(() => null, {
      id: "isExpired",
      header: "",
      cell: ({ row }) => {
        const isExpired =
          dayjs(row.original.expiresAt).diff(dayjs(), "minute") <= 0;

        return (
          <FlexContainer justifyContent="center" alignItems="center">
            <SVGIcon name={isExpired ? "statusError" : "statusSuccess"} />
          </FlexContainer>
        );
      },
      meta: MediumIconColumn,
    }),
    columnHelper.accessor((row) => row?.name, {
      header: "API Key Name",
      cell: ({ row }) => <NameWithLink name={row.original.name} />,
      meta: FlexColumn,
    }),
    columnHelper.accessor((row) => row?.description, {
      header: "Roles",
      cell: ({ row }) => (
        <div>
          {row.original.roleBindings?.result?.map((role) => (
            <div key={role?.role.id}>
              <Tag text={role?.role.name} />
            </div>
          ))}
        </div>
      ),
      meta: FlexColumnMax,
    }),
    columnHelper.accessor((row) => row?.changeDetails?.createdAt, {
      header: "Created",
      cell: ({ row }) => (
        <DateCell value={row.original.changeDetails.createdAt} />
      ),
      meta: SmallColumn,
    }),
    columnHelper.accessor((row) => row?.lastUsedAt, {
      header: "Last Used At",
      cell: ({ row }) => <DateCell value={row.original.lastUsedAt} />,
      meta: SmallColumn,
    }),
    columnHelper.accessor((row) => row?.changeDetails?.updatedAt, {
      header: "Last Updated",
      cell: ({ row }) => (
        <DateCell value={row.original.changeDetails.updatedAt} />
      ),
      meta: SmallColumn,
    }),
    columnHelper.accessor((row) => row?.expiresAt, {
      header: "Expires",
      cell: ({ row }) => <DateCell value={row?.original?.expiresAt} />,
      meta: SmallColumn,
    }),
    columnHelper.accessor(() => null, {
      id: "options",
      header: () => null,
      cell: ({ row }) => {
        const id = row.original.id;
        const rolesBindings =
          row.original.roleBindings?.result?.map((role) => role?.id ?? "") ??
          [];
        return (
          <FlexContainer justifyContent="space-around">
            <Actions
              ids={[id]}
              apiKey={row.original}
              rolesIds={rolesBindings}
            />
          </FlexContainer>
        );
      },
      meta: ActionsColumn,
    }),
  ] as never;
}

type Props = {
  value?: string | null;
};

function DateCell({ value }: Props) {
  return (
    <FlexContainer
      justifyContent="flex-start"
      alignItems="flex-start"
      direction="column"
    >
      <div>{value && format(dayjs(value).toDate())}</div>
      <FlexContainer alignItems="center">
        <Text
          size="sm"
          tag="span"
          mr={theme.spacing.spacing01}
          weight="regular"
          color={theme.color.text.text02}
        >
          {value && dayjs(value).format(dateFormat.dayMonthYear)}
        </Text>
      </FlexContainer>
    </FlexContainer>
  );
}
