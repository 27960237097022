/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FlexContainer, theme } from "@nordcloud/gnui";
import { CodeHighlighter } from "~/components/CodeHighlighter";

type Props = {
  actionOutput: string;
};

export function ActionOutput({ actionOutput }: Props) {
  return (
    <>
      <FlexContainer
        justifyContent="center"
        css={{ minHeight: theme.spacing.spacing08 }}
      >
        <CodeHighlighter language="text" code={actionOutput} wrapLongLines />
      </FlexContainer>
    </>
  );
}
