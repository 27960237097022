/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export function isValidJSONObject(jsonString: string): boolean {
  try {
    const parsedValue = JSON.parse(jsonString);
    return typeof parsedValue === "object" && parsedValue !== null;
  } catch (error) {
    return false;
  }
}

export function areObjectsEqual(obj1: unknown, obj2: unknown): boolean {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
