/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";

type Props = {
  label: string;
  count?: number;
};

export function TabCounter({ label, count }: Props) {
  return (
    <span>
      <FlexContainer
        justifyContent="space-between"
        mb={theme.spacing.spacing01}
        css={{ height: "2rem" }}
      >
        <FlexContainer columnGap={theme.spacing.spacing03}>
          <Text mb={0} weight="medium">
            {label}
          </Text>
        </FlexContainer>
        <When condition={count}>
          <Box boxStyle="grey" innerSpacing="spacing01" css={{ width: "auto" }}>
            <Text
              m={0}
              size="sm"
              p={`calc(${theme.spacing.spacing01} / 2) ${theme.spacing.spacing02}`}
            >
              {count}
            </Text>
          </Box>
        </When>
      </FlexContainer>
    </span>
  );
}
