/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction, useReducer } from "react";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { Else, If, Then } from "react-if";
import { theme, FlexContainer, Label, Text } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";
import { ScheduleTimezone } from "~/views/plans/components";
import { useGenerateDatesFromCron } from "~/views/plans/hooks";
import { PlanData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import {
  CronScheduleState,
  FormField,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { PlanExtended } from "~/views/plans/PlanDetails/PlanProvider";
import { ReadableCron } from "./ReadableCron";
import {
  CronDelayUnitDropDown,
  CronDelayInput,
  CronExpressionInput,
  CronNextDates,
} from ".";

type Props = {
  planData?: Partial<PlanExtended>;
  planDataForm?: PlanData;
  nextDates?: string[];
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
  includeTimezone?: boolean;
};

export function ScheduleCron({
  planData,
  planDataForm,
  nextDates,
  setPlanData,
}: Props) {
  const [state, updateState] = useReducer(
    (data: CronScheduleState, partialData: Partial<CronScheduleState>) => ({
      ...data,
      ...partialData,
    }),
    {
      timezone:
        planDataForm?.schedule_plan?.scheduleTimezone ??
        planData?.scheduleTimezone ??
        dayjs.tz.guess(),
      cronExpression:
        planDataForm?.schedule_plan?.scheduleCron ??
        planData?.scheduleCron ??
        "",
      offset:
        planDataForm?.schedule_plan?.scheduleOffset ??
        planData?.scheduleOffset ??
        0,
      offsetUnit:
        planDataForm?.schedule_plan?.scheduleOffsetUnit ?? TimeUnits.minutes,
      cronNextDates: nextDates ?? [],
    }
  );

  const {
    formState: { errors },
  } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    onSuccess: (dates) => {
      updateState({ cronNextDates: dates });
    },
  });

  const generateDates = (timezone: string) => {
    generateDatesFromCron({
      cron_expression: state.cronExpression,
      location: timezone,
      offset_in_minutes: state.offset,
    });
  };

  const timezoneDates = state.cronNextDates.map((date) => {
    return dayjs(date)
      .tz(state.timezone)
      .format(dateFormat.dayMonthYearHourMinute);
  });

  return (
    <>
      <ScheduleTimezone {...{ generateDates }} />
      <FormGroup>
        <Label name="Cron Window Start" htmlFor="scheduleCron" required />
        <FlexContainer
          mb={theme.spacing.spacing01}
          gap={theme.spacing.spacing03}
          alignContent="flex-start"
        >
          <div css={{ minWidth: "12rem" }}>
            <CronExpressionInput
              state={state}
              updateState={updateState}
              setPlanData={setPlanData}
            />
          </div>
          <If condition={isNotEmpty(state.cronNextDates)}>
            <Then>
              <ReadableCron cronExpression={state.cronExpression} />
            </Then>
            <Else>
              <Text size="sm" color={theme.color.text.text02} tag="div">
                No preview available
              </Text>
            </Else>
          </If>
        </FlexContainer>
        <div css={{ height: "2rem" }}>
          <CronNextDates dates={timezoneDates} />
          <Text size="sm" color={theme.colors.danger} tag="div">
            <>{errors[FormField.SCHEDULE_CRON]?.message}</>
          </Text>
        </div>
      </FormGroup>
      <FormGroup error={errors[FormField.SCHEDULE_OFFSET]}>
        <Label css={{ marginBottom: 0 }} name="Delay" />
        <Text
          mb={theme.spacing.spacing02}
          size="sm"
          color={theme.color.text.text02}
        >
          Choose a time to delay the plan execution after the scheduled cron.
        </Text>
        <FlexContainer
          mb={theme.spacing.spacing04}
          gap={theme.spacing.spacing03}
        >
          <div css={{ width: "5rem" }}>
            <CronDelayInput
              state={state}
              updateState={updateState}
              setPlanData={setPlanData}
            />
          </div>
          <div>
            <CronDelayUnitDropDown
              state={state}
              updateState={updateState}
              setPlanData={setPlanData}
            />
          </div>
        </FlexContainer>
      </FormGroup>
    </>
  );
}
