/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import download from "downloadjs";
import { useParams } from "react-router-dom";
import { Button } from "@nordcloud/gnui";
import { useGetEventActionReportsLazyQuery } from "~/generated/graphql";
import { showError, showSuccess } from "~/services/toast";
import { generateActionSuccessText, isNotNil } from "~/tools";
import { EventDetailsParams } from "~/views/events/EventsDetails/types";
import { downloadButtonState } from "./utils";

type Props = {
  eventActionId: string;
};

export function ReportDownload({ eventActionId }: Props) {
  const { eventId } = useParams<EventDetailsParams>();

  const [getReport, { data: isData, loading, error: isError }] =
    useGetEventActionReportsLazyQuery({
      onCompleted: (data) => {
        const fileName = `${eventId}_${eventActionId}_report`;
        if (data?.eventActionReports) {
          download(
            JSON.stringify(data?.eventActionReports),
            fileName,
            "application/json"
          );
        }
        showSuccess(generateActionSuccessText(fileName)()("downloaded")());
      },
      onError: (error) => {
        showError(error.message);
      },
    });

  const handleGetReport = () =>
    getReport({ variables: { eventActionId, eventId } });

  return (
    <Button
      severity="low"
      disabled={loading}
      icon="download"
      initialState={downloadButtonState({
        isData: isNotNil(isData),
        loading,
        isError: isNotNil(isError),
      })}
      onClick={handleGetReport}
    >
      Download JSON
    </Button>
  );
}
