/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { SkipWindowType } from "~/generated/graphql";

export function convertSkipWindow(skipWindow: SkipWindowType | undefined) {
  switch (skipWindow) {
    case SkipWindowType.Always:
      return "Always";
    case SkipWindowType.Never:
      return "Never";
    case SkipWindowType.OnSuccess:
      return "On Success";
    default:
      return "No Data";
  }
}
