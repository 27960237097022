/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { theme } from "@nordcloud/gnui";
import {
  ColorLabel,
  ToggleButtonItem,
} from "~/components/ToggleItemBox/styles";

const conditionalStyles = (props: {
  isNested: boolean;
  isExpanded: boolean;
}) => {
  if (props.isNested === false) {
    return "";
  }
  if (props.isExpanded === false) {
    return `
    & > ${ToggleButtonItem} {
      border-radius: ${theme.radius.md};
    }
    `;
  }
  if (props.isNested === true) {
    return `
    & > ${ToggleButtonItem}:first-child {
      border-radius: ${theme.radius.md} ${theme.radius.md} 0 0;
    }
    & > ${ToggleButtonItem}:first-child ${ColorLabel} {
      border-top-left-radius: ${theme.radius.md};
    }
    & > ${ToggleButtonItem}:last-child {
      border-radius: 0 0 ${theme.radius.md} ${theme.radius.md};
    }
    & > ${ToggleButtonItem} {
      border-radius: 0;
    }
    & > ${ToggleButtonItem} ${ColorLabel} {
      border-radius: 0;
    }
    & > ${ToggleButtonItem}:last-child ${ColorLabel} {
      border-bottom-left-radius: ${theme.radius.md};
    }
  `;
  }
};

export const Wrap = styled.div<{ isNested: boolean; isExpanded: boolean }>`
  width: 100%;
  margin: ${theme.spacing.spacing01} 0;

  ${conditionalStyles}
`;
