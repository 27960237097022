/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Else, If, Then, When } from "react-if";
import { ExtendedTooltip, Spacer, Text, theme } from "@nordcloud/gnui";
import { TooltipText } from "~/components";
import { dateFormat } from "~/constants";
import { getLastItem, isNil } from "~/tools";
import { LogsOutputButton } from "~/views/events/EventsDetails/components/LogOutputButton";
import {
  isNotFinishedExecution,
  hasExecutionStarted,
} from "~/views/events/EventsDetails/Tabs/Details/components/utils";
import { EventEntity } from "~/views/events/types";

export function ActionDetails({
  eventEntity,
  isBatchEntity,
}: {
  eventEntity: EventEntity;
  isBatchEntity: boolean;
}) {
  const formatDate = (date?: string | null) => {
    return dayjs(date).format(dateFormat.dayMonthYearHourMinute);
  };
  const startTime = isBatchEntity
    ? formatDate(eventEntity.startTime)
    : formatDate(
        eventEntity?.globalState?.startTime ??
          eventEntity?.globalState?.estimatedStartTime
      );

  const endTime = isBatchEntity
    ? eventEntity.actions &&
      formatDate(getLastItem(eventEntity.actions).globalState?.endTime)
    : formatDate(eventEntity?.globalState?.endTime);

  const isActionAwaitingCompletion = (actionEntity: EventEntity) => {
    return (
      isNil(actionEntity?.globalState?.status) ||
      (actionEntity?.globalState?.status &&
        isNotFinishedExecution(actionEntity?.globalState?.status))
    );
  };

  const isActionAwaitingStart = (actionEntity: EventEntity) => {
    return (
      isNil(startTime) ||
      (actionEntity?.globalState?.status &&
        !hasExecutionStarted(actionEntity?.globalState?.status))
    );
  };

  const isBatchAwaitingCompletion = (batch: EventEntity) => {
    return (
      isNotFinishedExecution(batch.status) ||
      batch?.actions?.some((batchAction) => {
        return isActionAwaitingCompletion(batchAction);
      })
    );
  };

  const isBatchAwaitingStart = (batch: EventEntity) => {
    return !hasExecutionStarted(batch.status);
  };

  const isAwaitingCompletion = isBatchEntity
    ? isBatchAwaitingCompletion(eventEntity)
    : isActionAwaitingCompletion(eventEntity);

  const isAwaitingStart = isBatchEntity
    ? isBatchAwaitingStart(eventEntity)
    : isActionAwaitingStart(eventEntity);

  return (
    <div css={{ minHeight: "19.3rem" }}>
      <Text weight="medium">{isBatchEntity ? "Batch" : "Action"} Details</Text>
      <Text size="sm" tag="div">
        Name
      </Text>
      <Text>{eventEntity?.name}</Text>
      <Text size="sm" tag="div">
        Start Time (local)
      </Text>
      <If condition={isAwaitingStart}>
        <Then>
          <ExtendedTooltip
            caption={
              <>
                Start Time will appear when the{" "}
                {isBatchEntity ? "batch" : "action"} will be in progress
              </>
            }
          >
            <TooltipText
              size="md"
              tag="span"
              mr={theme.spacing.spacing01}
              weight="regular"
              color={theme.color.text.text01}
            >
              Awaiting start
            </TooltipText>
          </ExtendedTooltip>
        </Then>
        <Else>{startTime}</Else>
      </If>

      <Spacer height={theme.spacing.spacing04} />
      <Text size="sm" tag="div">
        End Time (local)
      </Text>
      <If condition={isAwaitingCompletion}>
        <Then>
          <ExtendedTooltip
            caption={
              <>
                End Time will appear when the{" "}
                {isBatchEntity ? "batch" : "action"} is complete
              </>
            }
          >
            <TooltipText
              size="md"
              tag="span"
              mr={theme.spacing.spacing01}
              weight="regular"
              color={theme.color.text.text01}
            >
              Awaiting completion
            </TooltipText>
          </ExtendedTooltip>
        </Then>
        <Else>{endTime}</Else>
      </If>

      <When condition={eventEntity?.globalState?.outputUri}>
        <LogsOutputButton
          actionLogLabel={eventEntity?.name}
          outputUri={eventEntity?.globalState?.outputUri}
        />
      </When>

      {/* TODO AP-4540}
      
      <When condition={isNotEmpty(eventEntity?.resourceStates ?? [])}>
        <Text size="sm" tag="div">
          Resource Status Summary
        </Text>
      </When> */}
    </div>
  );
}
