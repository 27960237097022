/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useLocation } from "react-router-dom";
import { trimText, capitalize } from "~/tools";
import { ComponentLabel } from "./types";

const START_PAGE_LABEL = "Dashboard";
const normalize = (value: string) => value.split("_").map(capitalize).join(" ");

type Props = {
  title?: string;
  labels?: ComponentLabel[];
};

// above function based on url '/machines/a91fabe'
// and return breadcrumbs [{label: 'Dashboard', uri: '/'},{label: 'machines', uri: '/machines'},{label: 'a91fabe1', uri: '/machines/a91fabe1'}]

export function useBreadcrumbs({ title, labels }: Props) {
  const location = useLocation();
  const uniquePathSegments = [...new Set(location.pathname.split("/"))];

  const displayTitle = title || normalize(uniquePathSegments.at(-1) || "");

  const breadcrumbs = uniquePathSegments.map((item: string, index: number) => {
    const label =
      item === ""
        ? trimText(START_PAGE_LABEL, 25)
        : trimText(labels?.find((el) => el.value === item)?.label || item, 25);
    const uri = `${uniquePathSegments.slice(0, index).join("/")}/${item}`;

    return { label, uri };
  });

  return { breadcrumbs, displayTitle };
}
