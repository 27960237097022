/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { BreadcrumbsBox } from "~/components/BreadcrumbsBox";
import { ROUTES } from "~/routing/routes";
import { NotificationGroupsCreateWizard } from "./components/NotificationGroupsCreateWizard";

export function NotificationGroupsCreate() {
  return (
    <>
      <BreadcrumbsBox
        title="Create Notification Group"
        customBreadcrumbs={[
          { label: "Dashboard", uri: "/" },
          {
            label: "Notification Groups",
            uri: ROUTES.notificationGroups.index,
          },
          {
            label: "Create Notification Group",
            uri: ROUTES.notificationGroups.create,
          },
        ]}
      />
      <NotificationGroupsCreateWizard />
    </>
  );
}
