/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Button,
  Input,
  Label,
  Sidebar,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { RoleRuleInput } from "~/generated/graphql";
import { FormGroup } from "~/components/Forms";
import { useDisclosure } from "~/hooks";
import { PermissionSelect } from "../components/PermissionSelect";
import { schema } from "../components/schema";
import { initialState } from "../constants";
import { useCreateRole } from "../hooks/useCreateRole/useCreateRole";
import { usePermissionState } from "../hooks/usePermissionState";
import { prepareData } from "../utils";
import { FormData } from "./formConfig";

export function RoleCreate() {
  const { isOpen, open, close } = useDisclosure();

  const { state, updateState, updateRowState, updateColState, updateAllState } =
    usePermissionState(initialState);

  const { handleSubmit, register, formState, clearErrors, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const clean = () => {
    clearErrors();
    reset({
      name: "",
      description: "",
    });
    close();
    updateAllState(0, {
      name: "",
      checked: "",
    });
  };

  const [createRole, loading] = useCreateRole({ onSuccess: clean });

  const onSubmit = (formData: FormData) => {
    const input = {
      name: formData.name ?? "",
      description: formData.description,
      rules: prepareData(state) as RoleRuleInput[],
    };

    return createRole(input);
  };

  return (
    <>
      <Button icon="plus" onClick={open}>
        Create Role
      </Button>
      <Sidebar
        width="43rem"
        title="Create Role"
        isOpen={isOpen}
        onClick={clean}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup error={formState.errors["name"]}>
            <Label name="Name" htmlFor="name" required />
            <Input id="name" {...register("name")} />
          </FormGroup>
          <FormGroup error={formState.errors["description"]}>
            <Label name="Description" htmlFor="description" />
            <Input id="description" {...register("description")} />
          </FormGroup>
          <FormGroup>
            <Label name="Permissions" css={{ marginBottom: 0 }} />
            <Text size="sm" color={theme.color.text.text02}>
              Select permissions for the role.
            </Text>
            <PermissionSelect
              state={state}
              onUpdate={updateState}
              onUpdateRow={updateRowState}
              onUpdateCol={updateColState}
            />
          </FormGroup>
          <Spacer height={theme.spacing.spacing02} />
          <Button
            disabled={loading}
            initialState={loading ? "loading" : "success"}
          >
            Apply
          </Button>
        </form>
      </Sidebar>
    </>
  );
}
