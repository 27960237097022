// Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
import { Plan } from "~/generated/graphql";
import { DetailData } from "~/components/Details/Detail";
import { stringRequired } from "~/components/Forms";
import { timezones } from "~/constants";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { useUpdatePlanEntityName } from "~/views/plans/hooks/useUpdatePlanEntityName/useUpdatePlanEntityName";
import { useUpdatePlan } from "../../hooks/useUpdatePlan/useUpdatePlan";
import { PlanEntityNameSchema } from "../../schemas";

const successMessage = () =>
  showSuccess(generateActionSuccessText("Plan")()("updated")());

export const HeaderPresetPlan: (plan?: Partial<Plan>) => DetailData[] = (
  plan
) => [
  {
    label: "Plan Name",
    value: plan?.name ?? plan?.id,
    editProps: {
      property: "name",
      updateHook: useUpdatePlan,
      schema: {
        name: stringRequired("Name"),
      },
      onSuccess: successMessage,
    },
  },
];

export const DetailsColumnFirst: (plan?: Partial<Plan>) => DetailData[] = (
  plan
) => [
  {
    label: "Plan ID",
    value: plan?.id,
    clipboard: true,
  },
  {
    label: "Description",
    value: plan?.description,
    editProps: {
      property: "description",
      updateHook: useUpdatePlan,
      schema: {
        description: stringRequired("Description"),
      },
      onSuccess: successMessage,
    },
  },
];

export const DetailsColumnSecond: (plan: Partial<Plan>) => DetailData[] = (
  plan
) => {
  return [
    {
      label: "Plan Timezone",
      value: plan?.scheduleTimezone,
      editProps: {
        property: "scheduleTimezone",
        updateHook: useUpdatePlan,
        schema: {
          scheduleTimezone: stringRequired("Schedule Timezone"),
        },
        onSuccess: successMessage,
        selectOptions: timezones.map((timezone) => {
          return {
            value: timezone,
            label: timezone,
          };
        }),
        minWidth: "80%",
      },
    },
  ];
};

type DetailPlanAction = {
  name: string;
  id: string;
};

export const DetailPlanEntityName: (
  planAction?: DetailPlanAction,
  batchId?: string,
  batchName?: string
) => DetailData = (planAction, batchId, batchName) => {
  return {
    value: planAction?.name ?? batchName,
    editProps: {
      property: "name",
      schema: { name: PlanEntityNameSchema },
      updateHook: useUpdatePlanEntityName,
      updateHookProps: { id: planAction?.id, batchId, batchName },
      onSuccess: successMessage,
    },
  };
};
