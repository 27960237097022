/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Label, Upload } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { useCustomActionWizard } from "../../../CustomActionProvider";
import { FormField } from "../../../formConfig";
import { hasFiles } from "./helpers";

const UPLOAD_PLACEHOLDER_TEXT = "File.name";

export function ScriptForm() {
  const { customActionData } = useCustomActionWizard();
  const { control, formState } = useFormContext();
  const [file, setFile] = useState<File>();

  const fileName = customActionData?.settings?.script?.name;

  const uploadPlaceholder = file
    ? file.name
    : fileName || UPLOAD_PLACEHOLDER_TEXT;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    callback: (e: File) => void
  ) => {
    const fileList = e.target.files;
    if (fileList instanceof FileList && hasFiles(fileList)) {
      const f = fileList[0];

      setFile(f);
      callback(f);
    }
  };

  return (
    <>
      <FormGroup error={formState.errors[FormField.SCRIPT_FILE]}>
        <Label
          htmlFor="scriptFile"
          name="Select Script File to Upload"
          required
        />
        <Controller
          name={FormField.SCRIPT_FILE}
          control={control}
          render={({ field: { onChange } }) => (
            <Upload
              id="scriptFile"
              placeholder={uploadPlaceholder}
              onChange={(e) => handleInputChange(e, onChange)}
            />
          )}
        />
      </FormGroup>
    </>
  );
}
