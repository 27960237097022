/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import cronstrue from "cronstrue";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";

export function ReadableCron({ cronExpression }: { cronExpression: string }) {
  const readableCron = () => {
    try {
      return cronstrue.toString(cronExpression);
    } catch (e) {
      return "";
    }
  };

  return (
    <FlexContainer>
      <Text
        mb={theme.spacing.spacing02}
        color={theme.color.text.text02}
        size="sm"
      >
        {readableCron()}
      </Text>
    </FlexContainer>
  );
}
