/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PlanActionType } from "~/generated/graphql";
import { ExecutionMethod } from "./types";

export const actionsType = [
  {
    label: "System Actions",
    value: PlanActionType.System,
  },
  {
    label: "Custom Actions",
    value: PlanActionType.Custom,
  },
];

export const defaultState = {
  isSidebarOpen: false,
  isModalOpen: false,
  name: "",
  actionType: PlanActionType.System,
  selectedActions: [],
  selectionType: ExecutionMethod.single,
};

export const sidebarTitleBatch = "Select Actions within the Batch";
export const sidebarTitleSingle = "Select Actions";
export const batchActionSupportTooltip =
  "Only resource based scope actions supported within the batch.";
