/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
  FieldValues,
} from "react-hook-form";
import { Button, Input, Text, theme, FlexContainer } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { FormField } from "../../../formConfig";

type Props = {
  index: number;
  remove: UseFieldArrayRemove;
};

function HeaderItem({ index, remove }: Props) {
  const { register, formState } = useFormContext();
  const error = formState.errors[`headers`] as FieldValues[] | undefined;
  return (
    <>
      <FlexContainer
        justifyContent="flex-start"
        alignContent="flex-start"
        gap="1rem"
      >
        <FormGroup error={error?.[index]?.key}>
          <Input
            aria-label="provide header"
            placeholder="Provide Header"
            {...register(`${FormField.HEADERS}.${index}.key` as const)}
          />
        </FormGroup>
        <FormGroup error={error?.[index]?.value}>
          <Input
            aria-label="provide header value"
            placeholder="Provide Header Value"
            {...register(`${FormField.HEADERS}.${index}.value` as const)}
          />
        </FormGroup>
        <Button
          onClick={() => remove(index)}
          type="button"
          severity="low"
          icon="trash"
          mb={theme.spacing.spacing05}
        />
      </FlexContainer>
    </>
  );
}

export function WebhookHeaders() {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: FormField.HEADERS,
  });

  return (
    <>
      <Text mb={0}>Headers</Text>
      {fields.map((field, index) => {
        return <HeaderItem key={field.id} {...{ index, remove }} />;
      })}
      <Button
        onClick={() => append({ key: "", value: "" })}
        type="button"
        severity="low"
        icon="plus"
      >
        Add Header
      </Button>
    </>
  );
}
