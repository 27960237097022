/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { Dropdown, Label, Spacer, theme } from "@nordcloud/gnui";
import { ActionScope, ActionType } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { FormField } from "../../../formConfig";

type Props = {
  onTypeChange: (type: string) => void;
};

export function ActionTypeSelect({ onTypeChange }: Props) {
  const { control, formState, watch, clearErrors } = useFormContext();
  const scope = watch("scope");
  return (
    <>
      <FormGroup error={formState.errors[FormField.TYPE]}>
        <Label name="Action Type" required />
        <Controller
          control={control}
          name={FormField.TYPE}
          render={({ field: { onChange, value } }) => {
            return (
              <Dropdown
                name="Select Type"
                options={
                  scope === ActionScope.Plan ? planOptions : resourceOptions
                }
                onChange={(val: string) => {
                  clearErrors();
                  onTypeChange(val);
                  onChange(val);
                }}
                value={value}
                minNumOfOptionsToShowSearchBox={10}
              />
            );
          }}
        />
      </FormGroup>
      <Spacer height={theme.spacing.spacing04} />
    </>
  );
}

const planOptions = [
  {
    value: ActionType.AwsLambda,
    label: "AWS Lambda",
  },
  {
    value: ActionType.AzureFunction,
    label: "AZURE Function",
  },
  {
    value: ActionType.Webhook,
    label: "Webhook",
  },
];
const resourceOptions = [
  ...planOptions,
  {
    value: ActionType.SsmDocument,
    label: "SSM Document",
  },
  {
    value: ActionType.Script,
    label: "Script",
  },
];
