/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Row } from "react-awesome-styled-grid";
import styled from "styled-components";
import { Heading } from "@nordcloud/gnui";

export const StyledRow = styled(Row)`
  display: flex;
  place-items: center;
`;

export const HeadlineWrap = styled.div`
  display: inline-flex;
  place-items: center;
`;

export const StyledHeading = styled(Heading)`
  word-break: "break-all";
`;
