/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { StateItem } from "./types";

export function prepareData(data: StateItem[]) {
  return data.map((item: StateItem) => {
    const removeInitialState = item.checked.filter((i) => i.length !== 0);
    return {
      actions: removeInitialState,
      resources: [item.name],
    };
  });
}
