/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useFormContext } from "react-hook-form";
import { Input, Label } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { FormField, getLabel } from "../../../formConfig";

export function AwsForm() {
  const { formState, register } = useFormContext();
  return (
    <FormGroup error={formState.errors[FormField.LAMBDA_ARN]}>
      <Label
        htmlFor={FormField.LAMBDA_ARN}
        name={getLabel(FormField.LAMBDA_ARN)}
        required
      />
      <Input id={FormField.LAMBDA_ARN} {...register(FormField.LAMBDA_ARN)} />
    </FormGroup>
  );
}
