/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";

const forbiddenCharactersRegexp = /^((?![<>]).)*$/;
const forbiddenCharactersErrorMessage = "Forbidden characters entered";

export const safeStr = z.string().regex(forbiddenCharactersRegexp, {
  message: forbiddenCharactersErrorMessage,
});

export const stringRequired = (name: string) =>
  z
    .string({
      required_error: `${name} is required`,
    })
    .min(1, { message: `${name} is required` })
    .regex(forbiddenCharactersRegexp, {
      message: forbiddenCharactersErrorMessage,
    });
