/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, FlexContainer, Spacer, theme } from "@nordcloud/gnui";
import { ResourcesSortFields, SortOrder } from "~/generated/graphql";
import { SortSelector } from "~/components/SortSelector";
import { useQueryState } from "~/hooks";
import { ResourceQuery } from "../types";
import { Actions } from "./Actions";
import { ResourceTableState } from "./const";
import { ResourceSearch } from "./ResourcesSearch/ResourceSearch";

const sortOptions = [
  {
    value: ResourcesSortFields.Name,
    label: "Resource Name",
  },
  {
    value: ResourcesSortFields.CreatedAt,
    label: "Date Added",
  },
];

type Props = {
  resourcesDetails: {
    id: string;
    name: string;
    scanned: boolean;
  }[];
  setResourcesSearchPage: (resourcesPage: ResourceTableState) => void;
};

export function ResourceListHeader({
  resourcesDetails,
  setResourcesSearchPage,
}: Props) {
  const {
    queryState: { field, order },
    updateQueryState,
  } = useQueryState<ResourceQuery>();

  const handleSortDirection = (sortOrder: SortOrder) => {
    updateQueryState({
      order: sortOrder,
    });
  };

  const handleSortField = (sortField: ResourcesSortFields) => {
    updateQueryState({
      field: sortField,
    });
  };

  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer justifyContent="space-between">
        <SortSelector<ResourcesSortFields>
          options={sortOptions}
          value={field ?? ResourcesSortFields.Name}
          direction={order ?? SortOrder.Asc}
          onSelect={handleSortField}
          onSortChange={handleSortDirection}
        />
        <Spacer width={theme.spacing.spacing04} />
        <ResourceSearch setResourcesSearchPage={setResourcesSearchPage} />
        <Spacer width={theme.spacing.spacing04} />
        <Actions size="large" {...{ resourcesDetails }} />
      </FlexContainer>
    </Box>
  );
}
