/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { CloudAccountsSortFields, SortOrder } from "~/generated/graphql";
import { Pagination } from "~/components/Pagination";
import { TableWrapper } from "~/components/Tables";
import { useQueryState } from "~/hooks";
import { isNotNil, isNotEmpty } from "~/tools";
import { useGetCloudAccounts } from "~/views/accounts/hooks/useGetCloudAccounts/useGetCloudAccounts";
import { CloudAccountCtxProvider } from "../CloudAccountProvider";
import { QuerySort } from "../types/QuerySort";
import { columns, CloudAccount } from "./CloudAccountsColumns";
import { CloudAccountListHeader } from "./CloudAccountsListTable";

export function CloudAccountsTable() {
  const [cloudAccountsIds, setCloudAccountsIds] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const {
    queryState: { page, limit, field, order },
  } = useQueryState<QuerySort>();

  const { data, loading, count } = useGetCloudAccounts({
    page: page,
    limit: limit,
    sort: {
      field: field ?? CloudAccountsSortFields.Name,
      order: order ?? SortOrder.Asc,
    },
  });

  const cleanRowSelection = () => setRowSelection({});

  if (isNotEmpty(data) || loading) {
    return (
      <CloudAccountCtxProvider value={{ cleanRowSelection }}>
        <CloudAccountListHeader cloudAccountsIds={cloudAccountsIds} />
        <TableWrapper<Partial<CloudAccount>>
          loading={loading}
          data={data.filter(isNotNil)}
          columns={columns()}
          rowSelection={rowSelection}
          selectedRowsHandler={setCloudAccountsIds}
          setRowSelection={setRowSelection}
          stripped
        />

        <Pagination count={count} />
      </CloudAccountCtxProvider>
    );
  }

  return <>No accounts available</>;
}
