/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { DraggableProvided } from "react-beautiful-dnd";
import { When } from "react-if";
import { ExtendedTooltip, SVGIcon, Text, theme } from "@nordcloud/gnui";
import {
  EventActionBatch,
  useEventActionBatchStatusChangedSubscription,
} from "~/generated/graphql";
import { IconWrapper } from "~/components/ToggleItemBox";
import { isNotNil } from "~/tools";
import { SvgName } from "~/tools/types";
import { getEventEntityStatus } from "~/views/events/helpers";
import { useGetEvent } from "~/views/events/hooks";
import {
  ActionBatchWrap,
  ActionBatchHeader,
  ActionBatchLabel,
} from "~/views/plans/styled";
import { useEvent } from "../../../EventProvider";

type Props = {
  name?: string | JSX.Element;
  actionBatch: EventActionBatch;
  provided?: DraggableProvided;
  children: React.ReactNode;
  onDelete?: () => void;
  onAddAction?: () => void;
  onClick?: () => void;
  active?: boolean;
};

export function EventActionBatchItem({
  actionBatch,
  children,
  onClick,
  active,
}: Props) {
  const { event: eventCtx } = useEvent();

  const { refetch: refetchEvent } = useGetEvent({
    eventId: eventCtx.id,
  });

  const { data } = useEventActionBatchStatusChangedSubscription({
    variables: {
      input: {
        id: actionBatch.id,
      },
    },
    onData: () => refetchEvent(),
  });

  const statusDetails = getEventEntityStatus(
    data?.eventActionBatchStatusChanged ?? actionBatch.status
  );

  return (
    <ActionBatchWrap active={active} onMouseDown={onClick}>
      <ActionBatchLabel>Action Batch</ActionBatchLabel>
      <ActionBatchHeader
        alignItems="center"
        gap={theme.spacing.spacing02}
        paddingLeft={`${theme.spacing.spacing04} !important`}
      >
        <When condition={isNotNil(statusDetails.icon)}>
          <IconWrapper>
            <ExtendedTooltip
              caption={statusDetails?.description}
              status={statusDetails?.tooltipColor}
              position="start"
            >
              <SVGIcon
                name={statusDetails.icon as SvgName}
                size="md"
                color={statusDetails.color()}
              />
            </ExtendedTooltip>
          </IconWrapper>
        </When>
        <Text
          weight="medium"
          tag="span"
          css={{
            marginRight: "auto",
          }}
        >
          {actionBatch.name}
        </Text>
      </ActionBatchHeader>
      {children}
    </ActionBatchWrap>
  );
}
