/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import { BrickLoader } from "@nordcloud/gnui";
import { ErrorWrap, BreadcrumbsBox, LoaderWrap, NoData } from "~/components";
import { ROUTES } from "~/routing/routes";
import { Actions } from "~/views/notificationGroups/components/Actions";
import { useGetNotificationGroup } from "~/views/notificationGroups/hooks/useGetNotificationGroup/useGetNotificationGroup";
import { NotificationGroupInfo } from "./components/NotificationGroupInfo";
import { NotificationGroupCtxProvider } from "./NotificationGroupProvider";
import { NotificationGroupDetailsParams } from "./types";

export function NotificationGroupDetails() {
  const { notificationGroupId } = useParams<NotificationGroupDetailsParams>();

  const { data, loading, error } = useGetNotificationGroup({
    id: notificationGroupId,
  });

  const title = data?.name ?? notificationGroupId;

  const breadcrumbs = [
    { label: "Home", uri: "/" },
    { label: "Notification Groups", uri: ROUTES.notificationGroups.index },
    { label: title, uri: "" },
  ];

  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <NotificationGroupCtxProvider value={{ data }}>
        <LoaderWrap
          Component={() => <BrickLoader height="14rem" />}
          loading={loading}
          viewBox="0 0 10 4"
        >
          <Row>
            <Col>
              <BreadcrumbsBox title={title} customBreadcrumbs={breadcrumbs}>
                <Actions
                  {...{
                    notificationGroupId: notificationGroupId,
                    notificationGroupName: title,
                  }}
                  size="large"
                />
              </BreadcrumbsBox>
            </Col>
          </Row>
          <Row>
            <Col>
              <NotificationGroupInfo />
            </Col>
          </Row>
        </LoaderWrap>
      </NotificationGroupCtxProvider>
    </ErrorWrap>
  );
}
