/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, Input, Label, Sidebar, Spacer, theme } from "@nordcloud/gnui";
import { KeyValue } from "~/generated/graphql";
import { FormGroup } from "~/components/Forms";
import { useDisclosure } from "~/hooks";
import { showSuccess } from "~/services/toast";
import { generateActionSuccessText } from "~/tools";
import { FormData, schema } from "./formConfig";
import { Props } from "./types";

type MetadataEditProps<T> = Props<T> & { selected: KeyValue };

export function MetadataEdit<T>({
  updateHook,
  metadata,
  id,
  selected,
}: MetadataEditProps<T>) {
  const { isOpen, open, close } = useDisclosure();

  const [updateGroup] = updateHook({
    onSuccess: async () => {
      callback();
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      key: selected.key,
      value: selected.value,
    },
  });

  const callback = () => {
    clearErrors();
    close();
    showSuccess(generateActionSuccessText("Metadata")()("updated")());
  };

  const onSubmit = (formData: FormData) => {
    const data =
      metadata
        .map(({ key, value }) => ({ key, value }))
        .filter(
          (meta) => meta.key !== selected.key && meta.value !== selected.value
        ) ?? [];

    updateGroup({
      id,
      metadata: [...data, formData],
    } as T);
  };
  return (
    <>
      <Button
        icon="edit"
        severity="low"
        mr={3}
        onClick={open}
        data-testid="Edit Metadata"
      />
      <Sidebar title="Edit Metadata" isOpen={isOpen} onClick={close}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup error={errors.key}>
            <Label name="Key" htmlFor="key" required />
            <Input id="key" {...register("key")} />
          </FormGroup>
          <FormGroup error={errors.value}>
            <Label name="Value" htmlFor="value" required />
            <Input id="value" {...register("value")} />
          </FormGroup>
          <Spacer height={theme.spacing.spacing02} />
          <Button initialState={"success"}>Apply</Button>
        </form>
      </Sidebar>
    </>
  );
}
