/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { v4 } from "uuid";
import { dateFormat } from "~/constants";
import { isEmpty } from "~/tools";
import { DateItem } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { ScheduleType } from "~/views/plans/types";

export const mapToSpecificDateItem = (
  date: string,
  timezone: string
): DateItem => {
  const dateObject = dayjs(date).tz(timezone);
  return {
    id: v4(),
    executionDate: dateObject.format(dateFormat.shortDate),
    executionTime: dateObject.format(dateFormat.hourMinute),
  };
};

type Props = {
  scheduleExecutionDates: string[];
  cronExpression?: string;
};

export const planScheduleType = ({
  scheduleExecutionDates,
  cronExpression,
}: Props) => {
  const hasScheduleExecutionDates = !isEmpty(scheduleExecutionDates);
  const hasCronExpression = !isEmpty(cronExpression ?? []);

  if (!hasScheduleExecutionDates && !hasCronExpression) {
    return ScheduleType.onDemand;
  }

  if (!hasScheduleExecutionDates) {
    return ScheduleType.recurring;
  }

  return ScheduleType.specifiedDates;
};
