/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";
import { AzureFunctionMethod, WebhookActionMethod } from "~/generated/graphql";
import { stringRequired } from "~/components/Forms";
import { awsRegions } from "~/constants";
import { FormField, getLabel } from "../../formConfig";

const MAX_PAYLOAD_SIZE = 1048576;

const urlRegExp =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

const lambdaArnRegExp = new RegExp(
  `^arn:aws:lambda:(${awsRegions.join("|")}):\\d{12}:function:([^\\s]+)$`
);

const notValid = (label: FormField) => `${getLabel(label)} is not valid`;
const isRequired = (label: FormField) => `${getLabel(label)} is required`;
const requiredString = (label: FormField) => stringRequired(getLabel(label));

export const genericSchemaFields = {
  [FormField.SCOPE]: requiredString(FormField.SCOPE),
  [FormField.TYPE]: requiredString(FormField.TYPE),
};

export const baseActionSchema = z.object({ ...genericSchemaFields });

export const lambdaArnSchema = z.object({
  ...genericSchemaFields,
  [FormField.LAMBDA_ARN]: requiredString(FormField.LAMBDA_ARN).refine(
    (lambdaArn) => lambdaArnRegExp.test(lambdaArn ?? ""),
    notValid(FormField.LAMBDA_ARN)
  ),
});

export const azureFunctionSchema = z.object({
  ...genericSchemaFields,
  [FormField.FUNCTION_URL]: requiredString(FormField.FUNCTION_URL).refine(
    (url) => urlRegExp.test(url ?? ""),
    notValid(FormField.FUNCTION_URL)
  ),
  [FormField.FUNCTION_METHOD]: z.nativeEnum(AzureFunctionMethod, {
    required_error: isRequired(FormField.FUNCTION_METHOD),
  }),
  [FormField.ACCESS_KEY_ID]: requiredString(FormField.ACCESS_KEY_ID).max(100),
});

export const ssmDocumentSchema = z.object({
  ...genericSchemaFields,
  [FormField.SSM_DOCUMENT_ID]: requiredString(FormField.SSM_DOCUMENT_ID),
});

const isValidJSON = (data: string): boolean => {
  try {
    JSON.parse(data);
    return true;
  } catch {
    return false;
  }
};

export const webHookSchema = z.object({
  ...genericSchemaFields,
  [FormField.WEB_HOOK_URL]: requiredString(FormField.WEB_HOOK_URL).refine(
    (url) => urlRegExp.test(url ?? ""),
    notValid(FormField.WEB_HOOK_URL)
  ),
  [FormField.WEBHOOK_METHOD]: z.nativeEnum(WebhookActionMethod, {
    required_error: isRequired(FormField.WEBHOOK_METHOD),
  }),
  [FormField.PAYLOAD]: requiredString(FormField.PAYLOAD)
    .max(MAX_PAYLOAD_SIZE)
    .refine((payload) => isValidJSON(payload), notValid(FormField.PAYLOAD)),
  [FormField.HEADERS]: z
    .object({
      key: stringRequired("Header Name"),
      value: stringRequired("Header Value"),
    })
    .array(),
});

export const scriptFileSchema = z.object({
  ...genericSchemaFields,
  [FormField.SCRIPT_FILE]: z
    .any()
    .refine(
      (file: File) => file && file.size > 0,
      isRequired(FormField.SCRIPT_FILE)
    ),
});
