/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer } from "@nordcloud/gnui";
import { ActionSortFields, SortOrder, Action } from "~/generated/graphql";
import { LoaderWrap } from "~/components/Loader";
import { NoData } from "~/components/NoData";
import { Pagination } from "~/components/Pagination";
import { SortSelector } from "~/components/SortSelector";
import { TableWrapper } from "~/components/Tables";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotNil } from "~/tools";
import { columns } from "~/views/customActions/components/CustomActionsTableColumns";
import { useGetCustomActions } from "../hooks/useGetCustomActions/useGetCustomActions";

type QuerySort = {
  field: ActionSortFields;
  order: SortOrder;
};

const sortOptions = [
  {
    value: ActionSortFields.Name,
    label: "Custom Actions Name",
  },
  {
    value: ActionSortFields.CreatedAt,
    label: "Date Added",
  },
  {
    value: ActionSortFields.UpdatedAt,
    label: "Date Updated",
  },
  {
    value: ActionSortFields.UpdatedBy,
    label: "Updated By",
  },
];

export function CustomActionsTable() {
  const { queryState, updateQueryState } = useQueryState<QuerySort>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const handleSortDirection = (order: SortOrder) => {
    updateQueryState({
      ...queryState,
      order,
    });
  };

  const handleSortField = (field: ActionSortFields) => {
    updateQueryState({
      ...queryState,
      field,
    });
  };

  const { data, loading, count } = useGetCustomActions({
    page: queryState.page,
    limit: queryState.limit,
    sort: {
      field: queryState.field ?? ActionSortFields.Name,
      order: queryState.order ?? SortOrder.Asc,
    },
  });

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <SortSelector<ActionSortFields>
            options={sortOptions}
            value={queryState.field ?? ActionSortFields.Name}
            direction={queryState.order ?? SortOrder.Asc}
            onSelect={handleSortField}
            onSortChange={handleSortDirection}
          />
        </FlexContainer>
      </Box>
      <LoaderWrap loading={loading} inContent>
        <If condition={isEmpty(data)}>
          <Then>
            <NoData hasIcon message={"There are no Custom Actions."} />
          </Then>
          <Else>
            <>
              <TableWrapper<Partial<Action>>
                loading={loading}
                data={data.filter(isNotNil) as Action[]}
                columns={columns()}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                stripped
              />
              <Pagination count={count} />
            </>
          </Else>
        </If>
      </LoaderWrap>
    </>
  );
}
