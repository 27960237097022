/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Tab, Tabs } from "@nordcloud/gnui";
import { PlanEntityIncomingEventDetails } from "~/views/events/types";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";
import { GeneralActionBatchSettings } from "./GeneralActionBatchSettings";
import { IncomingEventActionBatchDetails } from "./IncomingEventActionBatchDetails";

type Props = {
  planActionBatch?: PlanEntityIncomingEventDetails;
  planId?: string;
};

export function IncomingEventActionBatchSettingsSection({
  planActionBatch,
  planId,
}: Props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs step={tab} handleTab={setTab}>
        <Tab
          label={
            <span>
              <TabIcon caption="Action Batch Details" icon="eventLog" />
            </span>
          }
          width="auto"
        >
          <IncomingEventActionBatchDetails planActionBatch={planActionBatch} />
        </Tab>
        <Tab
          label={
            <span>
              <TabIcon
                caption="General Action Batch Settings"
                icon="settings"
              />
            </span>
          }
          width="auto"
        >
          <GeneralActionBatchSettings
            eventActionBatch={planActionBatch}
            planId={planId}
          />
        </Tab>
      </Tabs>
    </>
  );
}
