/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useHistory, useParams } from "react-router-dom";
import { PopoverControls } from "~/components";
import { useDisclosure } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { DeleteResourceGroup } from "../components/DeleteResourceGroup/DeleteResourceGroup";
import { ResourceGroupDetailsParams } from "./types";

type Props = {
  groupName?: string;
};

export function Action({ groupName }: Props) {
  const {
    isOpen: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useDisclosure(false);

  const { resourceGroupId } = useParams<ResourceGroupDetailsParams>();

  const history = useHistory();

  const onSuccess = () => history.push(ROUTES.resourceGroups.index);
  return (
    <>
      <PopoverControls
        size="large"
        disabled={false}
        items={[
          {
            label: "Delete Resource Group",
            onClick: openDeleteModal,
          },
        ]}
      />
      <DeleteResourceGroup
        {...{
          groupId: resourceGroupId,
          groupName: groupName,
          isDeleteModalOpen,
          onSuccess,
        }}
        close={closeDeleteModal}
      />
    </>
  );
}
