/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, Input, Label, Sidebar, theme } from "@nordcloud/gnui";
import { ChannelType } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { useDisclosure } from "~/hooks";
import { useUpdateNotificationGroup } from "~/views/notificationGroups/hooks/useUpdateNotificationGroup/useUpdateNotificationGroup";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";
import { emailSchema } from "~/views/notificationGroups/notificationGroupDetails/schema";

type Props = {
  emailId: string;
  defaultValue: string;
};

export function EditEmailForm({ emailId, defaultValue }: Props) {
  const { data } = useNotificationGroup();
  const { isOpen, open, close } = useDisclosure();

  const onSubmit = (emailData: { email: string }) => {
    updateNotificationGroup({
      id: data?.id,
      channels: [
        {
          id: emailId,
          type: ChannelType.Email,
          typeProperties: { email: emailData },
        },
      ],
    });
  };

  const [updateNotificationGroup] = useUpdateNotificationGroup({
    onSuccess: () => {
      close();
    },
  });

  const { handleSubmit, formState, register } = useForm({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      id: data?.id,
      email: defaultValue,
    },
  });

  const error = formState.errors;
  return (
    <>
      <Button icon="edit" severity="low" onClick={open} size="md" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Sidebar
          isOpen={isOpen}
          title="Edit Email"
          onClick={close}
          footer={
            <div
              css={{
                margin: `${theme.spacing.spacing04} ${theme.spacing.spacing01}`,
              }}
            >
              <Button
                icon="checkmark"
                ml={theme.spacing.spacing04}
                type="submit"
              >
                Apply
              </Button>
            </div>
          }
        >
          <FormGroup error={error.email}>
            <Label name="Email" htmlFor="email" />
            <Input
              type="text"
              aria-label="provide email"
              placeholder="Email"
              id="email"
              {...register("email")}
            />
          </FormGroup>
        </Sidebar>
      </form>
    </>
  );
}
