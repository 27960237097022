/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer, Spacer, theme } from "@nordcloud/gnui";
import {
  PlanSortFields,
  SortOrder,
  Plan,
  PlansFilterByInput,
  PlansFilterByFields,
  FilterComparisonOperator,
} from "~/generated/graphql";
import {
  LoaderWrap,
  NoData,
  Pagination,
  SortSelector,
  TableWrapper,
} from "~/components";
import { TableSearchInput } from "~/components/SearchInput";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotNil, isNotEmpty } from "~/tools";
import { createFilterByInput } from "~/utils/createFilterByInput";
import { useGetPlans } from "~/views/plans/hooks";
import { sortOptions, QueryGetPlans } from "../components/constants";
import { PlanFiltersSidebar } from "../components/PlanFiltersSidebar";
import { columns } from "./PlansTableColumns";

type Props = {
  customActionId?: string;
};

const isQueryStateDate = (queryState: QueryGetPlans): boolean => {
  return (
    isNotNil(queryState.startDateUTC) &&
    isNotEmpty(queryState.startDateUTC) &&
    isNotNil(queryState.endDateUTC) &&
    isNotEmpty(queryState.endDateUTC)
  );
};

const prepareFilterBy = (
  queryState: QueryGetPlans
): PlansFilterByInput[] | undefined => {
  const createdAtFilter = isQueryStateDate(queryState)
    ? [
        {
          field: PlansFilterByFields.CreatedAt,
          operator: FilterComparisonOperator.Ge,
          values: [queryState.startDateUTC ?? ""],
        },
        {
          field: PlansFilterByFields.CreatedAt,
          operator: FilterComparisonOperator.Le,
          values: [queryState.endDateUTC ?? ""],
        },
      ]
    : [];

  const planStatusesFilter = createFilterByInput<PlansFilterByFields>(
    PlansFilterByFields.Status,
    queryState.planStatuses,
    queryState.excludePlanStatuses
  );

  const planActionIdsFilter = createFilterByInput<PlansFilterByFields>(
    PlansFilterByFields.ActionId,
    queryState.planActionIds,
    queryState.excludePlanActionIds
  );

  const planScheduleOptionsFilter = createFilterByInput<PlansFilterByFields>(
    PlansFilterByFields.ScheduleOption,
    queryState.scheduleOptions,
    queryState.excludeScheduleOptions
  );

  const retVal = [
    ...createdAtFilter,
    ...planStatusesFilter,
    ...planActionIdsFilter,
    ...planScheduleOptionsFilter,
  ];

  return isEmpty(retVal) ? undefined : retVal;
};

export function PlansTable({ customActionId }: Props) {
  const { queryState, updateQueryState } = useQueryState<QueryGetPlans>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const handleSortDirection = (order: SortOrder) => {
    updateQueryState({
      ...queryState,
      order,
    });
  };

  const handleSortField = (field: PlanSortFields) => {
    updateQueryState({
      ...queryState,
      field,
    });
  };

  const handlePhraseField = (field: string | undefined) => {
    updateQueryState({
      ...queryState,
      searchPhrase: field,
    });
  };

  const { data, loading, count } = useGetPlans({
    page: queryState.page,
    limit: queryState.limit,
    filter: {
      filterBy: prepareFilterBy(queryState),
      phrase: isNotEmpty(queryState.searchPhrase?.trim() || "")
        ? queryState.searchPhrase
        : undefined,
      listId: customActionId,
    },
    sort: {
      field: queryState.field ?? PlanSortFields.Name,
      order: queryState.order ?? SortOrder.Asc,
    },
  });

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <SortSelector<PlanSortFields>
            options={sortOptions}
            value={queryState.field ?? PlanSortFields.Name}
            direction={queryState.order ?? SortOrder.Asc}
            onSelect={handleSortField}
            onSortChange={handleSortDirection}
          />
          <Spacer width={theme.spacing.spacing04} />
          <TableSearchInput<QueryGetPlans>
            onSubmit={handlePhraseField}
            placeholder={`Type a Keyword (e.g. Plan Name, Plan ID, etc.)`}
          />
          <Spacer width={theme.spacing.spacing04} />
          <PlanFiltersSidebar />
        </FlexContainer>
      </Box>
      <LoaderWrap loading={loading} inContent>
        <If condition={isEmpty(data)}>
          <Then>
            <NoData hasIcon message={"There are no Plans."} />
          </Then>
          <Else>
            <TableWrapper<Partial<Plan>>
              loading={loading}
              data={data.filter(isNotNil) as Plan[]}
              columns={columns()}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              stripped
            />
            <Pagination count={count} />
          </Else>
        </If>
      </LoaderWrap>
    </>
  );
}
