/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { z } from "zod";
import { stringRequired } from "~/components/Forms";

export enum FormField {
  key = "key",
  value = "value",
}

export type FormData = {
  [FormField.key]: string;
  [FormField.value]: string;
};

export const schema = z.object({
  key: stringRequired("Key"),
  value: stringRequired("Value"),
});

export const MetadataSchema = schema.array();
