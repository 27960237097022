/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { FieldErrorsImpl } from "react-hook-form";
import { dateFormat } from "~/constants";
import {
  convertDateItemToDateString,
  convertDateStringToDateItem,
} from "~/views/plans/utils";
import { FormField, DateItem, TimeUnits } from "../types";

export const convertToMinutes = (value: number, unit: TimeUnits): number => {
  switch (unit) {
    case TimeUnits.hours: {
      return Math.floor(value * 60);
    }
    case TimeUnits.days: {
      return Math.floor(value * 24 * 60);
    }
    case TimeUnits.minutes: {
      return Math.floor(value);
    }
    case TimeUnits.weeks: {
      return Math.floor(value * 7 * 24 * 60);
    }

    default:
      return 0;
  }
};

export const isFormValid = (
  errors: Partial<FieldErrorsImpl<{ [x: string]: unknown }>>
): boolean => {
  return Object.keys(errors).length === 0;
};

export function adjustInvalidCronMessage(text: string) {
  return capitalizeFirstLetter(trimCronValidationMessage(text));
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function trimCronValidationMessage(text: string): string {
  const trimIndex = text.indexOf(".");

  return text.length > 200 && trimIndex !== -1
    ? text.slice(0, trimIndex + 1)
    : text;
}

export function exchangeDate(date: DateItem, updateDate: string): DateItem {
  // Change keeping the old time, output ISO string
  const oldDate = dayjs(convertDateItemToDateString(date));
  if (!oldDate.isValid()) {
    return convertDateStringToDateItem(
      dayjs(updateDate).isValid() ? dayjs(updateDate).toISOString() : "",
      date[FormField.ID]
    );
  }
  const newDate = dayjs(updateDate);
  const modifiedDate = oldDate
    .set("date", newDate.date())
    .set("month", newDate.month())
    .set("year", newDate.year());

  return convertDateStringToDateItem(
    dayjs(modifiedDate).isValid()
      ? modifiedDate.toISOString()
      : oldDate.toISOString(),
    date[FormField.ID]
  );
}

export function exchangeTime(date: DateItem, modifier: string): DateItem {
  // Change keeping the old date, output ISO string
  const oldDate = dayjs(convertDateItemToDateString(date, dateFormat.fullDate));
  const [hour, minute] = modifier.split(":").map(Number);
  if (!oldDate.isValid()) {
    return convertDateStringToDateItem(
      dayjs().set("hour", hour).set("minute", minute).toISOString(),
      date[FormField.ID]
    );
  }
  const newDate = oldDate.set("hour", hour).set("minute", minute);
  return convertDateStringToDateItem(
    dayjs(newDate).isValid()
      ? dayjs(newDate).toISOString()
      : dayjs(oldDate).set("hour", 0).set("minute", 0).toISOString(),
    date[FormField.ID]
  );
}
