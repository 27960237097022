/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

const enum Path {
  index = "/",
  api = "/graphql",
  cloudAccounts = "/cloud-accounts",
  cloudAccountsCreate = "/cloud-accounts/create",
  cloudAccountsDetails = "/cloud-accounts/:accountId",
  cloudAccountsGenerateDetails = "/cloud-accounts/:accountId",
  resources = "/resources",
  resourcesDetails = "/resources/:resourceId",
  resourcesCreate = "/resources/create",
  resourceGroups = "/resource-groups",
  resourceGroupsCreate = "/resource-groups/create",
  resourceGroupsDetails = "/resource-groups/:resourceGroupId",
  plans = "/plans",
  plansCreate = "/plans/create",
  plansDetails = "/plans/:planId",
  events = "/events",
  eventsDetails = "/events/:eventId",
  customActions = "/custom-actions",
  customActionsCreate = "/custom-actions/create",
  customActionsDetails = "/custom-actions/:customActionId",
  notificationGroups = "/notification-groups",
  notificationGroupsCreate = "/notification-groups/create",
  notificationGroupsDetails = "/notification-groups/:notificationGroupId",
  permissions = "/permissions",
  roles = "/roles",
  rolesCreate = "/roles/create",
  apiKeys = "/api-keys-and-permissions",
  apiKeysCreate = "/api-keys-and-permissions/create",
  users = "/users-and-permissions",
  usersCreate = "/users-and-permissions/create",
}

export const ROUTES = {
  index: Path.index,
  events: {
    index: Path.events,
    details: Path.eventsDetails,
    generateDetails: (eventId: string) => `${Path.events}/${eventId}`,
  },
  api: Path.api,
  accounts: {
    index: Path.cloudAccounts,
    create: Path.cloudAccountsCreate,
    details: Path.cloudAccountsDetails,
    generateDetails: (id: string) => `${Path.cloudAccounts}/${id}`,
  },
  resources: {
    index: Path.resources,
    details: Path.resourcesDetails,
    create: Path.resourcesCreate,
    generateDetails: (id: string) => `${Path.resources}/${id}`,
  },
  resourceGroups: {
    index: Path.resourceGroups,
    create: Path.resourceGroupsCreate,
    details: Path.resourceGroupsDetails,
    generateDetails: (id: string) => `${Path.resourceGroups}/${id}`,
  },
  plans: {
    index: Path.plans,
    details: Path.plansDetails,
    create: Path.plansCreate,
    generateDetails: (id: string) => `${Path.plans}/${id}`,
  },
  customActions: {
    index: Path.customActions,
    create: Path.customActionsCreate,
    details: Path.customActionsDetails,
    generateDetails: (id: string) => `${Path.customActions}/${id}`,
  },
  notificationGroups: {
    index: Path.notificationGroups,
    create: Path.notificationGroupsCreate,
    details: Path.notificationGroupsDetails,
    generateDetails: (id: string) => `${Path.notificationGroups}/${id}`,
  },
  permissions: {
    index: Path.permissions,
    roles: Path.roles,
    rolesCreate: Path.rolesCreate,
    apiKeys: Path.apiKeys,
    apiKeysCreate: Path.apiKeysCreate,
    users: Path.users,
  },
};
