/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Channel } from "~/generated/graphql";
import { NoData } from "~/components/NoData";
import { TableWrapper } from "~/components/Tables";
import { isEmpty, isNotNil } from "~/tools";
import {
  ChannelsTypes,
  getEmailsChannels,
} from "~/views/notificationGroups/components/helpers";
import { useNotificationGroup } from "~/views/notificationGroups/notificationGroupDetails/NotificationGroupProvider";
import { columns } from "./EmailsTableColumns";

export function EmailsTable() {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data } = useNotificationGroup();
  if (data === undefined) {
    return <NoData message="no data" />;
  }

  const { emailChannels } = getEmailsChannels(data?.channels ?? []);

  const emailData = emailChannels.map((channel) => ({
    email:
      channel.typeProperties.__typename === ChannelsTypes.EMAIL
        ? channel.typeProperties.email
        : "",
    id: channel.id,
  }));

  return (
    <If condition={isEmpty(emailChannels)}>
      <Then>
        <NoData hasIcon message={"There are no Emails."} />
      </Then>
      <Else>
        <TableWrapper<Partial<Channel>>
          loading={false}
          data={emailData.filter(isNotNil)}
          columns={columns()}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          stripped
        />
      </Else>
    </If>
  );
}
