/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller } from "react-hook-form";
import { FlexContainer, Radio, Text, theme } from "@nordcloud/gnui";
import { RadioLabel } from "~/components/Styled";
import { scheduleTypeOptions } from "~/views/plans/constants";
import { ScheduleType } from "~/views/plans/types";

type Props = {
  activeScheduleType: ScheduleType;
  onScheduleTypeChange: (type: ScheduleType) => void;
};

export function SelectSchedule({
  activeScheduleType,
  onScheduleTypeChange,
}: Props) {
  return (
    <>
      <FlexContainer
        justifyContent="space-between"
        alignItems="flex-start"
        mb={theme.spacing.spacing03}
      >
        {scheduleTypeOptions.map(({ name, label, value }) => (
          <ScheduleTypeRadioCheckbox
            key={name}
            name={name}
            label={label}
            scheduleType={value}
            onScheduleTypeChange={onScheduleTypeChange}
            activeScheduleType={activeScheduleType}
          />
        ))}
      </FlexContainer>
    </>
  );
}

type RadioCheckboxProps = {
  name: string;
  label: string;
  scheduleType: ScheduleType;
  onScheduleTypeChange: (type: ScheduleType) => void;
  activeScheduleType: ScheduleType;
};

export function ScheduleTypeRadioCheckbox({
  name,
  label,
  scheduleType,
  onScheduleTypeChange,
  activeScheduleType,
}: RadioCheckboxProps) {
  return (
    <div
      css={{
        width: "50%",
      }}
    >
      <RadioLabel htmlFor={name}>
        <FlexContainer direction="column">
          <Controller
            name="scheduleType"
            render={({ field: { onChange } }) => {
              return (
                <Radio
                  id={name}
                  labelText={name}
                  value={scheduleType}
                  checked={activeScheduleType === scheduleType}
                  onChange={(e) => {
                    onScheduleTypeChange(scheduleType);
                    onChange(e.target.value);
                  }}
                />
              );
            }}
          />
          <Text
            tag="span"
            size="sm"
            ml="1.75rem"
            css={{
              marginTop: `-${theme.spacing.spacing03}`,
            }}
          >
            {label}
          </Text>
        </FlexContainer>
      </RadioLabel>
    </div>
  );
}
