/**
 * Copyright 2022 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, FlexContainer } from "@nordcloud/gnui";
import { CloudAccountsSortFields, SortOrder } from "~/generated/graphql";
import { SortSelector } from "~/components/SortSelector";
import { useQueryState } from "~/hooks";
import { QuerySort } from "../types/QuerySort";
import { Actions } from "./Actions";

const sortOptions = [
  {
    value: CloudAccountsSortFields.Name,
    label: "Account Name",
  },
  {
    value: CloudAccountsSortFields.CreatedAt,
    label: "Date Added",
  },
  {
    value: CloudAccountsSortFields.UpdatedAt,
    label: "Date Updated",
  },
  {
    value: CloudAccountsSortFields.Status,
    label: "Status",
  },
  {
    value: CloudAccountsSortFields.Provider,
    label: "Cloud Provider",
  },
];

type Props = {
  cloudAccountsIds: string[];
};

export function CloudAccountListHeader({ cloudAccountsIds }: Props) {
  const { queryState, updateQueryState } = useQueryState<QuerySort>();

  const handleSortDirection = (order: SortOrder) => {
    updateQueryState({
      ...queryState,
      order,
    });
  };

  const handleSortField = (field: CloudAccountsSortFields) => {
    updateQueryState({
      ...queryState,
      field,
    });
  };

  return (
    <Box boxStyle="lightGrey" spacing="spacing04">
      <FlexContainer justifyContent="space-between">
        <SortSelector<CloudAccountsSortFields>
          options={sortOptions}
          value={queryState.field ?? CloudAccountsSortFields.Name}
          direction={queryState.order ?? SortOrder.Asc}
          onSelect={handleSortField}
          onSortChange={handleSortDirection}
        />
        <Actions size="large" {...{ cloudAccountsIds }} />
      </FlexContainer>
    </Box>
  );
}
